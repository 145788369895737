import React, { useCallback, useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import { SegmentEntity } from "../../../../shared/data/taskmanagement/Segment";
import EQMarker from "../../../../components/GoogleMap/EQMarker";

interface SegmentMapProps {
  initialLocations?: SegmentLocation[];
  segment?: SegmentEntity;
  centerLat?: number;
  centerLng?: number;
  onSegmentLocationsChanged?: (segmentLocations: SegmentLocation[]) => void;
}

interface SegmentLocation {
  lat?: number;
  lng?: number;
}

export const SegmentMap = ({
  initialLocations,
  centerLat = 0.0,
  centerLng = 0.0,
  onSegmentLocationsChanged = () => {},
}: SegmentMapProps) => {
  const [mapState, setMapState] = useState({
    zoom: 19,
    center: {
      lat: centerLat,
      lng: centerLng,
    },
    draggable: true,
    editable: true,
  });

  const [segmentLocation, setSegmentLocation] = useState<SegmentLocation[]>(
    () => {
      return initialLocations ?? [];
    }
  );

  useEffect(() => {
    onSegmentLocationsChanged(segmentLocation);
  }, [segmentLocation]);

  const changePinLocation = (childKey: number, childProps: any, mouse: any) => {
    if (mapState.editable) {
      setMapState((previousState) => {
        return { ...previousState, draggable: false };
      });

      setSegmentLocation((previousState) => {
        previousState[childKey] = {
          lat: mouse.lat,
          lng: mouse.lng,
        };
        return [...previousState];
      });
    }
  };

  const addLocation = (lat: number, lng: number) => {
    const locations = segmentLocation;
    if (locations.length < 2) {
      locations.push({ lat: lat, lng: lng });
    } else {
      locations[1] = { lat: lat, lng: lng };
    }

    setSegmentLocation(() => {
      return [...locations];
    });
  };

  function fitBounds(map: any, maps: any) {
    if (segmentLocation && segmentLocation.length > 0) {
      const bounds = new maps.LatLngBounds();
      segmentLocation.forEach((location) => {
        bounds.extend(new maps.LatLng(location.lat, location.lng));
      });

      map.fitBounds(bounds);
      maps.event.addDomListenerOnce(map, "idle", () => {
        maps.event.addDomListener(map, maps, "resize", () => {
          map.fitBounds(bounds);
        });
      });
    }
  }

  return (
    <React.Fragment>
      <div className="mt-3 mb-3">
        <span className="font-weight-boldest">
          Drop a pin to map to add segment
        </span>
      </div>

      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          options={{
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            },
            mapTypeId: "satellite",
          }}
          draggable={mapState.draggable}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
          }}
          defaultZoom={mapState.zoom}
          center={mapState.center}
          onClick={(event) => {
            // addLocation(event.lat, event.lng);
          }}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            fitBounds(map, maps);
            map.addListener("click", (e: any) => {
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              addLocation(lat, lng);
            });
          }}
          onChildMouseDown={(index, props, mouse) => {
            //console.log(index);
          }}
          onChildMouseMove={changePinLocation}
          onChildMouseUp={(index, props, mouse) => {
            setMapState((previousState) => {
              return { ...previousState, draggable: true };
            });
          }}
          onChildClick={() => {
            console.log("on child click");
          }}
        >
          {segmentLocation.map((location, index) => {
            return (
              <EQMarker
                key={index}
                lat={location.lat}
                lng={location.lng}
                pinColor={"blue"}
                icon={"flaticon2-pin"}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default SegmentMap;
