import React from "react";
import WorkTimeEntity from "../../../shared/data/job/Job";

interface DailyCheckInExternalProps {
  externalWorkTimes: WorkTimeEntity[];
  startTime: string;
  endTime: string;
  onSelect: (data: WorkTimeEntity) => void;
}

const DailyCheckInExternal = ({
  externalWorkTimes = [],
  startTime,
  endTime,
  onSelect = () => {},
}: DailyCheckInExternalProps) => {
  return (
    <React.Fragment>
      <div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Select external crewmember
              </h5>
            </div>
            <div className="modal-body">
              <div className="mt-2 mb-2 card card-stretch p-2">
                {externalWorkTimes.map((workTime) => {
                  return (
                    <div
                      className="m-1 card card-custom d-flex flex-column pb-1"
                      onClick={() => {
                        onSelect(workTime);
                      }}
                    >
                      <div>{`${workTime.firstName} ${workTime.lastName}`}</div>
                      <div>{`Work time: ${workTime.startTime} - ${workTime.endTime}`}</div>
                      <div>{`Travel time: ${workTime.travelTime}`}</div>
                    </div>
                  );
                })}

                <button
                  onClick={() => {
                    const workTime = new WorkTimeEntity("", "");
                    workTime.startTime = startTime
                    workTime.endTime = endTime
                    workTime.isExternal = true;
                    onSelect(workTime);
                  }}
                  type="button"
                  className={"btn btn-primary font-weight-bold"}
                  data-dismiss="modal"
                >
                  Add new
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyCheckInExternal;
