import React from 'react';

const MultiSelect = (props) => {
    function isSelected(value) {
        const arr = props.field.value.split(',');
        return arr.includes(value);
    }

    return (
        <>
            {props.field.options.map(x => {
                return (
                    <label className="checkbox mb-2">
                        <input
                            type="checkbox"
                            value={x.value}
                            name={props.field.name}
                            checked={isSelected(x.value)}
                            onChange={props.handleChange} />
                        <span />&nbsp; {x.label}</label>
                );
            })}
        </>
    );
}

export default MultiSelect;