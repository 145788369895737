export class Location {
    lat: number;
    lng: number;
    address?: string | null = null

    constructor(lat: number, lng: number, address?: string | null) {
        this.lat = lat
        this.lng = lng
        this.address = address
    }
}