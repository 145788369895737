import React, { Component } from 'react';
import BasicForm from '../../components/Form/BasicForm';
import { addSoilCondition } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';
import { fieldsToObject, touchField, updateFormOnChange } from '../../shared/FormHelper';

class SoilConditionsNew extends Component {
    static contextType = AuthContext;
    state = {
        soilConditionForm: {
            name: 'add-soil-condition',
            isValid: true,
            submitErrorMessage: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'soilConditions',
                    value: 'Caliche',
                    defaultValue: 'None',
                    type: 'select',
                    label: 'Soil Conditions',
                    placeholder: 'Soil Conditions',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: false,
                    allowCustom: true,
                    custom: "Other",
                    customValue: "",
                    options: [
                        {
                            value: 'Caliche',
                            display: 'Caliche'
                        },
                        {
                            value: 'Clay',
                            display: 'Clay'
                        },
                        {
                            value: 'Cobbles',
                            display: 'Cobbles'
                        },
                        {
                            value: 'Dirt',
                            display: 'Dirt'
                        },
                        {
                            value: 'Gravel',
                            display: 'Gravel'
                        },
                        {
                            value: 'Hardpan',
                            display: 'Hardpan'
                        },
                        {
                            value: 'Sand',
                            display: 'Sand'
                        },
                        {
                            value: 'Sandstone',
                            display: 'Sandstone'
                        },
                        {
                            value: 'Shale',
                            display: 'Shale'
                        }
                    ],
                },
                {
                    name: 'depth',
                    value: 0,
                    dataType: 'float',
                    defaultValue: '',
                    type: 'number',
                    label: 'Depth',
                    placeholder: 'Depth',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                },
                {
                    name: 'location',
                    value: {
                        lat:40.7127753,
                        long: -74.0059728
                    },
                    defaultValue: '',
                    type: 'location',
                    label: 'Location',
                    placeholder: 'Location',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "The location for this soil condition.",
                    onSuggest: (e) => this.onSuggestSelect(e),
                    setLoc: (e) => this.setLocation(e),
                },
                {
                    name: 'publicEntry',
                    value: false,
                    defaultValue: false,
                    type: 'checkbox',
                    label: 'Public Entry',
                    placeholder: 'Public Entry',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "Make soil condition for this location public to Equappment community. Your personal data will not be shared.",
                    required: false
                },
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addSoilCondit(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    }
    addSoilCondit = async () => {
        const currentUser = this.context.currentUser;
        
        const form = { ...this.state.soilConditionForm };
        form.saving = true;
        this.setState({ soilConditionForm: form });
        const soilCondition = fieldsToObject(this.state.soilConditionForm.fields);
        soilCondition.boreId="";
        soilCondition.userId = currentUser.uid;
        soilCondition.lat = soilCondition.location.lat;
        soilCondition.long = soilCondition.location.long;
        soilCondition.companyId = currentUser.userProfile.companyID;
        soilCondition.createdAt = new Date();
        soilCondition.validatedBy = [currentUser.uid];

        delete soilCondition.location;
        const soilConditionResult = await addSoilCondition(soilCondition);
        const id = soilConditionResult.data;
        
        form.saving= false;
        this.setState({ soilConditionForm: form });
        this.props.history.goBack()
        // this.props.history.push('/soilconditions/' + id);
    }
    
    prepareAddSoilConditionForm = () => {
        let form = this.state.soilConditionForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.soilConditionForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.soilConditionForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }
    onSuggestSelect = (suggest) => {
        let form = this.state.soilConditionForm;
        let fields = [...form.fields];

        let locationField = fields.find(x => x.name === 'location');

        if(suggest && suggest.location) {
            locationField.value = {
                lat: suggest.location.lat,
                long: suggest.location.lng
            }
            this.setState({ soilConditionForm: form });
        }
    } 
    setLocation = (coords) => {
        
        let form = this.state.soilConditionForm;
        let fields = [...form.fields];
        
        let locationField = fields.find(x => x.name === 'location');
        
        locationField.value = {
            lat: coords.lat,
            long: coords.lng
        }
        
        this.setState({ soilConditionForm: form });
    }

    render(){
        const addSoilConditionForm = this.prepareAddSoilConditionForm();
        
        return(
            <div className="card">
                    <div className="card-body">
                        <h3 className="mb-10 font-weight-bold text-dark">New Soil Condition</h3>
                        <div className="mb-20">
                            <BasicForm {...addSoilConditionForm} />
                        </div>
                    </div>
                </div>
        )
    }
}

export default SoilConditionsNew;