import React, { Component } from 'react';
import { db, storage } from '../../firebase';
import { AuthContext } from "../../shared/Auth";
import DefaultList from '../../components/Lists/DefaultList'
import Loader from '../../components/Layout/Loader/Loader';

class ActiveUsers extends Component {
   static contextType = AuthContext;

   state={

      data: null,
      currentPage: 1,
      postsPerPage: 10,
  };

  componentDidMount() {
   this.loadActiveUsers();
 }

 loadActiveUsers = async () => {
   const currentUser = this.context.currentUser;
   const inventoryId = currentUser.company.inventoryID;

   const snapshot = await db.collection("users")
     .get();

   const data = await Promise.all(snapshot.docs.map(async(doc) => {
     let dat = doc.data();
     dat.id = doc.id;

     return dat;
   }));

   let filteredData=[];
   
  //  if (currentUser.userProfile.permissions==="administrator") {
  //   filteredData=data.filter(dat=>currentUser.uid!==dat.id)
  //   this.setState({ data: filteredData });
  //  } else {
  //    filteredData = data.filter(dat => currentUser.userProfile.companyID===dat.companyID && currentUser.uid!==dat.id)
  //    this.setState({ data: filteredData })
  //  }
  filteredData = data.filter(dat => currentUser.userProfile.companyID===dat.companyID && currentUser.uid!==dat.id)
     this.setState({ data: filteredData })
 }

 //Pagination try
paginate=(pageNumber) => {
  this.setState({currentPage: pageNumber});
}
 


 render(){
  const currentUser = this.context.currentUser;
  if(!this.state.data) {
    return <Loader/>
  }
    return (
      <React.Fragment>
        <DefaultList
        data={this.state.data}
        currentPage={this.state.currentPage}
        postsPerPage={this.state.postsPerPage}
        paginate={this.paginate}
        mode="default"
        currentUser={currentUser}
        {...this.props}
       />
      </React.Fragment>
    )
 }

}

export default ActiveUsers;