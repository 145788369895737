import React, { useContext, useEffect, useRef, useState } from "react";
import { functions } from "../../firebase";
import { showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";
import { CrewMemberEntity } from "../../shared/data/Crew";
import ReportComponent, { ReportRecipient } from "./ReportComponent";

interface ScheduleServiceReportProps {
  reservationId: string;
  crewMembers: CrewMemberEntity[];
  showButton: boolean;
  performClick: boolean;
  onReportSent: ()=>void
}

const ScheduleServiceReport = ({
  reservationId,
  crewMembers,
  showButton = true,
  performClick = false,
  onReportSent = () =>{}
}: ScheduleServiceReportProps) => {
  const authContext = useContext(AuthContext);

  const [sending, setSending] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const sendScheduleServiceReport = async (emailsToSend: string[]) => {
    if (!sending) {
      setSending(true);
      let sendJobReportFunc = functions.httpsCallable("sendEquipmentReport");

      await sendJobReportFunc({
        reservationID: reservationId,
        userID: authContext.currentUser.uid,
        recipients: emailsToSend.join(","),
      })
        .then((result) => {
          showToast("success", "Report has been sent successfully.");
          onReportSent()
        })
        .catch((exception: any) => {
          showToast("danger", "Error sending report.");
        });
      setSending(false);
    }
  };

  const getDialogItems = () => {
    const user = authContext.currentUser;

    const crewMemberItems: ReportRecipient[] =
      crewMembers
        ?.filter((crewMember) => crewMember.id != null)
        .map((crewMember) => {
          return new ReportRecipient(
            crewMember.id ?? "",
            `${crewMember.firstName} ${crewMember.lastName}`,
            crewMember.email ?? "Unknown email"
          );
        }) ?? [];

    const items: ReportRecipient[] = [
      new ReportRecipient(
        user.uid,
        user.displayName ?? "Unknown",
        user.email,
        true,
        true
      ),
    ].concat(crewMemberItems);

    return items;
  };

  useEffect(() => {
    if(!showButton && performClick && !sending){
      setShowModal(true)
    }
  }, [performClick])

  return (
    <React.Fragment>
      <div className="float-right">
        {showButton && (
          <button
            id="schedule-service-report-btn"
            data-toggle="tooltip"
            onClick={() => {
              if (!sending) setShowModal(true);
            }}
            className={
              sending
                ? "btn btn-primary font-weight-bold mr-4 w-100 spinner spinner-white spinner-right"
                : "btn btn-primary font-weight-bold mr-4 w-100"
            }
          >
            Share Service Request
          </button>
        )}
      </div>
      <ReportComponent
        id="schedule-service-report"
        title="Share service report"
        showDialog={showModal}
        reportRecipients={getDialogItems()}
        onDismiss={() => {
          setShowModal(false);
          onReportSent()
        }}
        sendReport={(recipients) => {
          setSending(true);
          setShowModal(false);
          sendScheduleServiceReport(recipients.map((recipients) => recipients.email));
        }}
      />
    </React.Fragment>
  );
};

export default ScheduleServiceReport;
