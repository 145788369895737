import moment from "moment/moment";
import Select from "react-select";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../shared/Auth";
import { getNextTaskId, saveTask } from "../../../../shared/api/TaskManagerApi";
import TaskProduct from "./TaskProduct";
import {
  CustomTaskFieldEntity,
  GroundConditions,
  TaskEntity,
  TaskPriority,
  TaskProductEntity,
  TaskType,
  TaskTypeEntity,
  showCustomFields,
  showFootage,
  showGroundConditions,
  showProduct,
  showQuantity,
  showTaskName,
  showYards,
} from "../../../../shared/data/taskmanagement/Task";
import { parseFromFirebaseDate } from "../../../../shared/Util";
import { Timestamp } from "firebase/firestore";
import TaskCustomFieldComponent from "./TaskCustomFieldComponent";
import { CrewEntity, CrewMemberEntity } from "../../../../shared/data/Crew";
import { EquipmentEntity } from "../../../../shared/data/equipment/Equipment";
import { useHistory } from "react-router-dom";
import {
  getJobAssignedCrewMembers,
  getJobAssignedCrews,
} from "../../../../shared/api/CrewApi";
import { getJobAssignedEquipment } from "../../../../shared/api/EquipmentApi";
import TaskNewMap from "./TaskNewMap";
import { Location } from "../../../../shared/data/Location";
import {
  getJobTaskTypes,
  saveCompanyTaskType,
} from "../../../../shared/api/SettingsApi";

interface TaskProps {
  taskDateMin?: Timestamp;
  taskDateMax?: Timestamp;
  task: TaskEntity;
  segmentStartLocation?: Location | null;
  segmentEndLocation?: Location | null;
  segmentTasksLocations?: Location[] | null;
}

interface Workers {
  crews?: CrewEntity[];
  crewMembers?: CrewMemberEntity[];
  equipment?: EquipmentEntity[];
}

const TaskNew = ({
  taskDateMin,
  taskDateMax,
  task,
  segmentStartLocation,
  segmentEndLocation,
  segmentTasksLocations,
}: TaskProps) => {
  const context = useContext(AuthContext);
  const history = useHistory();

  const save = async () => {
    const currentUser = context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    await saveTask(inventoryId, { ...taskState, taskType: taskState.title });
    if (taskState.title)
      await saveCompanyTaskType(inventoryId, taskState.title);
    history.goBack();
  };

  const [taskState, setTaskState] = useState<TaskEntity>(task);
  const [workersState, setWorkersState] = useState<Workers>();

  const [taskTypes, setTaskTypes] = useState<TaskTypeEntity[]>([]);

  useEffect(() => {
    const currentUser = context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    async function fetchData() {
      const crews: any = await getJobAssignedCrews(
        inventoryId,
        taskState?.jobID ?? ""
      );
      const crewMembers: any = await getJobAssignedCrewMembers(
        inventoryId,
        taskState?.jobID ?? ""
      );
      const equipments: any = await getJobAssignedEquipment(
        inventoryId,
        taskState?.jobID ?? ""
      );
      const taskTypesResponse = await getJobTaskTypes(inventoryId);
      const taskTypes = [
        ...(taskTypesResponse.data ?? []),
        new TaskTypeEntity("", "Custom", false),
      ];

      if(!task.taskID){
        const nextTaskId = await getNextTaskId(inventoryId, task.jobID)
        setTaskState((previousState)=>({...previousState,  taskID:nextTaskId.data}))
      }

      setTaskTypes(taskTypes);

      setWorkersState(() => {
        return {
          crews: crews.data,
          crewMembers: crewMembers.data,
          equipment: equipments.data,
        };
      });
    }

    fetchData();
  }, []);

  const isFormValid = () => {
    if (
      taskState.estimatedDuration &&
      taskState.taskType &&
      taskState.lat &&
      taskState.lng
    )
      return true;
    else return false;
  };

  const changeTaskPriority = (event: any) => {
    setTaskState((previousState) => {
      return { ...previousState, priority: event.target.value };
    });
  };

  const changeTaskType = (event: any) => {
    let taskName: string = event.target.value;
    if (event.target.value == TaskType.custom) taskName = task?.title ?? "";
    setTaskState((previousState) => {
      return {
        ...previousState,
        taskType: event.target.value,
        title: taskName,
      };
    });
  };

  const changeGroundConditions = (event: any) => {
    setTaskState((previousState) => {
      return { ...previousState, groundConditions: event.target.value };
    });
  };

  const crewsSelectionChanged = (
    checked: boolean,
    selectedCrew: CrewEntity
  ) => {
    const selectedCrewmembers = workersState?.crewMembers?.reduce(
      (results: string[], item) => {
        if (item?.id && item?.crewID == selectedCrew.id) results.push(item.id);
        return results;
      },
      []
    );
    const selectedEquipments = workersState?.equipment?.reduce(
      (results: string[], item) => {
        if (item?.id && item?.crewID == selectedCrew.id) results.push(item.id);
        return results;
      },
      []
    );

    if (checked) {
      selectedCrewmembers?.push(...(taskState.crewmembers ?? []));
      selectedEquipments?.push(...(taskState.equipments ?? []));
      setTaskState((previousState) => {
        if (selectedCrew.id)
          return {
            ...previousState,
            crews: [selectedCrew.id, ...(previousState?.crews ?? [])],
            crewmembers: selectedCrewmembers,
            equipments: selectedEquipments,
          };
        else return previousState;
      });
    } else {
      const clearedCrews = taskState?.crews?.filter(
        (crew) => crew != selectedCrew.id
      );

      const clearedCrewmembers = taskState?.crewmembers?.filter(
        (crewmember) => {
          return !selectedCrewmembers?.includes(crewmember);
        }
      );

      const clearedEquipments = taskState?.equipments?.filter((equipment) => {
        return !selectedEquipments?.includes(equipment);
      });

      setTaskState((previousState) => {
        if (selectedCrew.id)
          return {
            ...previousState,
            crews: clearedCrews,
            crewmembers: clearedCrewmembers,
            equipments: clearedEquipments,
          };
        else return previousState;
      });
    }
  };

  const getCurrentTaskLocation = () => {
    if (taskState.lat && taskState.lng)
      return new Location(taskState.lat, taskState.lng);
    else return null;
  };

  const options = taskTypes.map((item) => ({
    value: item.name,
    label: item.isCustom ? `*${item.name}` : item.name,
  }));

  const handleChange = (selectedOption:any) => {
    changeTaskType({ target: { value: selectedOption.value } });
  };

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div key="taskMap" className="form-group">
            <label>
              Task Location<span className="text-danger">*</span>
            </label>
            <TaskNewMap
              taskCurrentLocation={getCurrentTaskLocation()}
              segmentLocationStart={segmentStartLocation}
              segmentLocationEnd={segmentEndLocation}
              segmentTasksLocations={segmentTasksLocations}
              onLocationAdded={(location) => {
                setTaskState((previousValue) => {
                  return {
                    ...previousValue,
                    lat: location.lat,
                    lng: location.lng,
                    address: location?.address ?? "",
                  };
                });
              }}
            />
          </div>
          <Select
            value={options.find(
              (option) => option.value === taskState.taskType
            )}
            className="mb-4"
            placeholder={"Select task type"}
            onChange={handleChange}
            options={options}
          />
          {/* <div key="task-new-task-type" className="form-group">
            <label>Select task type</label>
            <select
              key="task-new-task-type-select"
              className="form-control"
              onChange={changeTaskType}
            >
              <option value="" disabled selected>
                Select task type
              </option>
              {taskTypes.map((item, index) => {
                return (
                  <option
                    key={"task type " + index}
                    value={item.name}
                    className="form-control"
                    selected={item.name == taskState.taskType}
                  >
                    {item.isCustom ? "*" + item.name : item.name}
                  </option>
                );
              })}
              ;
            </select>
          </div> */}
          <div key="task-new-task-id" className="form-group">
            <label>Task ID</label>
            <input
              type="text"
              className="form-control"
              value={taskState?.taskID ?? ""}
              onChange={(input) => {
                setTaskState((previousValue) => {
                  return { ...previousValue, taskID: input.target.value };
                });
              }}
            ></input>
            <div className="form-text text-muted">Enter task ID</div>
          </div>
          {showTaskName(taskState) && (
            <div key="task-new-task-name" className="form-group">
              <label>Task Name</label>
              <input
                type="text"
                className="form-control"
                value={taskState?.title ?? ""}
                onChange={(input) => {
                  setTaskState((previousValue) => {
                    return { ...previousValue, title: input.target.value };
                  });
                }}
              ></input>
            </div>
          )}
          <div key="task-new-size" className="form-group">
            <label>Task Details</label>
            <textarea
              className="form-control"
              rows={3}
              value={taskState?.notes ?? ""}
              onChange={(input) => {
                setTaskState((previousState) => {
                  return { ...previousState, notes: input.target.value };
                });
              }}
            ></textarea>
            <div className="form-text text-muted">Details about this task.</div>
          </div>
          <div key="task-new-start-date" className="form-group">
            <label>Estimated Start Date</label>
            <input
              type="date"
              className="form-control"
              value={parseFromFirebaseDate(task?.startDate, "YYYY-MM-DD")}
              min={parseFromFirebaseDate(taskDateMin, "YYYY-MM-DD")}
              max={parseFromFirebaseDate(taskDateMax, "YYYY-MM-DD")}
              onChange={(input) => {
                setTaskState((previousValue) => {
                  return {
                    ...previousValue,
                    startDate: Timestamp.fromDate(
                      moment(input.target.value).toDate()
                    ),
                  };
                });
              }}
            />
            <div className="form-text text-muted">Select start date</div>
          </div>
          <div key="task-new-end-date" className="form-group">
            <label>Estimated End Date</label>
            <input
              type="date"
              className="form-control"
              value={parseFromFirebaseDate(task?.endDate, "YYYY-MM-DD")}
              min={parseFromFirebaseDate(taskDateMin, "YYYY-MM-DD")}
              max={parseFromFirebaseDate(taskDateMax, "YYYY-MM-DD")}
              onChange={(input) => {
                setTaskState((previousValue) => {
                  return {
                    ...previousValue,
                    endDate: Timestamp.fromDate(
                      moment(input.target.value).toDate()
                    ),
                  };
                });
              }}
            />
            <div className="form-text text-muted">Select end date</div>
          </div>
          <div key="task-new-duration" className="form-group">
            <label>Estimated Duration</label>
            <input
              type="number"
              className="form-control"
              value={taskState.estimatedDuration?.toString()}
              onChange={(input) => {
                setTaskState((previousState) => {
                  return {
                    ...previousState,
                    estimatedDuration: parseInt(input.target.value) || null,
                  };
                });
              }}
            ></input>
            <div className="form-text text-muted">
              Estimated duration for this task in man hours
            </div>
          </div>
          {showFootage(taskState) && (
            <div key="task-new-footage" className="form-group">
              <label>Footage</label>
              <input
                type="number"
                className="form-control"
                value={taskState.expectedFootage?.toString()}
                onChange={(input) => {
                  setTaskState((previousState) => {
                    return {
                      ...previousState,
                      expectedFootage: parseInt(input.target.value) || null,
                    };
                  });
                }}
              ></input>
              <div className="form-text text-muted">
                The estimate footage for the task (ft)
              </div>
            </div>
          )}
          {showYards(taskState) && (
            <div key="task-new-filled-yards" className="form-group">
              <label>Filled yards</label>
              <input
                type="number"
                className="form-control"
                value={taskState.expectedFilledYards?.toString()}
                onChange={(input) => {
                  setTaskState((previousState) => {
                    return {
                      ...previousState,
                      expectedFilledYards: parseInt(input.target.value) || null,
                    };
                  });
                }}
              ></input>
              <div className="form-text text-muted">
                The estimate filled yards
              </div>
            </div>
          )}
          {showQuantity(taskState) && (
            <div key="task-new-quantity" className="form-group">
              <label>Quantity</label>
              <input
                type="number"
                className="form-control"
                value={taskState.expectedQuantity?.toString()}
                onChange={(input) => {
                  setTaskState((previousState) => {
                    return {
                      ...previousState,
                      expectedQuantity: parseInt(input.target.value) || null,
                    };
                  });
                }}
              ></input>
              <div className="form-text text-muted">The estimate quantity.</div>
            </div>
          )}
          <div key="task-new-task-priority" className="form-group">
            <label>Task Priority</label>
            <select
              key="task-new-task-priority-select"
              className="form-control"
              onChange={changeTaskPriority}
            >
              {Object.entries(TaskPriority).map((item, index) => {
                return (
                  <option
                    key={"task type " + index}
                    value={item[0]}
                    className="form-control"
                    selected={item[0] == taskState.priority}
                  >
                    {item[1]}
                  </option>
                );
              })}
              ;
            </select>
          </div>
          {showGroundConditions(taskState) && (
            <div key="task-new-ground-conditions" className="form-group">
              <label>Ground Condition</label>
              <select
                key="task-new-task-type-select"
                className="form-control"
                onChange={changeGroundConditions}
              >
                <option value="" disabled selected>
                  Select ground condition
                </option>
                {Object.entries(GroundConditions).map((item, index) => {
                  return (
                    <option
                      key={"task type " + index}
                      value={item[0]}
                      className="form-control"
                      selected={item[0] == taskState.groundConditions}
                    >
                      {item[1]}
                    </option>
                  );
                })}
                ;
              </select>
            </div>
          )}
          {showProduct(taskState) && (
            <div
              key="task-new-products"
              className="form-group card card-border p-2"
            >
              {(taskState?.products?.length ?? 0) < 5 && (
                <div className="mb-5">
                  <div className="h4 mb-5">Products</div>
                  <a
                    role="button"
                    onClick={() => {
                      setTaskState((previousState) => {
                        const products: TaskProductEntity[] = [
                          ...(previousState?.products ?? []),
                        ];
                        products.push(new TaskProductEntity());
                        return { ...previousState, products: products };
                      });
                    }}
                    className="navi-link"
                  >
                    Add product
                  </a>
                </div>
              )}
              {taskState?.products?.map((product, index) => {
                return (
                  <TaskProduct
                    title={`Product #${index + 1}`}
                    productType={product}
                    onChange={(value: TaskProductEntity) => {
                      setTaskState((previousState) => {
                        const newProducts = [
                          ...(previousState?.products ?? []),
                        ];
                        newProducts[index] = value;
                        return { ...previousState, products: newProducts };
                      });
                    }}
                    onDelete={(product) => {
                      const itemIndex =
                        task.products?.findIndex((taskProduct) => {
                          return (
                            taskProduct.type == product.type &&
                            taskProduct.quantity == product.quantity &&
                            taskProduct.size == product.size
                          );
                        }) ?? -1;
                      const taskProducts = [...(task.products ?? [])];
                      if (itemIndex > -1 && taskProducts.length > 0) {
                        taskProducts.splice(itemIndex, 1);
                        setTaskState((previousState) => {
                          return { ...previousState, products: taskProducts };
                        });
                      }
                    }}
                  />
                );
              })}
            </div>
          )}
          {showCustomFields(taskState) && (
            <div
              key="task-new-custom-fields"
              className="form-group card card-border p-2"
            >
              {(taskState?.customFields?.length ?? 0) < 5 && (
                <div className="mb-5">
                  <div className="h4 mb-5">Custom fields</div>
                  <a
                    role="button"
                    onClick={() => {
                      setTaskState((previousState) => {
                        const customFields: CustomTaskFieldEntity[] = [
                          ...(previousState?.customFields ?? []),
                        ];
                        customFields.push(new CustomTaskFieldEntity());
                        return { ...previousState, customFields: customFields };
                      });
                    }}
                    className="mb-10 navi-link"
                  >
                    Add custom field
                  </a>
                </div>
              )}
              {taskState?.customFields?.map((customField, index) => {
                return (
                  <TaskCustomFieldComponent
                    customField={customField}
                    onChanged={(result) => {
                      setTaskState((previousState) => {
                        const customFields = [
                          ...(previousState?.customFields ?? []),
                        ];
                        customFields[index] = result;
                        return { ...previousState, customFields: customFields };
                      });
                    }}
                  />
                );
              })}
            </div>
          )}
          <div className="d-flex flex-column-fluid">
            <div className="container">
              Crews
              <div
                style={{ height: "400px", overflowY: "scroll" }}
                className="card card-border p-2 mr-2"
              >
                {workersState?.crews?.map((crew, index) => {
                  return (
                    <label>
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(event) => {
                          crewsSelectionChanged(
                            event.currentTarget.checked,
                            crew
                          );
                        }}
                        checked={
                          taskState.crews?.some(
                            (crewId) => crewId == crew.id
                          ) ?? false
                        }
                      />
                      {crew.crewName}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="container">
              Crewmembers
              <div
                style={{ height: "400px", overflowY: "scroll" }}
                className="container card card-border p-2 mr-2"
              >
                {workersState?.crewMembers?.map((crewmember, index) => {
                  return (
                    <label>
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(event) => {
                          setTaskState((previousValue) => {
                            let crewmembers: string[];
                            if (event.currentTarget.checked) {
                              crewmembers = [
                                ...(previousValue?.crewmembers ?? []),
                              ];
                              if (crewmember.id)
                                crewmembers.push(crewmember.id);
                            } else {
                              crewmembers =
                                taskState?.crewmembers?.filter((result) => {
                                  return result != crewmember?.id;
                                }) ?? [];
                            }
                            return {
                              ...previousValue,
                              crewmembers: crewmembers,
                            };
                          });
                        }}
                        checked={
                          taskState.crewmembers?.some(
                            (crewmemberId) => crewmemberId == crewmember.id
                          ) ?? false
                        }
                      />
                      {`${crewmember.firstName} ${crewmember.lastName}`}
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="container">
              Equipment
              <div
                style={{ height: "400px", overflowY: "scroll" }}
                className="container card card-border p-2"
              >
                {workersState?.equipment?.map((equipment, index) => {
                  return (
                    <label>
                      <input
                        type="checkbox"
                        className="mr-2"
                        onChange={(event) => {
                          setTaskState((previousValue) => {
                            let equipments: string[];
                            if (event.currentTarget.checked) {
                              equipments = [
                                ...(previousValue?.equipments ?? []),
                              ];
                              if (equipment.id) equipments.push(equipment.id);
                            } else {
                              equipments =
                                taskState?.equipments?.filter((result) => {
                                  return result != equipment?.id;
                                }) ?? [];
                            }
                            return { ...previousValue, equipments: equipments };
                          });
                        }}
                        checked={
                          taskState.equipments?.some(
                            (equipmentId) => equipmentId == equipment.id
                          ) ?? false
                        }
                      />
                      {`${equipment.make}-${equipment.model}`}
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="justify-content-between border-top mt-5 pt-10">
            <button
              key="segment-new-btn-save"
              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
              disabled={!isFormValid()}
              onClick={(event) => {
                event.preventDefault();
                save();
              }}
            >
              Save
            </button>
            <button
              onClick={() => {
                history.goBack();
              }}
              className="btn btn-secondary font-weight-bold text-uppercase px-7 py-4"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaskNew;
