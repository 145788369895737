import React, { Component } from 'react';
import app, { db } from '../../firebase';
import * as moment from 'moment';

import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';

import { Link } from 'react-router-dom';

import { storage } from '../../firebase';
import { CheckIcon, Edit, Trash, UpdateIcon } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';
import { showToast } from '../../shared/Util';

class JobEquipmentDetails extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';

    state = {
        equipment: null,
        appointment: null,
        deleteEquipmentModalActive: false,
        startDate: null,
        endDate: null,
        allAppointments: [],
        updating: false,
        removing: false,
        reserving: false,
        canAdd: true,
    }

    componentDidMount = async () => {
        this.loadData();
    }

    loadData = async () => {
        const jobId = this.props.match.params.id;
        const equipmentId = this.props.match.params.equipmentId;
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        let job = await db.collection("inventories")
            .doc(inventoryId)
            .collection("jobs")
            .doc(jobId)
            .get();
        job = job.data();
        job.id = jobId;

        let result = await db.collection("inventories")
            .doc(inventoryId)
            .collection("equipment")
            .doc(equipmentId)
            .get();

        let obj = result.data();
        obj.id = result.id;

        if (obj.hasProfileImage && obj.imageTimeStamp) {
            const storageRef = storage.ref('/' + currentUser.userProfile.companyID + '/equipment/' + obj.id + '/' + obj.imageTimeStamp.seconds + '/');
            const url = storageRef.child('med').getDownloadURL()
                .then((url) => {
                    obj.image = url;
                    this.setState({ equipment: obj });
                })
                .catch(err => {
                    this.setState({ equipment: obj });
                });
        } else {
            this.setState({ equipment: obj });
        }

        result = await db.collection("inventories")
            .doc(inventoryId)
            .collection("reservations")
            .where("jobID", "==", jobId)
            .where("equipmentID", "==", equipmentId)
            .get();

        const datacrewSchedules = await Promise.all(result.docs.map(async (doc) => {
            let dat = doc.data();
            dat.id = doc.id;
            return dat;
        }));


        let allAppointments = await db.collection("inventories")
            .doc(inventoryId)
            .collection("reservations")
            .where("equipmentID", "==", equipmentId)
            .get();
        allAppointments = await Promise.all(allAppointments.docs.map(async (doc) => {
            let dat = doc.data();
            dat.id = doc.id;
            return dat;
        }));
        const appointment = datacrewSchedules && datacrewSchedules.length > 0 ? datacrewSchedules[0] : null;
        const sd =  moment(this.getDatesForDatepicker(job, allAppointments, "start", appointment));
        const ed =  moment(this.getDatesForDatepicker(job, allAppointments, "end", appointment));
        
        this.setState({ startDate: sd, endDate: ed });

        this.setState({ appointment: appointment, equipment: obj, allAppointments: allAppointments });
        this.setDates(appointment, job, allAppointments);
    }

    setDates = (appointment, job, allAppointments) => {
        const self = this;
        const id = "daterangepicker_appointment_dates";
        window.$("#" + id).daterangepicker({
            buttonClasses: ' btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            startDate:  this.state.startDate,
            endDate: this.state.endDate,
            minDate: job.startDate.toDate(),
            maxDate: job.endDate.toDate(),
            isInvalidDate: (date) => {
                let invalid = false;
                this.state.allAppointments.forEach(x => {
                    if (x.jobID !== job.id) {
                        let tmpInvalid = (moment(date).isBetween(moment(x.startDate.toDate()), moment(x.endDate.toDate()), undefined, '[]'));
                        if (tmpInvalid) invalid = true;
                    }
                });

                return invalid;
            }
        }, function (start, end, label) {
            self.setState({ startDate: moment(start), endDate: moment(end) });
        });
    }


    getDatesForDatepicker = (job, allAppointments, dateType, appointment) => {
        
        if(appointment && dateType === "start" ) {
            return appointment.startDate.toDate()
        }
        if(appointment && dateType === "end" ) {
            return appointment.endDate.toDate()
        }

        const jobDates = this.getJobDates(job);
        let availableDates = [];
        
        if(!allAppointments || allAppointments.length === 0 && dateType === "start") {
            return job.startDate.toDate();
        }
        
        if(!allAppointments || allAppointments.length === 0 && dateType === "end") {
            return job.endDate.toDate();
        }

        jobDates.forEach(date => {
            let isValid = true;
            allAppointments.forEach(x => {
                if(x.jobID !== job.id) {
                    let tmpValid = !(moment(date).isBetween(moment(x.startDate.toDate()), moment(x.endDate.toDate()), undefined, '[]'))
                    if (!tmpValid) {
                        isValid = false
                    }
                } 
            });
            if(isValid) {
                availableDates.push(date)
            }
        });
        
        if(!availableDates || availableDates.length === 0 && dateType === "start") {
            this.setState({ canAdd: false })
            return job.startDate.toDate();
        }
        if(!availableDates || availableDates.length === 0 && dateType === "end") {
            return job.endDate.toDate();
        }

       let indexToFind = this.getLastConsecutiveIndex(availableDates);

       if(dateType === "start") {
            return availableDates[0];
        }

        if(dateType === "end"){
            return availableDates[indexToFind];
        }
    }

    getLastConsecutiveIndex = (arr) => {
        
        let index = arr.length -1;

        if(arr.length === 1) {
            return arr.length -1;
        }
        
        for(let i = 1; i < arr.length; i++) {
            if(moment(arr[i]).diff(moment(arr[i-1]), 'days') > 1) {
                index = i-1;
                break;
            }
        }
        return index;
    }

    getJobDates = (job) => {
        let startDate = job.startDate.toDate();
        let endDate =  job.endDate.toDate();
        let dateArray = [];
    
        while (startDate <= endDate) {
            dateArray.push( startDate)
            startDate = moment(startDate).add(1, 'd').toDate();
        }
        return dateArray;
    }

    deleteEquipment = async () => {
        this.setState({ deleteEquipmentModalActive: false });
        this.setState({ removing: true });
        const id = this.state.equipment.id;

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        await db.collection("inventories")
            .doc(inventoryId)
            .collection("reservations")
            .doc(this.state.appointment.id)
            .delete();

        this.setState({ removing: false });
        this.props.history.goBack();
    }

    reserveEquipment = async () => {
        this.setState({ reserving: true });
        const jobId = this.props.match.params.id;
        const equipmentId = this.props.match.params.equipmentId;

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const obj = {
            equipmentID: equipmentId,
            jobID: jobId,
            type: 1,
            startDate: this.state.startDate.toDate(),
            endDate: this.state.endDate.toDate()
        }

        await db.collection("inventories")
            .doc(inventoryId)
            .collection("reservations")
            .add(obj);

        this.loadData();
        this.setState({ reserving: false });
    }

    updateReservation = async () => {
        this.setState({ updating: true });
        const jobId = this.props.match.params.id;
        const equipmentId = this.props.match.params.equipmentId;

        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        await db.collection("inventories")
            .doc(inventoryId)
            .collection("reservations")
            .doc(this.state.appointment.id)
            .update({
                startDate: this.state.startDate.toDate(),
                endDate: this.state.endDate.toDate()
            });

        this.loadData();
        this.setState({ updating: false });
        showToast('success', 'Reservation has been updated successfully.');

    }

    getReserveIconOrMessage = () => {
        if(this.state.canAdd) {
            return (
                <a onClick={this.reserveEquipment} className={this.state.reserving ? "btn btn-success btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-success btn-sm font-weight-bold mr-4"}>
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <CheckIcon />
                 </span>Reserve</a>
            )
        }
    }

    getCalendar = () => {
        if(this.state.canAdd) {
            return (
                <div className="form-group row my-2">
                    <label className="col-4 col-form-label">Equipment appointment dates:</label>
                    <div className="col-8">
                        <input type='text'
                        className="form-control"
                        id="daterangepicker_appointment_dates"
                        readOnly
                        placeholder="Select dates" />
                    </div>
                </div>
            )
        }
    }

    render() {
        if (this.state.equipment) {
            return (
                <React.Fragment>
                    <div className="card card-custom">
                        {/*begin::Header*/}
                        <div className="card-header h-auto py-4">
                            <div className="card-title">
                                <h3 className="card-label">Equipment details
                                <span className="d-block text-muted pt-2 font-size-sm">{this.state.equipment.make} - {this.state.equipment.model}</span></h3>
                            </div>
                            <div className="card-toolbar">
                                {this.canEdit &&
                                    <Link to={"/equipment/" + this.state.equipment.id + "/edit"} className="btn btn-success btn-sm font-weight-bold mr-4">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                    <Edit/>
                                </span>Edit</Link>}
                            </div>
                        </div>
                        {/*end::Header*/}
                        {/*begin::Body*/}
                        <div className="card-body py-4">
                            {this.state.equipment.image ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label"></label>
                                    <div className="col-8">
                                        <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                                            <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + this.state.equipment.image + ')', backgroundSize: 'contain, cover', backgroundPosition: 'center', width: '160px', height: '160px' }} />
                                        </div>
                                    </div>
                                </div> : null}
                            {this.state.equipment.make ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Make:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.make}</span>
                                    </div>
                                </div> : null}
                            {this.state.equipment.model ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Model:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.model}</span>
                                    </div>
                                </div> : null}
                                {this.state.equipment.year ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Year:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.year}</span>
                                    </div>
                                </div> : null}
                            {this.state.equipment.equipmentID ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Id:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext">
                                            <span className="font-weight-bolder">{this.state.equipment.equipmentID}</span>
                                        </span>
                                    </div>
                                </div> : null}
                            {this.state.equipment.tag ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Tag:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.tag}</span>
                                    </div>
                                </div> : null}
                                {this.state.equipment.serialNumber ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Serial Number:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.serialNumber}</span>
                                    </div>
                                </div> : null}
                                {this.state.equipment.category ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Category:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.category}</span>
                                    </div>
                                </div> : null}
                                {this.state.equipment.subCategory ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Sub Category:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.subCategory}</span>
                                    </div>
                                </div> : null}
                            {this.state.equipment.description ?
                                <div className="form-group row my-2">
                                    <label className="col-4 col-form-label">Description:</label>
                                    <div className="col-8">
                                        <span className="form-control-plaintext font-weight-bolder">{this.state.equipment.description}</span>
                                    </div>
                                </div> : null}
                                {this.getCalendar()}
                                {!this.state.canAdd &&
                                <span className="text-warning">This piece of equipment is not available for this job.</span>
                            }
                        </div>
                        <div className="card-footer">
                            {this.canEdit &&
                                <>
                                    {this.state.appointment ?
                                        <>
                                            <a onClick={this.updateReservation} className={this.state.updating ? "btn btn-success btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-success btn-sm font-weight-bold mr-4"}>
                                            <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                <UpdateIcon/>
                                            </span>Update</a>
                                            <a onClick={() => { this.setState({ deleteEquipmentModalActive: true }) }} className={this.state.removing ? "btn btn-danger btn-sm font-weight-bold spinner spinner-white spinner-right" : "btn btn-danger btn-sm font-weight-bold"}>
                                            <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                                <Trash/>
                                            </span>Remove</a>
                                        </>
                                        :
                                        this.getReserveIconOrMessage()
                                    }
                                </>}
                        </div>
                    </div>
                    <ConfirmModal
                        show={this.state.deleteEquipmentModalActive}
                        title="Remove equipment"
                        body={getMessages('remove_equipment')}
                        yesButton={{
                            title: "Yes",
                            onClick: () => { this.deleteEquipment(); }
                        }}
                        noButton={{
                            title: "Cancel",
                            onClick: () => { this.setState({ deleteEquipmentModalActive: false }) }
                        }}
                    />
                </React.Fragment>
            );
        } else {
            return <Loader />
        }
    }
}

export default JobEquipmentDetails;