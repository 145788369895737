import React, { useEffect, useState } from "react";
import { CustomTaskFieldEntity } from "../../../../shared/data/taskmanagement/Task";

interface CustomFieldComponentProps {
  customField: CustomTaskFieldEntity;
  onChanged?: (result: CustomTaskFieldEntity) => void;
}

const TaskCustomFieldComponent = ({
  customField,
  onChanged = (result) => {},
}: CustomFieldComponentProps) => {
  const [customFieldState, setCustomFieldState] =
    useState<CustomTaskFieldEntity>(customField);

    useEffect(()=>{
        onChanged(customFieldState)
    },[customFieldState])

  return (
    <React.Fragment>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div key="task-new-custom-field-name" className="form-group">
            <label>Name</label>
            <input
              type="text"
              placeholder="Size"
              className="form-control"
              value={customField?.title ?? ""}
              onChange={(input) => {
                setCustomFieldState((previousState) => {
                  return { ...previousState, title: input.target.value };
                });
              }}
            />
          </div>
        </div>
        <div className="container">
          <div key="task-new-custom-field-value" className="form-group">
            <label>Value</label>
            <input
              type="text"
              placeholder="Size"
              className="form-control"
              value={customField?.value ?? ""}
              onChange={(input) => {
                setCustomFieldState((previousState) => {
                  return { ...previousState, value: input.target.value };
                });
              }}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaskCustomFieldComponent;
