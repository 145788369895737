import React, { useState } from "react";
import AddRemoveModal from "../Modals/AddRemoveModal";

interface ReportComponent {
  id:string,
  title?: string; 
  showDialog?: boolean;
  reportRecipients: ReportRecipient[];
  onDismiss?: () => void;
  sendReport?: (recipients: ReportRecipient[]) => void;
}

export class ReportRecipient {
  id: string;
  fullName: string;
  email: string;
  selected: boolean = false;
  disabled: boolean = false;

  constructor(
    id: string,
    fullName: string,
    email: string,
    selected?: boolean,
    disabled?: boolean
  ) {
    this.id = id;
    this.fullName = fullName;
    this.email = email;
    this.selected = selected ?? false;
    this.disabled = disabled ?? false;
  }
}

const ReportComponent = ({
  id,
  title = "",
  showDialog = false,
  reportRecipients,
  onDismiss = () => {},
  sendReport = () => {},
}: ReportComponent) => {

  const [selectedRecipients, setSelectedRecipients] = useState<
    ReportRecipient[]
  >(
    reportRecipients.filter((reportRecipient) => reportRecipient.selected) ?? []
  );

  const updateRecipient = (recipient: ReportRecipient) => {
    if (
      !selectedRecipients.find(
        (selectedRecipient) => selectedRecipient.email == recipient.email
      )
    ) {
      setSelectedRecipients([...selectedRecipients, recipient]);
    } else {
      setSelectedRecipients(
        selectedRecipients.filter(
          (selectedRecipient) => selectedRecipient.email !== recipient.email
        )
      );
    }
  };

  const getDialogItems = () => {
      return reportRecipients?.map((reportRecipients) => {
        return {
          id: reportRecipients.id,
          display: reportRecipients.fullName,
          additional: reportRecipients.email,
          value:
            selectedRecipients.find(
              (selectedRecipient) =>
                selectedRecipient.email == reportRecipients.email
            ) != null,
          disabled: reportRecipients.disabled,
        };
      }) ?? [];
  };

  return (
    <React.Fragment>
      <AddRemoveModal
        id={`id-modal-${id}`}
        title={title}
        items={getDialogItems()}
        show={showDialog}
        toggle={() => {
          onDismiss()
          setSelectedRecipients(reportRecipients.filter((reportRecipient)=>reportRecipient.selected))
        }}
        save={() => {
          sendReport([...selectedRecipients])
          setSelectedRecipients(reportRecipients.filter((reportRecipient)=>reportRecipient.selected))
        }}
        saveText={"Send"}
        onChangeHandler={(value: any) => {
          const recipient = reportRecipients?.find(
            (reportRecipient) => reportRecipient.id == value
          );
          if (recipient) updateRecipient(recipient);
        }}
      />
    </React.Fragment>
  );
};

export default ReportComponent;
