import React, { Component } from 'react';
import { validateField } from '../../shared/Validation';
import app, { db, storage } from '../../firebase';
import ReauthenticateModal from '../../components/Modals/ReauthenticateModal';
import DropzoneInput from '../../components/Form/Inputs/DropzoneInput';
import { Link } from 'react-router-dom';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { normalizeInput } from '../../shared/FormHelper';

class ProfileEdit extends Component {
  static contextType = AuthContext;

  state = {
    user: null,
    reauthenticateData: {
      email: '',
      password: '',
      error: null,
      loading: false
    },
    reauthenticateModalShow: false,
    fields: [
      {
        name: 'image',
        value: '',
        defaultValue: '',
        type: 'dropzone',
        label: 'Avatar',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        userProfile: true,
        originalUser: false,
        localSrc: '',
        onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
        onRemove: () => { this.onImageRemove(); }
      },
      {
        name: 'firstName',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'First Name',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        userProfile: true,
        originalUser: false,
        validators: [
          {
            type: 'required',
            message: getMessages('firstname_required')
          }
        ]
      },
      {
        name: 'lastName',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Last Name',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        userProfile: true,
        originalUser: false,
        validators: [
          {
            type: 'required',
            message: getMessages('lastname_required')
          }
        ]
      },
      {
        name: 'email',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Email',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        userProfile: true,
        originalUser: true,
        validators: [
          {
            type: 'required',
            message: getMessages('email_required')
          },
          {
            type: 'email',
            message: getMessages('invalid_email')
          }
        ]
      },
      {
        name: 'phoneNumber',
        value: '',
        defaultValue: '',
        type: 'text',
        subtype: 'phone',
        label: 'Phone',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        userProfile: true,
        originalUser: false,
        validators: [
          {
            type: 'phoneNumber',
            message: getMessages('invalid_phone')
          }
        ]
      }
    ],
    isValid: true,
    submitError: false,
    submitErrorMessage: '',
    successMessage: '',
    loading: false,
    accountCreated: false
  };

  componentDidMount() {
    this.loadFields();
  }

  loadFields = () => {
    let fields = [...this.state.fields];
    fields.map(x => {
      if (x.name === 'image') {
        x.value = this.props.user.avatar;
        x.defaultValue = this.props.user.avatar;
        x.localSrc = this.props.user.avatar;
      } else {
        x.value = this.props.user[x.name];
        x.defaultValue = this.props.user[x.name];
      }
    });

    this.setState({ fields: fields });
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    if(field.subtype === 'phone') {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    this.validate();

    if (field.value === field.defaultValue)
      field.isTouched = false;

    this.setState({ fields: fields, isValid: !(fields.some(x => !x.isValid)) });
  }

  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach(item => {
      if(item.subtype === 'phone') {
        obj[item.name] = normalizeInput(item.value, '');
      } else if(item.name !== 'image') {
        obj[item.name] = item.value;
      }
    });

    return obj;
  }

  onImageDrop = (acceptedFiles) => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    const reader = new FileReader();
    const loc = window.URL.createObjectURL(acceptedFiles[0]);

    imageField.value = acceptedFiles[0];
    imageField.localSrc = loc;
    this.setState({ fields: fields });
  }

  onImageRemove = () => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    imageField.value = '';
    imageField.localSrc = '';
    this.setState({ fields: fields });
  }

  touchField = (field) => {
    let fields = [...this.state.fields];
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = field.value !== field.defaultValue;
    this.setState({ fields: fields });
  }

  reauthenticate = () => {
    const currentUser = this.context.currentUser;
    const self = this;

    app.auth()
       .signInWithEmailAndPassword(currentUser.email, this.state.reauthenticateData.password)
       .then(function(userCredential) {
          self.setState({ reauthenticateModalShow: false });
          self.updateUser(userCredential);
       })
       .catch(err => {
         console.log(err);
         self.setState({ reauthenticateData: {...this.state.reauthenticateData, error: 'Password confirmation failed.' } })
       });
  }

  updateUser = (credential) => {
    const currentUser = credential ? credential.user : this.context.currentUser;

    this.setState({ loading: true });
    const obj = this.getObjectFromFields();
    const imageField = this.state.fields.find(x => x.name === 'image');

    let userProfile = {...this.context.currentUser.userProfile};
    userProfile.email = obj.email;
    userProfile.firstName = obj.firstName;
    userProfile.lastName = obj.lastName;
    userProfile.phoneNumber = obj.phoneNumber;
    userProfile.permissions = this.props.user.permissions;
    userProfile.imageTimeStamp = imageField.value && imageField.value !== imageField.defaultValue ? new Date() : userProfile.imageTimeStamp;
    delete userProfile.recurlyAccountCode;

    if(this.isEmailChanged()) {
      const emailField = this.state.fields.find(x => x.name === 'email');
      currentUser.updateEmail(emailField.value)
                 .then(res => {
                    db.collection('users')
                      .doc(currentUser.uid)
                      .update(userProfile)
                      .then(doc => {  
                          let updatedUser = this.context.currentUser;
                          const updateUser = this.context.updateCurrentUser;
                          updatedUser.userProfile = userProfile;   
                
                          if(this.isImageChanged()) {
                            db.collection('users')
                              .doc(currentUser.uid)
                              .get()
                              .then(result => {
                                const storageRef = storage.ref(currentUser.userProfile.companyID + '/user/' + currentUser.uid + '/' + result.data().imageTimeStamp.seconds + '/med'); 
                                storageRef.put(imageField.value).then((snapshot) => {
                                  storageRef.child('med').getDownloadURL()
                                            .then((url) => {
                                              updatedUser.userProfile.avatar = url;
                                              updateUser(updatedUser);
                                              this.setState({ loading: false, successMessage: "Profile has been updated successfully." });
                                            })
                                            .catch(err => {
                                              updateUser(updatedUser);
                                            });
                                });
                              });           
                          } else {
                            updateUser(updatedUser);   
                            this.setState({ loading: false, successMessage: "Profile has been updated successfully." });
                          }        
                        })
                        .catch(error => {
                          console.log(error);
                          this.setState({ loading: false });
                        });
                 })
                 .catch(err => {
                   if(err.code === 'auth/requires-recent-login') {
                    this.setState({ loading: false, reauthenticateModalShow: true });
                   }
                   if(err.code === 'auth/email-already-in-use') {
                    this.setState({ loading: false, submitError: true, submitErrorMessage: 'The email address is already in use by another account.' });
                   }
                 });
    } else if(this.isUserProfileChanged()) {
      db.collection('users')
        .doc(currentUser.uid)
        .update(userProfile)
        .then((result) => {    
          let updatedUser = this.context.currentUser;
          const updateUser = this.context.updateCurrentUser;
          updatedUser.userProfile = userProfile;   

          if(this.isImageChanged()) {
            db.collection('users')
              .doc(currentUser.uid)
              .get()
              .then(result => {
                const storageRef = storage.ref(currentUser.userProfile.companyID + '/user/' + currentUser.uid + '/' + result.data().imageTimeStamp.seconds + '/med'); 
                storageRef.put(imageField.value).then((snapshot) => {
                  storageRef.getDownloadURL()
                            .then((url) => {
                              updatedUser.userProfile.avatar = url;
                              updateUser(updatedUser);
                              this.setState({ loading: false, successMessage: "Profile has been updated successfully." });
                            })
                            .catch(err => {
                              updateUser(updatedUser);
                            });
                });
              });           
          } else {
            updateUser(updatedUser);   
            this.setState({ loading: false, successMessage: "Profile has been updated successfully." });
          }                       
        })
        .catch(error => {
          console.log(error);
        }); 
    }
  }

  addValidationClass = (field, cl) => {
    if (field.isValid && field.value && field.isTouched) return cl + ' is-valid';
    if (!field.isValid) return cl + ' is-invalid';

    return cl;
}

  handleChangeReauthenticate = (event) => {
    let reauth = {...this.state.reauthenticateData};
    reauth[event.target.name] = event.target.value;

    this.setState({ reauthenticateData: reauth });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.updateUser();
  }

  isChanged() {
    return this.state.fields.some(x => x.value !== x.defaultValue);
  }

  isUserProfileChanged() {
    return this.state.fields.filter(x => x.userProfile).some(x => x.value !== x.defaultValue);
  }

  isEmailChanged() {
    const emailField = this.state.fields.find(x => x.name === 'email');
    return emailField.value !== emailField.defaultValue;
  }

  validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }

  isImageChanged() {
    const imageField = this.state.fields.find(x => x.name === 'image');
    return imageField.value !== imageField.defaultValue;
  }

  render() {
    const submitBtnClasses = 'btn btn-light-primary font-weight-bold mr-4';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-9">
            <div className="my-5 step" data-wizard-type="step-content" data-wizard-state="current">
              <h5 className="text-dark font-weight-bold mb-10">User's Profile Details:</h5>
              {this.state.fields.map((field, index) => (
                <div  key = {"prfl-edt" + index}className="form-group row">
                  <label className="col-xl-3 col-lg-3 col-form-label">{field.label} 
                  {field.required ? <span className="text-danger">*</span> : null}</label>
                  <div className="col-lg-9 col-xl-9">
                    {field.name === 'image' ? 
                    <DropzoneInput field={field} />
                    :
                    <React.Fragment>
                    <input
                      className={this.addValidationClass(field, "form-control form-control-solid form-control-lg")}
                      name={field.name}
                      type="text"
                      value={field.value}
                      disabled={field.disabled}
                      onChange={this.handleChange}
                      onBlur={() => { this.touchField(field) }}
                    />
                    <div className="invalid-feedback">{field.validationMessage}</div>
                    </React.Fragment> }
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card-footer pb-0">
          <div className="row">
            <div className="col-xl-2" />
            <div className="col-xl-7">
              <div className="row">
                <div className="col-3" />
                <div className="col-9">
                  <button 
                    onClick={this.handleSubmit}
                    disabled={!this.isChanged() || !this.state.isValid} 
                    className={submitBtnClassesFinal}>Save changes</button>
                  <Link to="/me"
                    className="btn btn-outline font-weight-bold">Cancel</Link>
                    <span className="form-text text-danger">{this.state.submitErrorMessage}</span>
                    <span className="form-text text-success">{this.state.successMessage}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ReauthenticateModal
            show={this.state.reauthenticateModalShow}
            title="Confirm your identity"
            yesButton={{
              title: "Confirm",
              onClick: () => { this.reauthenticate(); }
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ reauthenticateModalShow: false }) }
            }}
            handleChange={this.handleChangeReauthenticate}
            data={this.state.reauthenticateData}
          />
      </React.Fragment>
    );
  }
}

export default ProfileEdit;