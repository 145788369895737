import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Company, Email, Phone, Shield } from '../../components/Icons/Svg';

import { AuthContext } from "../../shared/Auth";

class GettingStarted extends Component {
  static contextType = AuthContext;

  state = {
    user: null,
    loading: false
  };

  render() {
    return (
      <div className="w-100">
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 1 - Install App</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Use Equappment from any browser (like Chrome, Edge, Safari) by going to app.equappment.com <br /><br />
            * If you have an iPhone or iPad, download and install Equappment from the Apple App Store <br />
            <Link to={{ pathname: "https://apps.apple.com/us/app/equappment/id1454739075?ls=1" }} target="_blank" className="btn btn-outline-dark font-weight-bold px-8 py-4 my-3 rounded-0 mt-4">
              <i className="socicon-apple"></i> Download on the App Store
            </Link>
          </div>
        </div>
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 2 - Log In</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Log in to Equappment with the same credentials you used to create your account
          </div>
        </div>
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 3 - Add Equipment</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Go to the Equipment feature and touch the "+" in upper right corner<br /><br />
            * Enter details about the equipment that are important to you and your crew<br /><br />
            * Equipment is sorted on the list screen by the "Equipment ID" field<br /><br />
            * Take pictures of your piece of equipment to help identify it<br /><br />
            * All fields are searchable from the equipment list screen. Ex: search on "excavator" to find all equipment with excavator included in any of the fields<br /><br />
            * Use the import tool to quickly add your entire fleet of equipment into Equappment.
          </div>
        </div>
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 4 - Add Crewmembers</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Go to the Crew feature and touch the "+" in upper right corner<br /><br />
            * Enter crew member name and contact info<br /><br />
            * Add skills and certifications that are important to know when assigning to a job<br /><br />
            * Add any other details you want. Maybe their start date or birthday<br /><br />
            * Take a picture of the crew member for easy identification
          </div>
        </div>
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 5 - Add Jobs</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Go to the Jobs feature and touch the "+" in upper right corner<br /><br />
            * Enter a title for the job and job ID. These will be displayed on the Job Schedule<br /><br />
            * Select or add the Client that the job is being done for<br /><br />
            * Add the Start Date and End Date for the job<br /><br />
            * Include any other details about the job in the Description field<br /><br />
            * Select "Next" in the upper right corner<br /><br />
            * Enter the location of the job by entering an address or intersection in the search field; or drop a pin at the location on the map<br /><br />
            * Select "Next" in the upper right corner. Select the equipment that will be used on this job<br /><br />
            * For each piece of equipment, choose the job's default start and end dates, or select custom dates<br /><br />
            * The dates selected will impact if the equipment is shown as available or unavailable for other jobs<br /><br />
            * Select "Next" in the upper right corner. Here you will see the Checklists that are automatically created once equipment is added to the job<br /><br />
            * Create a custom checklist for other materials, safety gear, and tools that you want to be sure are taken to the job site<br /><br />
            * Select "Next" in the upper right corner. Choose the crew or crew members that will be assigned to this job<br /><br />
            * Select "Next" in the upper right corner. You can now output a Job Report by selecting "Share a Report"<br /><br />
            * Finally, save this as a "Bid" if you have not been awarded the job; or save as a "Job"
          </div>
        </div>
        <div className="pb-5 pb-lg-15">
          <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">STEP 6 - Invite Users</h3>
          <div className="font-weight-bold font-size-h6 pl-6 mt-8">
            * Equappment is most powerful when more employees and crew members have access to the app and the information it contains<br /><br />
            * To invite a crew member to be a user on the app, first add them as a Crewmember<br /><br />
            * Then, select them from the crew member listing and select "Invite to App". They will receive an email with links to download and install Equappment and log in
          </div>
        </div>
      </div>
    );
  }
}

export default GettingStarted;