import React, { Component, useCallback } from 'react';

import app, { db, functions } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';

import { storage } from '../../firebase';
import { getReservation, deleteReservation } from '../../shared/Api';

import { Link } from 'react-router-dom';
import * as moment from 'moment';
import $ from 'jquery';
import { Edit, Trash } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';
import { showToast } from '../../shared/Util';
import ScheduleServiceReport from '../../components/Reports/ScheduleServiceReport';
import { getAllCrewMembers } from '../../shared/api/CrewApi';

class ScheduleEquipmentDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;

  state = {
    loading:false,
    schedule: null,
    deleteScheduleModalActive: false,
    crewMembers:[]
  }


  componentDidMount = async () => {
    const scheduleId = this.props.match.params.scheduleId;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const reservation = await getReservation(inventoryId, scheduleId);
    const crewMembersResult =
      (await getAllCrewMembers(inventoryId)).data?.filter(
        (crewMember) => crewMember.email !== this.user.email
      ) ?? [];

    const schedule = reservation.data;
    this.setState({ schedule: schedule, crewMembers: crewMembersResult });
  }

  deleteSchedule = async () => {
    const equipmentId = this.props.match.params.id;
    const scheduleId = this.props.match.params.scheduleId;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await deleteReservation(inventoryId, scheduleId);
    this.setState({ deleteScheduleModalActive: false });
    this.props.history.push('/equipment/' + equipmentId);
  }

  shareServiceRequest = async () => {
    this.setState(previousState=>{
      return {...previousState, loading:true}
    })
    let shareServiceRequestFunc = functions.httpsCallable("sendEquipmentReport");
    let result = await shareServiceRequestFunc({ reservationID: this.state.schedule.id, userID: this.context.currentUser.uid});
    this.setState(previousState=>{
      return {...previousState, loading:false}
    })
    // this.setState({ jobStatusReportSending: false });
    showToast('success', 'Service request has been sent successfully.');
}

reportBtnClass = (className, flag) => {
  if(flag)
      className += ' spinner spinner-white spinner-right';

  return className;
}

  render() {
    if (this.state.schedule) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">Schedule details</h3>
              </div>
              <div className="d-flex flex-row align-items-center">
                {this.user.permissions !== 'crewmember' &&
                  <>
                  <ScheduleServiceReport reservationId={this.state.schedule.id} crewMembers={this.state.crewMembers} />
                    <button onClick={() => { this.props.history.push('/equipment/' + this.props.match.params.id + '/schedule/' + this.state.schedule.id + '/edit') }} className="btn btn-success font-weight-bold mr-4 ml-4">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Edit/>
                    </span>
                    Edit</button>
                    <button onClick={() => { this.setState({ deleteScheduleModalActive: true }) }} className="btn btn-danger font-weight-bold">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Trash/>
                    </span>Delete</button>
                  </>
                }
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-4">
              {this.state.schedule.repairType ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Repair Type:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{this.state.schedule.repairType.split(/(?=[A-Z])/).join(" ")
}</span>
                  </div>
                </div> : null}
                {this.state.schedule.repairDetails ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Repair Details:</label>
                  <div className="col-8">
                    <span style={{ whiteSpace: "pre-line" }} className="form-control-plaintext font-weight-bolder">{this.state.schedule.repairDetails}</span>
                  </div>
                </div> : null}
                {this.state.schedule.startDate && this.state.schedule.endDate ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Service Dates:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{moment(this.state.schedule.startDate.toDate()).format("MM/DD/YYYY")} - {moment(this.state.schedule.endDate.toDate()).format("MM/DD/YYYY")}</span>
                  </div>
                </div> : null}
                {this.state.schedule.hourMeter ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Hour meter reading:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{this.state.schedule.hourMeter}</span>
                  </div>
                </div> : null}
            </div>
            <div className="card-footer">
            </div>
          </div>
          <ConfirmModal
            show={this.state.deleteScheduleModalActive}
            title="Delete schedule"
            body={getMessages('delete_schedule')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteSchedule(); }
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteScheduleModalActive: false }) }
            }}
          />
        </React.Fragment>
      );
    } else {
      return <Loader />
    }
  }
}

export default ScheduleEquipmentDetails;