import { data } from 'jquery';
import React, { Component } from 'react';

class ReauthenticateModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
      }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if(!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
            <div className="modal fade" id="confirmModal" 
                data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                    <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button>
                    </div>
                    <div className="modal-body">
                        This operation is sensitive and requires recent authorization. Please provide your current password before retrying this request. <br /><br />
                        <div className="form-group">
                            <input value={this.props.data.password} 
                            type="password" className="form-control" 
                            onChange={this.props.handleChange}
                            name="password"
                            placeholder="Enter password" />
                            <span className="form-text text-danger">{this.props.data.error}</span>
                        </div>
                    </div>
                    <div className="modal-footer">
                    <button onClick={this.props.yesButton.onClick} type="button" className="btn btn-primary font-weight-bold">{this.props.yesButton.title}</button>
                    <button onClick={this.props.noButton.onClick} type="button" className="btn btn-light-secondary font-weight-bold" data-dismiss="modal">{this.props.noButton.title}</button>
                    </div>
                </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className={this.props.loading ? "btn btn-primary spinner spinner-white spinner-right" : "btn btn-primary"} data-toggle="modal" data-target="#confirmModal">
            </button>
            </React.Fragment>
        );
    }
}

export default ReauthenticateModal;