import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField } from '../../shared/Validation';
import Loader from '../../components/Layout/Loader/Loader';

import app, { db, storage } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { getCategories, getEquipment, getSingleEquipment, updateEquipment, uploadImage } from '../../shared/Api';
import { showToast, sortAlphabetically } from '../../shared/Util';

class EquipmentEdit extends Component {
  static contextType = AuthContext;

  state = {
    equipment: null,
    equipmentList: [],
    fields: [
      {
        name: 'equipmentID',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Equipment Id',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_id_required')
          }
        ]
      },
      {
        name: 'make',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Make',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_make_required')
          }
        ]
      },
      {
        name: 'model',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Model',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: true,
        validators: [
          {
            type: 'required',
            message: getMessages('equipment_model_required')
          }
        ]
      },
      {
        name: 'year',
        value: '',
        defaultValue: '',
        type: 'number',
        label: 'Year (optional)',
        min: 0,
        max: 2050,
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        validators: [
          
          {
            type: 'min',
            value: 0,
            message: getMessages('year_span')
          },
          {
            type: 'max',
            value: 2050,
            message: getMessages('year_span')
          }
        ]
      },
      {
        name: 'serialNumber',
        value: '',
        defaultValue: '',
        type: 'text',
        label: 'Serial Number (optional)',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false
      },
      // {
      //   name: 'tag',
      //   value: '',
      //   defaultValue: '',
      //   type: 'text',
      //   label: 'Tag (optional)',
      //   isValid: true,
      //   validationMessage: '',
      //   isTouched: false,
      //   additionalMessage: null,
      //   required: false
      // },
      {
        name: 'category',
        value: '',
        defaultValue: '',
        type: 'select',
        options: [],
        label: 'Category',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false
      },
      {
        name: 'subCategory',
        value: '',
        defaultValue: '',
        type: 'select',
        options: [],
        label: 'Sub Category',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false
      },
      {
        name: 'description',
        value: '',
        defaultValue: '',
        type: 'textarea',
        label: 'Description',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        rows: 4
      },
      {
        name: 'image',
        value: '',
        defaultValue: '',
        type: 'multiimage',
        label: 'Image',
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        localSrc: '',
        onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
        onRemove: (index) => { this.onImageRemove(index); }
      }
    ],
    isValid: true,
    submitError: false,
    submitErrorMessage: '',
    loading: false,
    accountCreated: false
  };

  componentDidMount = async () => {
    await this.loadCategories();
    this.loadEquipment();

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipment = await getEquipment(inventoryId, companyId);
    this.setState({ equipmentList: equipment.data });
  }

  loadEquipment = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipmentResult = await getSingleEquipment(inventoryId, id, companyId, true);

    if(!equipmentResult.data.category) {
      const category = this.state.categories[0];
      equipmentResult.data.category = category.name;    
    }

    let fields = [...this.state.fields];
    fields.forEach(x => {
      if(x.name === 'image') {
        x.value = equipmentResult.data.images.map((y, index) => { 
          const timestamp = index === 0 ? equipmentResult.data.imageTimeStamp : equipmentResult.data.photos[index - 1];
          return { value: null, localSrc: y, timestamp: timestamp, index: index - 1 }
        });
      } else {
        x.value = equipmentResult.data[x.name];
        x.defaultValue = equipmentResult.data[x.name];
      }
    });

    if(equipmentResult.data.category && equipmentResult.data.subCategory) {
      const category = this.state.categories.find(x => x.name === equipmentResult.data.category);

      if(category) {
        const subCategory = category.subCategories.find(x => x.name === equipmentResult.data.subCategory);
        const subTypes = this.getCategoryTypes(subCategory, equipmentResult.data);

        let newFields = fields.slice(0, 7);
        newFields = newFields.concat(subTypes);
        newFields = newFields.concat(fields.slice(7, fields.length));
        fields = newFields;
      }
    }

    await this.loadCategories(equipmentResult.data.category);
    this.setState({ equipment: equipmentResult.data, fields: fields });
  }

  loadCategories = async (categorySelected) => {
    const categories = await getCategories();

    const fields = [...this.state.fields];
    const categoryField = fields.find(x => x.name === 'category');

    categoryField.options = categories.data.map(x => { return { display: x.name, value: x.name } });
    //categoryField.value = categories.data[0].name;

    const catSelected = categorySelected ? categories.data.find(x => x.name === categorySelected) : categories.data[0];
    this.populateSubCategories(catSelected);

    this.setState({ categories: categories.data, fields: fields });
  }

  populateSubCategories = async (category) => {
    const fields = [...this.state.fields];
    const subCategoryField = fields.find(x => x.name === 'subCategory');

    subCategoryField.options = category.subCategories.map(x => { return { display: x.name, value: x.name } });
    
    sortAlphabetically(subCategoryField.options);

    subCategoryField.options.unshift({ display: 'None', value: null });
  }

  getCategoryTypes = (subCategory, data) => {
    if(!subCategory)
      return [];

    const typeFields = subCategory.types.map(x => {
      const value = data && data.subCategoryTypes ? data.subCategoryTypes.find(y => y.name === x.name).value : '';

      const obj = {
        name: x.name,
        value: value,
        defaultValue: value,
        type: this.getTypeForSubcategoryType(x.type),
        label: x.unitI ? x.name + "("+ x.unitI +")" : x.name,
        isValid: true,
        validationMessage: '',
        isTouched: false,
        additionalMessage: null,
        required: false,
        categoryTypeField: true
      };
  
      if(obj.type === 'select') {
        obj.options = x.value.split(',').map(x => { return { display: x, value: x } });
        sortAlphabetically(obj.options);
      }
      return obj;
    });

    return typeFields;
  }

  populateSubcategoryTypes = async (subCategory) => {
    const pureFields = this.state.fields.filter(x => !x.categoryTypeField);

    if(!subCategory) {
      this.setState({ fields: pureFields });
      return;
    }

    const typeFields = this.getCategoryTypes(subCategory);

    let newFields = pureFields.slice(0, 7);
    newFields = newFields.concat(typeFields);
    newFields = newFields.concat(pureFields.slice(7, pureFields.length));

    this.setState({ fields: newFields });
  }  

  getTypeForSubcategoryType = (type) => {
    switch(type) {
      case 'string':
        return 'text';
      case 'number':
        return 'number';
      case 'singleSelect':
        return 'select';
      default:
        return type;
    }
  }

  validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }

  onImageDrop = (acceptedFiles) => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    acceptedFiles.forEach(element => {
      const reader = new FileReader();
      const loc = window.URL.createObjectURL(element);

      imageField.value.push({
        value: element,
        localSrc: loc
      });
    });

    this.setState({ fields: fields });
  }

  onImageRemove = (index) => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');


    imageField.value.splice(index, 1);

    this.setState({ fields: fields });
  }

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach(element => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach(element => {
      if ((element.required && !element.value) || !element.isValid) isValid = false;
    });

    return isValid;
  }

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = field.value !== field.defaultValue;
    this.setState({ fields: fields });
  }

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + ' is-valid';
    if (!field.isValid && field.value) return cl + ' is-invalid';

    return cl;
  }

  gnerateInputId(field) {
    return "ca-input-" + field.name;
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    field.value = event.target.value;
    this.validate();

    if (!field.value)
      field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });

    if(field.name === 'category') {
      const category = this.state.categories.find(x => x.name === event.target.value);
      if(category) {
        this.populateSubCategories(category);
        this.populateSubcategoryTypes(null);
      }
    }

    if(field.name === 'subCategory') {
      const categoryName = this.state.fields.find(x => x.name === 'category').value;
      const category = this.state.categories.find(x => x.name === categoryName);
      if(category) {
        const subCategory = category.subCategories.find(x => x.name === event.target.value);

        this.populateSubcategoryTypes(subCategory);
      }
    }
  }

  getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach(item => {
      if(item.name !== 'image')
        obj[item.name] = item.value;
    });

    const categoryField = this.state.fields.find(x => x.name === 'category');
    const subCategoryField = this.state.fields.find(x => x.name === 'subCategory');
    const category = this.state.categories.find(x => x.name === categoryField.value);
    const subCategory = category.subCategories.find(x => x.name === subCategoryField.value);

    if(subCategory) {
      const categoryTypes = subCategory.types;
      const categoryTypeFields = this.state.fields.filter(x => x.categoryTypeField);

      obj['subCategoryTypes'] = categoryTypes.map(x => {
        const ctf = categoryTypeFields.find(y => y.name === x.name);

        return {
          ...x,
          value: ctf.value
        };
      });
    }

    return obj;
  }

  checkDuplicateEquipmentID = (equipmentObj) => {
    const id = this.props.match.params.id;

    return this.state.equipmentList.some(x => x.id !== id && x.equipmentID === equipmentObj.equipmentID);
  }

  handleSubmit = async (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    const obj = this.getObjectFromFields();
    const imageField = this.state.fields.find(x => x.name === 'image');

    if(this.checkDuplicateEquipmentID(obj)) {
      showToast('danger', 'Equipment ID: ' + obj.equipmentID + ' has already been taken.');
      this.setState({ loading: false });
      return;
    }

    const newPhotosIndexes = [];
    if(imageField.value.length > 0) {
      const photos = [];
      imageField.value.forEach((element, index) => {
        if(index === 0)
          return;

        if(!element.timestamp) {
          let date = new Date();
          date.setSeconds(date.getSeconds() + 100 * index);
          photos.push(date);
          newPhotosIndexes.push(index);
        } else {
          photos.push(element.timestamp);
        } 
      });

      if(!imageField.value[0].timestamp) {
        obj.imageTimeStamp = new Date();
        newPhotosIndexes.push(0);
      } else if(imageField.value[0].index !== -1) {
        obj.imageTimeStamp = imageField.value[0].timestamp;
      }

      obj.photos = photos;
      obj.hasProfileImage = true;
    } else {
      obj.photos = [];
      obj.hasProfileImage = false;
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const updateEquipmentResult = await updateEquipment(inventoryId, this.state.equipment.id, obj);
    const equipmentResult = await getSingleEquipment(inventoryId, this.state.equipment.id, false);

    await Promise.all(newPhotosIndexes.map(async (x) => {            
      const timestamp = x === 0 ? equipmentResult.data.imageTimeStamp : equipmentResult.data.photos[x - 1];
      const value = imageField.value[x].value;
      await uploadImage(companyId, 'equipment', equipmentResult.data.id, timestamp, value);
    })); 

    this.props.history.goBack()
  }

  submitButton = () => {
    const submitBtnClasses = 'btn btn-primary mr-2';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handleSubmit,
      disabled: !this.state.isValid || this.state.loading
    };
  }

  cancelButton = () => {
    return {
      title: "Cancel",
      className: "btn btn-secondary mr-2",
      onClick: () => {
        this.props.history.goBack()
        // this.props.history.push('/equipment'); 
      }
    };
  }

  render() {
    if(!this.state.equipment) {
      return <Loader/>
    }
        return (
        <React.Fragment>
            <div className="card card-custom">
            <div className="card-header">
                <h3 className="card-title">
                Edit equipment
                            </h3>
                <div className="card-toolbar">
                <div className="example-tools justify-content-center">
                    <span className="example-toggle" data-toggle="tooltip" title="View code" />
                    <span className="example-copy" data-toggle="tooltip" title="Copy code" />
                </div>
                </div>
            </div>
            <SimpleForm
                formUniqueName="edit-equipment"
                fields={this.state.fields}
                touchField={this.touchField}
                submitButton={this.submitButton()}
                cancelButton={this.cancelButton()}
                handleInputChange={this.handleChange}
                onSubmit={this.handleSubmit}
            />
            </div>
        </React.Fragment>
        );
  }
}

export default EquipmentEdit;