import React, { Component } from 'react';
import { db, storage, functions } from '../../firebase';
import { AuthContext } from "../../shared/Auth";
import { validateField } from '../../shared/Validation';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { Route, Link, Switch, BrowserRouter } from 'react-router-dom';
import Loader from '../../components/Layout/Loader/Loader';
import { Trash } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';
import { updateCrewmember } from '../../shared/Api';


class ActiveUserEdit extends Component {
  static contextType = AuthContext;

  state = {
    data: null,
    permissions: "",
    permissionsDefault: "",
    deleteUserModalActive: false,
    loading: false,
  };

  componentDidMount = async () => {
    await this.loadActiveUser();
  }

  loadActiveUser = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection("users")
      .doc(id)
      .get()
      .then(async (doc) => {
        let activeUserData = doc.data();
        activeUserData.id = doc.id;
        let permissions = activeUserData.permissions;

        this.setState({ data: activeUserData, permissionsDefault: permissions, permissions: permissions });
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target
    this.setState({
      [name]: value
    })
  }

  deleteUser = async () => {
    const id = this.state.data.id
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    let userDoc = await db.collection("users").doc(id).get();
      updateCrewmember(inventoryId, userDoc.data().crewmemberID, { authorizationStatus: 'accessRevoked', userID: null })

      db.collection("users")
        .doc(id)
        .delete()
        .then(() => {
          var removeAuthentication = functions.httpsCallable('removeAuthUser');

            removeAuthentication({ userId: id }).then((result) => {
              var updateQuantityFunction = functions.httpsCallable('api/updateSubscriptionQuantity');
              updateQuantityFunction({ recurlyAccountCode: currentUser.userProfile.recurlyAccountCode, increment: -1 }).then(async (result) => {
                    
                this.props.history.goBack()
                // this.props.history.push('/settings/activeusers')

              }).catch((error) => {
                console.log(error);
              });
            }).catch((error) => {
                console.log(error);
            });          
        }).catch((error) => {

        });

    this.setState({ deleteUserModalActive: false });
  }

  isChanged = () => {
    return this.state.permissions !== this.state.permissionsDefault;
  }

  handleSubmit = (event) => {
    this.setState({loading: true})
    event.preventDefault();
    const id = this.state.data.id;

    db.collection("users")
      .doc(id)
      .update({
        permissions: this.state.permissions
      })
      .then(()=>{
        this.props.history.goBack()
        // this.props.history.push('/settings/activeusers')
      }).catch((err)=>{

      });

  }
  
  render() {
    const currentUser = this.context.currentUser;
    if (this.state.data) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">User details
          <span className="d-block text-muted pt-2 font-size-sm">{this.state.data.firstName} {this.state.data.lastName}</span></h3>
              </div>
              <div className="card-toolbar">
                <a onClick={() => { this.setState({ deleteUserModalActive: true }) }} className="btn btn-danger btn-sm font-weight-bold">
                <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                    <Trash/>
                </span>Delete</a>
              </div>
            </div>
            {/*end::Header*/}
            <div className="card-body">
              <div className="form" id="kt_form">
                <div className="tab-content">
                  {/*begin::Tab*/}
                  <div className="tab-pane show active px-7" id="kt_user_edit_tab_1" role="tabpanel">
                    {/*begin::Row*/}
                    <div className="row">
                      <div className="col-xl-2" />
                      <div className="col-xl-7 my-2">
                        {/*begin::Row*/}
                        <div className="row">
                          <label className="col-3" />
                          <div className="col-9">
                            <h6 className="text-dark font-weight-bold mb-10">Change permissions</h6>
                          </div>
                        </div>
                        {/*end::Row*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left"></label>
                          <div className="col-9">
                            <div className="symbol symbol-50 symbol-lg-120">
                              {this.state.data &&
                                <>
                                  {this.state.data.avatar ? <div className="symbol symbol-50 symbol-lg-120"><img alt="Pic" src={this.state.data.avatar} /></div> : <div className="symbol symbol-50 symbol-lg-120 symbol-light-danger">
                                    <span className="font-size-h3 symbol-label font-weight-boldest">{this.state.data.firstName.charAt(0)}{this.state.data.lastName.charAt(0)}</span>
                                  </div>}
                                </>
                              }
                            </div>
                          </div>
                        </div>
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left">First Name:</label>
                          <div className="col-9">
                            <span className="form-control-plaintext font-weight-bolder">{this.state.data.firstName}</span>
                          </div>
                        </div>
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left">Last Name:</label>
                          <div className="col-9">
                            <span className="form-control-plaintext font-weight-bolder">{this.state.data.lastName}</span>
                          </div>
                        </div>
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left">Contact Phone:</label>
                          <div className="col-9">
                            <span className="form-control-plaintext font-weight-bolder">{this.state.data.phoneNumber}</span>
                          </div>
                        </div>
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left">Email Address:</label>
                          <div className="col-9">
                            <span className="form-control-plaintext font-weight-bolder">{this.state.data.email}</span>
                          </div>
                        </div>
                        {/*end::Group*/}
                        {/*begin::Group*/}
                        <div className="form-group row">
                          <label className="col-form-label col-3 text-lg-right text-left">User permissions:</label>
                          <div className="col-9">
                            <label className="radio">
                              <input
                                type="radio"
                                value="owner"
                                name="permissions"
                                disabled={true}
                                checked={this.state.permissions === "owner"}
                                onChange={this.handleChange} 
                                style={{ marginRight: '22px' }}
                                />
                              <span />&nbsp; Owner</label>
                            {currentUser.userProfile.permissions === "administrator" || currentUser.userProfile.permissions === "owner" && <label className="radio">
                              <input
                                type="radio"
                                value="administrator"
                                name="permissions"
                                checked={this.state.permissions === "administrator"}
                                onChange={this.handleChange} />
                              <span />&nbsp; Administrator</label>}
                            <label className="radio">
                              <input
                                type="radio"
                                value="crewmember"
                                name="permissions"
                                checked={this.state.permissions === "crewmember"}
                                onChange={this.handleChange} />
                              <span />&nbsp; Crewmember</label>
                            <span className="form-text text-muted">Set the type of role and permission for this user</span>
                          </div>
                        </div>
                        {/*end::Group*/}
                      </div>
                    </div>
                    {/*end::Row*/}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer pb-0">
              <div className="row">
                <div className="col-xl-2" />
                <div className="col-xl-7">
                  <div className="row">
                    <div className="col-3" />
                    <div className="col-9">
                      <button
                        onClick={this.handleSubmit}
                        disabled={!this.isChanged()}
                        className={this.state.loading ? "btn btn-light-primary font-weight-bold mr-4 spinner spinner-white spinner-right mr-" : "btn btn-light-primary font-weight-bold mr-4" }>Save changes</button>
                      <button
                        onClick={()=>{
                          this.props.history.goBack()
                          // this.props.history.push('/settings/activeusers')
                        }}
                        className="btn btn-outline font-weight-bold"
                      >Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmModal
            show={this.state.deleteUserModalActive}
            title="Delete user"
            body={getMessages('delete_user')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteUser(); }
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteCrewModalActive: false }) }
            }}
          />

        </React.Fragment>
      );
    } else {
      return <Loader />;
    }
  }




}
export default ActiveUserEdit;