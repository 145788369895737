export default {
   delete_crewmember: "Are you sure you want to delete this crewmember?",
   delete_client: "Are you sure you want to delete this client?",
   delete_contact: "Are you sure you want to delete this contact?",
   delete_crew: "Are you sure you want to delete this crew?",
   delete_equipment: "Are you sure you want to delete this piece of equipment?",
   delete_schedule: "Are you sure you want to delete repair schedule for this equipment?",
   delete_bore: "Are you sure you want to delete this bore?",
   delete_folder: "Are you sure you want to delete this folder?",
   delete_file: "Are you sure you want to delete this file?",
   remove_crewmember: "Are you sure you want to remove this crewmember from the job?",
   remove_equipment: "Are you sure you want to remove this piece of equipment from the job?",
   delete_user: "Are you sure you want to delete this user?",
   invalid_subscription: "Your subscription is not valid.",
   incorrect_user_pass: "Incorrect username or password.",
   invalid_email: "Email is not valid.",
   something_wrong: "Something went wrong. Please contact customer support.",
   email_taken: "Email is already taken. Please choose another one or contact us (support@equappment.com)",
   pass_lowercase: "Password must contain at least one lowercase letter.",
   pass_uppercase: "Password must contain at least one uppercase letter.",
   pass_digit: "Password must contain at least one digit.",
   pass_characters: "Password must contain at least 8 characters.",
   pass_nomatch: "Passwords do not match.",
   cannot_email: "Cannot find a user with that email.",
   firstname_required: "First name is required.",
   lastname_required: "Last name is required.",
   phone_required: "Phone number is required.",
   invalid_phone: "Phone number is not valid.",
   company_required: "Company name is required.",
   email_required: "Email is required.",
   equipment_id_required: "Equipment ID is required.",
   equipment_make_required: "Make is required.",
   equipment_model_required: "Model is required.",
   year_number: "Year must be a number.",
   year_span: "Year must be between 0 and 2050.",
   equipment_repair: "The type of service needed.",
   repair_details: "Details about the requested equipment service.",
   service_dates: "The dates of service for this piece of equipment.",
   hour_meter: "The hour meter value for this piece of equipment.",
   bore_distance: "The distance drilled since the last bore log entry (ft).",
   bore_depth: "The total depth drilled since the last bore log entry (in).",
   bore_pitch: "The change in pitch since the last bore log entry (%).",
   distance_from_eop: "Distance from end of pavement (ft)",
   bore_details: "Details about the bore since the last log entry.",
   bore_photo: "Photo of the bore",
   no_completed_items: "(No completed items)",
   no_current_items: "(No current items)",
   no_upcoming_items: "(No upcoming items)",
   bore_firstday: "The first day for the bore.",
   bore_last_day: "The last day for the bore.",
   bore_startdate: "Start date is required.",
   bore_end_date: "End date is required.",
   bore_soil_condit: "The main soil condition type for this bore.",
   bore_length: "The total distance expected for this bore (ft).",
   bore_diameter: "The final bore diameter for this bore (in).",
   bore_pipe: "The main type of pipe used for this bore.",
   enter_company: "Enter the name of the company.",
   enter_client_firstname: "Enter the first name of the contact.",
   enter_client_lastname: "Enter the last name of the contact.",
   enter_contact_position: "Enter position of the contact.",
   client_firstname_required: "Client first name is required.",
   client_lastname_required: "Client last name is required.",
   enter_client_email: "Enter the email of the client.",
   enter_contact_email: "Enter the email of the contact.",
   client_email_required: "Client email is required.",
   enter_client_phone: "Enter the phone number of the client.",
   enter_client_address: "Enter the address of the client.",
   enter_client_notes: "Enter client notes",
   enter_contact_notes: "Enter contact notes",
   enter_contact_phone: "Enter the phone number of the contact.",
   client_phone_required: "Client phone number is required.",
   phone_type: "Select phone number type.",
   equipment_identifier: "The unique identifier for this piece of equipment.",
   equipment_make: "The make or manufacturer of this piece of equipment.",
   equipment_model: "The model of this piece of equipment.",
   equipment_serial: "The serial number for this piece of equipment.",
   equipment_year: "The year the equipment was made.",
   equipment_tag: "Tag for this piece of equipment.",
   equipment_description: "Details about the equipment.",
   job_name: "Use a unique name to differentiate this job from others.",
   job_name_required: "Job name is required.",
   job_id: "The unique ID for this job.",
   job_id_required: "Job ID is required.",
   job_startend: "The start and end dates for this job.",
   job_question: "Is this a job or a bid?",
   job_description: "Details about the job.",
   job_footage: "The distance for the job, measured in feet.",
   equipment_name: "The name of the equipment for this job.",
   preset_folder_name: "The name of the folder.",
   equipment_name_required: "Equipment name is required",
   preset_folder_name_required: "Name of the folder is required.",
   equappment_jobtype: "The type of job to keep track of in Equappment.",
   password_required: "Password is required.",
   confirm_pass_required: "Confirmation password is required.",
   delete_job: "Are you sure you want to delete this job? All data related to this job, including reservations and crew schedules, will also be deleted.",
   form_field_description_message_crew_id: "The unique ID for this crew.",
   form_field_description_message_crew_name: "The name for this crew.",
   form_field_description_message_crew_color: "The color for this crew.",
   bore_diameter_validation: "Bore diameter cannot be a negative number.",
   bore_name: "Use a unique name to differentiate this bore from others.",
   bore_name_required: "Bore name is required.",
   bore_finish: "Check here to finish and finalize this bore.",
   bore_crew: "The crew assigned to this bore.",
   degree_required: "Degree is required.",
   degree_unit: "deg",
   degree_span: "Degree value must be between 0 and 360.",
   pitch_required: "Pitch is required.",
   pitch_unit: "%",
   pitch_span: "Pitch value must be between 0 and 100.",
   distance_unit: "feet",
   distance_required: "Distance is required.",
   starting_depth_required: "Starting depth is required.",
   starting_depth_unit: "inches",
   new_depth_required: "New depth is required.",
   bit_or_reamer_size_required: "Bit or reamer size is required.",
   hole_size_required: "Hole size is required.",
   bore_length_required: "Bore length is required.",
   soil_type_required: "Soil Type is required.",
   hole_diameter_required: "Hole diameter is required.",
   rod_length_required: "Rod length is required.",
   pump_output_unit: "gpm",
   pump_output_required: "Pump output is required.",
   number_pipes_required: "Number of pipes is required.",
   pipes_span: "Number of pipes must be between 1 and 40.",
   pipe_diameter_required: "Pipe diameter is required.",
   degree_to_pitch_example: "Example: 15deg = tan(15) * 100 = 26.8%",
   pitch_to_degree_example: "Example: 25% = 1 / tan(100/25) = 14.0deg",
   change_in_depth_example: "Example: SD = 36inches; P = 5%; L = 15feet; sin(1 / tan(100/5)) * 15 * 12 + 36 = 27.4 inches",
   required_pitch_example: "Example: SD = 36inches; ND = 50inches; L = 15feet; tan(1 / sin((15 * 12) / (50 - 36))) * 100 = -7.9%",
   soil_removed_example: "Example: (5\" bit)2 / 24.5 = 1.02 gallons of soil per linear foot",
   required_drill_fluid_example: "Example: 10\" hole in fine sand for 200' bore; 10*10 / 24.5*3 = 12.2 gallons fluid per foot x 200 = 2,449 gallons of fluid",
   pullback_rate_example: "12\"\ hole; 10' rod; Reactive clay; D24x40 S3 with 50 gpm pump === [(12*12)/24.5 * 10 * 5] / 50 = 5.88 min/rod",
   multiple_pipe_size_example: "Bundle of 14 pipes 3\" HDPE (OD = 3.5\"); N=14; d=3.5\"; For N=14, D/d=4.4, bundle size D=4.4 * 3.4 = 15.4\" ",
   notification_title_required: "Notification title is required.",
   notification_msg_required: "Notification message is required",
   required: "Required",
   segment_location:"Segment location",
   add_bore_task:"Add bore task",
   should_we_automatically_add_task_for_bore: "Should we automatically create new task for this bore?"
}