import React, { useContext } from "react";
import {
  CheckCircle,
} from "../../../components/Icons/Svg";
import { AuthContext } from "../../../shared/Auth";

const LandingScreenInfo = () => {
  const context = useContext(AuthContext);

  return (
    <React.Fragment>
      <div className="login-aside pt-lg-40 pl-lg-10  pr-lg-10 pb-lg-0 pl-10 py-20 m-0 justify-content-lg-start">
        <div className="d-flex flex-center-h flex-row-fluid">
          <div className=" d-flex flex-column">
            <a className="login-logo pb-xl-20 pb-15">
              <img
                src="assets/media/logos/logo-eq-landing.png"
                className="max-h-100px"
                alt=""
              />
            </a>
          </div>
        </div>

        <h3 className=" font-weight-boldest text-white">
          <div className="d-flex flex-row align-items-center text-dark">
            <div className="mr-5">
              <CheckCircle />
            </div>
            Significantly streamline project management processes
          </div>
          <div className="d-flex flex-row align-items-center text-dark mt-8">
            <div className="mr-5">
              <CheckCircle />
            </div>
            Have a single source for all operational information
          </div>
          <div className="d-flex flex-row align-items-center text-dark mt-8">
            <div className="mr-5">
              <CheckCircle />
            </div>
            Maximize the utilization of your workforce & equipment
          </div>
          <div className="d-flex flex-row align-items-center text-dark mt-8">
            <div className="mr-5">
              <CheckCircle />
            </div>
            Get your new hires up to speed & productive faster
          </div>
        </h3>
        <br />
        {!context.isMobile &&
        <div>
        <hr color="grey" />
          <div className="d-flex d-row font-weight-boldest text-dark">
            <img
              src="assets/media/equappment/anderson_underground_logo.png"
              className="w-25 h-25 pr-5"
            />
            "The Equappment app has proven to be a valuable organizational tool
            in my business. Being able to track resources, monitor schedules,
            and keep projects on track, all in the palm of my hand in an
            easy-to-use package has been very helpful in my daily operations."
            <br /> - Nick Anderson, President
          </div>
          <hr color="grey" />
          <div className="text-dark pt-10">
            <h5>
              Questions?
              <br />
              Email us at{" "}
              <a href="mailto:support@equappment.com" className="navi-item">
                support@equappment.com
              </a>
              <br />
              If you want to quickly see if it is a good fit, you can{" "}
              <a href="https://www.equappment.com/live-demo">request a demo</a>
            </h5>
          </div>
        </div>
        }
      </div>
    </React.Fragment>
  );
};

export default LandingScreenInfo;
