import { UserEntity } from "./UserEntity";

export class ContextEntity {
    currentUser?: UserEntity | null = null
    isMobile: boolean = false
    isTrial:boolean = false
    isTutorial: boolean = false
    jobIdBack?: string | null = null
}

export class AppState{
    job?: JobAppState | null = null
}

export class JobAppState{
    calendarView?: String | null = null
    calendarDate?: String | null = null
}
