import React, { Component } from 'react';
import { getEquipment, addMaterial, getMaterial, uploadImage, getReservations, getCustomChecklists } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, fieldsToObject } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';
import { Materials } from '../../../../components/Icons/Svg';

class MaterialsStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        savingMaterial: false,
        equipment: [],
        addEquipmentShow: false,
        search: '',
        materials: this.props.obj.materials ? this.props.obj.materials : [],
        newEquipmentForm: {
            name: 'add-new-equipment',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'title',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Equipment Name',
                    placeholder: 'Equipment Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('equipment_name_required')
                        }
                    ]
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Description (optional)',
                    placeholder: 'Description',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_description'),
                    required: false,
                    rows: 4
                },
                {
                    name: 'image',
                    value: '',
                    defaultValue: '',
                    type: 'dropzone',
                    label: 'Equipment Photo',
                    placeholder: 'Equipment Photo',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: '',
                    required: false,
                    localSrc: '',
                    onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
                    onRemove: () => { this.onImageRemove(); }
                }
            ],
            buttons: [
                {
                    title: 'Add Equipment',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addMaterial(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewEquipmentForm(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        this.props.setStep('materials');
    }

    onImageDrop = (acceptedFiles) => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        const loc = window.URL.createObjectURL(acceptedFiles[0]);

        imageField.value = acceptedFiles[0];
        imageField.localSrc = loc;
        this.setState({ newEquipmentForm: form });
    }

    onImageRemove = () => {
        let form = { ...this.state.newEquipmentForm };
        let imageField = form.fields.find(x => x.name === 'image');

        imageField.value = '';
        imageField.localSrc = '';
        this.setState({ newEquipmentForm: form });
    }

    loadEquipment = async () => {
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        const equipment = await getCustomChecklists(inventoryId, true);
        this.setState({ equipment: equipment.data, loading: false });
    }

    addMaterial = () => {
        this.setState({savingMaterial: true})
        const equipment = fieldsToObject(this.state.newEquipmentForm.fields);
        const imageField = this.state.newEquipmentForm.fields.find(x => x.name === 'image');
        if (imageField.value) {
            equipment.imageField = {...imageField};
            equipment.imageTimeStamp = new Date()
        }

        let materials = [...this.state.materials];
        materials.push(equipment);

        this.setState({ materials: materials });
        this.props.setJobMaterials(materials)

        this.closeAddNewEquipmentForm();
        this.setState({savingMaterial: false})
    }

    next = () => {
        this.props.setJobMaterials(this.state.materials);
        this.props.history.push('/jobs/new/crewmembers');
    }

    closeAddNewEquipmentForm = () => {
        let updatedForm = { ...this.state.newEquipmentForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            newEquipmentForm: updatedForm,
            addEquipmentShow: false
        });
    }

    prepareAddEquipmentForm = () => {
        let form = this.state.newEquipmentForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newEquipmentForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    equipment = () => {
        if (!this.state.search)
            return this.state.materials;

        return this.state.materials.filter(x => x.title.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    render() {
        const addEquipmentForm = this.prepareAddEquipmentForm();
        const equipment = this.equipment();

        return (
            <>
                <h3 className="mb-10 font-weight-bold text-dark">Materials</h3>

                {equipment.length > 0 &&
                    <>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                onChange={(e) => { this.setState({ search: e.target.value }); }}
                                placeholder="Search Materials" />
                        </div>

                        <button onClick={() => this.setState({ addEquipmentShow: true })}
                            className="btn btn-block btn-light-primary font-weight-bold mb-8">
                            <i className="ki ki-plus icon-md mr-2" />Create custom equipment</button>

                        {this.state.addEquipmentShow &&
                            <div className="mb-20">
                                <BasicForm {...addEquipmentForm} />
                            </div>
                        }
                    </>
                }

                {this.state.loading
                    ?
                    <Loader height="100px" />
                    :
                    <div>
                        {equipment.length > 0 ?
                            <>
                                {equipment.map((equipment,index) => (
                                    <div key={"mtrls-stp"+index} className="d-flex align-items-center mb-10">
                                        <div className="d-flex align-items-center">

                                            <div className="symbol symbol-100 symbol-sm flex-shrink-0">
                                                {equipment.imageField && equipment.imageField.localSrc ?
                                                    <img className="" src={equipment.imageField.localSrc} alt="photo" />
                                                    : <span className="symbol-label"></span>}
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                                            <a className="text-dark text-hover-primary mb-1 font-size-lg">
                                                {equipment.title}
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </>
                            :
                            <>
                                <div className="mt-15" style={{ textAlign: 'center' }}>
                                <div className='mainpage-graphic'>
                                    <Materials width={'200px'} height={'200px'} />
                                </div>
                                    <h6>No materials or custom equipment found.</h6>
                                    <span>Add materials and custom equipment to be able to easily keep track of them for this job.</span>
                                </div>
                                <button onClick={() => this.setState({ addEquipmentShow: true })}
                                    className="btn btn-block btn-light-primary font-weight-bold mb-8 mt-10">
                                    <i className="ki ki-plus icon-md mr-2" />Create custom equipment</button>

                                {this.state.addEquipmentShow &&
                                    <div className="mb-20">
                                        <BasicForm {...addEquipmentForm} />
                                    </div>
                                }
                            </>
                        }

                        <div className="justify-content-between border-top mt-5 pt-10">
                            <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                                onClick={this.next}>Next</button>
                            <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                                onClick={this.props.save}
                            >Skip and Save</button>
                            <Link className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                                to='/jobs/new/equipment'>Back</Link>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default MaterialsStep;