import React from 'react'

const Pagination = ({postsPerPage, totalPosts, paginate, currentPage}) => {
   const pageNumbers=[];

   for(let i =1; i<=Math.ceil(totalPosts/postsPerPage);i++) {
      pageNumbers.push(i);
   }

   
   return (
  
   <div>
      <div className="card card-custom">
         <div className="card-body py-7">
            {/*begin::Pagination*/}
            <div className="d-flex justify-content-between align-items-center flex-wrap">
               <div className="d-flex flex-wrap mr-3">
               {currentPage!==1 && <a onClick={()=>paginate(1)} href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                  <i className="ki ki-bold-double-arrow-back icon-xs" />
               </a> }
               {currentPage!==1 &&  <a onClick={()=>paginate(currentPage-1)}  href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                   <i className="ki ki-bold-arrow-back icon-xs" />
                </a> }
                  <ul className="nav">
                  {pageNumbers.map((number,index)=>(
                     <a
                     key={"pgntn-nmbrs" + index}
                     onClick={()=>paginate(number)} 
                     href="#" 
                     className={currentPage===number?"btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1":"btn btn-icon btn-sm border-0 btn-hover-primary mr-2 my-1"}>{number}
                     </a>
                  ))}
                  </ul>
               {currentPage!==pageNumbers.length && <a onClick={()=>paginate(currentPage+1)} href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                     <i className="ki ki-bold-arrow-next icon-xs" />
                  </a> }
               {currentPage!==pageNumbers.length && <a onClick={()=>paginate(pageNumbers.length)} href="#" className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                     <i className="ki ki-bold-double-arrow-next icon-xs" />
                  </a> }
               </div>
                  <div className="d-flex align-items-center">
                     <span className="text-muted">Displaying {postsPerPage>totalPosts ? totalPosts : postsPerPage} of {totalPosts} records</span>
                  </div>
            </div>
    {/*end:: Pagination*/}
         </div>
      </div>
         
   </div>
   )
}

export default Pagination;

