import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CheckOutInItem from '../../components/Layout/CheckOutInItem/CheckOutInItem';
import * as moment from 'moment';
import { getUnixDate } from '../../shared/Util';
import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import { EquipmentIos } from '../../components/Icons/Svg';

class JobChecklist extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;
    canEdit = this.user.permissions !== 'crewmember';

    componentDidMount() {
        this.props.setTabActive('checklist');
    }

    render() {
        return (
            <>
                {this.canEdit &&
                <div className="row">
                    <div className="col-xl-2">
                        <div className="form-group">
                            <Link
                                to={'/jobs/' + this.props.job.id + '/custom_equipment/new'}
                                className='btn btn-primary font-weight-bold mr-4 w-100'>Add new material</Link>
                        </div>
                    </div>
                </div>}
                <div className="row">
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Check-out List</a>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                                {this.props.checklistsLoading ? <Loader height="100px"/> :
                                <div className="card-body pt-2 d-flex flex-column justify-content-between">
                                {this.props.checkoutList.length > 0 ?
                                    <>
                                        {this.props.checkoutList.map((item, index) => {
                                            return (
                                                <Link key={"jb-chklst-chkout"+index} to={"/jobs/" + this.props.job.id + "/checkout/" + getUnixDate(item.date)}>
                                                    <div className="d-flex align-items-center mb-1">
                                                        <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                            <CheckOutInItem id={item.date+Math.random().toString()} percentage={item.percentage} />
                                                        </div>
                                                        <div className="d-flex flex-column">
                                                            <h6 className="font-weight-bold text-dark">Equipment Check-out List</h6>
                                                            <h6 className="font-weight-bold text-dark">{moment(item.date).format("dddd, MMMM D, YYYY")}</h6>
                                                            <h6 className="text-muted font-weight-bold">{item.checkedOutNumber} of {item.reservations.length} equipment checked out</h6>
                                                        </div>
                                                    </div>
                                                </Link>
                                            );
                                        })}
                                    </>
                                    :
                                    <div className="d-flex align-items-center mb-10">
                                        <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                        <div className='mainpage-graphic'>
                                            <EquipmentIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                        </div>
                                            <span className="font-italic">There is no equipment to be checked out.</span>
                                        </div>
                                    </div>
                                    }
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Check-in List</a>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                            {this.props.checklistsLoading ? <Loader height="100px"/> :
                            <div className="card-body pt-4 d-flex flex-column justify-content-between">
                            {this.props.checkinList.length > 0 ?
                                <>
                                    {this.props.checkinList.map((item, index) => {
                                        return (
                                            <Link key={"jb-chklst-chkin"+index} to={"/jobs/" + this.props.job.id + "/checkin/" + getUnixDate(item.date)}>
                                                <div className="d-flex align-items-center mb-1">
                                                    <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                        <CheckOutInItem id={item.date+Math.random().toString()} percentage={item.percentage} />
                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h6 className="font-weight-bold text-dark">Equipment Check-in List</h6>
                                                        <h6 className="font-weight-bold text-dark">{moment(item.date).format("dddd, MMMM D, YYYY")}</h6>
                                                        <h6 className="text-muted font-weight-bold">{item.checkedInNumber} of {item.reservations.length} equipment checked in</h6>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                                :
                                <div className="d-flex align-items-center mb-10">
                                    <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                        <div className='mainpage-graphic'>
                                            <EquipmentIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                        </div>
                                        <span className="font-italic">There is no equipment to be checked in.</span>
                                    </div>
                                </div>
                                }
                        </div>
                        }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default JobChecklist;