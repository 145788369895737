import { Timestamp } from "firebase/firestore";

export interface FileEntity {
  id?: string | null;
  name?: string | null;
  fileName?: string | null;
  readonly?: boolean;
  parentId?: string | null;
  createdBy?: string | null;
  createdAt?: Timestamp | null;
  url?: string | null;
  fileType: FileType
  description?: string | null
  createdByID?: string | null
  lat?: Number | null
  lng?: Number | null,
  file?: File | null
}

export interface FolderEntity {
  id: string;
  name: string;
  readonly: boolean;
  parentId: string | null;
  createdBy: string | null;
  createdByID: string | null
  createdAt: Timestamp | null;
  description: string | null
  children: (FolderEntity | FileEntity)[];
}

export class FileEntityDto {
  type: FileType
  createdAt?: Timestamp | null = null
  fileName?: string | null = null
  desc?: string | null = null
  name?: string | null = null
  userName?: string | null = null
  userID?: string | null = null
  lat?: Number | null = null
  lng?: Number | null = null
  categoryID?: string | null = null
  url?: string | null = null

  constructor(fileType:FileType){
    this.type = fileType
  }
}

export function toFileEntity(fileEntityDto: FileEntityDto, documentId?: string | null): FileEntity {
  const fileType:FileType = fileEntityDto.type=="photo" ? "image" : fileEntityDto.type
  return {
    id: documentId,
    fileType: fileType,
    description: fileEntityDto.desc,
    name: fileEntityDto.name,
    fileName: fileEntityDto.fileName,
    createdAt: fileEntityDto.createdAt,
    createdBy: fileEntityDto.userName,
    createdByID: fileEntityDto.userID,
    lat: fileEntityDto.lat,
    lng: fileEntityDto.lng,
    parentId: fileEntityDto.categoryID,
    readonly: false,
    url: fileEntityDto.url ?? ""
  }
}

export function toFileEntityDto(fileEntity: FileEntity): FileEntityDto {
  return {
    type: fileEntity.fileType,
    createdAt: fileEntity.createdAt ?? null,
    fileName: fileEntity.fileName ?? null,
    desc: fileEntity.description ?? null,
    name: fileEntity.name ?? null,
    userName: fileEntity.createdBy ?? null,
    userID: fileEntity.createdByID ?? null,
    lat: fileEntity.lat ?? null,
    lng: fileEntity.lng ?? null,
    categoryID: fileEntity.parentId ?? null,
    url: fileEntity?.url
  }
}

export type FileType = "document" | "photo" | "image" | "other"