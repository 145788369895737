import React, { useEffect, useRef, useState } from "react";
import EQInputText from "../../../components/Form/Inputs/EQInputText";
import { convertToNumberOrNull } from "../../../shared/Utils";

interface FinishBoreModalProps {
  show: boolean;
  onFinishBore: (duration: number, footage: number) => void;
  onCancel: () => void;
}

interface FormState {
  totalFootage: number | null;
  totalDuration: number | null;
}

const FinishBoreModal = (props: FinishBoreModalProps) => {
  const hiddenBtnRef = useRef<HTMLButtonElement>(null);

  const [form, setForm] = useState<FormState>({
    totalFootage: null,
    totalDuration: null,
  });

  useEffect(() => {
    if (props.show) hiddenBtnRef?.current?.click();
  }, [props.show]);

  const canFinishBore = () => {
    return form.totalFootage != null && form.totalDuration != null;
  };

  return (
    <React.Fragment>
      {props.show ? (
        <div>
          <div
            className="modal fade"
            id={"finish-bore-dialog"}
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden={false}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="finish-bore-modal-label">
                    Finish Bore
                  </h5>
                </div>
                <div className="modal-body">
                  <EQInputText
                    title="Total duration for this bore"
                    placeholder="Enter duration"
                    isNumerical={true}
                    required={true}
                    onOnInputChange={(value) => {
                      setForm((previousValue) => {
                        return {
                          ...previousValue,
                          totalDuration: convertToNumberOrNull(value),
                        };
                      });
                    }}
                  />
                  <EQInputText
                    title="Total footage for this bore"
                    placeholder="Enter footage"
                    isNumerical={true}
                    required={true}
                    onOnInputChange={(value) => {
                      setForm((previousValue) => {
                        return {
                          ...previousValue,
                          totalFootage: convertToNumberOrNull(value),
                        };
                      });
                    }}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    disabled={!canFinishBore()}
                    onClick={() => {
                      if (
                        form.totalDuration != null &&
                        form.totalFootage != null
                      )
                        props.onFinishBore(
                          form.totalDuration,
                          form.totalFootage
                        );
                    }}
                    type="button"
                    className={"btn btn-primary font-weight-bold"}
                    data-dismiss="modal"
                  >
                    Finish bore
                  </button>
                  <button
                    onClick={() => {
                      setForm({ totalDuration: null, totalFootage: null });
                      props.onCancel();
                    }}
                    type="button"
                    className="btn btn-light-secondary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{ display: "none" }}
            ref={hiddenBtnRef}
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target={"#finish-bore-dialog"}
          ></button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default FinishBoreModal;
