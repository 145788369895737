import React from 'react';

const TextInput = (props) => {
    return (
        <input
            type="text"
            className={props.className}
            // autoFocus={props.index === 0}
            value={props.field.value}
            placeholder={props.field.placeholder}
            name={props.field.name}
            onChange={props.handleChange}
            onBlur={(event) => {
                if(props.touchField) props.touchField(props.field);
                if(props.onBlur) props.onBlur(event);
            }}
            id={props.id}
            data-toggle={props.field.dataToggle}
            title={props.field.title}
            data-placement={props.field.dataPlacement}
        />
    );
}

export default TextInput;