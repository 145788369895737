import { Timestamp } from "firebase/firestore";
import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import { parseFromFirebaseDate } from "../../shared/Util";
import moment from "moment";

interface DatePickerModalProp {
  show: boolean;
  confirmationButtonText?: string;
  startDate?: Timestamp | null;
  endDate?: Timestamp | null;
  onCancel?: () => void;
  onConfirm?: (startDate: Timestamp, endDate: Timestamp) => void;
}

const DatePickerModal = ({
  show = false,
  confirmationButtonText = undefined,
  startDate = null,
  endDate = null,
  onCancel = () => {},
  onConfirm = () => {},
}: DatePickerModalProp) => {
  const [startEndDate, setStartEndDate] = useState<DatePickerModalProp>({
    show: show,
    startDate: startDate,
    endDate: endDate,
  });

  const hiddenBtnRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (show) hiddenBtnRef?.current?.click();
  }, [show]);

  return (
    <React.Fragment>
      {show ? (
        <div>
          <div
            className="modal fade"
            id={"date-range-selector-dialog"}
            data-backdrop="static"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden={false}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Select report start and end date
                  </h5>
                </div>
                <div className="modal-body">
                  <div>
                    <label className="mr-10">Select start date</label>
                    <input
                      className=" mr-10 form-control"
                      type="date"
                      value={parseFromFirebaseDate(
                        startEndDate.startDate,
                        "YYYY-MM-DD"
                      )}
                      min={parseFromFirebaseDate(startDate, "YYYY-MM-DD")}
                      max={parseFromFirebaseDate(endDate, "YYYY-MM-DD")}
                      onChange={(input) => {
                        setStartEndDate((previousValue) => {
                          return {
                            ...previousValue,
                            startDate: Timestamp.fromDate(
                              moment(input.target.value).toDate()
                            ),
                          };
                        });
                      }}
                    ></input>
                  </div>
                  <div>
                    <label className="mr-10">Select end date</label>
                    <input
                      className="mr-10 form-control"
                      type="date"
                      value={parseFromFirebaseDate(
                        startEndDate.endDate,
                        "YYYY-MM-DD"
                      )}
                      min={parseFromFirebaseDate(startDate, "YYYY-MM-DD")}
                      max={parseFromFirebaseDate(endDate, "YYYY-MM-DD")}
                      onChange={(input) => {
                        setStartEndDate((previousValue) => {
                          return {
                            ...previousValue,
                            endDate: Timestamp.fromDate(
                              moment(input.target.value).toDate()
                            ),
                          };
                        });
                      }}
                    ></input>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    disabled={!startEndDate?.startDate || !startEndDate.endDate}
                    onClick={() => {
                      if (startEndDate.startDate && startEndDate.endDate)
                        onConfirm(
                          startEndDate.startDate,
                          startEndDate?.endDate
                        );
                    }}
                    type="button"
                    className={"btn btn-primary font-weight-bold"}
                    data-dismiss="modal"
                  >
                    {confirmationButtonText ?? "Send report"}
                  </button>
                  <button
                    onClick={onCancel}
                    type="button"
                    className="btn btn-light-secondary font-weight-bold"
                    data-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{ display: "none" }}
            ref={hiddenBtnRef}
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target={"#date-range-selector-dialog"}
          ></button>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DatePickerModal;
