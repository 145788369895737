import { SubscriptionPlanCode } from "./Subscription"


export enum SubscriptionTierUI {
    TIER1,
    TIER2,
    TIER3
}

export type SubscriptionPlanTypeUI = keyof typeof SubscriptionPlanCode

export class SubscriptionUI {
    id: string
    name: string
    description?: string | null
    price: string
    sectionsDescription?: string = ""
    includedSections: PlanSectionUI[] = []
    nextPlanDate?:string | null = null

    constructor(id: string, name: string, price: string, description?: string, sectionsDescription?: string, includedSections: PlanSectionUI[] = []) {
        this.id = id
        this.name = name
        this.price = price
        this.description = description
        this.sectionsDescription = sectionsDescription
        this.includedSections = includedSections
    }
}

export class PlanSectionUI {
    name: string
    constructor(name: string) {
        this.name = name
    }
}

export const generateMonthlyPlans = () => {
    const monthlyPlan: SubscriptionUI[] = [
        new SubscriptionUI(SubscriptionPlanCode.T1_MONTH.toString(), 'Steel', "15/user/month", "Manage all your assets in one place ... plus some extra productivity tools.", "", buildSteelSections()),
        new SubscriptionUI(SubscriptionPlanCode.T2_MONTH.toString(), 'Carbide', "29/user/month", "Manage all the moving parts of the jobs to maximize your team's production.", "All the tools of Steel plus:", buildCarbideSections()),
        // new SubscriptionUI(SubscriptionPlanCode.T3_MONTH.toString(), 'Diamond', "49/user/month", "Complete single source system for your utility construction jobs.", "All the power tools of Carbide plus:", buildDiamondSections()),
    ]

    return monthlyPlan
}


export const generateYearlyPlans = () => {
    const monthlyPlan: SubscriptionUI[] = [
        new SubscriptionUI(SubscriptionPlanCode.T1_YEAR.toString(), 'Steel', "145/user/year", "Manage all your assets in one place ... plus some extra productivity tools.", "", buildSteelSections()),
        new SubscriptionUI(SubscriptionPlanCode.T2_YEAR.toString(), 'Carbide', "275/user/year",  "Manage all the moving parts of the jobs to maximize your team's production.", "All the tools of Steel plus:", buildCarbideSections()),
        // new SubscriptionUI(SubscriptionPlanCode.T3_YEAR.toString(), 'Diamond', "470/user/year", "Complete single source system for your utility construction jobs.", "All the power tools of Carbide plus:", buildDiamondSections()),
    ]

    return monthlyPlan
}

export const buildSteelSections = () => {
    const steelSections: PlanSectionUI[] = [
        new PlanSectionUI('Equipment and tooling management'),
        new PlanSectionUI('Equipment service workorders'),
        new PlanSectionUI('Crewmember management'),
        new PlanSectionUI('Crews'),
        new PlanSectionUI('Soil mapping'),
        new PlanSectionUI('Dump site mapping'),
        new PlanSectionUI('HDD calculators'),
        new PlanSectionUI('Equipment and tooling import'),
        new PlanSectionUI('User roles and permissions'),

    ]
    return steelSections
}

export const buildCarbideSections = () => {
    const carbide: PlanSectionUI[] = [
        new PlanSectionUI('Job management and scheduling'),
        new PlanSectionUI('Document storage and organization'),
        new PlanSectionUI('Job photo storage and organization'),
        new PlanSectionUI('Task management and time logging'),
        new PlanSectionUI('Task progress dashboard'),
        new PlanSectionUI('Bore logging'),
        new PlanSectionUI('Production reports'),
        new PlanSectionUI('Job production data exports'),
        new PlanSectionUI('Job folder export'),
        new PlanSectionUI('Client database'),



    ]
    return carbide
}

export const buildDiamondSections = () => {
    const carbide: PlanSectionUI[] = [
        new PlanSectionUI('Locate ticket management system'),
        new PlanSectionUI('Digital forms'),
    ]
    return carbide
}