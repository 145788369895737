import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { SegmentEntity } from "../../../shared/data/taskmanagement/Segment";
import { TaskEntity } from "../../../shared/data/taskmanagement/Task";
import EQMarker from "../../../components/GoogleMap/EQMarker";
import Loader from "../../../components/Layout/Loader/Loader";
import { calculateDistance, calculateMiddlePoint } from "../../../shared/Util";
import { JobEntity } from "../../../shared/data/job/Job";
import { useHistory } from "react-router-dom";

interface TaskManagementMapProps {
  job: JobEntity;
  segments?: SegmentEntity[];
  tasks?: TaskEntity[];
  isLoading:boolean;
}

export const TaskManagementMap = ({
  job,
  segments,
  tasks,
  isLoading
}: TaskManagementMapProps) => {
  const history = useHistory();
  const [mapState, setMapState] = useState({
    zoom: 10,
    center: {
      lat: 37.42216,
      lng: -122.08427,
    },
    draggable: true,
    editable: false,
    segments: segments,
    clickedLat: null,
    clickedLng: null,
    googleApiLoaded: false,
  });

  const getSegmentTasks = (segmentId?: string) => {
    return tasks?.filter((task) => task.segmentID == segmentId);
  };

  function getAllTasks(): TaskEntity[] {
    return tasks ?? [];
  }

  const showMarkerInfo = (lat?: number, lng?: number) => {
    return mapState?.clickedLat == lat && mapState?.clickedLng == lng;
  };

  const onMarkerClicked = (location: any) => {
    setMapState((previousState) => {
      return {
        ...previousState,
        clickedLat: location.lat,
        clickedLng: location.lng,
        center: { lat: location.lat, lng: location.lng },
      };
    });
  };

  function fitBounds(map: any, maps: any) {
    const bounds = new maps.LatLngBounds();
    const tasks = getAllTasks()
    tasks.forEach((task) => {
      bounds.extend(new maps.LatLng(task.lat, task.lng));
    });
    segments?.forEach((segment) => {
      bounds.extend(new maps.LatLng(segment.startLat, segment.startLng));
      bounds.extend(new maps.LatLng(segment.endLat, segment.endLng));
    });
    if (segments?.length || tasks?.length) map.fitBounds(bounds);
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(map, maps, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  }

  return (
    <>
      {isLoading ? (<Loader height={"320px"} />):(<div>
      {segments && (
        <React.Fragment>
          <div className="form-group">
            {job && (
              <div style={{ height: "50vh", width: "100%" }}>
                <GoogleMapReact
                  options={{
                    mapTypeControl: true,
                    mapTypeControlOptions: {
                      style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                    },
                    mapTypeId: "satellite",
                  }}
                  draggable={mapState.draggable}
                  bootstrapURLKeys={{
                    key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
                  }}
                  defaultZoom={mapState.zoom}
                  center={mapState.center}
                  yesIWantToUseGoogleMapApiInternals={false}
                  onGoogleApiLoaded={({ map, maps }) => {
                    fitBounds(map, maps);
                    segments?.map((segment) => {
                      new maps.Circle({
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.1,
                        map: map,
                        center: calculateMiddlePoint(
                          segment.startLat,
                          segment.startLng,
                          segment.endLat,
                          segment.endLng
                        ),
                        radius:
                          calculateDistance(
                            segment.startLat,
                            segment.startLng,
                            segment.endLat,
                            segment.endLng
                          ) / 2,
                      });
                    });
                  }}
                >
                  {getAllTasks().map((task, index) => {
                    return (
                      <EQMarker
                        key={index}
                        lat={task.lat}
                        lng={task.lng}
                        pinColor={"darkgreen"}
                        infoText={`Task: ${task.title} ID: ${task.taskID}`}
                        onClick={onMarkerClicked}
                        show={showMarkerInfo(
                          task?.lat ?? undefined,
                          task?.lng ?? undefined
                        )}
                        onInfoClicked={() => {
                          history.push({
                            pathname: `/jobs/${task?.jobID}/tasks/${task?.id}`,
                            state: {
                              task: task,
                            },
                          });
                        }}
                      />
                    );
                  })}
                  {segments.map((segment, index) => {
                    return (
                      <EQMarker
                        key={index}
                        lat={segment.startLat}
                        lng={segment.startLng}
                        pinColor={"blue"}
                        icon={"flaticon2-pin"}
                        infoText={`Segment start location Name: ${segment.title} ID: ${segment.segmentID}`}
                        onClick={onMarkerClicked}
                        show={showMarkerInfo(
                          segment.startLat,
                          segment.startLng
                        )}
                        onInfoClicked={() => {
                          history.push({
                            pathname: `/jobs/${segment?.jobID}/segments/${segment?.id}`,
                            state: {
                              segment: segment,
                              segmentTasks: getSegmentTasks(segment?.id ?? ""),
                              job: job,
                            },
                          });
                        }}
                      />
                    );
                  })}
                  {segments.map((segment, index) => {
                    return (
                      <EQMarker
                        key={index + 1}
                        lat={segment.endLat}
                        lng={segment.endLng}
                        pinColor={"blue"}
                        icon={"flaticon2-pin"}
                        infoText={`Segment end location Name: ${segment.title} ID: ${segment.segmentID}`}
                        onClick={onMarkerClicked}
                        show={showMarkerInfo(segment.endLat, segment.endLng)}
                        onInfoClicked={() => {
                          history.push({
                            pathname: `/jobs/${segment?.jobID}/segments/${segment?.id}`,
                            state: {
                              segment: segment,
                              segmentTasks: getSegmentTasks(segment?.id ?? ""),
                              job: job,
                            },
                          });
                        }}
                      />
                    );
                  })}
                </GoogleMapReact>
              </div>
            )}
          </div>
        </React.Fragment>
        
      )}
      </div>)}
    </>
  );
};

export default TaskManagementMap;
