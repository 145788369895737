import React, { Component } from 'react';
import BasicForm from '../../components/Form/BasicForm';
import loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { deleteSoilCondition, getSoilCondition, updateSoilCondition } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';
import { fieldsToObject, objectToFields, touchField, updateFormOnChange } from '../../shared/FormHelper';

class SoilConditionsEdit extends Component {
    static contextType = AuthContext;

    state = {
        soilCondition: null,
        deleteConditionSelected: false,
        soilConditionForm: {
            name: 'edit-soil-condition',
            isValid: true,
            submitErrorMessage: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'soilConditions',
                    value: 'None',
                    defaultValue: 'None',
                    type: 'select',
                    label: 'Soil Conditions',
                    placeholder: 'Soil Conditions',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: false,
                    allowCustom: true,
                    custom: "Other",
                    customValue: "",
                    options: [
                        {
                            value: 'Caliche',
                            display: 'Caliche'
                        },
                        {
                            value: 'Clay',
                            display: 'Clay'
                        },
                        {
                            value: 'Cobbles',
                            display: 'Cobbles'
                        },
                        {
                            value: 'Dirt',
                            display: 'Dirt'
                        },
                        {
                            value: 'Gravel',
                            display: 'Gravel'
                        },
                        {
                            value: 'Hardpan',
                            display: 'Hardpan'
                        },
                        {
                            value: 'Sand',
                            display: 'Sand'
                        },
                        {
                            value: 'Sandstone',
                            display: 'Sandstone'
                        },
                        {
                            value: 'Shale',
                            display: 'Shale'
                        }
                    ],
                },
                {
                    name: 'depth',
                    value: '',
                    defaultValue: '',
                    type: 'number',
                    label: 'Depth',
                    placeholder: 'Depth',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: 'Depth is required.'
                        }
                    ]
                },
                {
                    name: 'location',
                    value: {
                        lat: null,
                        long: null
                    },
                    defaultValue: '',
                    type: 'location',
                    label: 'Location',
                    placeholder: 'Location',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "The location for this soil condition.",
                    onSuggest: (e) => this.onSuggestSelect(e),
                    setLoc: (e) => this.setLocation(e),
                },
                {
                    name: 'publicEntry',
                    value: false,
                    defaultValue: false,
                    type: 'checkbox',
                    label: 'Public Entry',
                    placeholder: 'Public Entry',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "Make soil condition for this location public to Equappment community. Your personal data will not be shared.",
                    required: false
                },
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.editSoilCondition(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Delete',
                    className: 'btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.setState({deleteConditionSelected: true}),
                    disabled: () => false
                }
            ]
        }
    }
    
    componentDidMount = async () => {
        await this.loadSoilCondition();
      }

    loadSoilCondition = async () => {
        const id = this.props.match.params.id;
        const currentUser = this.context.currentUser;

        const soilConditionResult = await getSoilCondition(id);
        const form = { ...this.state.soilConditionForm };
        const fields = objectToFields(form.fields, soilConditionResult.data);
        
        form.fields = fields;
        
        this.setState({soilCondition: soilConditionResult.data, soilConditionForm: form});
    }

    deleteCondit = async () => {
        const id = this.props.match.params.id;
        await deleteSoilCondition(id);
        this.setState({deleteConditionSelected: false});
        this.props.history.goBack()
        // this.props.history.push('/soilconditions/');
        
    }

    editSoilCondition = async () => {
        const form = { ...this.state.soilConditionForm };
        form.saving = true;
        this.setState({ soilConditionForm: form });
        const id = this.props.match.params.id;

        const soilCondition = fieldsToObject(this.state.soilConditionForm.fields);
        soilCondition.lat = soilCondition.location.lat;
        soilCondition.long = soilCondition.location.long;
        
        delete soilCondition.location;
        const soilConditionResult = await updateSoilCondition(id, soilCondition);
        
        form.saving= false;
        this.setState({ soilConditionForm: form });
        this.props.history.goBack()
        // this.props.history.push('/soilconditions/' + id);
    }

    prepareEditSoilConditionForm = () => {
        let form = this.state.soilConditionForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.soilConditionForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.soilConditionForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    onSuggestSelect = (suggest) => {
        let form = this.state.soilConditionForm;
        let fields = [...form.fields];

        let locationField = fields.find(x => x.name === 'location');

        if(suggest && suggest.location) {
            locationField.value = {
                lat: suggest.location.lat,
                long: suggest.location.lng
            }
            this.setState({ soilConditionForm: form });
        }
    } 
    setLocation = (coords) => {
        
        let form = this.state.soilConditionForm;
        let fields = [...form.fields];
        
        let locationField = fields.find(x => x.name === 'location');
        
        locationField.value = {
            lat: coords.lat,
            long: coords.lng
        }
        
        this.setState({ soilConditionForm: form });
    }   
      render() {
          if(!this.state.soilCondition) {
              return loader
          }
        const soilConditionForm = this.prepareEditSoilConditionForm();
        
        return(
            <>
                <div className="card">
                    <div className="card-body">
                        <h3 className="mb-10 font-weight-bold text-dark">Edit Soil Condition</h3>
                        <div className="mb-20">
                            <BasicForm {...soilConditionForm} />
                        </div>
                    </div>
                </div>
                <ConfirmModal
                show={this.state.deleteConditionSelected}
                title="Delete Soil Condition"
                body="Are you sure you want to delete this soil condition?"
                yesButton={{
                    title: "Yes",
                    onClick: () => { this.deleteCondit(); }
                }}
                noButton={{
                    title: "Cancel",
                    onClick: () => { this.setState({ deleteConditionSelected: false }) }
                }}
                />
            </>
            
        );
    }
}

export default SoilConditionsEdit;