import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface DropZoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  supportedFileTypes?: string;
}

const DropZone = (props: DropZoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      props.onDrop(acceptedFiles);
    },
    [props]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <React.Fragment>
      <div
        className={
          isDragActive
            ? "dropzone dropzone-default dropzone-primary dz-clickable"
            : "dropzone dropzone-default dz-clickable"
        }
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className="dropzone-msg dz-message needsclick">
          <h3 className="dropzone-msg-title">
            Drop files here or click to upload.
          </h3>
          {props.supportedFileTypes ? (
            <span className="dropzone-msg-desc">
              {props.supportedFileTypes}
            </span>
          ) : (
            <span className="dropzone-msg-desc">{props.supportedFileTypes??""}</span>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DropZone;
