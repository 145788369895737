import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Company, Email, Phone, Shield } from '../../components/Icons/Svg';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import app, { functions, db } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import { normalizeInput } from '../../shared/FormHelper';
import InputTextModal from '../../components/Modals/InputTextModal';
import { showToast } from '../../shared/Util';

class Profile extends Component {
  static contextType = AuthContext;

  state = {
    user: null,
    loading: false,
    deleteAccountModalActive: false,
    confirmEmailModal: false,
    deleting: false,
  };

  deleteAccount = async (value) => {
    this.setState({ deleting: true });
    const user = this.context.currentUser.userProfile;
    const code = user.recurlyAccountCode;
    const inventoryId = this.context.currentUser.company.inventoryID;
    const companyId = this.context.currentUser.userProfile.companyID;
    const userId = this.context.currentUser.uid;

    if (value != user.email) {
      showToast("danger", "Entered email does't match user email");
      this.setState({ confirmEmailModal: false, deleting: false });
    } else {
      var updateQuantityFunction = functions.httpsCallable(
        "api/deleteCompanyAccount"
      );
      updateQuantityFunction({ recurlyAccountCode: code })
        .then(async (result) => {
          this.setState({ confirmEmailModal: false, deleting: false });
          app.auth().signOut();
        })
        .catch((error) => {
          showToast("danger", "Error deleting account")
          this.setState({ deleting: false });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="d-flex">
          {/*begin: Pic*/}
          <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
            {this.props.user.avatar ? (
              <div className="symbol symbol-50 symbol-lg-120">
                <img alt="Pic" src={this.props.user.avatar} />
              </div>
            ) : (
              <div className="symbol symbol-50 symbol-lg-120 symbol-light-danger">
                <span className="font-size-h3 symbol-label font-weight-boldest">
                  {this.props.user.firstName.charAt(0)}
                  {this.props.user.lastName.charAt(0)}
                </span>
              </div>
            )}
          </div>
          {/*end::Pic*/}
          {/*begin::Info*/}
          <div className="flex-grow-1">
            {/*begin::Title*/}
            <div className="d-flex justify-content-between flex-wrap mt-1">
              <div className="d-flex mr-3">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                >
                  {this.props.user.firstName} {this.props.user.lastName}
                </a>
                <a href="#">
                  <i className="flaticon2-correct text-success font-size-h5" />
                </a>
              </div>
              <div className="my-lg-0 my-3">
                {this.context.currentUser.userProfile.permissions ===
                  "owner" && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ deleteAccountModalActive: true });
                    }}
                    className="btn btn-sm btn-light-danger font-weight-bolder text-uppercase mr-5"
                  >
                    Delete Account
                  </button>
                )}
                <Link
                  to={`${this.props.match.url}/edit`}
                  className="btn btn-sm btn-light-success font-weight-bolder text-uppercase"
                >
                  Edit
                </Link>
              </div>
            </div>
            {/*end::Title*/}
            {/*begin::Content*/}
            <div className="d-flex flex-wrap justify-content-between mt-1">
              <div className="d-flex flex-column flex-grow-1 pr-8">
                <div className="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Email />
                    </span>
                    {this.props.user.email}
                  </a>
                </div>
                <div className="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Company />
                    </span>
                    {this.props.user.company}
                  </a>
                </div>
                <div className="d-flex flex-wrap mb-4">
                  <a
                    href="#"
                    className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Phone />
                    </span>
                    {normalizeInput(this.props.user.phoneNumber, "")}
                  </a>
                </div>
                <div className="d-flex flex-wrap mb-4">
                  <a
                    style={{ textTransform: "capitalize" }}
                    href="#"
                    className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Shield />
                    </span>
                    {this.props.user.permissions}
                  </a>
                </div>
              </div>
            </div>
            {/*end::Content*/}
          </div>
          {/*end::Info*/}
        </div>
        <ConfirmModal
          id={"delete-account"}
          show={this.state.deleteAccountModalActive}
          title="Delete account"
          body="You have requested to delete your company's account with Equappment. This will suspend your account and delete ALL data entered into your account including Equipment, Jobs, Crewmembers after 30 days. Are you sure you want to delete your account?"
          yesButton={{
            title: "Yes",
            spinner: false,
            onClick: () => {
              this.setState({
                deleteAccountModalActive: false,
                confirmEmailModal: true,
              });
            },
          }}
          noButton={{
            title: "Cancel",
            onClick: () => {
              this.setState({ deleteAccountModalActive: false });
            },
          }}
        />
        <InputTextModal
          id={"confirm-email"}
          show={this.state.confirmEmailModal}
          title="Delete account"
          body="Last chance to change your mind. If you still wish to suspend your account and DELETE ALL data enter your email."
          yesButton={{
            title: "Yes",
            spinner: this.state.deleting,
            onClick: (value) => {
              this.deleteAccount(value);
            },
          }}
          noButton={{
            title: "Cancel",
            onClick: (value) => {
              this.setState({ confirmEmailModal: false });
            },
          }}
        />
      </React.Fragment>
    );
  }
}

export default Profile;