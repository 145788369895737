import React, { Component } from 'react';
import BasicForm from '../../components/Form/BasicForm';
import { getMessages } from '../../components/Messages/GetMessages';
import { touchField, updateFormOnChange } from '../../shared/FormHelper';

class Calculators extends Component {

    state = {
        mode: "degreeToPitch",
        result: null,
        loading: false,
        degreeToPitchForm: {
            name: 'degree-to-pitch',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages('degree_to_pitch_example'),
            loading: false,
            fields: [
                {
                    name: 'degree',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Degree',
                    type: 'number',
                    label: 'Enter Degree',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('degree_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('degree_required')
                        },
                        {
                            type: 'min',
                            value: 0,
                            message: getMessages('degree_span')
                        },
                        {
                            type: 'max',
                            value: 360,
                            message: getMessages('degree_span')
                        }
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getDegreeToPitch(),
                    disabled: () => false
                },
            ]
        },
        pitchToDegreeForm: {
            name: 'pitch-to-degree',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages('pitch_to_degree_example'),
            loading: false,
            fields: [
                {
                    name: 'pitch',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Pitch',
                    type: 'number',
                    label: 'Enter Pitch',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('pitch_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('pitch_required')
                        },
                        {
                            type: 'min',
                            value: -100,
                            message: getMessages('pitch_span')
                        },
                        {
                            type: 'max',
                            value: 100,
                            message: getMessages('pitch_span')
                        }
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getPitchToDegree(),
                    disabled: () => false
                },
            ]
        },
        changeInDepthForm: {
            name: 'change-in-depth',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages('change_in_depth_example'),
            loading: false,
            fields: [
                {
                    name: 'pitch',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Pitch (P)',
                    type: 'number',
                    label: 'Pitch (P)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('pitch_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('pitch_required')
                        },
                        {
                            type: 'min',
                            value: -100,
                            message: getMessages('pitch_span')
                        },
                        {
                            type: 'max',
                            value: 100,
                            message: getMessages('pitch_span')
                        }
                    ]
                },
                {
                    name: 'distance',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Distance (L)',
                    type: 'number',
                    label: 'Distance (L)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('distance_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('distance_required')
                        },
                    ]
                },
                {
                    name: 'startingDepth',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Starting Depth (SD)',
                    type: 'number',
                    label: 'Starting Depth (SD)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('starting_depth_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getChangeInDepth(),
                    disabled: () => false
                },
            ]
        },
        requiredPitchForm: {
            name: 'required_pitch_form',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages('required_pitch_example'),
            loading: false,
            fields: [
                {
                    name: 'startingDepth',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Starting Depth (SD)',
                    type: 'number',
                    label: 'Starting Depth (SD)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('starting_depth_required')
                        },
                    ]
                },
                {
                    name: 'newDepth',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter New Depth (ND)',
                    type: 'number',
                    label: 'New Depth (ND)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('new_depth_required')
                        },
                    ]
                },
                {
                    name: 'distance',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Distance (L)',
                    type: 'number',
                    label: 'Distance (L)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('distance_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('distance_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getRequiredPitch(),
                    disabled: () => false
                },
            ]
        },
        soilBeingRemovedForm: {
            name: 'soil_being_removed_form',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages("soil_removed_example"),
            loading: false,
            fields: [
                {
                    name: 'bitOrReamerSize',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Bit or Reamer size',
                    type: 'number',
                    label: 'Bit or Reamer Size',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('bit_or_reamer_size_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getSoilBeingRemoved(),
                    disabled: () => false
                },
            ]
        },
        requiredDrillFluidForm: {
            name: 'required_drill_fluid_form',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages("required_drill_fluid_example"),
            loading: false,
            fields: [
                {
                    name: 'holeSize',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Hole Size',
                    type: 'number',
                    label: 'Hole Size',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('hole_size_required')
                        },
                    ]
                },
                {
                    name: 'boreLength',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Bore Length',
                    type: 'number',
                    label: 'Bore Length',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('distance_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('bore_length_required')
                        },
                    ]
                },
                {
                    name: 'soilType',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Select Soil Type',
                    type: 'select',
                    label: 'Soil Type',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    options: [
                        {
                            value: '',
                            display: 'None',
                        },
                        {
                            value: 'Caliche',
                            display: 'Caliche',
                            multiplier: 1,
                        },
                        {
                            value: 'Clay',
                            display: 'Clay',
                            multiplier: 4,
                        },
                        {
                            value: 'Reactive Clay',
                            display: 'Reactive Clay',
                            multiplier: 5,
                        },
                        {
                            value: 'Sandy Clay',
                            display: 'Sandy Clay',
                            multiplier: 3,
                        },
                        {
                            value: 'Cobbles',
                            display: 'Cobbles',
                            multiplier: 2,
                        },
                        {
                            value: 'Dirt',
                            display: 'Dirt',
                            multiplier: 1,
                        },
                        {
                            value: 'Gravel',
                            display: 'Gravel',
                            multiplier: 2,
                        },
                        {
                            value: 'Hardpan',
                            display: 'Hardpan',
                            multiplier: 2,
                        },
                        {
                            value: 'Coarse Sand',
                            display: 'Coarse Sand',
                            multiplier: 2,
                        },
                        {
                            value: 'Fine Sand',
                            display: 'Fine Sand',
                            multiplier: 3,
                        },
                        {
                            value: 'Sandstone',
                            display: 'Sandstone',
                            multiplier: 2,
                        },
                        {
                            value: 'Shale',
                            display: 'Shale',
                            multiplier: 3,
                        },
                    ],
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('soil_type_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getRequiredDrillFluidVolume(),
                    disabled: () => false
                },
            ]
        },
        pullbackRateForm: {
            name: 'pullback_rate_form',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            exampleMessage: getMessages("pullback_rate_example"),
            fields: [
                {
                    name: 'holeVolume',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Diameter',
                    type: 'number',
                    label: 'Hole Diameter',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('hole_diameter_required')
                        },
                    ]
                },
                {
                    name: 'rodLength',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Rod Length',
                    type: 'number',
                    label: 'Rod Length',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('distance_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('rod_length_required')
                        },
                    ]
                },
                {
                    name: 'pumpOutput',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Pump Output',
                    type: 'number',
                    label: 'Pump Output',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('pump_output_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('pump_output_required')
                        },
                    ]
                },
                {
                    name: 'soilType',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Select Soil Type',
                    type: 'select',
                    label: 'Soil Type',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    options: [
                        {
                            value: '',
                            display: 'None',
                        },
                        {
                            value: 'Caliche',
                            display: 'Caliche',
                            multiplier: 1,
                        },
                        {
                            value: 'Clay',
                            display: 'Clay',
                            multiplier: 4,
                        },
                        {
                            value: 'Reactive Clay',
                            display: 'Reactive Clay',
                            multiplier: 5,
                        },
                        {
                            value: 'Sandy Clay',
                            display: 'Sandy Clay',
                            multiplier: 3,
                        },
                        {
                            value: 'Cobbles',
                            display: 'Cobbles',
                            multiplier: 2,
                        },
                        {
                            value: 'Dirt',
                            display: 'Dirt',
                            multiplier: 1,
                        },
                        {
                            value: 'Gravel',
                            display: 'Gravel',
                            multiplier: 2,
                        },
                        {
                            value: 'Hardpan',
                            display: 'Hardpan',
                            multiplier: 2,
                        },
                        {
                            value: 'Coarse Sand',
                            display: 'Coarse Sand',
                            multiplier: 2,
                        },
                        {
                            value: 'Fine Sand',
                            display: 'Fine Sand',
                            multiplier: 3,
                        },
                        {
                            value: 'Sandstone',
                            display: 'Sandstone',
                            multiplier: 2,
                        },
                        {
                            value: 'Shale',
                            display: 'Shale',
                            multiplier: 3,
                        },
                    ],
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('soil_type_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getPullbackRate(),
                    disabled: () => false
                },
            ]
        },
        multiplePipeBundleForm: {
            name: 'multiple-pipe-bundle',
            isValid: false,
            submitErrorMessage: null,
            exampleMessage: getMessages("multiple_pipe_size_example"),
            loading: false,
            fields: [
                {
                    name: 'numberOfPipes',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter Number of Pipes',
                    type: 'number',
                    label: 'Enter Number of Pipes',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    options: [
                        {
                            value: "1",
                            selectedNumber: 1.0
                        },
                        {
                            value: "2",
                            selectedNumber: 2.0
                        },
                        {
                            value: "3",
                            selectedNumber: 2.2
                        },
                        {
                            value: "4",
                            selectedNumber: 2.4
                        },
                        {
                            value: "5",
                            selectedNumber: 2.7
                        },
                        {
                            value: "6",
                            selectedNumber: 3.0
                        },
                        {
                            value: "7",
                            selectedNumber: 3.1
                        },
                        {
                            value: "8",
                            selectedNumber: 3.3
                        },
                        {
                            value: "9",
                            selectedNumber: 3.6
                        },
                        {
                            value: "10",
                            selectedNumber: 3.8
                        },
                        {
                            value: "11",
                            selectedNumber: 3.9
                        },
                        {
                            value: "12",
                            selectedNumber: 4.0
                        },
                        {
                            value: "13",
                            selectedNumber: 4.2
                        },
                        {
                            value: "14",
                            selectedNumber: 4.4
                        },
                        {
                            value: "15",
                            selectedNumber: 4.5
                        },
                        {
                            value: "16",
                            selectedNumber: 4.6
                        },
                        {
                            value: "17",
                            selectedNumber: 4.8
                        },
                        {
                            value: "18",
                            selectedNumber: 4.9
                        },
                        {
                            value: "19",
                            selectedNumber: 4.9
                        },
                        {
                            value: "20",
                            selectedNumber: 5.1
                        },
                        {
                            value: "21",
                            selectedNumber: 5.3
                        },
                        {
                            value: "22",
                            selectedNumber: 5.4
                        },
                        {
                            value: "23",
                            selectedNumber: 5.6
                        },
                        {
                            value: "24",
                            selectedNumber: 5.7
                        },
                        {
                            value: "25",
                            selectedNumber: 5.8
                        },
                        {
                            value: "26",
                            selectedNumber: 5.8
                        },
                        {
                            value: "27",
                            selectedNumber: 5.9
                        },
                        {
                            value: "28",
                            selectedNumber: 6.0
                        },
                        {
                            value: "29",
                            selectedNumber: 6.1
                        },
                        {
                            value: "30",
                            selectedNumber: 6.2
                        },
                        {
                            value: "31",
                            selectedNumber: 6.3
                        },
                        {
                            value: "32",
                            selectedNumber: 6.4
                        },
                        {
                            value: "33",
                            selectedNumber: 6.5
                        },
                        {
                            value: "34",
                            selectedNumber: 6.6
                        },
                        {
                            value: "35",
                            selectedNumber: 6.7
                        },
                        {
                            value: "36",
                            selectedNumber: 6.8
                        },
                        {
                            value: "37",
                            selectedNumber: 6.9
                        },
                        {
                            value: "38",
                            selectedNumber: 7.0
                        },
                        {
                            value: "39",
                            selectedNumber: 7.1
                        },
                        {
                            value: "40",
                            selectedNumber: 7.1
                        },
                    ],
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('number_pipes_required')
                        },
                        {
                            type: 'min',
                            value: 1,
                            message: getMessages('pipes_span')
                        },
                        {
                            type: 'max',
                            value: 40,
                            message: getMessages('pipes_span')
                        }
                    ]
                },
                {
                    name: 'pipeDiameter',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Enter individual pipe or puller diameter',
                    type: 'number',
                    label: 'Pipe Diameter',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('starting_depth_unit'),
                    required: true,
                    validators: [
                        {
                        type: 'required',
                        message: getMessages('pipe_diameter_required')
                        },
                    ]
                },
            ],
            buttons: [
                {
                    title: 'CALCULATE',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.getMultiplePipeBundleSize(),
                    disabled: () => false
                },
            ]
        }
    }

    getDegreeToPitch = () => {
        this.setState({loading: true})
        const form = this.state.degreeToPitchForm;
        const degreeField = form.fields.find(x => x.name === "degree");
        const degree = parseFloat(degreeField.value);

        let result = (Math.tan(degree * Math.PI / 180) * 100).toFixed(2);
        let resultToDisplay = result.toString() + "%"

        this.setState({result: resultToDisplay, loading: false});
    }

    getPitchToDegree = () => {
        this.setState({loading: true})
        const form = this.state.pitchToDegreeForm;
        const pitchField = form.fields.find(x => x.name === "pitch");
        
        const pitch = parseFloat(pitchField.value);
        
        let result = (Math.atan(pitch / 100) * 180 / Math.PI).toFixed(1);
        let resultToDisplay = result.toString() + "deg"

        this.setState({result: resultToDisplay, loading: false});
    }

    getChangeInDepth = () => {
        this.setState({loading: true})
        const form = this.state.changeInDepthForm;
        const pitchField = form.fields.find(x => x.name === "pitch");
        const distanceField = form.fields.find(x => x.name === "distance");
        const startingDepthField = form.fields.find(x => x.name === "startingDepth");
        
        const pitch = parseFloat(pitchField.value);
        const distance = parseFloat(distanceField.value);
        const startingDepth = parseFloat(startingDepthField.value);

        let result = (Math.sin((1 / Math.tan((100 / - pitch) * Math.PI / 180)) * Math.PI / 180) * distance * 12 + startingDepth).toFixed(1);
        
        let resultToDisplay = result.toString() + " inches"
 
        this.setState({result: resultToDisplay, loading: false});
    }

    getRequiredPitch = () => {
        this.setState({loading: true})
        const form = this.state.requiredPitchForm;
        const newDepthField = form.fields.find(x => x.name === "newDepth");
        const distanceField = form.fields.find(x => x.name === "distance");
        const startingDepthField = form.fields.find(x => x.name === "startingDepth");
        
        const newDepth = parseFloat(newDepthField.value);
        const distance = parseFloat(distanceField.value);
        const startingDepth = parseFloat(startingDepthField.value);

        let result = - (Math.tan((1 / Math.sin(((distance * 12)/ (newDepth - startingDepth)) * Math.PI / 180)) * Math.PI / 180) * 100).toFixed(1);
        
        let resultToDisplay = result.toString() + "%"
 
        this.setState({result: resultToDisplay, loading: false});
    }
    getSoilBeingRemoved = () => {
        this.setState({loading: true})
        const form = this.state.soilBeingRemovedForm;
        const bitOrReamerSizeField = form.fields.find(x => x.name === "bitOrReamerSize");
        
        const bitOrReamerSize = parseFloat(bitOrReamerSizeField.value);

        let result = ((bitOrReamerSize * bitOrReamerSize) / 24.5).toFixed(2);
        
        let resultToDisplay = result.toString() + " gallons of soil per linear foot"
 
        this.setState({result: resultToDisplay, loading: false});  
    }

    getRequiredDrillFluidVolume = () => {
        this.setState({loading: true})
        const form = this.state.requiredDrillFluidForm;
        const holeSizeField = form.fields.find(x => x.name === "holeSize");
        const boreLengthField = form.fields.find(x => x.name === "boreLength");
        const soilTypeField = form.fields.find(x => x.name === "soilType");
        const multiplierField = soilTypeField.options.find(x => x.value === soilTypeField.value);
        
        const holeSize = parseFloat(holeSizeField.value);
        const boreLength = parseFloat(boreLengthField.value);
        const multiplier = multiplierField.multiplier;

        let result = (((holeSize * holeSize) / 24.5) * multiplier * boreLength).toFixed(2);
        let resultToDisplay = result.toString() + " gallons of fluid"
 
        this.setState({result: resultToDisplay, loading: false});
    }

    getPullbackRate = () => {
        this.setState({loading: true})
        const form = this.state.pullbackRateForm;
        const holeVolumeField = form.fields.find(x => x.name === "holeVolume");
        const rodLengthField = form.fields.find(x => x.name === "rodLength");
        const pumpOutputField = form.fields.find(x => x.name === "pumpOutput");
        const soilTypeField = form.fields.find(x => x.name === "soilType");
        const multiplierField = soilTypeField.options.find(x => x.value === soilTypeField.value);

        const holeVolume = parseFloat(holeVolumeField.value);
        const rodLength = parseFloat(rodLengthField.value);
        const pumpOutput = parseFloat(pumpOutputField.value);
        const multiplier = multiplierField.multiplier;
        
        let result = ((((holeVolume * holeVolume)/ 24.5) * multiplier * rodLength) / pumpOutput).toFixed(2);
        let resultToDisplay = result.toString() + " min/rod"
        this.setState({result: resultToDisplay, loading: false});
    }

    getMultiplePipeBundleSize = () => {
        this.setState({loading: true})
        const form = this.state.multiplePipeBundleForm;
        const pipeNumberField = form.fields.find(x => x.name === "numberOfPipes");
        const selectedNumberField = pipeNumberField.options.find(x => x.value === pipeNumberField.value);
        const diameterField = form.fields.find(x => x.name === "pipeDiameter");

        const selectedNumber = selectedNumberField.selectedNumber;
        const diameter = parseFloat(diameterField.value);

        let result = (selectedNumber * diameter).toFixed(2);
        let resultToDisplay = result.toString() + " inches"
        this.setState({result: resultToDisplay, loading: false});
    }

    prepareMultiplePipeBundleForm = () => {
        let form = this.state.multiplePipeBundleForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.multiplePipeBundleForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.multiplePipeBundleForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    preparePullbackRateForm = () => {
        let form = this.state.pullbackRateForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.pullbackRateForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.pullbackRateForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    prepareRequiredDrillFluidForm = () => {
        let form = this.state.requiredDrillFluidForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.requiredDrillFluidForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.requiredDrillFluidForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    prepareSoilBeingRemovedForm = () => {
        let form = this.state.soilBeingRemovedForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.soilBeingRemovedForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.soilBeingRemovedForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }
    prepareRequiredPitchForm = () => {
        let form = this.state.requiredPitchForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.requiredPitchForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.requiredPitchForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    prepareChangeInDepthForm = () => {
        let form = this.state.changeInDepthForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.changeInDepthForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.changeInDepthForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }


    prepareDegreeToPitchForm = () => {
        let form = this.state.degreeToPitchForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.degreeToPitchForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.degreeToPitchForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    preparePitchToDegreeForm = () => {
        let form = this.state.pitchToDegreeForm;
        
        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.pitchToDegreeForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.pitchToDegreeForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }



    render() {
        const degreeToPitchForm = this.prepareDegreeToPitchForm();
        const pitchToDegreeForm = this.preparePitchToDegreeForm();
        const changeInDepthForm = this.prepareChangeInDepthForm();
        const requiredPitchForm = this.prepareRequiredPitchForm();
        const soilBeingRemovedForm = this.prepareSoilBeingRemovedForm();
        const requiredDrillFluidForm = this.prepareRequiredDrillFluidForm();
        const pullbackRateForm = this.preparePullbackRateForm();
        const multiplePipeBundleForm = this.prepareMultiplePipeBundleForm();
        
        return(
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Calculators</h3>
                        
                    </div>
                </div>
                <div className="card-body">
                        <select value={this.state.mode} 
                            onChange={(e) => this.setState({ mode: e.target.value, result: null })}
                            className="custom-select col-lg-4 my-2 my-md-0">
                            <option value="degreeToPitch">Degree to % Pitch</option>
                            <option value="pitchToDegree">% Pitch to Degree</option>
                            <option value="changeInDepth">Change in Depth</option>
                            <option value="requiredPitch">Required Pitch</option>
                            <option value="soilBeingRemoved">Soil Being Removed</option>
                            <option value="requiredVolumeRemoveSoil">Required Drill Fluid Volume to Remove Soil</option>
                            <option value="pullbackRate">Pullback Rate</option>
                            <option value="multiplePipeBundleSize">Multiple Pipe Bundle Size</option>
                          </select>
                    {this.state.mode === "degreeToPitch" && 
                        <div className="mb-20 mt-5">
                            <p>Input the pitch in degrees to find the pitch as a %</p>
                            <BasicForm
                                {...degreeToPitchForm}
                            />
                        </div>
                    }
                    {this.state.mode === "pitchToDegree" && 
                        <div className="mb-20 mt-5">
                            <p>Input the pitch in % slope to find the pitch in degrees</p>
                            <BasicForm
                                {...pitchToDegreeForm}
                            />
                        </div>
                    }
                    {this.state.mode === "changeInDepth" && 
                        <div className="mb-20 mt-5">
                            <p>Determine what your depth will be at your destination based on the distance away and average pitch</p>
                            <BasicForm
                                {...changeInDepthForm}
                            />
                        </div>
                    }
                    {this.state.mode === "requiredPitch" && 
                        <div className="mb-20 mt-5">
                            <p>Determine what average pitch is required in order to reach your desired depth at a certain distance away</p>
                            <BasicForm
                                {...requiredPitchForm}
                            />
                        </div>
                    }
                    {this.state.mode === "soilBeingRemoved" && 
                        <div className="mb-20 mt-5">
                            <p>Determine how much soil is being removed per foot based on the diameter of the hole being cut</p>
                            <BasicForm
                                {...soilBeingRemovedForm}
                            />
                        </div>
                    }
                    {this.state.mode === "requiredVolumeRemoveSoil" && 
                        <div className="mb-20 mt-5">
                            <p>Determine the optimal volume of drill fluid required based on the diameter of the hole to be cut, the distance to drill, and the soil conditions</p>
                            <BasicForm
                                {...requiredDrillFluidForm}
                            />
                        </div>
                    }
                    {this.state.mode === "pullbackRate" && 
                        <div className="mb-20 mt-5">
                            <p>Determine optimal speed to drill the pilot hole or pullback the reamer based on the hole diameter being cut, fluid pump volume capacity, and soil conditions</p>
                            <BasicForm
                                {...pullbackRateForm}
                            />
                        </div>
                    }
                    {this.state.mode === "multiplePipeBundleSize" && 
                        <div className="mb-20 mt-5">
                            <p>Calculate the minimum diameter that a bundle of ducts will have. Note: all ducts must be the same size to use this calculation</p>
                            <BasicForm
                                {...multiplePipeBundleForm}
                            />
                        </div>
                    }
                    

                        <div className="d-flex align-items-center mb-10">
                            <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                <h3>{this.state.result}</h3>
                            </div>
                        </div>
                </div>
            </div>
        )
    }
}

export default Calculators;