import { SubscriptionTierUI } from "../subscription/SubscriptionUI"
import { SubscriptionEntity, SubscriptionPlanCode, SubscriptionPlanEntity } from "../subscription/Subscription"


export class UserEntity {
    displayName?: string | null = null
    email?: string | null = null
    uid?: string | null = null
    subscription?: SubscriptionEntity | null = null
    hostedLoginToken?: string | null = null
    userProfile?: UserProfile | null = null
    company: CompanyEntity | null = null
}

export function getPlanTier(plan: string) {
    switch (plan) {
        case SubscriptionPlanCode.T1_MONTH.toString():
        case SubscriptionPlanCode.T1_YEAR.toString(): {
            return SubscriptionTierUI.TIER1
        }
        case SubscriptionPlanCode.T2_MONTH.toString():
        case SubscriptionPlanCode.T2_YEAR.toString():
        case SubscriptionPlanCode.OLD_PLAN_1.toString():
        case SubscriptionPlanCode.OLD_PLAN_2.toString():
        case SubscriptionPlanCode.OLD_PLAN_3.toString():
        case SubscriptionPlanCode.OLD_PLAN_4.toString():

            {
                return SubscriptionTierUI.TIER2
            }
        case SubscriptionPlanCode.T3_MONTH.toString():
        case SubscriptionPlanCode.T3_YEAR.toString(): {
            return SubscriptionTierUI.TIER3
        }
    }
}

class UserProfile {
    companyID?: string | null = null
}

export class CompanyEntity {
    inventoryID?: string | null = null
    name?: string | null = null
    owner?: string | null
}