import { Timestamp } from "firebase/firestore";

export enum CrewMemberPosition {
  fieldCrew = "Field Crew",
  foreman = "Foreman",
  service = "Service",
  operator = "Operator",
  projectManager = "Project Manager",
  generalManager = "General Manager",
  constructionManager = "Construction Manager",
  supervisor = "Supervisor",
  safetyCoordinator = "Safety Coordinator",
  administration = "Administration",
  laborer = "Laborer",
  office = "Office",
  marketing = "Marketing",
  management = "Management"
}

export type CrewMemberPositionType = keyof typeof CrewMemberPosition

export class CrewMemberEntity {
  id?: string | null = null;
  firstName?: string | null = null;
  lastName?: string | null = null;
  userID?: string | null = null;
  employeeID?: string | null = null;
  crewID?: string | null = null;
  email?: string | null = null;
  authorizationStatus?: AuthorizationStatus | null = null;
  certifications?: string | null = null;
  hasProfileImage?: boolean | null = false;
  imageTimeStamp?: string | null = null;
  phoneNumber?: string | null = null;
  crew?: CrewEntity | null = null;
  position?: CrewMemberPosition | null = null;
}

export enum AuthorizationStatus {
  AccessRevoked = "accessRevoked",
}

export class CrewEntity {
  id?: string | null;
  crewID?: string | null;
  crewName?: string | null;
  alpha?: number | null;
  red?: number | null;
  green?: number | null;
  blue?: number | null;
}

export class CrewSchedulesEntity {
  documentId?: string | null;
  crewMemberID?: string | null;
  endDate?: Timestamp | null;
  jobID?: string | null
  startDate?: Timestamp | null
}

export class CrewsAppointmentsEntity {
  documentId?: string | null;
  crewID?: string | null;
  endDate?: Timestamp | null;
  jobID?: string | null
  startDate?: Timestamp | null
}
