export class ResponseEntity<T> {
    documentId?: string | null = null
    data?: T | null
    message?: string | null;

    constructor(data?: T | null, message?: string | null, documentId?: string | null) {
        this.message = message
        this.data = data
        this.documentId = documentId
    }
}