import React from "react";
import { Link } from "react-router-dom";

const SubscriptionRestrictionComponent = ({
  permission,
}: {
  permission?: string | null;
}) => {
  return (
    <React.Fragment>
      <div className="card card-custom d-flex align-items-center">
        {(() => {
          if (permission == "owner")
            return (
              <label className="p-10 h5 text-center">
                Your current subscription does not allow access to this section
                of EQ. Please go to
                <Link to="me/subscription"> subscription </Link>
                to upgrade for access.
              </label>
            );
          else
            return (
              <label className="p-10 h5 text-center">
                Your current subscription does not allow access to this section
                of EQ. Please contact your administrator or{" "}
                <a href="mailto:support@equappment.com?subject=Subscription">
                  support@equappment.com
                </a>{" "}
                to upgrade for access.
              </label>
            );
        })()}
        <img
          src="assets/media/equappment/subscription_restricted.png"
          className="w-50 p-20"
        />
      </div>
    </React.Fragment>
  );
};

export default SubscriptionRestrictionComponent;
