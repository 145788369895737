import React, { Component } from 'react';
import moment from 'moment';
import { ArrowLeft, Folder } from '../Icons/Svg';

class MoveFileModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if (!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    isDisabled = () => {

        if(!this.props.moveFile)
            return true;

        if(!this.props.moveFile.categoryID && !this.props.selectedFolder)
            return true;

        if(!this.props.selectedFolder)
            return false;

        return this.props.moveFile.categoryID === this.props.selectedFolder.id;
    }

    render() {
        const documents = this.props.getFolders();
        const path = this.props.getFolderPath(this.props.selectedFolder);

        return (
            <React.Fragment>
                <div className="modal fade" id="moveFileModal"
                    data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                {this.props.selectedFolder ?
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        <span onClick={() => { this.props.handleBack() }} style={{ cursor: 'pointer' }}><ArrowLeft /></span>
                                        <span className="ml-2">{this.props.selectedFolder.name}</span>
                                    </h5>
                                    :
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        <span className="ml-2">Root</span>
                                    </h5>                              
                                }
                                <p className="mt-2 font-italic">{path}</p>  
                                <div className="row mt-8">
                                    <div className="col-xl-12 pt-10 pt-xl-0">
                                        <>
                                            {documents.map((x, index) => (
                                                <div key={"jb-dcmnt" + index} className="d-flex align-items-center mb-10">
                                                    <div className="symbol symbol-40 symbol-light-success mr-5">
                                                        <Folder />
                                                    </div>
                                                    <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                                        <a role="button" onClick={(e) => { e.preventDefault(); this.props.setSelectedFolder(x); }} className="text-dark text-hover-primary mb-1 font-size-lg">{x.name}</a>
                                                        <span className="text-muted">Created at: {x.createdAt ? moment(x.createdAt.toDate()).format("MM/DD/YY, hh:mma") : "-"}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.props.save} type="button" disabled={this.isDisabled()}
                                    className={this.props.saving ? "btn btn-danger font-weight-bold spinner spinner-white spinner-right" : "btn btn-danger font-weight-bold"}>Move Here</button>
                                <button onClick={this.props.toggle} type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target="#moveFileModal">
                </button>
            </React.Fragment>
        );
    }
}

export default MoveFileModal;