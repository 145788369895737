import { Timestamp } from "firebase/firestore"
import { TaskProductEntity } from "../taskmanagement/Task"
import { FirebaseBoreCollections, FirebaseBoreLogCollections, FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections } from "../../firebase/FirebaseSchema"

export class BoreEntity{
    id?: string | null = null
    boreName?: string | null = null
    startDate?: Timestamp | null = null
    endDate?: Timestamp | null = null
    boreDiameter?: number | null = null
    boreLength?: number | null = null
    boreNumber?: number | null = null
    boreProgress?: number | null = null
    startStation?: string | null = null
    endStation?: string | null = null
    crewID?: string | null = null
    endBoreLog?: string | null = null
    finished: boolean = false
    jobID?: string | null  = null
    address?: string | null = null
    lat?: number | null = null
    long?: number | null = null
    lng?: number | null = null
    notes?: string | null = null
    productPipe?: string | null = null
    soilConditions?: string | null = null
    startBoreLog?: string | null = null
    taskID?: string | null = null
    defaultDistance?: number | null = null
    totalFootage?: number | null = null
    totalDuration?: number | null = null
    products: TaskProductEntity[] | null = []
}


export const buildBoreLogFileEntityPath = (inventoryId: string, boreId:string, boreLogId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.BORES}/${boreId}/${FirebaseBoreCollections.BORE_LOGS}/${boreLogId}/${FirebaseBoreLogCollections.FILES}`
}

export const buildBoreLogFileUploadPath = (companyId: string, boreLogId: string, folderName: string) => {
    return `${companyId}/boreLog/${boreLogId}/${folderName}`
}

export const buildBoreLogFilesPath = (companyId:string, boreLogId: string) => {
    return `${companyId}/boreLog/${boreLogId}`
}