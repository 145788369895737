import React, { useContext, useEffect, useState } from "react";
import { DumpSiteEntity } from "../../shared/data/DumpSite";
import { Edit, People, ShieldLock, Trash } from "../../components/Icons/Svg";
import { parseFromFirebaseDate } from "../../shared/Util";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../shared/Auth";
import { getDumpSite } from "../../shared/api/SoilConditionsApi";

interface DumpSiteProps {
  dumpSite?: DumpSiteEntity;
}

const DumpSiteDetailsComponent = ({ dumpSite }: DumpSiteProps) => {
  const history = useHistory();
  const context = useContext(AuthContext);

  const [formState, setFormState] = useState({
    isWorking: false,
    canTogglePrivate: false,
    canEdit: true,
  });
  const [dumpSiteState, setDumpSiteState] = useState<
    DumpSiteEntity | null | undefined
  >(dumpSite);

  useEffect(() => {
    const currentUser = context.currentUser;
    const canTogglePrivate =
      currentUser.userProfile.companyID === dumpSite?.companyId;
    setFormState((previousState) => {
      return { ...previousState, canTogglePrivate: canTogglePrivate };
    });
    loadDumpSiteDetails();
  }, []);

  const loadDumpSiteDetails = async () => {
    if (dumpSiteState?.documentId) {
      const result = await getDumpSite(dumpSiteState?.documentId);
      if(!result.message){
        setDumpSiteState(()=>result.data)
      }
    }
  };

  return (
    <React.Fragment>
      {dumpSiteState && (
        <div className="card card-custom">
          <div className="card-header h-auto py-4">
            <div className="card-title">
              <h3 className="card-label">Dump-Site Details</h3>
            </div>
            <div className="card-toolbar">
              {/* {dumpSiteState.canValidate && dumpSiteState.validated &&
                         <button disabled className={dumpSiteState.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                             <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                 <Check />
                             </span>Validated
                         </button>
                     }
                     {dumpSiteState.canValidate && !dumpSiteState.validated &&
                         <button onClick={validate} className={dumpSiteState.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                             <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                 <Check />
                             </span>Validate
                         </button>
                     } */}
              {formState.canEdit && (
                <>
                  <a
                    onClick={() => {
                      history.push({
                        pathname: `/dumpsite/new`,
                        state: {
                          dumpSite: dumpSiteState,
                        },
                      });
                    }}
                    className="btn btn-success btn-sm font-weight-bold mr-4"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Edit />
                    </span>
                    Edit
                  </a>
                  <button
                    onClick={() => {
                      setFormState((previousState) => {
                        return { ...previousState, isWorking: true };
                      });
                    }}
                    className={
                      formState.isWorking
                        ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                        : "btn btn-primary btn-sm font-weight-bold mr-4"
                    }
                  >
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Trash />
                    </span>
                    Delete
                  </button>
                </>
              )}
              {dumpSiteState.publicEntry && formState.canTogglePrivate && (
                <button
                  onClick={() => {
                    /*TODO implement making private*/
                  }}
                  className={
                    formState.isWorking
                      ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                      : "btn btn-primary btn-sm font-weight-bold mr-4"
                  }
                >
                  <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                    <ShieldLock />
                  </span>
                  Make Private
                </button>
              )}
              {!dumpSiteState.publicEntry && formState.canTogglePrivate && (
                <button
                  onClick={() => {
                    /*TODO implement making private*/
                  }}
                  className={
                    formState.isWorking
                      ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                      : "btn btn-primary btn-sm font-weight-bold mr-4"
                  }
                >
                  <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                    <People />
                  </span>
                  Make Public
                </button>
              )}
            </div>
          </div>
          <div className="card-body py-4">
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Notes:</label>
              <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">
                  {dumpSiteState.notes}
                </span>
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Location:</label>
              <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">{`${dumpSiteState.address}\n${dumpSiteState.lat}, ${dumpSiteState.long}`}</span>
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Created at:</label>
              <div className="col-8">
                <span className="form-control-plaintext">
                  <span className="font-weight-bolder">
                    {parseFromFirebaseDate(
                      dumpSite?.createdAt,
                      "MM/DD/YY hh:mm a"
                    )}
                  </span>
                </span>
              </div>
            </div>
            <div className="form-group row my-2">
              <label className="col-4 col-form-label">Validated by</label>
              <div className="col-8">
                <span className="form-control-plaintext font-weight-bolder">
                  {dumpSiteState.validatedBy ?? "0"} users
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default DumpSiteDetailsComponent;
