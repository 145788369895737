import React, { useEffect, useState } from "react";
import DropZone from "./DropZone";
import MultipleItems from "../../Carousels/MultipleItems";
import { number } from "prop-types";

interface FileUploadComponentProps {
  isUploading: boolean;
  onSaveFiles: (files: FileUpload[]) => void;
  onCancel: () => void;
  supportedFileTypes?: string | null
}

export interface FileUpload {
  file: File;
  name?: string | null;
}

const FileUploadComponent = (props: FileUploadComponentProps) => {
  const [files, setFiles] = useState<FileUpload[]>([]);

  const [formState, setFormState] = useState({
    selectedFile: -1,
    inputText: "",
  });

  useEffect(() => {
    if (formState.selectedFile > -1 && formState.selectedFile < files.length) {
      const newFiles = files.map((value, index) => {
        if (index === formState.selectedFile) {
          value.name = formState.inputText;
        }
        return value;
      });
      setFiles(newFiles);
    }
  }, [formState.inputText]);

  const allFilesHaveName = () => {
    return files.every(
      (fileUpload) => fileUpload.name
    );
  };

  return (
    <React.Fragment>
      <input
        type="text"
        className="form-control mb-4"
        value={formState.inputText}
        placeholder="Photo Name"
        name="name"
        onChange={(event) => {
          setFormState((previousState) => {
            return { ...previousState, inputText: event.target.value };
          });
        }}
      />
      <DropZone
        onDrop={(files) => {
          setFiles((previousState) => {
            const fileUpload: FileUpload[] = files.map((file) => ({
              file: file,
              name: file.name,
            }));
            return [...previousState, ...fileUpload];
          });
        }}
        supportedFileTypes={`${props.supportedFileTypes  ?? "JPEG, PNG, SVG"}`}
      />
      {!allFilesHaveName() && (
        <label className="text-danger mt-3">
          Some images do not have name. Click on those images to enter the name
        </label>
      )}
      <div className="m-5">
        <MultipleItems
          slidesToShow={5}
          laptop={5}
          tablet={5}
          mobile={2}
          images={
            files?.map((file) => ({
              image: URL.createObjectURL(file.file),
              title: file.name ?? "No Name",
            })) ?? []
          }
          removePhoto={(indexToRemove: number) => {
            setFiles((previousState) =>
              previousState.filter((_, index) => index !== indexToRemove)
            );
            setFormState({ selectedFile: -1, inputText: "" });
          }}
          setSelectedImage={(index: number) => {
            setFormState((previousState) => {
              return {
                ...previousState,
                selectedFile: index,
                inputText: files[index].name ?? "",
              };
            });
          }}
          selectedImageIndex={formState.selectedFile}
        />
      </div>
      <div className="modal-footer">
        <button
          onClick={() => {
            props.onSaveFiles(files);
          }}
          type="button"
          disabled={!allFilesHaveName() || files.length<1}
          className={
            props.isUploading
              ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right"
              : "btn btn-primary font-weight-bold"
          }
        >
          Save
        </button>
        <button
          onClick={()=>props.onCancel()}
          type="button"
          className="btn btn-light-primary font-weight-bold"
          data-dismiss="modal"
        >
          Cancel
        </button>
      </div>
    </React.Fragment>
  );
};

export default FileUploadComponent;
