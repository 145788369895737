import { documentId, getDocs, or, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { DumpSiteEntity } from "../data/DumpSite";
import { ResponseEntity } from "../data/Response";
import { FirebaseRootCollections } from "../firebase/FirebaseSchema";

// export const getDumpSites = async (companyId: string) => {
//     try {
//         const companyDumpSites = db
//             .collection(FirebaseRootCollections.DUMP_SITE)
//             .where("companyId", "==", companyId)
//             .get()

//         const publicDumpSites = db
//             .collection(FirebaseRootCollections.DUMP_SITE)
//             .where("publicEntry", "==", true)
//             .where("companyId", "!=", companyId)
//             .get()

//         const result = await Promise.all([companyDumpSites, publicDumpSites]).then(result => {
//             const companySites: DumpSiteEntity[] = result[0].docs.map((doc: any) => {
//                 return { ...doc.data(), documentId: doc.id }
//             })
//             const publicSites: DumpSiteEntity[] = result[1].docs.map((doc: any) => {
//                 return { ...doc.data(), documentId: doc.id }
//             })
//             return [...companySites, ...publicSites]
//         })
//         return new ResponseEntity<DumpSiteEntity[]>(result, "")

//     } catch (error: any) {
//         return new ResponseEntity<DumpSiteEntity[]>([], error)

//     }
// }

export const getCompanyDumpSites = async (companyId: string) => {
    try {

        const publicDumpSites = await db
            .collection(FirebaseRootCollections.DUMP_SITE)
            .where("companyId", "==", companyId)
            .get()

            const dumpSites:DumpSiteEntity[] = publicDumpSites.docs.map((doc:any)=>{
                return {...doc.data(), documentId:doc.id}
            })
        return new ResponseEntity<DumpSiteEntity[]>(dumpSites, "")

    } catch (error: any) {
        return new ResponseEntity<DumpSiteEntity[]>([], error)

    }
}

export const getPublicDumpSites = async (companyId: string) => {
    try {

        const publicDumpSites = await db
            .collection(FirebaseRootCollections.DUMP_SITE)
            .where("publicEntry", "==", true)
            .get()

            const dumpSites:DumpSiteEntity[] = publicDumpSites.docs.filter((doc:any)=>{return doc.data().companyId!=companyId}).map((doc:any)=>{
                return {...doc.data(), documentId:doc.id}
            })
        return new ResponseEntity<DumpSiteEntity[]>(dumpSites, "")

    } catch (error: any) {
        return new ResponseEntity<DumpSiteEntity[]>([], error)

    }
}

export const getDumpSite = async (documentId: string) => {
    try {
        const response = await db
            .collection(FirebaseRootCollections.DUMP_SITE)
            .doc(documentId)
            .get()

        const data: DumpSiteEntity = { ...response.data(), documentId: response.id }

        return new ResponseEntity<DumpSiteEntity>(data, "")

    } catch (error: any) {
        return new ResponseEntity<DumpSiteEntity>(null, error)
    }
}

export const saveDumpSite = async (dumpSite: DumpSiteEntity) => {
    const { documentId, ...dumpSiteWithoutId } = dumpSite
    try {
        if (dumpSite.documentId) {
            const response = await db
                .collection(FirebaseRootCollections.DUMP_SITE)
                .doc(dumpSite.documentId)
                .set(dumpSiteWithoutId)
        } else {
            const response = await db
                .collection(FirebaseRootCollections.DUMP_SITE)
                .add(dumpSiteWithoutId)
            dumpSite.documentId = response.id
        }
        return new ResponseEntity<DumpSiteEntity>(dumpSite, "")
    } catch (error: any) {
        return new ResponseEntity<DumpSiteEntity>(dumpSite, error)
    }
}