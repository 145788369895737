import React from 'react';

const DatePicker = (props) => {
    return (
        <input
            type="date"
            className={props.className}
            autoFocus={props.index === 0}
            value={props.field.value}
            placeholder={props.field.placeholder}
            name={props.field.name}
            onChange={props.handleChange}
            onBlur={() => { if(props.touchField) props.touchField(props.field) }}
            max={props.field.max}
            min={props.field.min}
            id={props.id}
        />
    );
}

export default DatePicker;