import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CrewsIos } from '../../components/Icons/Svg';
import Loader from '../../components/Layout/Loader/Loader';
import { fromRGBValue } from '../../shared/Util';

class JobCrews extends Component {
    state = {
        search: ''
    }

    componentDidMount() {
        this.props.setTabActive('crews');
    }

    getAdded = () => {
        if(this.state.search === '')
            return this.props.addedCrews;

        return this.props.addedCrews.filter(x => x.crewName.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    getOther = () => {
        if(this.state.search === '')
            return this.props.otherCrews;

        return this.props.otherCrews.filter(x => x.crewName.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 pt-10 pt-xl-0">
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control"
                                onChange={(e) => { this.setState({ search: e.target.value }); }}
                                placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Added crews</a>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                            {!this.props.addedCrews ? <Loader height="100px"/> : 
                            <div className="card-body pt-4 d-flex flex-column justify-content-between">
                            {this.getAdded().length > 0 ?
                            <>
                            {this.getAdded().map((item, index) => {
                                return (
                                    <div key={"crws-itm" + index} className="d-flex flex-wrap align-items-center mb-10">
                                        <div className="symbol symbol-30 flex-shrink-0 mr-4">
                                            <div className="symbol-label" 
                                                style={{ backgroundColor: 'rgba(' + fromRGBValue(item.red) + ', ' + fromRGBValue(item.green) + ', ' + fromRGBValue(item.blue) + ', ' + item.alpha + ')' }} />
                                        </div>
                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                            <Link to={"/jobs/" + this.props.job.id + "/crews/" + item.id} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.crewName}</Link>
                                            <span className="text-muted font-weight-bold font-size-sm my-1">{item.crewID}</span>
                                        </div>
                                    </div>
                                );
                            })}
                            </>
                            :
                            <div className="d-flex align-items-center mb-10">
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                    <div className='mainpage-graphic'>
                                        <CrewsIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                    </div>
                                    <span className="font-italic">You don't have any crews added to the job at the moment. You can start by clicking on a crew from the list on the right and then selecting 'Add To Job'.</span>
                                </div>
                            </div>  
                            }
                        </div>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Other crews</a>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                            {!this.props.otherCrews ? <Loader height="100px"/> : 
                            <div className="card-body pt-4 d-flex flex-column justify-content-between">
                            {this.getOther().length > 0 ?
                                <>
                                {this.getOther().map((item,index) => {
                                    return (
                                        <div key={"crws-itm" + index} className="d-flex flex-wrap align-items-center mb-10">
                                            <div className="symbol symbol-30 flex-shrink-0 mr-4">
                                                <div className="symbol-label" 
                                                    style={{ backgroundColor: 'rgba(' + fromRGBValue(item.red) + ', ' + fromRGBValue(item.green) + ', ' + fromRGBValue(item.blue) + ', ' + item.alpha + ')' }} />
                                            </div>
                                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                                                <Link to={"/jobs/" + this.props.job.id + "/crews/" + item.id} className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{item.crewName}</Link>
                                                <span className="text-muted font-weight-bold font-size-sm my-1">{item.crewID}</span>
                                            </div>
                                        </div>
                                    );
                                })}
                                </>
                                :
                                <div className="d-flex align-items-center mb-10">
                                    <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                    <div className='mainpage-graphic'>
                                        <CrewsIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                    </div>
                                        <span className="font-italic">There are no crews for you to add to this job.</span>
                                    </div>
                                </div> 
                                }
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default JobCrews;