import React from 'react';

const TextInput = (props) => {
    return (
        <span
            className="font-weight-bold mr-2 d-block"
        >
            {props.field.value}
        </span>
    );
}

export default TextInput;