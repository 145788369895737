import React, { useEffect, useMemo, useState } from "react";
import GoogleMapReact from "google-map-react";
import Geosuggest from "react-geosuggest";
import { Location } from "../../../../shared/data/Location";
import EQMarker from "../../../../components/GoogleMap/EQMarker";
import {
  calculateDistance,
  calculateMiddlePoint,
} from "../../../../shared/Util";

interface TaskNewMapProps {
  taskCurrentLocation?: Location | null;
  segmentLocationStart?: Location | null;
  segmentLocationEnd?: Location | null;
  segmentTasksLocations?: Location[] | null;
  onLocationAdded?: (location: Location) => void;
}

const TaskNewMap = ({
  taskCurrentLocation = null,
  segmentLocationStart,
  segmentLocationEnd,
  segmentTasksLocations,
  onLocationAdded = () => {},
}: TaskNewMapProps) => {
  const [taskLocation, setTaskLocation] = useState<Location | null>(
    taskCurrentLocation
  );
  const visibleTaskLocations = useMemo(()=>{
    return segmentTasksLocations
    ?.filter((location, index) => {
      return location?.lat != taskLocation?.lat && location?.lng!=taskLocation?.lng;
    })
  }, [])

  useEffect(() => {
    if (taskLocation) onLocationAdded(taskLocation);
  }, [taskLocation]);

  const fitBounds = (map:any, maps:any) => {
    const allLocations:Location[]=[]
    if (segmentTasksLocations) allLocations.push(...segmentTasksLocations);
    if (taskCurrentLocation) allLocations.push(taskCurrentLocation);
    if (segmentLocationStart) allLocations.push(segmentLocationStart);
    if (segmentLocationEnd) allLocations.push(segmentLocationEnd);

    const bounds = new maps.LatLngBounds();
    allLocations?.forEach((location) => {
      bounds.extend(new maps.LatLng(location.lat, location.lng));
    });
    map.fitBounds(bounds);
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(map, maps, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  return (
    <React.Fragment>
      <div className="form-group">
        <Geosuggest
          autoActivateFirstSuggest={true}
          inputClassName="form-control"
          suggestsClassName="sug-container"
          suggestItemClassName="dropdown-item"
          suggestItemActiveClassName="sug-item-active"
          onSuggestSelect={(result: any) => {
            setTaskLocation(() => {
              return new Location(
                result?.location?.lat,
                result?.location?.lng,
                result?.label
              );
            });
          }}
          autoComplete="off"
        />
      </div>
      <div className="mt-3 mb-3"><span className="font-weight-boldest">Drop a pin to map to add location to this task</span></div>
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
        options={{
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
          },
          mapTypeId: "satellite",
        }}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "" }}
          center={{
            lat: taskLocation?.lat ?? segmentLocationStart?.lat ?? 0,
            lng: taskLocation?.lng ?? segmentLocationStart?.lng ?? 0,
          }}
          zoom={14}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            new maps.Circle({
              strokeColor: "#FF0000",
              strokeOpacity: 0.8,
              clickable:false,
              strokeWeight: 2,
              fillColor: "#FF0000",
              fillOpacity: 0.1,
              map,
              center: calculateMiddlePoint(
                segmentLocationStart?.lat,
                segmentLocationStart?.lng,
                segmentLocationEnd?.lat,
                segmentLocationEnd?.lng
              ),
              radius:
                calculateDistance(
                  segmentLocationStart?.lat,
                  segmentLocationStart?.lng,
                  segmentLocationEnd?.lat,
                  segmentLocationEnd?.lng
                ) / 2,
            });
            fitBounds(map, maps);
            map.addListener("click", (e: any) => {
              setTaskLocation(() => {
                return new Location(e.latLng.lat(),e.latLng.lng());
              });
            })
          }}
        >
          {visibleTaskLocations
            ?.filter((location, index) => {
              return location?.lat != taskLocation?.lat && location?.lng!=taskLocation?.lng;
            })
            ?.map((location, index) => {
              return (
                <EQMarker
                  key={index}
                  lat={location.lat}
                  lng={location.lng}
                  pinColor={"darkgreen"}
                />
              );
            })}
          {segmentLocationStart && <EQMarker
            key={101}
            lat={segmentLocationStart?.lat}
            lng={segmentLocationStart?.lng}
            icon={"flaticon2-pin"}
          />}
          {segmentLocationEnd && <EQMarker
            key={102}
            lat={segmentLocationEnd?.lat}
            lng={segmentLocationEnd?.lng}
            icon={"flaticon2-pin"}
          />}
          {taskLocation && (
            <EQMarker
              key={103}
              lat={taskLocation?.lat}
              lng={taskLocation?.lng}
              pinColor={"darkgreen"}
              />
          )}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default TaskNewMap;
