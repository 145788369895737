export const FirebaseRootCollections = {
  COMPANIES: "companies",
  INVENTORIES: "inventories",
  INVITATIONS: "invitations",
  NOTIFICATIONS: "notifications",
  SETTINGS: "settings",
  SOIL_CONDITIONS: "soilConditions",
  USERS: "users",
  DUMP_SITE: "dumpSite",
  LOCATE_TICKETS: "locateTickets"
};

export const FirebaseInventoriesCollections = {
  CLIENTS: "clients",
  CREW: "crew",
  CREWS: "crews",
  CREW_MEMBERS: "crewmembers",
  EQUIPMENT: "equipment",
  JOBS: "jobs",
  BORES: "bores",
  CREW_SCHEDULES: "crew_schedules",
  CREWS_APPOINTMENTS: "crews_appointments",
  CUSTOM_CHECKLIST: "custom_checklist",
  PRESET_FOLDERS: "preset_folders",
  RESERVATIONS: "reservations",
  SEGMENTS: "segments",
  TASKS: "tasks",
  TIMELOG: "timelog",
  CUSTOM_TASK_TYPES: "custom_task_types"
};

export const FirebaseBoreCollections = {
  BORE_LOGS: "boreLogs"
}

export const FirebaseBoreLogCollections = {
  FILES: "files"
}

export const FirebaseTimeLogCollections = {
  FILES: "files"
}

export const FirebaseJobCollections = {
  FILES:"files",
  FOLDERS:"folders",
  PHOTOS: "photos"
}

export const FirebaseSettingsDocuments = {
  EQUIPMENT: "equipment",
  EXAMPLE: "example",
  TASK: "task"
}

export const FirebaseSettingsTaskCollections = {
  TASK_TYPE: "taskType"
}

export const FirebaseLocateTicketCollection = {
  FILES: "files"
}