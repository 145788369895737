import React, { useState } from "react";
import { SubscriptionUI } from "../../../shared/data/subscription/SubscriptionUI";
import {
  PendingSubscriptionEntity,
  SubscriptionPlanCode,
  isOldPlan,
} from "../../../shared/data/subscription/Subscription";
import moment from "moment";

interface SubscriptionPlanProps {
  subscriptions?: SubscriptionUI[];
  pendingSubscription?: PendingSubscriptionEntity | null;
  currentPlan: string;
  isLoading?: boolean;
  onPlanSelected?: (planId: string) => void;
}

export const SubscriptionPlanComponent = ({
  subscriptions = [],
  pendingSubscription = null,
  currentPlan = "",
  isLoading = false,
  onPlanSelected = () => {},
}: SubscriptionPlanProps) => {
  const planId = isOldPlan(currentPlan)
    ? SubscriptionPlanCode.T2_MONTH.toString()
    : currentPlan;

  return (
    <React.Fragment>
      <div className="d-flex flex-row justify-content-center">
        {subscriptions.map((plan) => {
          return (
            <div
              className={`col-xl-4 ml-5  ${
                planId == plan.id ? "bg-primary-o-90" : "py-15 bg-light"
              } `}
            >
              <div className={`d-flex bg-opacity-75 align-items-center`}>
                <div
                  className={`w-100 d-flex flex-column align-items-stretch px-10 py-10`}
                >
                  <div className="mb-7 text-center">
                    <h1 className="text-gray-900 mb-5 fw-bolder">
                      {plan.name}
                    </h1>

                    <div className="text-gray-600 fw-semibold mb-5">
                      {plan.description}
                    </div>

                    <div className="text-center" key={plan.id}>
                      <span className="mb-2 text-primary">{`$${plan.price}`}</span>
                    </div>
                    <div className="mt-2 font-weight-bold">*2 users minimum</div>
                    <div
                      key="plan-selectionDescription"
                      className="mt-8 font-weight-bold"
                    >
                      {plan.sectionsDescription}
                    </div>
                  </div>
                  <div className="w-100 mb-10">
                    {plan.includedSections.map((section) => {
                      return (
                        <div className="d-flex align-items-center mb-5">
                          <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                            {section.name}
                          </span>
                          {/* <i className="fas fa-check-circle"></i> */}
                          {/* <i className="fas fa-times-circle"></i> */}
                        </div>
                      );
                    })}
                  </div>
                  {!pendingSubscription && planId != plan.id && !isLoading && (
                    <div
                      className={`btn btn-sm btn-primary`}
                      onClick={() => {
                        onPlanSelected(plan.id);
                      }}
                    >
                      Select
                    </div>
                  )}
                  {isLoading && (
                    <div className="spinner-center mt-10 spinner spinner-primary spinner-lg" />
                  )}
                  {pendingSubscription &&
                    pendingSubscription.plan?.plan_code == plan.id && (
                      <div>
                        <div className="text-primary text-center">{`Your subscription will change to ${
                          plan.name
                        } plan on ${moment(plan.nextPlanDate).format(
                          "YYYY-MM-DD"
                        )}`}</div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default SubscriptionPlanComponent;
