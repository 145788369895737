import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Hummer, People, Tools, Person, Company, EquipmentIos, CrewsIos, CrewmemberIos, JobsIos, Calculator, SoilCondtions, SoilCondition, LocateTicket } from '../../Icons/Svg';
import { useLocation } from "react-router-dom";
import { AuthContext } from '../../../shared/Auth';

const Sidebar = () => {

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const { isTrial } = useContext(AuthContext);
  
  return (
        <div className="aside aside-left aside-fixed d-flex flex-column flex-row-auto" id="kt_aside">
          {/*begin::Brand*/}
          <div className="brand flex-column-auto" id="kt_brand">
            {/*begin::Logo*/}
            <a style={{ color: "white" }} className="brand-logo">
              {/* <img alt="Logo" src="/assets/media/logos/logo-light.png" /> */}
              EQUAPPMENT
            </a>
            {/*end::Logo*/}
            {/*begin::Toggle*/}
            {/* <button className="brand-toggle btn btn-sm px-0" id="kt_aside_toggle">
              <span className="svg-icon svg-icon svg-icon-xl">
               
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path d="M5.29288961,6.70710318 C4.90236532,6.31657888 4.90236532,5.68341391 5.29288961,5.29288961 C5.68341391,4.90236532 6.31657888,4.90236532 6.70710318,5.29288961 L12.7071032,11.2928896 C13.0856821,11.6714686 13.0989277,12.281055 12.7371505,12.675721 L7.23715054,18.675721 C6.86395813,19.08284 6.23139076,19.1103429 5.82427177,18.7371505 C5.41715278,18.3639581 5.38964985,17.7313908 5.76284226,17.3242718 L10.6158586,12.0300721 L5.29288961,6.70710318 Z" fill="#000000" fillRule="nonzero" transform="translate(8.999997, 11.999999) scale(-1, 1) translate(-8.999997, -11.999999)" />
                    <path d="M10.7071009,15.7071068 C10.3165766,16.0976311 9.68341162,16.0976311 9.29288733,15.7071068 C8.90236304,15.3165825 8.90236304,14.6834175 9.29288733,14.2928932 L15.2928873,8.29289322 C15.6714663,7.91431428 16.2810527,7.90106866 16.6757187,8.26284586 L22.6757187,13.7628459 C23.0828377,14.1360383 23.1103407,14.7686056 22.7371482,15.1757246 C22.3639558,15.5828436 21.7313885,15.6103465 21.3242695,15.2371541 L16.0300699,10.3841378 L10.7071009,15.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" transform="translate(15.999997, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-15.999997, -11.999999)" />
                  </g>
                </svg>
               
              </span>
            </button> */}
            {/*end::Toolbar*/}
          </div>
          {/*end::Brand*/}
          {/*begin::Aside Menu*/}
          <div className="aside-menu-wrapper flex-column-fluid" id="kt_aside_menu_wrapper">
            {/*begin::Menu Container*/}
            <div id="kt_aside_menu" className="aside-menu my-4" data-menu-vertical={1} data-menu-scroll={1} data-menu-dropdown-timeout={500}>
              {/*begin::Menu Nav*/}
              <ul className="menu-nav">
              <li className={splitLocation[1] === "jobs" || splitLocation[1]==="" ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/jobs" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <JobsIos />
                    </span>
                    <span className="menu-text">Jobs</span>
                  </Link>
                </li>
              <li className={splitLocation[1] === "equipment"  ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/equipment" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <EquipmentIos />
                    </span>
                    <span className="menu-text">Equipment</span>
                  </Link>
                </li>
                <li className={splitLocation[1] === "crewmembers" ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/crewmembers" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <CrewmemberIos />
                    </span>
                    <span className="menu-text">Crewmembers</span>
                  </Link>
                </li>                
                <li className={splitLocation[1] === "crews" ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/crews" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <CrewsIos />
                    </span>
                    <span className="menu-text">Crews</span>
                  </Link>
                </li>
                <li className={splitLocation[1] === "clients" ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/clients" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <Company />
                    </span>
                    <span className="menu-text">Clients</span>
                  </Link>
                </li>
                <li className={splitLocation[1] === "calculators" ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to="/calculators" className="menu-link">
                    <span className="svg-icon menu-icon">
                      <Calculator />
                    </span>
                    <span className="menu-text">Calculators</span>
                  </Link>
                </li>
                <li className={(splitLocation[1] === "soilconditions" || splitLocation[1] === "dumpsite") ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to={{ pathname: "/soilconditions", state: { from: location } }} className="menu-link">
                    <span className="svg-icon menu-icon">
                      <SoilCondition />
                    </span>
                    <span className="menu-text">Soil and Dump-Site Map</span>
                  </Link>
                </li>
                <li className={(splitLocation[1] === "locatetickets") ? "menu-item menu-item-active" : "menu-item"}>
                  <Link to={{ pathname: "/locatetickets", state: { from: location } }} className="menu-link">
                    <span className="svg-icon menu-icon">
                      <LocateTicket />
                    </span>
                    <span className="menu-text">Locate tickets</span>
                  </Link>
                </li>
              </ul>
              {/*end::Menu Nav*/}
              {isTrial && <div className="card-toolbar align-items-center trial-graphic">
                      <h6 className='font-weight-bold'>Trial Period</h6>
                      <a href="https://www.equappment.com/demo-the-app" className="btn btn-primary font-weight-bold" role="button" target="_blank">
                          <i className="flaticon2-help" />Help
                      </a>&nbsp;
              </div>}
            </div>
            {/*end::Menu Container*/}
          </div>
          {/*end::Aside Menu*/}
        </div>
  );
}

export default Sidebar;