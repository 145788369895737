import { functions } from "../../firebase";
import { SubscriptionEntity } from "../data/subscription/Subscription";

export const changeSubscription = async (subscriptionPlanCode: string) => {
    return new Promise<SubscriptionEntity | null>((resolve, reject) => {
        functions.httpsCallable("changeSubscription")({ plan_code: subscriptionPlanCode })
            .then(result => {
                resolve(result.data)
            })
            .catch((error) => {
                return reject(error)
            })
    })
}