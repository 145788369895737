import React, { useContext } from "react";
import { AuthContext } from "../../../shared/Auth";
import LandingScreenInfo from "./LandingInfo";
import { Link } from "react-router-dom";

interface LandingPageProps {
  content: React.ReactNode;
}

const LandingPage = (props: LandingPageProps) => {
  const context = useContext(AuthContext);
  return (
    <React.Fragment>
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column flex-root"
      >
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-aside order-1 order-lg-1 bgi-no-repeat bgi-position-x-right">
            <div className="login-conteiner bgi-position-x-right bgi-position-y-bottom">
              <LandingScreenInfo />
            </div>
          </div>

          <div className="login-container order-2 order-lg-2 d-flex flex-center-h flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
            <div className="login-content d-flex flex-column">
             <div>
                {props.content}
             </div>
              {context.isMobile && (
                <div>
                  <hr color="grey" />
                  <div className="d-flex d-row font-weight-boldest text-dark">
                    <img
                      src="assets/media/equappment/anderson_underground_logo.png"
                      className="w-25 h-25 pr-5"
                    />
                    "The Equappment app has proven to be a valuable
                    organizational tool in my business. Being able to track
                    resources, monitor schedules, and keep projects on track,
                    all in the palm of my hand in an easy-to-use package has
                    been very helpful in my daily operations."
                    <br /> - Nick Anderson, President
                  </div>
                  <hr color="grey" />
                  <div className="text-dark pt-10">
                    <h5>
                      Questions?
                      <br />
                      Email us at{" "}
                      <a
                        href="mailto:support@equappment.com"
                        className="navi-item"
                      >
                        support@equappment.com
                      </a>
                      <br />
                      If you want to quickly see if it is a good fit, you can{" "}
                      <a href="https://www.equappment.com/live-demo">
                        request a demo
                      </a>
                    </h5>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
