import { Timestamp } from "firebase/firestore";
import { TimeLogEntity } from "./TimeLog";

export enum TaskType {
  backfill = "Backfill",
  boreBackreamPullback = "Bore - Backream & Pullback",
  borePilot = "Bore - Pilot",
  cablePull = "Cable Pull",
  cameraInspection = "Camera Inspection",
  cleanOutConduit = "Clean Out Conduit",
  conduitMakeConnections = "Conduit - Make Connections",
  conduitStageforInstallation = "Conduit - Stage for Installation",
  dailyReportSubmitted = "Daily Report Submitted",
  dailySafetyTalkGiven = "Daily Safety Talk Given",
  drop = "Drop",
  excavate = "Excavate",
  fiberBlowPull = "Fiber - Blow/Pull",
  fiberSplices = "Fiber - Splices",
  gradeCompact = "Grade & Compact",
  handHoleInstallation = "Hand Hole Installation",
  incidentReportCreated = "Incident Report Created",
  jobWrapUpTasks = "Job Wrap-Up Tasks",
  locateExisting = "Locate Existing Utilities",
  locatesMade = "Locates Have Been Made",
  manHoleInstallation = "Man Hole Installation",
  mudMixing = "Mud Mixing",
  pedestalInstallation = "Pedestal Installation",
  permitsReceived = "Permits Received",
  pipeBurtstingCracking = "Pipe Burtsting - Cracking",
  pipeCoating = "Pipe Coating",
  pipeFusing = "Pipe Fusing",
  pipeJointCoating = "Pipe Joint Coating",
  pipeReline = "Pipe Reline",
  plow = "Plow",
  pothole = "Pothole",
  preStartTasks = "Pre-Start Tasks",
  pressureTest = "Pressure Test",
  restorationResidential = "Restoration - Residential",
  restorationSite = "Restoration - Site",
  restorationStreet = "Restoration - Street",
  riserInstallation = "Riser Installation",
  safetyHazMatSetUp = "Safety & HazMat Set Up",
  shoringInstallation = "Shoring - Installation",
  shoringRemoval = "Shoring - Removal",
  siteSurvey = "Site Survey",
  taps = "Taps",
  trench = "Trench",
  vaultInstallation = "Vault Installation",
  tracerWireInstallation = "Tracer Wire Installation",
  warningTapeInstallation = "Warning Tape Installation",
  flowerBoxInstallation = "Flower Box Installation",
  trafficControlSetUp = "Traffic Control Set Up",
  molingPiercingTool = "Moling (piercing tool)",
  custom = "Custom",
}

export enum TaskPriority {
  high = "High",
  medium = "Medium",
  low = "Low",
}

export type TaskPriorityType = keyof typeof TaskPriority

export enum TaskStatus {
  open = "Open",
  inProgress = "In Progress",
  onHold = "On Hold",
  closed = "Closed",
}

export type TaskStatusType = keyof typeof TaskStatus

export enum GroundConditions {

  none = "None",
  caliche = "Caliche",
  clay = "Clay",
  cobbles = "Cobbles",
  dirt = "Dirt",
  gravel = "Gravel",
  hardpan = "Hardpan",
  sand = "Sand",
  sandstone = "Sandstone",
  shale = "Shale",
  other = "Other",
}

export type GroundConditionsType = keyof typeof GroundConditions


export class CustomTaskFieldEntity {
  title: string = ""
  value: string = ""
}

export enum TaskProductType {
  none = "None",
  cable = "Cable",
  fusiblePvc = "Fusible PVC",
  hdpe = "HDPE",
  pvc = "PVC",
  steel = "Steel"
}

export type TaskProductDataType = typeof TaskProductType[keyof typeof TaskProductType]

export class TaskTypeEntity {
  id:string
  name: string
  isCustom: boolean = false

  constructor(id: string, name: string, isCustom: boolean = false) {
    this.id = id
    this.name = name
    this.isCustom = isCustom
  }
}

export class TaskWithTimeLogsEntity{
  task:TaskEntity
  timeLogs: TimeLogEntity[] = []

  constructor(task:TaskEntity, timeLogs:TimeLogEntity[] = []){
    this.task = task
    this.timeLogs = timeLogs
  }
}

export class TaskEntity {
  id?: string | null = null;
  taskType?: string | null = null;
  jobID: string;
  segmentID?: string | null = null;
  taskID?: string | null = null;
  boreID?: string | null = null;
  title?: string | null = null;
  notes?: string | null = null;
  startDate?: Timestamp | null = null;
  endDate?: Timestamp | null = null;
  estimatedDuration?: number | null = null;
  currentDuration?: number | null = null;
  lat?: number | null = null;
  lng?: number | null = null;
  address?: string | null = null;
  crews?: string[] | null = [];
  crewmembers?: string[] | null = [];
  equipments?: string[] | null = [];
  taskStatus?: TaskStatusType = "open"
  priority?: TaskPriorityType = "low"
  products?: TaskProductEntity[] | null = [];
  groundConditions?: GroundConditionsType | null = null;
  expectedFilledYards?: number | null = null;
  currentFilledYards?: number | null = null;
  expectedFootage?: number | null = null;
  currentFootage?: number | null = null;
  expectedQuantity?: number | null = null;
  currentQuantity?: number | null = null;
  customFields?: CustomTaskFieldEntity[] | null = null;
  category?: string | null = null;

  constructor(jobId: string, segmentId?: string, lat?: number, lng?: number) {
    this.jobID = jobId
    this.segmentID = segmentId
    this.lat = lat
    this.lng = lng
  }
}

export const showFootage = (taskState?:TaskEntity) => {
    if (
      taskState?.taskType === TaskType.boreBackreamPullback ||
      taskState?.taskType === TaskType.borePilot ||
      taskState?.taskType === TaskType.plow ||
      taskState?.taskType === TaskType.cablePull ||
      taskState?.taskType === TaskType.cameraInspection ||
      taskState?.taskType === TaskType.cleanOutConduit ||
      taskState?.taskType === TaskType.fiberBlowPull ||
      taskState?.taskType === TaskType.pipeCoating ||
      taskState?.taskType === TaskType.pipeReline ||
      taskState?.taskType === TaskType.pipeBurtstingCracking || 
      taskState?.taskType === TaskType.tracerWireInstallation ||
      taskState?.taskType === TaskType.warningTapeInstallation ||
      taskState?.taskType === TaskType.molingPiercingTool
    )
      return true;
    else return false;
  };

  export const showTaskName = (taskState:TaskEntity) => {
    if (taskState?.taskType === TaskType.custom) return true;
    else return false;
  };

  export const showCustomFields = (taskState:TaskEntity) => {
    if (taskState?.taskType === TaskType.custom) return true;
    else return false;
  };

  export const showYards = (taskState?:TaskEntity) => {
    if (
      taskState?.taskType === TaskType.backfill ||
      taskState?.taskType === TaskType.gradeCompact ||
      taskState?.taskType === TaskType.excavate ||
      taskState?.taskType === TaskType.trench ||
      taskState?.taskType === TaskType.molingPiercingTool
    )
      return true;
    else return false;
  };

  export const showQuantity = (taskState?:TaskEntity) => {
    if (
      taskState?.taskType === TaskType.conduitMakeConnections ||
      taskState?.taskType === TaskType.drop ||
      taskState?.taskType === TaskType.fiberSplices ||
      taskState?.taskType === TaskType.handHoleInstallation ||
      taskState?.taskType === TaskType.manHoleInstallation ||
      taskState?.taskType === TaskType.pedestalInstallation ||
      taskState?.taskType === TaskType.pipeJointCoating ||
      taskState?.taskType === TaskType.riserInstallation ||
      taskState?.taskType === TaskType.taps ||
      taskState?.taskType === TaskType.vaultInstallation ||
      taskState?.taskType === TaskType.pipeFusing ||
      taskState?.taskType === TaskType.pothole ||
      taskState?.taskType === TaskType.flowerBoxInstallation
    )
      return true;
    else return false;
  };

  export const showProduct = (taskState?:TaskEntity) => {
    if (
      taskState?.taskType === TaskType.boreBackreamPullback ||
      taskState?.taskType === TaskType.borePilot ||
      taskState?.taskType === TaskType.plow ||
      taskState?.taskType === TaskType.pipeFusing ||
      taskState?.taskType === TaskType.trench
    )
      return true;
    else return false;
  };

  export const showGroundConditions = (taskState?:TaskEntity) => {
    if (
      taskState?.taskType === TaskType.boreBackreamPullback ||
      taskState?.taskType === TaskType.borePilot ||
      taskState?.taskType === TaskType.plow ||
      taskState?.taskType === TaskType.excavate ||
      taskState?.taskType === TaskType.mudMixing ||
      taskState?.taskType === TaskType.pipeBurtstingCracking ||
      taskState?.taskType === TaskType.pothole ||
      taskState?.taskType === TaskType.shoringInstallation ||
      taskState?.taskType === TaskType.trench
    )
      return true;
    else return false;
  };

export class TaskProductEntity {
  type?: TaskProductDataType | null = null;
  size?: string | null = null;
  quantity?: string | null = null;
  constructor(type?: TaskProductDataType, size?: string, quantity?: string) {
    this.type = type;
    this.size = size;
    this.quantity = quantity;
  }
}

export const getTaskProductDescription = (taskProduct: TaskProductEntity) => {
  return `Type: ${taskProduct?.type}, Size: ${taskProduct?.size} in, Quantity: ${taskProduct?.quantity}`
}
