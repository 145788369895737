import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import BasicForm from '../../components/Form/BasicForm';
import { getMessages } from '../../components/Messages/GetMessages';
import { addNotification } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';
import { fieldsToObject, touchField, updateFormOnChange } from '../../shared/FormHelper';

class NotificationsNew extends Component {
    static contextType = AuthContext;
    loadNotifications = this.context.loadNotifications;

    state = {
        addNotificationForm: {
            name: 'add-notification',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'title',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Notification Title',
                    placeholder: 'Title',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('notification_title_required')
                        }
                    ]
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    label: 'Notification Message',
                    placeholder: 'Message',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('notification_msg_required')
                        }
                    ]
                },
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addNewNotification(),
                    disabled: () => false,
                    submit: true
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-danger font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.props.history.goBack(),
                    disabled: () => false
                }
            ]
        }
    }

    addNewNotification = async () => {
        const currentUser = this.context.currentUser;
        
        const form = { ...this.state.addNotificationForm };
        form.saving = true;
        this.setState({ addNotificationForm: form });
        const notification = fieldsToObject(this.state.addNotificationForm.fields);
        notification.createdAt = new Date();
        notification.seenByUser = [currentUser.uid];
        notification.createdBy = "Equappment Admin";

        const notificationResult = await addNotification(notification);
        form.saving = false;
        this.setState({ addNotificationForm: form });
        await this.loadNotifications(currentUser.uid);
        this.props.history.goBack()
        // this.props.history.push('/notifications/');
    }

    prepareAddNotificationForm = () => {
        let form = this.state.addNotificationForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.addNotificationForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.addNotificationForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    render() {
        if((this.context.currentUser.uid !== "zfnO1CRDeWetLPsqUB8a6lR8Wu32") && (this.context.currentUser.uid !== "CfaHqGxDiqhn0u0iI8HZM2q8cXC3"))
        return <Redirect to="/equipment" />
        

        const addNotificationForm = this.prepareAddNotificationForm();
        
        return(
            <div className="card">
                    <div className="card-body">
                        <h3 className="mb-10 font-weight-bold text-dark">Add Notification</h3>
                        <div className="mb-20">
                            <BasicForm {...addNotificationForm} />
                        </div>
                    </div>
            </div>
        )
    }
}

export default NotificationsNew;