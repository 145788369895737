import { Circle } from "../Icons/Svg";

const EQMarker = (props) => {
  const greatPlaceStyle = {
    position: "absolute",
    transform: "translate(-58%, -98%)",
    color: props.pinColor ?? "darkred",
    fontSize: "26px",
    cursor: "pointer",
  };

  const infoWindowStyle = {
    position: "relative",
    bottom: 100,
    left: "-45px",
    width:"fit-content",
    whiteSpace: "nowrap",
    backgroundColor: "white",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    padding: 6,
    fontSize: 14,
    zIndex: 100,
  };

  const getPinIcon = () => {
    if (props?.iconPath) return props.iconPath;
    else if (props?.icon) return props.icon;
    else return "flaticon2-pin";
  };

  return (
    <>
      <div
        style={greatPlaceStyle}
        className={getPinIcon()}
        onClick={() => {
          props?.onClick({ lat: props.lat, lng: props.lng });
        }}
      >
        <img src={getPinIcon()} className="max-h-100px" alt="" />
      </div>
      {(props?.show ?? false) && (
        <div
          style={infoWindowStyle}
          className="d-flex flex-row flex-center p-3"
          onClick={() => {
            props?.onInfoClicked();
          }}
        >
          <div className="d-flex flex-column">{props.infoText}</div>
          <div className="d-flex flex-column ml-7">
            <Circle />
          </div>
        </div>
      )}
    </>
  );
};

export default EQMarker;
