import React from 'react';

const RadioButtons = (props) => {
    function clearValidationClasses(className) {
        return className.replace("is-valid", "").replace("is-invalid", "");
    }

    return (
        <>
            {props.field.options.map((x,index) => {
                return (
                    <label key={"rd-btn-inpt"+index} className="radio mb-2">
                        <input
                            type="radio"
                            value={x.value}
                            name={props.field.name}
                            checked={props.field.value === x.value}
                            onChange={props.handleChange} />
                        <span />&nbsp; {x.label}</label>
                );
            })}
            {props.field.allowCustom &&
            <div className="input-group">
                <label className="radio mb-2">
                    <input
                        type="radio"
                        value={props.field.custom}
                        name={props.field.name}
                        checked={props.field.value === props.field.custom}
                        onChange={props.handleChange} />
                    <span />&nbsp; Other</label>
                <input
                    type="text"
                    className={clearValidationClasses(props.className) + " ml-10"}
                    value={props.field.customValue}
                    placeholder="Value"
                    name={props.field.name}
                    onChange={props.handleChange}
                    onBlur={() => { props.touchField(props.field) }}
                    id={props.id + "-custom-value"}
                    disabled={props.field.value !== props.field.custom}
                />
            </div>
            }
        </>
    );
}

export default RadioButtons;