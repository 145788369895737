import React from "react";
import { Email, Phone } from "../Icons/Svg";

const DefaultListContactItem = (props) => {
  return (
    <React.Fragment>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="d-flex">
            <div className="flex-grow-1">
              <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
                <div className="mr-3">
                  <a
                    role="button"
                    onClick={() => props.switchToEditForm(props.item)}
                    className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3"
                  >
                    {props.item.firstName} {props.item.lastName}
                    &nbsp;
                    <div className="text-muted font-size-h6 font-weight-bold">
                      {props.item.position}
                    </div>
                  </a>
                  <div className="d-flex flex-wrap my-2">
                    <div className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Email />
                      </span>
                      {props.item.email}
                    </div>
                    <div
                      href="#"
                      className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Phone />
                      </span>
                      {props.item.phoneNumber}
                    </div>
                  </div>
                </div>
                {props.showEditButton && (
                  <div className="my-lg-0 my-1">
                    <button
                      onClick={() => props.switchToEditForm(props.item)}
                      className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
                    >
                      Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
            {props.canSelect && (<label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0 mx-4">
              <input type="checkbox" onChange={(e) => {
                props.onSelected()
              }} checked={props.selectedItem} />
              <span />
            </label>)}
          </div>
          <div className="separator separator-solid my-7" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DefaultListContactItem;
