import React, { Component } from 'react';
import GoogleMap from '../../../../components/GoogleMap/GoogleMap';
import Geosuggest from 'react-geosuggest';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../shared/Auth';


class JobLocationStep extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: props.obj.job && props.obj.job.locationName ? props.obj.job.locationName : '',
            lng:  props.obj.job && props.obj.job.locationLong ? props.obj.job.locationLong : -74.0059728,
            lat:  props.obj.job && props.obj.job.locationLat ? props.obj.job.locationLat : 40.7127753
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        
        this.props.setStep('location');

        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-location-title"]').tooltip({ trigger: 'manual' });
            window.$('[id="job-location-title"]').tooltip('hide');
            window.$('[id="job-location-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-location-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    onSuggestSelect = (suggest) => {
        if(suggest && suggest.location) {
            this.setState({ lat: suggest.location.lat, lng: suggest.location.lng, address: suggest.description });
        }
    }

    setLocation = (coords) => {
        this.setState({ lat: coords.lat, lng: coords.lng })
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    next = () => {
        this.props.setJobLocation(this.state.address, this.state.lat, this.state.lng);
        this.props.history.push('/jobs/new/client');
    }

    save = async () => {
        await this.props.setJobLocation(this.state.address, this.state.lat, this.state.lng);
        this.props.save();
    }

    render() {
        return (
            <>
                <a id="job-location-title" data-toggle="tooltip" title="Enter the address or drop a pin so everyone knows where to go for the job."
                    data-placement="left">
                    <h3 className="mb-10 font-weight-bold text-dark">Set Job Location</h3>
                </a>
                <span className="text-dark">{this.state.address}</span><br />
                <span className="text-dark text-muted">Latitude: {parseFloat(this.state.lat).toFixed(3)}</span><br />
                <span className="text-dark text-muted">Longitude: {parseFloat(this.state.lng).toFixed(3)}</span><br /><br />
                <Geosuggest 
                    autoActivateFirstSuggest={true}
                    inputClassName="form-control"
                    suggestsClassName="sug-container"
                    suggestItemClassName="dropdown-item"
                    suggestItemActiveClassName="sug-item-active"
                    onSuggestSelect={this.onSuggestSelect}
                    autoComplete="off"
                />

                <div className="mt-5" style={{ width: '100%', height: '480px' }}>
                    <GoogleMap lat={this.state.lat} lng={this.state.lng} onClick={this.setLocation} />
                </div>
                
                <div className="justify-content-between border-top mt-5 pt-10"> 
                    <button className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2" 
                        onClick={this.next}>Next</button>                   
                    <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                        onClick={this.save}
                       >Skip and Save</button>
                    <Link className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2" 
                        to='/jobs/new'>Back</Link>
                </div>
            </>
        );
    }
}



export default JobLocationStep;