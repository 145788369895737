import React, { Component } from 'react';
import { Route, Link, Switch, BrowserRouter } from 'react-router-dom';
import ActiveUsers from './ActiveUsers'
import ActiveUserEdit from './ActiveUserEdit'
import RoleDefinitions from './RoleDefinitions'

import { AuthContext } from "../../shared/Auth";
import { Person, ShieldUser } from '../../components/Icons/Svg';

class Settings extends Component {
  static contextType = AuthContext;

  
  render() {
    const currentUser = this.context.currentUser;
    const splitLocation = this.props.location.pathname.split("/")
    
    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom">
            <div className="card-header card-header-tabs-line nav-tabs-line-3x">
              <div className="card-toolbar">
                <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                  <li className="nav-item mr-3">
                    <Link to={`${this.props.match.url}`} className={!splitLocation[2]  ?  "nav-link active" : "nav-link" } data-toggle="tab" >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          <Person/>
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">Role Definitions</span>
                    </Link>
                  </li>
                  <li className="nav-item mr-3">
                    <Link to={`${this.props.match.url}/activeusers`} className={splitLocation[2]  ?  "nav-link active" : "nav-link" } data-toggle="tab">
                      <span className="nav-icon">
                        <span className="svg-icon">
                          <ShieldUser/>
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">Active Users and Roles</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="card-body">
              <form className="form" id="kt_form">
                <div className="tab-content">
                  <div className="tab-pane show active px-7" id="kt_user_edit_tab_1" role="tabpanel">
                  <Route path={`${this.props.match.path}`} exact={true} 
                        render={(props) => <RoleDefinitions {...props}/> } />
                  <Route path={`${this.props.match.path}/activeusers`} exact={true} 
                        render={(props) => <ActiveUsers {...props} /> } />
                  <Route path={`${this.props.match.path}/activeusers/:id/edit`} exact={true} 
                        render={(props) => <ActiveUserEdit {...props} /> } />
                  </div>
                  <div className="tab-pane px-7" id="kt_user_edit_tab_3" role="tabpanel">
                  
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;