import React, { useEffect, useContext } from 'react';
import app from '../../../firebase';
import { AuthContext } from "../../../shared/Auth";
import { Link } from 'react-router-dom';
import { Email, GroupChat, Person, Privacy, SendingMail, ShieldLock, Terms } from '../../Icons/Svg';

const QuickUser = (props) => {
  const { currentUser } = useContext(AuthContext);
  const simulateOnCloseClick = React.useRef(null);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right p-10">
      {/*begin::Header*/}
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">{currentUser.userProfile.firstName + ' ' + currentUser.userProfile.lastName}
            <small className="text-muted font-size-sm ml-2"></small>
        </h3>
        <a ref={simulateOnCloseClick} href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      {/*end::Header*/}
      {/*begin::Content*/}
      <div className="offcanvas-content pr-5 mr-n5">
        {/*begin::Header*/}
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div className="symbol-label" style={{ backgroundImage: 'url("' + currentUser.userProfile.avatar + '")' }} />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <div className="text-muted mt-1">{currentUser.company.name}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon svg-icon-lg svg-icon-primary">
                      <Email/>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">{currentUser.email}</span>
                </span>
              </a>
              <a onClick={() => { simulateOnCloseClick.current.click(); app.auth().signOut(); }} className="btn btn-sm btn-light-primary font-weight-bolder mt-1 py-2 px-5 rounded-0">Sign Out</a>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed mt-8 mb-5" />
        <div className="navi navi-spacer-x-0 p-0">
          <Link onClick={() => { simulateOnCloseClick.current.click(); }} to="/me" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <Person/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Profile</div>
                <div className="text-muted">Account settings and more</div>
              </div>
            </div>
          </Link>
          <Link onClick={() => { simulateOnCloseClick.current.click(); }} to="/settings" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <ShieldLock/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Team Roles and Permissions</div>
                <div className="text-muted">Role definitions and active users</div>
              </div>
            </div>
          </Link>
          <a href="http://help.equappment.com/support/home" target="_blank" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <GroupChat/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Support - User Guide</div>
                <div className="text-muted">How can we help you today?</div>
              </div>
            </div>
          </a>
          <a href="mailto:support@equappment.com" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SendingMail/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Support - Contact</div>
                <div className="text-muted">Send us an email</div>
              </div>
            </div>
          </a>
          <Link onClick={() => { simulateOnCloseClick.current.click(); }} to="/" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <Terms/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Terms of Service</div>
                <div className="text-muted"></div>
              </div>
            </div>
          </Link>
          <Link onClick={() => { simulateOnCloseClick.current.click(); }} to="/" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <Privacy/>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Privacy Policy</div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default QuickUser;