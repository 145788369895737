import { Timestamp } from "firebase/firestore"
import { FirebaseInventoriesCollections, FirebaseJobCollections, FirebaseRootCollections } from "../../firebase/FirebaseSchema"

export class JobEntity {
    id?: string | null
    clientID?: string | null
    clients?: string[] | null
    jobClients?: JobClient[] | null
    jobID?: string | null
    description?: string | null
    startDate?: Timestamp | null
    endDate?: Timestamp | null
    isOverdue?: boolean = false
    locationLat?: number | null
    locationLong?: number | null
    locationName?: string | null
    photos?: string[] | null
    status?: JobStatus | null[]
    title?: string | null
    totalFootage?: string | null
}

export class JobClient {
    clientID?: string
    contactIDs: string[] = []

    constructor(clientID: string, contactIDs: string[]){
        this.clientID = clientID
        this.contactIDs = contactIDs
    }
}

export enum JobStatus {
    active = "published",
    draft = "draft"
}

export class DailyProductionCheckInEntity {
    jobID?: string | null;
    userID?: string | null;
    day?: string | null;
    startTime?: string | null;
    endTime?: string | null;
    accomplishments?: string | null;
    crewmembers?: string[] | null = [];
    hurdles?: string | null = null;
    notes?: string | null = null;
    saveFile: boolean = true;
    recipients: string[] = [];
    workTimes: WorkTimeEntity[] = []
    safetyTalk: string = "Yes"
    pdfExcel: number = 0
}

export default class WorkTimeEntity {
    firstName: string
    lastName: string
    startTime?: string | null = null
    endTime?: string | null = null
    crewName?: string | null = null
    travelTime: string = "0"
    phoneNumber?: string | null = null
    email?: string | null = null
    signature?: string | null = null
    safetyTalk: boolean = false
    isExternal: boolean = false
    notOnJob: boolean | null = false

    constructor(firstName:string, lastName:string)
    constructor(
        firstName: string, 
        lastName: string,
        startTime: string | null = null,
        endTime: string | null = null,
        crewName: string | null = null,
        phoneNumber: string | null = null,
        email: string | null = null,
        signature: string | null = null,
        travelTime: string = "0",
        safetyTalk: boolean = false,
        isExternal: boolean = false) {
        this.firstName = firstName
        this.lastName = lastName
        this.startTime = startTime
        this.endTime = endTime
        this.crewName = crewName
        this.travelTime = travelTime
        this.phoneNumber = phoneNumber
        this.email = email
        this.signature = signature
        this.safetyTalk = safetyTalk
        this.isExternal = isExternal
    }
}

export const buildJobPhotoEntityPath = (inventoryId: string, jobId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${jobId}/${FirebaseJobCollections.PHOTOS}`
}

export const buildJobFileEntityPath = (inventoryId: string, jobId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.JOBS}/${jobId}/${FirebaseJobCollections.FILES}`
}

export const buildJobFileUploadPath = (companyId: string, jobId: string, folderName: string) => {
    return `${companyId}/Files/${jobId}/${folderName}`
}

export const buildJobFilesPath = (companyId:string, jobId: string) => {
    return `${companyId}/Files/${jobId}`
}

export const buildJobPhotoUploadPath = (companyId: string, jobId: string, folderName: string) => {
    return `${companyId}/job/${jobId}/${folderName}`
}

export const buildJobPhotoPath = (companyId:string, jobId: string) => {
    return `${companyId}/job/${jobId}`
}