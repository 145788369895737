import React, { Component } from 'react';
import { getPresetFolders, addPresetFolder, deletePresetFolder, presetFolderCollectionExists } from '../../../../shared/Api';
import { resetFormValues, touchField, updateFormOnChange, fieldsToObject } from '../../../../shared/FormHelper';
import { AuthContext } from "../../../../shared/Auth";
import BasicForm from '../../../../components/Form/BasicForm';
import Loader from '../../../../components/Layout/Loader/Loader';
import { Link } from 'react-router-dom';
import { getMessages } from '../../../../components/Messages/GetMessages';
import { Folder } from '../../../../components/Icons/Svg';
import ConfirmModal from '../../../../components/Modals/ConfirmModal';

class JobFoldersStep extends Component {
    static contextType = AuthContext;

    state = {
        loading: false,
        savingFolder: false,
        presetFolders: [],
        addFoldersShow: false,
        search: '',
        deleteFolderModalShow: false,
        selectedFolderId: null,
        deletingFolder: false,
        newFolderForm: {
            name: 'add-new-preset-folder',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'name',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Folder Name',
                    placeholder: 'Folder Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('preset_folder_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('preset_folder_name_required')
                        }
                    ]
                }
            ],
            buttons: [
                {
                    title: 'Add Folder',
                    submit: true,
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2 ',
                    onClick: () => this.addFolder(),
                    disabled: () => false
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4',
                    onClick: () => this.closeAddNewFolderForm(),
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        
        this.props.setStep('folders');
        await this.loadPresetFolders();
        
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-folders-title"]').tooltip({ trigger: 'manual' });
            window.$('[id="job-folders-title"]').tooltip('hide');
            window.$('[id="job-folders-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-folders-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    loadPresetFolders = async () => {       
        this.setState({ loading: true });
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        const presetFolders = await getPresetFolders(inventoryId);
        this.setState({ presetFolders: presetFolders.data, loading: false });
    }

    addGeneralFolders = async () => {
        await this.addFolderWith("SOW - Blueprints");
        await this.addFolderWith("Job Site Documents");
        await this.addFolderWith("As Built for Final");
    }

    addFolderWith = async (name) => {
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        await addPresetFolder(inventoryId, { name: name });
    }

    addFolder = async () => {
        const currentUser = this.context.currentUser;
        const companyId = currentUser.userProfile.companyID;
        const inventoryId = currentUser.company.inventoryID;

        this.setState({savingFolder: true})
        const folder = fieldsToObject(this.state.newFolderForm.fields);

        await addPresetFolder(inventoryId, folder);
        await this.loadPresetFolders();

        this.closeAddNewFolderForm();
        this.setState({savingFolder: false})
    }

    next = async () => {
        //this.props.setJobFolders(this.state.presetFolders);
        const job = await this.props.save();
    }

    closeAddNewFolderForm = () => {
        let updatedForm = { ...this.state.newFolderForm };
        resetFormValues(updatedForm);
        updatedForm.isValid = false;
        updatedForm.submitErrorMessage = '';
        updatedForm.loading = false;

        this.setState({
            newFolderForm: updatedForm,
            addFolderShow: false
        });
    }

    deleteFolder = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        this.setState({ deletingFolder: true });        

        await deletePresetFolder(inventoryId, this.state.selectedFolderId);
        await this.loadPresetFolders();
        this.setState({ deletingFolder: false, selectedFolderId: null, deleteFolderModalShow: false });     
    }

    prepareAddFolderForm = () => {
        let form = this.state.newFolderForm;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.newFolderForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.newFolderForm);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        return form;
    }

    getClass = (className) => {
        if(this.props.saving)
            className += " spinner spinner-white spinner-right";

        return className;
    }

    folders = () => {
        if (!this.state.search)
            return this.state.presetFolders;

        return this.state.presetFolders.filter(x => x.name.toLowerCase().includes(this.state.search.toLowerCase()));
    }

    render() {
        const addFolderForm = this.prepareAddFolderForm();
        const folders = this.folders();

        return (
            <>
            <a id="job-folders-title" data-toggle="tooltip" title="We start you off with a common document folder structure. Add and delete folders as needed. Then drop in whatever forms, permits, drawings or other documents needed for this job."
                data-placement="left">
                    <h3 className="mb-10 font-weight-bold text-dark">Job Folders</h3>
            </a>

            <div className="form-group">
                <input
                    type="text"
                    className="form-control"
                    onChange={(e) => { this.setState({ search: e.target.value }); }}
                    placeholder="Search Folders" />
            </div>

            <button onClick={() => this.setState({ addFolderShow: true })}
                className="btn btn-block btn-light-primary font-weight-bold mb-8">
                <i className="ki ki-plus icon-md mr-2" />Add New Folder</button>

            {this.state.addFolderShow &&
                <div className="mb-20">
                    <BasicForm {...addFolderForm} saving={this.state.savingFolder}/>
                </div>
            }

            {this.state.loading
                ?
                <Loader height="100px" />
                :
                <div>
                    {folders.length > 0 ?
                        <>
                            {folders.map((folder, index) => (
                                <div key={"jb-dcmnt"+index} className="d-flex align-items-center mb-10">
                                <div className="symbol symbol-40 symbol-light-success mr-5">
                                    <Folder />
                                </div>
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                    <a role="button" className="text-dark text-hover-primary mb-1 font-size-lg">{folder.name}</a>
                                </div>
                                <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Delete" data-placement="left">
                                    <a onClick={() => { this.setState({ selectedFolderId: folder.id, deleteFolderModalShow: true }) }} className="btn btn-hover-light-primary btn-sm btn-icon" aria-haspopup="true" aria-expanded="false">
                                        <i className="flaticon2-trash" />
                                    </a>
                                </div>
                            </div>
                            ))}
                        </>
                        :
                        <div className="d-flex align-items-center mb-10">
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                <div className='mainpage-graphic'>
                                    <Folder width={'200px'} height={'200px'} />
                                </div>
                                    <span className="font-italic">You don't have any folders added to the job at the moment. Click the button above to start adding them.</span>
                                </div>
                        </div>
                        }
                    <div className="justify-content-between border-top mt-5 pt-10">
                        <button className={this.getClass("btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2")}
                            onClick={this.props.save}
                        >Save Job</button>
                        <Link className="btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2"
                            to='/jobs/new/crewmembers'>Back</Link>
                    </div>
                </div>
            }

            <ConfirmModal
                id={"delete_folder"}
                show={this.state.deleteFolderModalShow}
                title="Delete folder"
                body={getMessages('delete_folder')}
                yesButton={{
                    title: "Yes",
                    onClick: () => { this.deleteFolder(); },
                    spinner: this.state.deletingFolder
                }}
                noButton={{
                    title: "Cancel",
                    onClick: () => { this.setState({ deleteFolderModalShow: false }) }
                }}
                />
        </>
        );
    }
}

export default JobFoldersStep;