import { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import BasicForm from '../../components/Form/BasicForm';
import * as moment from 'moment';
import { updateFormOnChange, touchField, fieldsToObject } from '../../shared/FormHelper';
import { addReservation, getReservationsForEquipment } from '../../shared/Api';
import Loader from '../../components/Layout/Loader/Loader';
import { getMessages } from '../../components/Messages/GetMessages';
import ScheduleServiceReport from '../../components/Reports/ScheduleServiceReport';
import { getAllCrewMembers } from '../../shared/api/CrewApi';

class ScheduleEquipment extends Component {
    static contextType = AuthContext;

    state = {
        reservations: null,
        selectReportRecipients: false,
        reservationId: "",
        crewMembers:[],
        form: {
            name: 'service-equipment',
            isValid: true,
            submitErrorMessage: null,
            loading: false,
            saving: false,
            fields: [
                {
                    name: 'repairType',
                    value: 'Repair',
                    defaultValue: 'Repair',
                    type: 'multiselect',
                    label: 'Repair Type',
                    placeholder: 'Repair Type',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('equipment_repair'),
                    required: true,
                    options: [
                        {
                            value: 'Repair',
                            label: 'Repair'
                        },
                        {
                            value: 'RoutineService',
                            label: 'Routine Service'
                        }
                    ]
                },
                {
                    name: 'repairDetails',
                    value: '',
                    defaultValue: '',
                    type: 'textarea',
                    rows: 4,
                    label: 'Repair Details',
                    placeholder: 'Repair Details',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('repair_details'),
                    required: false
                },
                {
                    name: 'dates',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Service Dates',
                    type: 'daterangepicker',
                    label: 'Service Dates',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('service_dates'),
                    required: true,
                    minDate: moment(),
                    startDate: {
                        name: 'startDate',
                        value: moment()
                    },
                    endDate: {
                        name: 'endDate',
                        value: moment().add('days', 7)
                    }
                },
                {
                    name: 'hourMeter',
                    value: 0,
                    defaultValue: 0,
                    type: 'number',
                    dataType: 'float',
                    label: 'Hour Meter Reading (optional)',
                    placeholder: 'Hour Meter Reading',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('hour_meter'),
                    required: false
                },
                {
                    name: 'shareReport',
                    value: false,
                    defaultValue: false,
                    type: 'checkbox',
                    checkboxText: 'Share Report(optional)',
                    placeholder: 'Share Report',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: "Share this service request report",
                    required: false,
                  }
                  
                  
            ],
            buttons: [
                {
                    title: 'Save',
                    className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
                    onClick: () => this.scheduleService(),
                    disabled: () => false,
                    submit: true

                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase py-4',
                    onClick: () =>{if(!this.state.saving) this.props.history.goBack()},
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;
        const id = this.props.match.params.id;

        const reservations = await getReservationsForEquipment(inventoryId, id);
        const crewMembersResult =
        (await getAllCrewMembers(inventoryId)).data?.filter(
          (crewMember) => crewMember.email !== currentUser.userProfile.email
        ) ?? [];

        this.setState({ reservations: reservations.data, crewMembers:crewMembersResult });
    }

    scheduleService = async () => {
        if (!this.state.form.saving) {
          const obj = fieldsToObject(this.state.form.fields);
          let form = { ...this.state.form };
          form.saving = true;
          this.setState({ form: form });

          const currentUser = this.context.currentUser;
          const inventoryId = currentUser.company.inventoryID;
          const equipmentId = this.props.match.params.id;

          obj.equipmentID = equipmentId;
          obj.type = 0;
          const { shareReport, ...objectToSave } = obj;
          const result = await addReservation(inventoryId, objectToSave);

          if (result.status == "success" && obj.shareReport) {
            this.setState({
              reservationId: result.data,
              selectReportRecipients: true,
            });
          } else {
            form.saving = false;
            this.setState({ form: form });
            this.props.history.goBack();
          }
        }
    }

    prepareForm = () => {
        let form = this.state.form;

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.form);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.form);

            if (!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        let datesField = form.fields.find(x => x.name === 'dates');
        if(datesField) {
            datesField.invalidRanges = this.state.reservations.map(x => {
                return {
                    startDate: moment(x.startDate.toDate()),
                    endDate: moment(x.endDate.toDate())
                }
            })
        }

        return form;
    }

    render() {
        if(!this.state.reservations)
            return <Loader />

        const form = this.prepareForm();

        return (
            <div className="card">
                <div className="card-header">
                    <h3 className="font-weight-bold text-dark">Schedule Service</h3>
                </div>
                <ScheduleServiceReport reservationId={this.state.reservationId} crewMembers={this.state.crewMembers} showButton={false} performClick={this.state.selectReportRecipients} onReportSent={()=>{
                            this.props.history.goBack();
                }}/>
                <div className="card-body">
                    <BasicForm {...form} />
                </div>
            </div>
        );
    }
}

export default ScheduleEquipment;