import { Component } from "react";
import Geosuggest from "react-geosuggest";
import { Link, useLocation } from "react-router-dom";
import MarkersInfoMap from "../../components/GoogleMap/MarkersInfoMap";
import loader from "../../components/Layout/Loader/Loader";
import {
  getPrivateSoilConditions,
  getPublicSoilConditions,
} from "../../shared/Api";
import { AuthContext } from "../../shared/Auth";
import { getCompanyDumpSites, getPublicDumpSites } from "../../shared/api/SoilConditionsApi";

class SoilConditions extends Component {
  static contextType = AuthContext;
  setPublicView = this.context.setPublicView;
  setSelectedPlace = this.context.setSelectedPlace;
  selectedPlace = this.context.selectedPlace;
  zoomLevel = this.context.zoomLevel;
  updateZoomLevel = this.context.updateZoomLevel;

  state = {
    allData: [],
    loading: false,
    privateData: [],
    lng: -74.0059728,
    lat: 40.7127753,
    selectedPlace: this.selectedPlace,
  };

  componentDidMount = async () => {
    await this.loadSoilConditions();
    await this.loadDumpSites();
    if (this.props.location.state) {
      this.setPublicView(true);
      this.setSelectedPlace(this.selectedPlace);
      this.updateZoomLevel();
    }
    if (this.selectedPlace && !this.props.location.state) {
      this.setState({
        lat: this.selectedPlace.lat,
        lng: this.selectedPlace.long,
      });
    }
  };

  loadDumpSites = async () => {
    const currentUser = this.context.currentUser;
    const companyID = currentUser.userProfile.companyID;
    const publicDumpSites = await getPublicDumpSites(companyID);
    const companyDumpSites = await getCompanyDumpSites(companyID);

    const publicData = publicDumpSites.data
      .map((dumpSite) => {
        return {
          ...dumpSite,
          show: false,
          icon: "assets/media/png/dump_pin_red.png",
          onClick: () => {
            this.props.history.push({
              pathname: `/dumpsite/${dumpSite.documentId}`,
              state: {
                dumpSite: dumpSite,
              },
            });
          },
        };
      });

    const privateData = companyDumpSites.data
      .map((dumpSite) => {
        return {
          ...dumpSite,
          show: false,
          icon: "assets/media/png/dump_pin_green.png",
          onClick: () => {
            this.props.history.push({
              pathname: `/dumpsite/${dumpSite.documentId}`,
              state: {
                dumpSite: dumpSite,
              },
            });
          },
        };
      });

    const allData = [...publicData, ...privateData];
    this.setState({
      loading: false,
      allData: [...this.state.allData, ...allData],
      privateData: [...this.state.privateData, ...privateData],
    });
  };

  loadSoilConditions = async () => {
    this.setState({ loading: true });
    const currentUser = this.context.currentUser;
    const id = currentUser.userProfile.companyID;
    let publicSoilConditionsResult = await getPublicSoilConditions(id);
    let privateSoilConditionsResult = await getPrivateSoilConditions(id);

    let privateData = privateSoilConditionsResult.data;
    let filteredPublic = publicSoilConditionsResult.data.filter(
      (x) => x.companyId !== id
    );

    filteredPublic.forEach((x) => {
      x.show = false;
      x.pinColor = "red";
      x.onClick = () => {
        this.props.history.push("/soilconditions/" + x.documentId);
      };
    });

    privateData.forEach((x) => {
      x.show = false;
      x.pinColor = "green";
      x.onClick = () => {
        this.props.history.push("/soilconditions/" + x.documentId);
      };
    });

    let data = [...filteredPublic, ...privateData];

    if (privateData.length !== 0) {
      this.setState({
        lat: privateData[0].lat + 0.00001,
        lng: privateData[0].long + 0.00001,
      });
    }

    this.setState({
      loading: false,
      allData: [...this.state.allData, ...data],
      privateData: [...this.state.privateData, ...privateData],
    });
  };

  onSuggestSelect = (suggest) => {
    if (suggest && suggest.location) {
      this.setState({ lat: suggest.location.lat, lng: suggest.location.lng });
    }
  };

  onChildClickCallback = (marker) => {
    const allData = this.state.allData.map((place) => {
      if (place.documentId == marker.documentId)
        return { ...place, show: true };
      else return { ...place, show: false };
    });
    const privateData = this.state.privateData.map((place) => {
      if (place.documentId == marker.documentId)
        return { ...place, show: true };
      else return { ...place, show: false };
    });
    this.setState({
      allData: allData,
      privateData: privateData,
      lat: marker.lat,
      lng: marker.long,
    });
    this.setSelectedPlace(marker);
  };

  onZoomAnimationEnd = (value) => {
    this.updateZoomLevel(value);
  };

  prepareAllData = () => {
    let data = this.state.allData;
    data.forEach((x) => {
      x.onClick = () => {
        this.props.history.push("/soilconditions/" + x.id);
      };
    });

    return data;
  };

  getPlaces = () => {
    if (this.context.publicView) return this.state.allData;
    else return this.state.privateData;
  };

  render() {
    const publicView = this.context.publicView;
    const selectedPlace = this.context.selectedPlace;
    const zoomLevel = this.context.zoomLevel;

    if (!this.state.allData) return loader;
    return (
      <div className="card card-custom">
        <div className="card-header">
          <div className="card-title">
            <h3>Soil and Dump-Site Map</h3>
          </div>
          <div className="card-toolbar">
            <Link
              to="/soilconditions/new"
              className="btn btn-light-primary font-weight-bold mr-2"
            >
              <i className="ki ki-plus icon-md mr-2" />
              New Soil Condition
            </Link>
            <Link
              to="/dumpsite/new"
              className="btn btn-light-primary font-weight-bold"
            >
              <i className="ki ki-plus icon-md mr-2" />
              New Dump-Site
            </Link>
          </div>
        </div>
        <div className="card-body">
          <form className="form">
            <div className="form-group row">
              <label className="col-3 col-form-label">
                Show public entries
              </label>
              <div className="col-3">
                <span className="switch">
                  <label>
                    <input
                      type="checkbox"
                      name="publicView"
                      checked={this.context.publicView}
                      onChange={this.context.toggleView}
                    />
                    <span />
                  </label>
                </span>
              </div>
            </div>
          </form>
          {!publicView && this.state.privateData.length === 0 && (
            <p>
              Your company currently does not have any private soil conditions
              saved. You can use the slider above to view public soil conditions
              or you can start adding your own by clicking on the button in the
              top right corner.
            </p>
          )}
          {/* {!publicView && this.state.privateData.length > 0 && ( */}
          <div className="mt-5" style={{ width: "100%", height: "480px" }}>
            <Geosuggest
              autoActivateFirstSuggest={true}
              inputClassName="form-control"
              suggestsClassName="sug-container"
              suggestItemClassName="dropdown-item"
              suggestItemActiveClassName="sug-item-active"
              onSuggestSelect={this.onSuggestSelect}
              autoComplete="off"
            />
            &nbsp;
            <MarkersInfoMap
              onChildClickCallback={this.onChildClickCallback}
              zoomLevel={zoomLevel}
              onDrag={(lat, lng) => {
                this.setState({ lat: lat, lng: lng });
              }}
              lat={this.state.lat}
              lng={this.state.lng}
              places={this.getPlaces()}
              selectedPlace={selectedPlace}
            />
          </div>
          <div className="card-footer" />
        </div>
      </div>
    );
  }
}

export default SoilConditions;
