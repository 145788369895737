import React, { useContext, useMemo } from "react";
import { TaskEntity } from "../../../../shared/data/taskmanagement/Task";
import { AuthContext } from "../../../../shared/Auth";

interface TaskStatusProps {
  task: TaskEntity;
  onEdit?: (task: TaskEntity) => void;
  onInProgress?: (task: TaskEntity) => void;
  onHold?: (task: TaskEntity) => void;
  onComplete?: (task: TaskEntity) => void;
  onOpen?: (task: TaskEntity) => void;
  onDelete?: (task: TaskEntity) => void;
}

const TaskStatusComponent = ({
  task,
  onEdit = () => {},
  onInProgress = () => {},
  onHold = ()=>{},
  onComplete = () => {},
  onOpen = () => {},
  onDelete = () => {},
}: TaskStatusProps) => {
  const taskState = useMemo(() => task, [task]);
  const authContext = useContext(AuthContext);
  const canEdit = authContext.currentUser.userProfile.permissions !== "crewmember";

  const showInProgressOption = () =>{
    return taskState.taskStatus=="open" || (taskState.taskStatus=="onHold" && (taskState.currentDuration??0)>0)
  }

  const showOpenOption = () =>{
    return taskState.taskStatus=="closed" || (taskState.taskStatus=="onHold" && (taskState.currentDuration??0)==0)
  }

  const showOnHoldOption = () =>{
    return taskState.taskStatus!="onHold" && task.taskStatus !="closed"
  }

  return (
    <React.Fragment>
      <div className="dropdown dropdown-inline ml-2">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bolder dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {/* <span className="svg-icon svg-icon-md">
            <ExportIcon />
          </span> */}
          Task Actions
        </button>
        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi flex-column navi-hover py-2">
            <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">
              Task actions:
            </li>
            <li className="navi-item">
              <a
                role="button"
                onClick={() => {
                  onEdit(taskState);
                }}
                className="navi-link"
              >
                <span className="navi-text">Edit Task</span>
              </a>
            </li>
            {task.taskStatus != "closed" && (
              <li className="navi-item">
                <a
                  role="button"
                  onClick={() => {
                    onComplete(taskState);
                  }}
                  className="navi-link"
                >
                  <span className="navi-text">Complete Task</span>
                </a>
              </li>
            )}
            {showInProgressOption() &&(
              <li className="navi-item">
                <a
                  role="button"
                  onClick={() => {
                    onInProgress(taskState);
                  }}
                  className="navi-link"
                >
                  <span className="navi-text">Change to In progress</span>
                </a>
              </li>
            )}
            {showOpenOption() && (
              <li className="navi-item">
                <a
                  role="button"
                  onClick={() => {
                    onOpen(taskState);
                  }}
                  className="navi-link"
                >
                  <span className="navi-text">Open Task</span>
                </a>
              </li>
            )}
            {showOnHoldOption() && (
              <li className="navi-item">
                <a
                  role="button"
                  onClick={() => {
                    onHold(taskState);
                  }}
                  className="navi-link"
                >
                  <span className="navi-text">Set On Hold</span>
                </a>
              </li>
            )}
            {canEdit &&(
            <li className="navi-item">
              <a
                role="button"
                onClick={() => {
                  onDelete(taskState);
                }}
                className="navi-link "
              >
                <span className="navi-text">Delete Task</span>
              </a>
            </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TaskStatusComponent;
