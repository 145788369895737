import React, { useEffect, useState } from "react";
import {
  TaskProductEntity,
  TaskProductType,
} from "../../../../shared/data/taskmanagement/Task";
import { Trash } from "../../../../components/Icons/Svg";

interface TaskProductProps {
  title: string;
  productType: TaskProductEntity;
  onChange: (result: TaskProductEntity) => void;
  onDelete: (result: TaskProductEntity) => void;
}

export const TaskProduct = ({
  title,
  productType,
  onChange,
  onDelete
}: TaskProductProps) => {
  const [taskProduct, setTaskProduct] =
    useState<TaskProductEntity>(productType);

  useEffect(() => {
    onChange(taskProduct);
  }, [taskProduct]);

  return (
    <React.Fragment>
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div key={`task-new-product-${title}`} className="form-group">
            <label>{title}</label>
            <div key={`task-new-product-1-product-type-${title}`}>
              <select
                key={`task-new-task-type-select-${title}`}
                className="custom-select my-md-0"
                onChange={(event:any) => {
                  setTaskProduct((previousState) => {
                    return { ...previousState, type: event.target.value };
                  });
                }}
              >
                <option value="" disabled selected>
                  Product type
                </option>
                {Object.entries(TaskProductType).map((item, index) => {
                  return (
                    <option value={item[1]} selected={item[1] == taskProduct.type}>
                      {item[1]}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="container">
          <div key="task-new-product-1-size" className="form-group">
            <label>Size</label>
            <input
              type="number"
              placeholder="Size"
              className="form-control"
              value={productType?.size ?? ""}
              onChange={(input) => {
                setTaskProduct((previousState) => {
                  return { ...previousState, size: input.target.value };
                });
              }}
            />
            <div className="form-text text-muted">Size in inch</div>
          </div>
        </div>
        <div className="container">
          <div key="task-new-product-1-quantity" className="form-group">
            <label>Quantity</label>
            <input
              type="number"
              className="form-control"
              placeholder="Quantity"
              value={productType?.quantity ?? ""}
              onChange={(input) => {
                setTaskProduct((previousState) => {
                  return {
                    ...previousState,
                    quantity: input.target.value,
                  };
                });
              }}
            />
          </div>
        </div>
        <div className="mt-2 mr-3" onClick={()=>{onDelete(productType)}}>
          <div key="task-new-product-1-quantity" className="form-group"></div>
          <Trash />
          </div>
      </div>
    </React.Fragment>
  );
};

export default TaskProduct;
