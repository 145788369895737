import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CrewmemberIos } from '../../components/Icons/Svg';
import Loader from '../../components/Layout/Loader/Loader';

class JobCrew extends Component {
    state = {
        search: ''
    }

    componentDidMount() {
        this.props.setTabActive('crewmembers');
    }

    getInitials = (item) => {
        return (item.firstName + " " + item.lastName).split(" ").map((n)=>n[0]).join("");
    }

    getAdded = () => {
        if(this.state.search === '')
            return this.props.addedCrewmembers;

        return this.props.addedCrewmembers.filter(x => x.firstName.toLowerCase().includes(this.state.search.toLowerCase())
        || x.lastName.toLowerCase().includes(this.state.search.toLowerCase()) || 
        (x.firstName.toLowerCase() + " " + x.lastName.toLowerCase()).includes(this.state.search.toLowerCase()));
    }

    getOther = () => {
        if(this.state.search === '')
            return this.props.otherCrewmembers;

        return this.props.otherCrewmembers.filter(x => x.firstName.toLowerCase().includes(this.state.search.toLowerCase())
        || x.lastName.toLowerCase().includes(this.state.search.toLowerCase())|| 
        (x.firstName.toLowerCase() + " " + x.lastName.toLowerCase()).includes(this.state.search.toLowerCase()));
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-12 pt-10 pt-xl-0">
                        <div className="form-group">
                            <input 
                                type="text" 
                                className="form-control"
                                onChange={(e) => { this.setState({ search: e.target.value }); }}
                                placeholder="Search" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Added crewmembers</a>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                            {this.props.crewListLoading ? <Loader height="100px"/> : 
                            <div className="card-body pt-4 d-flex flex-column justify-content-between">
                            {this.getAdded().length > 0 ?
                            <>
                            {this.getAdded().map((item, index) => {
                                return (
                                    <div key={"jb-crw-gtadd"+index} className="d-flex align-items-center mb-7">
                                        <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                            {item.image ?
                                                <div className="symbol symbol-lg-50">
                                                    <img alt="Pic" src={item.image} />
                                                </div> :
                                                <div className="symbol symbol-lg-50 symbol-primary">
                                                    <span className="symbol-label font-size-h3 font-weight-boldest">{this.getInitials(item)}</span>
                                                </div>
                                            }
                                        </div>
                                        <div className="d-flex flex-column">
                                            <Link to={"/jobs/" + this.props.job.id + '/crewmembers/' + item.id} className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
                                                {item.firstName + " " + item.lastName}
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                            </>
                            :
                            <div className="d-flex align-items-center mb-10">
                                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                                <div className='mainpage-graphic'>
                                    <CrewmemberIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                </div>
                                    <span className="font-italic">You don't have any crewmembers added to the job at the moment. You can start by clicking on a crewmember from the list on the right and then selecting 'Reserve'.</span>
                                </div>
                            </div>
                            }
                        </div>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 pt-10 pt-xl-0">
                        <div className="card card-custom card-stretch" id="kt_todo_view">
                            <div className="card-header align-items-center flex-wrap justify-content-between border-0 py-6 h-auto">
                                <div className="d-flex flex-column mr-2 py-2">
                                    <a className="text-dark text-hover-primary font-weight-bold font-size-h4 mr-3">Other crewmembers</a>
                                    <span className="font-italic">Click on a crewmember to schedule them to the job</span>
                                </div>
                            </div>
                            <div className="separator separator-dashed mt-3 mb-2"></div>
                            <div className="card-body p-0">
                            {this.props.crewListLoading ? <Loader height="100px"/> : 
                            <div className="card-body pt-4 d-flex flex-column justify-content-between">
                            {this.getOther().length > 0 ?
                                <>
                                {this.getOther().map((item,index) => {
                                    return (
                                        <div key={"jb-crw-gtoth"+index} className="d-flex align-items-center mb-7">
                                            <div className="flex-shrink-0 mr-4 mt-lg-0 mt-3">
                                                {item.image ?
                                                    <div className="symbol symbol-lg-50">
                                                        <img alt="Pic" src={item.image} />
                                                    </div> :
                                                    <div className="symbol symbol-lg-50 symbol-primary">
                                                        <span className="symbol-label font-size-h3 font-weight-boldest">{this.getInitials(item)}</span>
                                                    </div>
                                                }
                                            </div>
                                            <div className="d-flex flex-column">
                                                <Link to={"/jobs/" + this.props.job.id + '/crewmembers/' + item.id} className="text-dark font-weight-bold text-hover-primary font-size-h4 mb-0">
                                                    {item.firstName + " " + item.lastName}
                                                </Link>
                                            </div>
                                        </div>
                                    );
                                })}
                                </>
                                :
                                <div className="d-flex align-items-center mb-10">
                                    <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                                    <div className='mainpage-graphic'>
                                        <CrewmemberIos width={'200px'} height={'200px'} opacity={'0.3'} />
                                    </div>
                                        <span className="font-italic">There are no crewmembers for you to add to this job.</span>
                                    </div>
                                </div> 
                                }
                            </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default JobCrew;