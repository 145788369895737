import { Component } from 'react';
import BasicForm from '../../../components/Form/BasicForm';
import { touchField, updateFormOnChange, fieldsToObject, objectToFields } from '../../../shared/FormHelper';
import { AuthContext } from "../../../shared/Auth";
import * as moment from 'moment';
import { updateBoreLog, uploadImage, getBoreLog } from "../../../shared/Api";
import { getMessages } from '../../../components/Messages/GetMessages';
import Loader from '../../../components/Layout/Loader/Loader';
import { toFileEntity, toFileEntityDto } from '../../../shared/data/folder/FolderStructure';
import { getFileData, isImageFile, saveFileData, saveFileEntity } from '../../../shared/api/FilesApi';
import { buildBoreLogFileEntityPath, buildBoreLogFilesPath, buildBoreLogFileUploadPath } from '../../../shared/data/bore/BoreEntity';
import { Timestamp } from "firebase/firestore";
import ConfirmModal from '../../../components/Modals/ConfirmModal';


class BorLogsEdit extends Component {
  static contextType = AuthContext;

  state = {
    uploadingImage: false,
    loading: false,
    showConfirmModal: false,
    indexOfFileToRemove: null,
    addBoreLogForm: {
      name: "edit-bore-log",
      isValid: true,
      boreLogNumber: 1,
      submitErrorMessage: null,
      boreLog: null,
      loading: false,
      saving: false,
      fields: [
        {
          name: "date",
          value: "",
          defaultValue: "",
          type: "static",
          label: "Date and Time",
          placeholder: "Date and Time",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: false,
        },
        {
          name: "boreLogNumber",
          value: "",
          defaultValue: "",
          type: "static",
          label: "Entry No.",
          placeholder: "Entry No.",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: false,
        },
        {
          name: "distance",
          value: "",
          defaultValue: "",
          type: "number",
          dataType: "float",
          label: "Distance",
          placeholder: "Distance",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("bore_distance"),
          required: true,
          validators: [
            {
              type: "required",
              message: "Distance is required field.",
            },
          ],
        },
        // {
        //     name: 'totalDistance',
        //     value: "",
        //     defaultValue: '',
        //     type: 'static',
        //     label: 'Total Distance Drilled',
        //     placeholder: 'Total Distance Drilled',
        //     dataType: 'float',
        //     isValid: true,
        //     validationMessage: '',
        //     isTouched: false,
        //     additionalMessage: null,
        //     required: false
        // },
        {
          name: "depth",
          value: "",
          defaultValue: "",
          type: "number",
          dataType: "float",
          label: "Current depth reading",
          placeholder: "Current depth reading",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("bore_depth"),
          required: false,
        },
        {
          name: "pitch",
          value: "",
          defaultValue: "",
          type: "number",
          dataType: "float",
          label: "Current pitch reading",
          placeholder: "Current pitch reading",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("bore_pitch"),
          required: false,
        },
        {
          name: "distanceEOP",
          value: "",
          defaultValue: "",
          type: "number",
          dataType: "float",
          label: "Distance from EOP",
          placeholder: "Distance from edge of pavement(ft)",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("distance_from_eop"),
          required: false,
        },
        {
          name: "notes",
          value: "",
          defaultValue: "",
          type: "textarea",
          rows: 4,
          label: "Notes (optional)",
          placeholder: "Notes",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: getMessages("bore_details"),
          required: false,
        },
        {
          name: "image",
          value: [],
          images: [],
          defaultValue: "",
          type: "multiimage",
          label: "",
          isValid: true,
          editFile: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: false,
          localSrc: "",
          onDrop: (acceptedFiles) => {
            this.onImageDrop(acceptedFiles);
          },
          onNameChanged: (value, index) => {
            this.changeFileName(value, index);
          },
          onRemove: (index) => {
            this.setState({
              showConfirmModal: true,
              indexOfFileToRemove: index,
            });
            // this.onImageRemove(index);
          },
          dataToggle: "tooltip",
          title: "",
          dataPlacement: "left",
          uploadingImage: false,
        },
        // {
        //     name: 'image',
        //     value: '',
        //     defaultValue: '',
        //     type: 'dropzone',
        //     label: 'Bore Photo',
        //     placeholder: 'Bore Photo',
        //     isValid: true,
        //     validationMessage: '',
        //     isTouched: false,
        //     additionalMessage: '',
        //     required: false,
        //     localSrc: '',
        //     onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
        //     onRemove: () => { this.onImageRemove(); }
        // }
      ],
      buttons: [
        {
          title: "Save",
          className:
            "btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ",
          onClick: () => this.editBoreLog(),
          disabled: () => false,
          submit: true,
        },
        {
          title: "Cancel",
          className:
            "btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4",
          onClick: () => this.props.history.goBack(),
          disabled: () => false,
        },
      ],
    },
  };

  componentDidMount = async () => {
    await this.loadBoreLog();
  };

  loadBoreLog = async () => {
    this.setState({ loading: true });
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;

    const boreLogResult = await getBoreLog(
      inventoryId,
      boreId,
      boreLogId,
      currentUser.userProfile.companyID,
      true
    );
    const form = { ...this.state.addBoreLogForm };
    const fields = objectToFields(form.fields, boreLogResult.data);

    let dateField = fields.find((x) => x.name === "date");
    if (dateField) {
      dateField.value = moment(dateField.value?.toDate())?.format(
        "M/DD/YY, h:mma"
      );
    }

    const boreLogFiles = await getFileData(
      buildBoreLogFileEntityPath(inventoryId, boreId, boreLogId),
      buildBoreLogFilesPath(companyId, boreLogId)
    );

    let image = fields?.find((field) => field.name == "image");
    const files = boreLogFiles?.data?.map((file) => {
      return {
        image: file.url,
        title: file.fileName,
        name: file.name,
        createdAt: moment(file.createdAt?.toDate()).format("MM/DD/YY, hh:mma"),
        uploadedBy: file.createdBy,
      };
    });
    if (image) image.value = files;

    this.setState({
      boreLog: boreLogResult.data,
      addBoreLogForm: form,
      files: boreLogFiles?.data,
      loading: false,
    });
  };

  onImageDrop = async (acceptedFiles) => {
    this.changeImageUploadState(true);

    const currentUser = this.context.currentUser;
    const jobId = this.props.match.params.id;
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;

    await Promise.all(
      acceptedFiles.map(async (file, index) => {
        const url = window.URL.createObjectURL(file);
        const createdAt = Timestamp.fromMillis(
          Timestamp.now().toMillis() + index * 5000
        );

        const fileEntity = {
          id: null,
          name: "",
          fileName: file.name ?? "",
          fileType: isImageFile(file.name ?? "") ? "image" : "document",
          createdAt: createdAt,
          createdBy: currentUser?.displayName,
          url: url,
          file: file,
        };

        //In case this is existing bore log we upload file as soon as it is added and save file entity in bore log.
        //In case this is new bore log we add file to file list and upload it when new bore log is created
        if (true) {
          //TODO: add form state for uploading image to show some indicator in case file is too big.
          await this.uploadFile(fileEntity, boreId, boreLogId);
        } else {
          this.setImageState(fileEntity);
        }
      })
    );
    this.changeImageUploadState(false);
  };

  changeImageUploadState = (uploadingImage) => {
    const form = { ...this.state.addBoreLogForm };
    let image = form.fields?.find((field) => field.name == "image");
    if (image) {
      image.uploadingImage = uploadingImage;
      this.setState({ addBoreForm: form });
    }
  };

  uploadFile = async (fileEntity, boreId, boreLogId) => {
    const companyId = this.context.currentUser.userProfile.companyID;
    const inventoryId = this.context.currentUser.company.inventoryID;

    const fileUpload = {
      fileEntityPath: buildBoreLogFileEntityPath(
        inventoryId,
        boreId,
        boreLogId
      ),
      file: fileEntity.file,
      fileUploadState: "upload",
      fileUploadPath: buildBoreLogFileUploadPath(
        companyId,
        boreLogId,
        fileEntity?.createdAt?.seconds?.toString() ?? ""
      ),
      fileEntityDto: toFileEntityDto(fileEntity),
      fileDocumentId: null,
    };

    //TODO: add form state for uploading image to show some indicator in case file is too big.
    const result = await saveFileData(fileUpload);

    if (result?.data?.fileEntityDto) {
      const file = toFileEntity(
        result?.data?.fileEntityDto,
        result?.data?.fileDocumentId
      );
      this.setImageState(file);
    }
  };

  setImageState = (file) => {
    let form = { ...this.state.addBoreLogForm };
    let imageField = form.fields.find((x) => x.name === "image");
    imageField.value.push({
      image: file.url ?? "",
      title: file.fileName,
      name: file.name ?? "",
      createdAt: moment(file.createdAt?.toDate()).format("MM/DD/YY, hh:mma"),
      uploadedBy: file.createdBy,
    });

    let files = [...(this.state.files ?? []), file];

    this.setState({ files: files, addBoreLogForm: form });
  };

  changeFileName = async (fileName, fileIndex) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;

    const fileToChangeName = this.state.files?.find(
      (_, index) => index == fileIndex
    );
    var newFiles = this.state.files;
    if (fileToChangeName) {
      newFiles = this.state.files?.map((file, index) => {
        if (index === fileIndex) {
          file.name = fileName;
        }
        return file;
      });
    }

    let form = { ...this.state.addBoreLogForm };
    let imageField = form.fields.find((x) => x.name === "image");

    imageField.value[fileIndex].name = fileName;

    this.setState({ addBoreLogForm: form, files: newFiles });

    await saveFileEntity(
      toFileEntityDto(fileToChangeName),
      buildBoreLogFileEntityPath(inventoryId, boreId, boreLogId),
      fileToChangeName.id
    );
  };

  onImageRemove = async (fileIndex) => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreId = this.props.match.params.boreId;
    const boreLogId = this.props.match.params.boreLogId;
    const companyId = currentUser.userProfile.companyID;

    let form = { ...this.state.addBoreLogForm };
    let imageField = form.fields.find((x) => x.name === "image");
    imageField.value?.splice(fileIndex, 1);

    const fileToDelete = this.state.files?.find(
      (_, index) => index == fileIndex
    );

    const files = this.state.files?.filter((_, index) => index !== fileIndex);

    this.setState({ addBoreLogForm: form, files: files });

    //We remove file from the list. In case this is existing locate ticket we try to remove file from backend too
    if (fileToDelete) {
      const fileUpload = {
        fileEntityPath: buildBoreLogFileEntityPath(
          inventoryId,
          boreId,
          boreLogId
        ),
        file: null,
        fileUploadState: "delete",
        fileUploadPath: buildBoreLogFileUploadPath(
          companyId,
          boreLogId,
          fileToDelete?.createdAt?.seconds?.toString() ?? ""
        ),
        fileEntityDto: toFileEntityDto(fileToDelete),
        fileDocumentId: fileToDelete.id,
      };
      await saveFileData(fileUpload);
    }
  };

  editBoreLog = async () => {
    const form = { ...this.state.addBoreLogForm };
    form.saving = true;
    this.setState({ addBoreLogForm: form });

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const boreLogId = this.props.match.params.boreLogId;
    const boreId = this.props.match.params.boreId;
    const jobId = this.props.match.params.id;

    const boreLog = fieldsToObject(this.state.addBoreLogForm.fields);
    delete boreLog.totalDistance;
    boreLog.edited = true;
    delete boreLog.date;

    const imageField = this.state.addBoreLogForm.fields.find(
      (x) => x.name === "image"
    );

    //imageTimeStamp needs to be set to null in case there are no files to keep backward compatibility
    //TODO handle this in onImageRemove??
    if (imageField.value.length === 0) {
      boreLog.imageTimeStamp = null;
    }

    const updateBoreLogResult = await updateBoreLog(
      inventoryId,
      boreId,
      boreLogId,
      boreLog
    );

    form.saving = false;
    this.setState({ addBoreLogForm: form });
    this.props.history.goBack();
  };

  prepareAddBoreForm = () => {
    let form = this.state.addBoreLogForm;

    form.handleInputChange = (event, obj) => {
      const updatedForm = updateFormOnChange(
        event,
        obj,
        this.state.addBoreLogForm
      );

      if (!updatedForm) return;

      this.setState({ form: updatedForm });
    };

    form.touchField = (event) => {
      const updatedForm = touchField(event, this.state.addBoreLogForm);

      if (!updatedForm) return;

      this.setState({ form: updatedForm });
    };

    return form;
  };

  render() {
    if (this.state.loading) return <Loader />;

    const addBoreForm = this.prepareAddBoreForm();

    return (
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">Edit bore log</h3>
          <div className="mb-20">
            <BasicForm {...addBoreForm} />
          </div>
        </div>
        <ConfirmModal
          show={this.state.showConfirmModal}
          title="Delete file"
          body={"Are you sure you want to delete this file?"}
          yesButton={{
            title: "Yes",
            onClick: () => {
              if (this.state.indexOfFileToRemove != null)
                this.onImageRemove(this.state.indexOfFileToRemove);
              this.setState({
                showConfirmModal: false,
                indexOfFileToRemove: null,
              });
            },
          }}
          noButton={{
            title: "Cancel",
            onClick: () => {
              this.setState({ showConfirmModal: false });
            },
          }}
        />
      </div>
    );
  }
}

export default BorLogsEdit;