import { Component } from "react";
import CustomGoogleMap from "./CustomGoogleMap";
import EQMarker from "./EQMarker";

class MarkersInfoMap extends Component {
  state = {
    zoomLevel: this.props.zoomLevel ?? 14,
  };

  render() {
    const { places } = this.props;
    const { selectedPlace } = this.props;

    return (
      <>
        {places && (
          <CustomGoogleMap
            defaultZoom={this.state.zoomLevel}
            zoom={this.state.zoomLevel}
            center={{
              lat: selectedPlace ? selectedPlace.lat : this.props.lat,
              lng: selectedPlace ? selectedPlace.long : this.props.lng,
            }}
            onGoogleApiLoaded={({ map, maps }) => {}}
            onDragEnd={(element) => {
              //this.props?.onDrag(element.center.lat(), element.center.lng())
            }}
            bootstrapURLKeys={{
              key: "AIzaSyCTL64lm5MvytuyaZtz5fGEoFK36v95_Yg",
            }}
          >
            {places.map((place) => (
              <EQMarker
                key={place.id}
                lat={place.lat}
                lng={place.long}
                iconPath={place.icon}
                pinColor={place.pinColor}
                infoText={place.address ?? place.soilConditions}
                onClick={() => {
                  this.props.onChildClickCallback(place);
                }}
                show={place.show}
                onInfoClicked={place?.onClick}
              />
            ))}
          </CustomGoogleMap>
        )}
      </>
    );
  }
}

export default MarkersInfoMap;
