export const validateField = (field, compareField) => {

    if (!field.validators || field.validators.length === 0) {
        field.isValid = true;
        field.validationMessage = '';
        return;
    }

    let failedValidator = null;
    let validatorIndex = 0;

    while (!failedValidator && validatorIndex < field.validators.length) {
        const validator = field.validators[validatorIndex];

        let tmpValid = true;
        switch (validator.type) {
            case 'required':
                tmpValid = !isEmpty(field.value);
                break;
            case 'minCharacters':
                tmpValid = !field.value || minCharacters(field.value, validator.value);
                break;
            case 'numeric':
                tmpValid = !field.value || isNumeric(field.value);
                break;
            case 'min':
                tmpValid = !field.value || min(field.value, validator.value);
                break;
            case 'max':
                tmpValid = !field.value || max(field.value, validator.value);
                break;
            case 'email':
                tmpValid = !field.value || isEmail(field.value);
                break;
            case 'phoneNumber':
                tmpValid = !field.value || isPhoneNumber(field.value);
                break;
            case 'lowercaseIncluded':
                tmpValid = !field.value || isLowercaseIncluded(field.value);
                break;
            case 'uppercaseIncluded':
                tmpValid = !field.value || isUpperaseIncluded(field.value);
                break;
            case 'digitIncluded':
                tmpValid = !field.value || isDigitIncluded(field.value);
                break;
            case 'equal':
                tmpValid = !field.value || isEqual(field.value, compareField.value);
                break;
            default:
                break;
        }

        if (!tmpValid) {
            failedValidator = validator;
        }
        validatorIndex++;
    }

    if (failedValidator) {
        field.isValid = false;
        field.validationMessage = failedValidator.message;
    } else {
        field.isValid = true;
        field.validationMessage = '';
    }
}

export const isLowercaseIncluded = (value) => {
    return !(value.search(/[a-z]/) < 0);
}

export const isUpperaseIncluded = (value) => {
    return !(value.search(/[A-Z]/) < 0);
}

export const isDigitIncluded = (value) => {
    return !(value.search(/[0-9]/) < 0);
}

export const isEqual = (value, compareValue) => {
    return value === compareValue;
}

export const isEmpty = (value) => {
    return !value;
}

export const isEmail = (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}

// export const isPhoneNumber = (value) => {
//     const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
//     return re.test(String(value).toLowerCase());
// }

export const isPhoneNumber = (value) => {
    let currentValue = value.replace(/[^\d]/g, '');   
    return currentValue.length > 9;
}

export const isLowercasePresent = (value) => {
    return !(value.search(/[a-z]/) < 0);
}

export const isUppercasePresent = (value) => {
    return !(value.search(/[A-Z]/) < 0);
}

export const isNumberPresent = (value) => {
    return !(value.search(/[0-9]/) < 0);
}

export const minCharacters = (value, minValue) => {
    return value.length >= minValue;
}

export const maxCharacters = (value, maxValue) => {
    return value.length <= maxValue;
}

export const isNumeric = (value) => {
    if (typeof value != "string")
        return false;

    return !isNaN(value) && !isNaN(parseFloat(value));
}

export const min = (value, minValue) => {
    return value >= minValue;
}

export const max = (value, maxValue) => {
    return value <= maxValue;
}


