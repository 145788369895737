import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Email, Phone, Shield } from '../Icons/Svg';

const DefaultListItem=(props)=>{

return(
   
<React.Fragment>
  <div className="d-flex flex-column-fluid">
  {/*begin::Container*/}
  <div className="container">
    {/*begin::Card*/}
    <div className="card card-custom gutter-b">
      <div className="card-body">
        {/*begin::Top*/}
        <div className="d-flex">
          {/*begin::Pic*/}
          <div className="flex-shrink-0 mr-7">
            {props.item.avatar ?  <div className="symbol symbol-50 symbol-lg-120"><img alt="Pic" src={props.item.avatar} /></div> : <div className="symbol symbol-50 symbol-lg-120 symbol-light-danger">
            <span className="font-size-h3 symbol-label font-weight-boldest">{props.item.firstName.charAt(0)}{props.item.lastName.charAt(0)}</span>
            </div> }
          </div>
          {/*end::Pic*/}
          {/*begin: Info*/}
          <div className="flex-grow-1">
            {/*begin::Title*/}
            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
              {/*begin::User*/}
              <div className="mr-3">
                {/*begin::Name*/}
                <a href="#" className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{props.item.firstName} {props.item.lastName}
                  <i className="flaticon2-correct text-success icon-md ml-2" /></a>
                {/*end::Name*/}
                {/*begin::Contacts*/}
                <div className="d-flex flex-wrap my-2">
                  <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/Communication/Mail-notification.svg*/}
                      <Email/>
                      {/*end::Svg Icon*/}
                    </span>{props.item.email}</a>
                  <a href="#" className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/General/Lock.svg*/}
                      <Phone/>
                      {/*end::Svg Icon*/}
                    </span>{props.item.phoneNumber}</a>
                  <a style={{ textTransform: 'capitalize' }} href="#" className="text-muted text-hover-primary font-weight-bold">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      {/*begin::Svg Icon | path:assets/media/svg/icons/Map/Marker2.svg*/}
                      <Shield/>
                      {/*end::Svg Icon*/}
                    </span>{props.item.permissions}</a>
                </div>
                {/*end::Contacts*/}
              </div>
              {/*begin::User*/}
              {/*begin::Actions*/}
              <div className="my-lg-0 my-1">
                {props.currentUser.userProfile.permissions === "owner" ? <Link to={`${props.match.url}/${props.item.id}/edit`} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2">Edit</Link> : null}
                {/* <a href="#" className="btn btn-sm btn-primary font-weight-bolder text-uppercase">Edit</a> */}
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Title*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Top*/}
        {/*begin::Separator*/}
        <div className="separator separator-solid my-7" />
        {/*end::Separator*/}
      </div>
    </div>
  </div>
  {/*end::Container*/}
</div>
</React.Fragment>
   )
}

export default DefaultListItem;
