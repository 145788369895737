import React from 'react';
import EQInput from './Inputs/EQInput';
import { Link } from 'react-router-dom';
import Geosuggest from 'react-geosuggest';
import GoogleMap from '../GoogleMap/GoogleMap';
import EQMap from '../GoogleMap/EQMap';

const BasicForm = (props) => {
    const addValidationClass = (field, cl) => {
        if (field.isValid && field.isTouched) return cl + ' is-valid';
        if (!field.isValid && field.isTouched) return cl + ' is-invalid';

        return cl;
    }

    const generateInputId = (field) => {
        return props.name + "-input-" + field.name;
    }

    return (
        <form id={props.name + "-form"} noValidate="novalidate">
                {props.fields.map((item, index) => {
                    if(item.type == 'eqmap'){
                        return <div key={index} className="from-group">
                            <EQMap {...item}/>
                        </div>
                    }else if(item.type == 'location' || item.name == 'location'){
                        const locationField =
                        <div key={index} className="form-group">
                           <label>{item.label} <span className="text-danger">{item.required ? "*" : null}</span></label>
                            <span className="form-text text-muted">{item.additionalMessage}</span>
                            {/* <span>
                                <label>{item.value.lat} , {item.value.long}</label>
                            </span> */}
                            <span className="text-dark text-muted">Latitude: {parseFloat(item.value.lat).toFixed(3)}</span><br />
                            <span className="text-dark text-muted">Longitude: {parseFloat(item.value.long).toFixed(3)}</span><br /><br />
                            <Geosuggest 
                                autoActivateFirstSuggest={true}
                                inputClassName="form-control"
                                suggestsClassName="sug-container"
                                suggestItemClassName="dropdown-item"
                                suggestItemActiveClassName="sug-item-active"
                                onSuggestSelect={(e) => item.onSuggest(e)}
                                autoComplete="off"
                            />
                            <div className="mt-5" style={{ width: '100%', height: '480px' }}>
                                <GoogleMap lat={item.value.lat} lng={item.value.long} onClick={(e) => item.setLoc(e)} />
                            </div>
                            
                        </div>
                        return locationField;
                    }else {
                        const field =
                        <div key={index} className="form-group">
                            {item.label && <label>{item.label} <span className="text-danger">{item.required ? "*" : null}</span></label>}
                            <EQInput 
                                field={item} 
                                id={generateInputId(item)}
                                touchField={props.touchField}
                                index={index}
                                className={addValidationClass(item, "form-control")}
                                handleChange={props.handleInputChange}
                            />
                            {item.additionalMessage ?
                                <span className="form-text text-muted">{item.additionalMessage}</span> : null}
                                <div className="invalid-feedback">{item.validationMessage}</div>
                        </div>
                    return field;
                    }
                })}
                {props.otherFields && 
                <>
                <h4>Contact</h4>
                {props.otherFields.map((item, index) => {
                    const field =
                        <div key={"smpl-frm-fld"+index} className="form-group">
                            <label>{item.label} <span className="text-danger">{item.required ? "*" : null}</span></label>
                            <EQInput 
                                field={item} 
                                id={generateInputId(item)}
                                touchField={props.touchField}
                                className={addValidationClass(item, "form-control")}
                                handleChange={props.handleInputChange}
                            />
                            {item.additionalMessage ?
                                <span className="form-text text-muted">{item.additionalMessage}</span> : null}
                            <div className="invalid-feedback">{item.validationMessage}</div>
                        </div>
                    return field;})}
                </>
                }
                {props.exampleMessage && 
                <span className="form-text text-muted">{props.exampleMessage}</span>
                }

                <div className="justify-content-between border-top mt-5 pt-10">
                    {props.buttons.map((button,index) => {
                        if(button.link) {
                            return (
                                <Link key={"bsc-form-btns" + index}
                                    disabled={button.disabled()}
                                    className={button.className}
                                    to={button.link}
                                >
                                    {button.title}
                                </Link>
                            );
                        } else {
                            return (
                                <button
                                    key={"bsc-form-btn"+index}
                                    disabled={ (button.submit && !props.isValid) || props.saving}
                                    className={button.submit && props.saving ? button.className + " spinner spinner-white spinner-right" : button.className}
                                    onClick={(e) => { e.preventDefault(); button.onClick(); }}
                                >
                                    {button.title}
                                </button>
                            );
                        }
                    })}
                </div>
        </form>
    );
}

export default BasicForm;