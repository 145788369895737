import React from "react";
import { ClientEntity } from "../../../../shared/data/ClientEntity";

interface DeleteClientConfirmationProps {
  client: ClientEntity;
  onRemoveClient: (clientId?: string | null) => void;
}

const DeleteClientConfirmation = ({
  client,
  onRemoveClient = () => {},
}: DeleteClientConfirmationProps) => {
  return (
    <React.Fragment>
      <div>
          <div className="d-flex flex-row  align-items-center flex-row-auto">
            <h3>{`Are you sure you want to remove ${
              client.companyName ?? "No name"
            } client from this job`}</h3>
          </div>
        <div className="mt-8">
          <button
            className="btn btn-primary text-uppercase float-right"
            onClick={() => {
              onRemoveClient(client.id)
            }}
          >
            Yes
          </button>
          <button
            className="btn btn-danger text-uppercase float-right mr-5"
            onClick={() => {
              onRemoveClient(null)
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeleteClientConfirmation;
