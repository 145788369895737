import React, { Component } from "react";
import { db } from "../../firebase";

import { AuthContext } from "../../shared/Auth";
import Loader from "../../components/Layout/Loader/Loader";
import ConfirmModal from "../../components/Modals/ConfirmModal";

import {
  getReservationsForEquipment,
  getJobs,
  getSingleEquipment,
} from "../../shared/Api";

import { Link } from "react-router-dom";
import * as moment from "moment";
import $ from "jquery";
import { Edit, ExportIcon, Trash } from "../../components/Icons/Svg";
import { getMessages } from "../../components/Messages/GetMessages";
import MultipleItems from "../../components/Carousels/MultipleItems";
import { downloadPDFTable, fromRGBValue, printPDFTable } from "../../shared/Util";
import Viewer from "react-viewer";
import { CSVLink } from "react-csv";

class EquipmentDetails extends Component {
  static contextType = AuthContext;
  
  getScheduledEquipmentExportTitles  =() => {
    return [
    { label: "Repair type", key: "repairType" },
    { label: "Repair details", key: "repairDetails" },
    { label: "Start date", key: "startDate" },
    { label: "End date", key: "endDate" },
    { label: "Hour Meter Reading", key: "hourMeter" }
  ]};

  user = this.context.currentUser.userProfile;

  state = {
    equipment: null,
    deleteEquipmentModalActive: false,
    schedules: [],
    view: "month",
    calendar: null,
    selectedImage: 0,
    imageViewerActive: false,
  };

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.context.updateBackButtonPoint(this.props.location.pathname);

    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const equipment = await getSingleEquipment(
      inventoryId,
      id,
      companyId,
      true,
      true
    );
    this.loadSchedules();

    let viewerImages = this.getViewerImages(equipment);

    equipment.data.viewerImages = viewerImages;

    this.setState({ equipment: equipment.data });
  };

  getViewerImages = (obj) => {
    let images = [];
    if (obj.data.images) {
      images = obj.data.images.map((x) => {
        let obj = {
          src: x,
        };
        return obj;
      });
    }
    return images;
  };

  deleteEquipment() {
    const id = this.state.equipment.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection("inventories")
      .doc(inventoryId)
      .collection("equipment")
      .doc(id)
      .delete()
      .then(() => {
        this.props.history.push("/equipment");
      })
      .catch((error) => {});

    this.setState({ deleteEquipmentModalActive: false });
  }

  getClass = (type) => {
    switch (type) {
      case 0:
        return "warning";
      case 1:
        return "primary";
      default:
        return "primary";
    }
  };

  loadSchedules = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.props.match.params.id;

    const reservations = await getReservationsForEquipment(inventoryId, id);
    const jobs = await getJobs(inventoryId);
    let schedules = reservations.data;

    schedules.forEach((schedule) => {
      schedule.job = jobs.data.find((x) => x.id === schedule.jobID);
      if (schedule.type === 0) {
        schedule.title = "In Repair";
        schedule.description = "Service";
      } else if (schedule.job) {
        schedule.title = schedule.job.jobID;
        schedule.description = schedule.job.description;
      }
    });

    schedules = schedules.filter((x) => x.job || x.type === 0);

    this.setState({
      schedules: schedules.sort((a, b) => (a.startDate > b.startDate ? -1 : 1)),
    });
    this.drawCalendar(schedules);
  };

  drawCalendar = (schedules) => {
    if (schedules.length <= 0) return;

    const self = this;

    var todayDate = moment().startOf("day");
    var YM = todayDate.format("YYYY-MM");
    var YESTERDAY = todayDate.clone().subtract(1, "day").format("YYYY-MM-DD");
    var TODAY = todayDate.format("YYYY-MM-DD");
    var TOMORROW = todayDate.clone().add(1, "day").format("YYYY-MM-DD");

    var calendarEl = document.getElementById("kt_calendar");
    var calendar = new window.FullCalendar.Calendar(calendarEl, {
      plugins: ["bootstrap", "interaction", "dayGrid", "basicWeek", "list"],
      themeSystem: "bootstrap",

      //isRTL: KTUtil.isRTL(),

      header: {
        left: "prev,next today",
        center: "title",
      },

      height: 800,
      contentHeight: 780,
      aspectRatio: 3, // see: https://fullcalendar.io/docs/aspectRatio

      nowIndicator: true,
      now: TODAY,

      views: {
        dayGridMonth: { buttonText: "month" },
        dayGridWeek: { buttonText: "week" },
      },

      defaultView: "dayGridMonth",
      defaultDate: TODAY,

      editable: true,
      eventLimit: true, // allow "more" link when too many events
      navLinks: true,
      eventColor: "#378006",
      events: schedules.map((schedule) => {
        return {
          id: schedule.id,
          title: schedule.title,
          description: schedule.description,
          equipmentId: schedule.equipmentID,
          jobId: schedule.jobID,
          start: schedule.startDate.toDate(),
          end: schedule.endDate.toDate(),
          type: schedule.type,
          className: "fc-event-" + this.getClass(schedule.type),
        };
      }),

      eventRender: function (info) {
        var element = $(info.el);

        if (info.event.extendedProps && info.event.extendedProps.description) {
          if (element.hasClass("fc-day-grid-event")) {
            element.data("content", info.event.extendedProps.description);
            element.data("placement", "top");
            //window.KTApp.initPopover(element);
          } else if (element.hasClass("fc-time-grid-event")) {
            element
              .find(".fc-title")
              .append(
                '<div class="fc-description">' +
                  info.event.extendedProps.description +
                  "</div>"
              );
          } else if (element.find(".fc-list-item-title").length !== 0) {
            element
              .find(".fc-list-item-title")
              .append(
                '<div class="fc-description">' +
                  info.event.extendedProps.description +
                  "</div>"
              );
          }
        }

        if (info.event.extendedProps.type === 1) {
          element.css("background-color", "#C9F7F5");
        } else if (info.event.extendedProps.type === 0) {
          element.css("background-color", "#FFA800");
        }
      },
      eventClick: function (info) {
        if (info.event.extendedProps.type === 0) {
          self.props.history.push(
            "/equipment/" +
              info.event.extendedProps.equipmentId +
              "/schedule/" +
              info.event.id
          );
        } else {
          self.props.history.push(
            "/jobs/" + info.event.extendedProps.jobId + "/details"
          );
        }
      },
    });

    this.setState({ calendar: calendar });

    try {
      calendar.render();
    } catch (err) {}
  };

  goTo = (view) => {
    switch (view) {
      case "list":
        this.setState({ view: "list" });
        break;
      case "month":
        this.setState({ view: "month" });
        this.state.calendar.changeView("dayGridMonth");
        break;
      case "week":
        this.setState({ view: "week" });
        this.state.calendar.changeView("dayGridWeek");
        break;
    }
  };

  setSelectedImage = (index) => {
    this.setState({ selectedImage: index });
  };

  getCSVScheduledServices = (data) => {
    return data.filter(schedule=>schedule.type===0).map((result) => {
      // const repairType = result.type == 0 ? "Repair" : "Routine Service";
      return {
        ...result,
        startDate: moment(result.startDate.toDate()).format("MMM DD, YYYY"),
        endDate: moment(result.endDate.toDate()).format("MMM DD, YYYY"),
      };
    });
  };

  render() {
    if (this.state.equipment) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">
                  Equipment details
                  <span className="d-block text-muted pt-2 font-size-sm">
                    {this.state.equipment.make +
                      " - " +
                      this.state.equipment.model}
                  </span>
                </h3>
              </div>
              <div className="card-toolbar">
                {this.user.permissions !== "crewmember" && (
                  <>
                    <a
                      onClick={() => {
                        this.props.history.push(
                          "/equipment/" + this.state.equipment.id + "/edit"
                        );
                      }}
                      className="btn btn-success btn-sm font-weight-bold mr-4"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Edit />
                      </span>
                      Edit
                    </a>
                    <a
                      onClick={() => {
                        this.setState({ deleteEquipmentModalActive: true });
                      }}
                      className="btn btn-danger btn-sm font-weight-bold mr-4"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Trash />
                      </span>
                      Delete
                    </a>
                    <a
                      role="button"
                      onClick={() => {
                        this.props.history.push(
                          "/equipment/new/" + this.state.equipment.id
                        );
                      }}
                      className="btn btn-primary btn-sm font-weight-bold"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <ExportIcon />
                      </span>
                      Duplicate
                    </a>
                  </>
                )}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-4">
              {this.state.equipment.image && (
                <>
                  <div className="form-group row my-2">
                    <div className="col-8">
                      <div
                        className="bgi-no-repeat bgi-size-cover rounded border min-h-265px image-input-wrapper"
                        onClick={() => {
                          this.setState({ imageViewerActive: true });
                        }}
                        style={{
                          backgroundImage:
                            "url(" +
                            this.state.equipment.images[
                              this.state.selectedImage
                            ] +
                            ")",
                          backgroundSize: "contain, cover",
                          backgroundPosition: "center",
                        }}
                      />
                      <div className="mt-5">
                        <MultipleItems
                          slidesToShow={9}
                          laptop={7}
                          tablet={5}
                          mobile={2}
                          images={this.state.equipment.images}
                          selectedImageIndex={this.state.selectedImage}
                          setSelectedImage={this.setSelectedImage}
                        />
                      </div>
                    </div>
                  </div>
                  <Viewer
                    images={this.state.equipment.viewerImages}
                    visible={this.state.imageViewerActive}
                    onClose={() => {
                      this.setState({ imageViewerActive: false });
                    }}
                    activeIndex={this.state.selectedImage}
                    onChange={(image, index) => {
                      this.setSelectedImage(index);
                    }}
                  />
                </>
              )}

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Equipment Id:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">
                    {this.state.equipment.equipmentID}
                  </span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Category:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">
                    {this.state.equipment.subCategory ? (
                      <>
                        {this.state.equipment.category} &#183;{" "}
                        {this.state.equipment.subCategory}
                      </>
                    ) : (
                      <>{this.state.equipment.category}</>
                    )}
                  </span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Make:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">
                    {this.state.equipment.make}
                  </span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Model:</label>
                <div className="col-8">
                  <span className="form-control-plaintext">
                    <span className="font-weight-bolder">
                      {this.state.equipment.model}
                    </span>
                  </span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Year:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">
                    {this.state.equipment.year}
                  </span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Serial Number:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">
                    {this.state.equipment.serialNumber}
                  </span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Description:</label>
                <div className="col-8">
                  <span
                    style={{ whiteSpace: "pre-line" }}
                    className="form-control-plaintext"
                  >
                    {this.state.equipment.description}
                  </span>
                  {this.state.equipment.tag && (
                    <>
                      &#10;&#10;
                      {this.state.equipment.tag}
                    </>
                  )}
                </div>
              </div>
              {this.state.equipment.crew ? (
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Crew:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">
                      {this.state.equipment.crew.crewName} &nbsp;&nbsp;
                      <span
                        className="dot crew-dot"
                        style={{
                          backgroundColor:
                            "rgba(" +
                            fromRGBValue(this.state.equipment.crew.red) +
                            ", " +
                            fromRGBValue(this.state.equipment.crew.green) +
                            ", " +
                            fromRGBValue(this.state.equipment.crew.blue) +
                            ", " +
                            this.state.equipment.crew.alpha +
                            ")",
                        }}
                      ></span>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="card-footer">
              {this.state.equipment.subCategoryTypes &&
                this.state.equipment.subCategoryTypes.map((type) => {
                  if (!type.value) return null;

                  return (
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        {type.name}:
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {type.value} {type.unitI}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="card card-custom mt-10">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Equipment schedule</h3>
              </div>
              <div className="card-toolbar">
                {this.state.schedules.length > 0 && (
                  <>
                    <a
                      onClick={() => this.goTo("month")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon2-calendar-2"></i>
                    </a>
                    <a
                      onClick={() => this.goTo("week")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon2-calendar-1"></i>
                    </a>
                    <a
                      onClick={() => this.goTo("list")}
                      className="btn btn-icon btn-light-secondary mr-2"
                    >
                      <i className="flaticon-list-2"></i>
                    </a>
                  </>
                )}

                {this.user.permissions !== "crewmember" && (
                  <div>
                    <Link
                      to={"/equipment/" + this.state.equipment.id + "/schedule"}
                      className="btn btn-light-primary font-weight-bold"
                    >
                      <i className="ki ki-plus icon-md mr-2" />
                      Schedule Service
                    </Link>
                    <div className="dropdown dropdown-inline ml-2">
                      <button
                        type="button"
                        className="btn btn-light-primary font-weight-bolder dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="svg-icon svg-icon-md">
                          <ExportIcon />
                        </span>
                        Export
                      </button>
                      <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <ul className="navi flex-column navi-hover py-2">
                          <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">
                            Choose an option:
                          </li>
                          <li className="navi-item">
                            <a
                              role="button"
                              onClick={() => {
                                 printPDFTable("scheduled-service-list.pdf",
                                 this.getScheduledEquipmentExportTitles(),
                                this.getCSVScheduledServices(
                                  this.state.schedules
                                ))
                              }}
                              className="navi-link"
                            >
                              <span className="navi-icon">
                                <i className="la la-print" />
                              </span>
                              <span className="navi-text">Print</span>
                            </a>
                          </li>
                          {this.state.schedules && (
                            <li className="navi-item">
                              <CSVLink
                                filename="scheduled-service-list.csv"
                                data={this.getCSVScheduledServices(
                                  this.state.schedules
                                )}
                                headers={this.getScheduledEquipmentExportTitles()}
                                className="navi-link"
                              >
                                <span className="navi-icon">
                                  <i className="la la-file-excel-o" />
                                </span>
                                <span className="navi-text">Excel</span>
                              </CSVLink>
                            </li>
                          )}
                          <li className="navi-item">
                            <a
                              onClick={() => {
                                downloadPDFTable("scheduled-service-list.pdf",
                                "scheduled-service-list.pdf",
                                this.getScheduledEquipmentExportTitles(),
                                this.getCSVScheduledServices(
                                  this.state.schedules
                                ))
                              }}
                              role="button"
                              className="navi-link"
                            >
                              <span className="navi-icon">
                                <i className="la la-file-pdf-o" />
                              </span>
                              <span className="navi-text">PDF</span>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="card-body">
              {this.state.schedules.length > 0 ? (
                <>
                  <div className="card-body">
                    <div
                      style={{
                        display: this.state.view !== "list" ? "none" : "block",
                      }}
                    >
                      <div className="timeline timeline-5">
                        <div className="timeline-items">
                          {this.state.schedules.map((schedule, index) => {
                            const toUrl = schedule.type == 0 ? `/equipment/${schedule.equipmentID}/schedule/${schedule.id}` : `/jobs/${schedule.job.id}/details`
                            return (
                            <Link
                              to={toUrl}
                              key={"eq-schdl-itm" + index}
                              className="timeline-item"
                            >
                              <div className="timeline-media bg-light-primary">
                                <span
                                  className={
                                    "svg-icon svg-icon-" +
                                    this.getClass(schedule.type) +
                                    " svg-icon-md"
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="24px"
                                    viewBox="0 0 24 24"
                                    version="1.1"
                                  >
                                    <g
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z"
                                        fill="#000000"
                                        fillRule="nonzero"
                                        opacity="0.3"
                                      />
                                      <path
                                        d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z"
                                        fill="#000000"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                              <div className="timeline-desc timeline-desc-light-primary">
                                <span className="font-weight-bolder text-secondary">
                                  {moment(schedule.startDate.toDate()).format(
                                    "MMM DD, YYYY"
                                  )}
                                </span>
                                <br />
                                <span className="font-weight-bolder text-secondary">
                                  {schedule.title}{" "}
                                  <span className="font-weight-normal text-dark-50 pb-2">
                                    (
                                    {moment(schedule.startDate.toDate()).format(
                                      "MMM DD, YYYY"
                                    ) +
                                      " - " +
                                      moment(schedule.endDate.toDate()).format(
                                        "MMM DD, YYYY"
                                      )}
                                    )
                                  </span>
                                </span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                  {schedule.description}
                                </p>
                              </div>
                            </Link>
                          )})}
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: this.state.view === "list" ? "none" : "block",
                      }}
                      id="kt_calendar"
                    />
                  </div>
                  <div
                    style={{
                      display: this.state.view === "list" ? "none" : "block",
                    }}
                    id="kt_calendar"
                  />
                </>
              ) : (
                <span className="font-italic">(No items)</span>
              )}
            </div>
          </div>
          <ConfirmModal
            show={this.state.deleteEquipmentModalActive}
            title="Delete equipment"
            body={getMessages("delete_equipment")}
            yesButton={{
              title: "Yes",
              onClick: () => {
                this.deleteEquipment();
              },
            }}
            noButton={{
              title: "Cancel",
              onClick: () => {
                this.setState({ deleteEquipmentModalActive: false });
              },
            }}
          />
        </React.Fragment>
      );
    } else {
      return <Loader />;
    }
  }
}

export default EquipmentDetails;
