import Dropzone from '../../Dropzone/Dropzone';

const DropzoneInput = (props) => {
    if (!props.field.value) {
        return (
            <Dropzone {...props} />
        );
    } else {
        return (
            <div>
                <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                    <div className="image-input-wrapper" style={{ backgroundImage: 'url('+ props.field.localSrc +')', backgroundSize: 'contain, cover', backgroundPosition: 'center', width: '220px', height: '220px' }} />
                    <span onClick={props.field.onRemove} className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove image">
                        <i className="ki ki-bold-close icon-xs text-muted" />
                    </span>
                </div>
            </div>
        );
    }
}

export default DropzoneInput;