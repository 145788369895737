import { useContext } from 'react';
import { ArrowLeft } from '../../Icons/Svg';
import { useLocation, useHistory } from 'react-router-dom'
import { AuthContext } from "../../../shared/Auth";

function checkReg(pathname, regex) {
  const matcher = new RegExp(regex.replace(/:[^\s/]+/g, '([\\w-]+)'));
  return pathname.match(matcher);
}

function getBackLink(pathname, authContext) {
  let path = null;
  let display = null;

  const splitArray = pathname.split("/");

  if(checkReg(pathname, "/equipment/:equipmentId/schedule/:scheduleId/edit")) {
    const equipmentId = splitArray[2];
    const scheduleId = splitArray[4];
    path = "/equipment/" + equipmentId + "/schedule/" + scheduleId;
    display = "Schedule";
  } else if(checkReg(pathname, "/equipment/:equipmentId/schedule/:scheduleId")) {
    const equipmentId = splitArray[2];
    path = "/equipment/" + equipmentId;
    display = "Equipment";
  } else if(checkReg(pathname, "/equipment/:equipmentId/schedule")) {
    const equipmentId = splitArray[2];
    path = "/equipment/" + equipmentId;
    display = "Equipment";
  } else if(checkReg(pathname, "/equipment/new")) {
    path = "/equipment";
    display = "Equipment";
  } else if(checkReg(pathname, "/equipment/:id")) {
    path = "/equipment";
    display = "Equipment";
  } if(checkReg(pathname, "/equipment/:id/edit")) {
    const jobId = authContext.jobIdBack;

    const equipmentId = splitArray[2];
    path = jobId ? "/jobs/" + jobId + "/equipment/" + equipmentId : "/equipment/" + equipmentId;
    display = "Equipment";
  } if(checkReg(pathname, "/crewmembers/new")) {
    path = "/crewmembers";
    display = "Crewmembers";
  } else if(checkReg(pathname, "/crewmembers/:id")) {
    path = "/crewmembers";
    display = "Crewmembers";
  } if(checkReg(pathname, "/crewmembers/:id/edit")) {
    const jobId = authContext.jobIdBack;

    const crewmemberId = splitArray[2];
    path = jobId ? "/jobs/" + jobId + "/crewmembers/" + crewmemberId : "/crewmembers/" + crewmemberId;
    display = "Crewmember";
  } else if(checkReg(pathname, "/jobs/:id/crews/:crewId/edit")) {
    const jobId = splitArray[2];
    const crewId = splitArray[4];
    path = "/jobs/" + jobId + "/details/crews/" + crewId;
    display = "Crew Details";
  } else if(checkReg(pathname, "/jobs/:id/crews/:crewId")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/crews";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/crewmembers/:crewmemberId")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/crewmembers";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/equipment/:equipmentId")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/equipment";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/checkout/:date")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/checklist";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/checkin/:date")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/checklist";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/checkout/:date")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId/borelogs/:date/:boreLogId/details")) {
    const jobId = splitArray[2];
    const boreId = splitArray[4];
    const date = splitArray[6];
    path = "/jobs/" + jobId + "/bores/" + boreId + '/borelogs/' + date;
    display = "Bore Log Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId/borelogs/:date/:boreLogId/edit")) {
    const jobId = splitArray[2];
    const boreId = splitArray[4];
    const date = splitArray[6];
    path = "/jobs/" + jobId + "/bores/" + boreId + '/borelogs/' + date;
    display = "Bore Log Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId/borelogs/:date")) {
    const jobId = splitArray[2];
    const boreId = splitArray[4];
    const date = splitArray[6];
    path = "/jobs/" + jobId + "/bores/" + boreId;
    display = "Bore Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId/borelogs/new")) {
    const jobId = splitArray[2];
    const boreId = splitArray[4];
    path = "/jobs/" + jobId + "/bores/" + boreId;
    display = "Bore Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/bores";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/new")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/bores";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/:id/bores/:boreId/edit")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/bores";
    display = "Job Details";
  } else if(checkReg(pathname, "/jobs/new")) {
    path = "/jobs";
    display = "Jobs";
  } else if(checkReg(pathname, "/jobs/:id")) {
    path = authContext.backButtonPoint ? authContext.backButtonPoint : "/jobs";
    display = "Jobs";
    
    if(authContext.backButtonPoint && authContext.backButtonPoint.includes("crewmembers")) {
      display = "Crewmember";
    }
    if(authContext.backButtonPoint && authContext.backButtonPoint.includes("crews")) {
      display = "Crew Details";
    }
  } else if(checkReg(pathname, "/jobs/:id/edit")) {
    path = "/jobs";
    display = "Jobs";
  } else if(checkReg(pathname, "/crews/new")) {
    path = "/crews";
    display = "Crews";
  } else if(checkReg(pathname, "/crews/:id")) {
    path = "/crews";
    display = "Crews";
  } if(checkReg(pathname, "/crews/:id/edit")) {
    path = "/crews";
    display = "Crews";
  }else if(checkReg(pathname, "/clients/new")) {
    path = "/clients";
    display = "Clients";
  } else if(checkReg(pathname, "/clients/:id")) {
    path = "/clients";
    display = "Clients";
  } if(checkReg(pathname, "/clients/:id/edit")) {
    path = "/clients";
    display = "Clients";
  } if(checkReg(pathname, "/clients/:clientId/contacts/new")) {
    const clientId = splitArray[2];
    path = "/clients/" + clientId;
    display = "Client Details";
  } if(checkReg(pathname, "/clients/:clientId/contacts/:contactId/edit")) {
    const clientId = splitArray[2];
    path = "/clients/" + clientId;
    display = "Client Details";
  }
  if(checkReg(pathname, "/soilconditions/new")) {
    path = "/soilconditions";
    display = "Soil Conditions";
  } else if(checkReg(pathname, "/soilconditions/:id")) {
    path = "/soilconditions";
    display = "Soil Conditions";
  } else if (checkReg(pathname, "/soilconditions/:id/edit")) {
    path = "/soilconditions";
    display = "Soil Conditions";
  }

  if(checkReg(pathname, "/notifications/new")) {
    path = "/notifications";
    display = "Notifications";
  } 
   else if (checkReg(pathname, "/notifications/:id/edit")) {
    path = "/notifications";
    display = "Notifications";
  }
  else if(checkReg(pathname, "/jobs/:id/taskmanagement/task")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/taskmanagement";
    display = "Task Management";
  }
  else if(checkReg(pathname, "/jobs/:id/segments/new")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/taskmanagement";
    display = "Task Management";
  }
  else if(checkReg(pathname, "/jobs/:id/segments/:id")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/taskmanagement";
    display = "Task Management";
  }
  else if(checkReg(pathname, "/jobs/:id/tasks/:id")) {
    const jobId = splitArray[2];
    path = "/jobs/" + jobId + "/details/taskmanagement";
    display = "Task Management";
  }
  
  /* if(checkReg(pathname, "/soilconditions/:id/edit")) {
    const jobId = authContext.jobIdBack;

    const crewmemberId = splitArray[2];
    path = jobId ? "/jobs/" + jobId + "/crewmembers/" + crewmemberId : "/crewmembers/" + crewmemberId;
    display = "Crewmember";
  } */

  if(!path)
    return null;

  return backLink()
}

const backLink = (pathname, user) => {
  const isBackButtonVisible = showBackButton(pathname, user);
  return (
    // <Link to={{pathname: path, prevPath: pathname}} className="text-primary" params={{ prevPath: pathname }}>
    <div>
      {isBackButtonVisible ? (
        <a href="#">
          <div>
            <span className="svg-icon menu-icon mr-2">
              <ArrowLeft />
            </span>
            <span className="text-hover-primary">Back</span>
          </div>
        </a>
      ) : (
        <div></div>
      )}{" "}
    </div>

    // </Link>
  );
};

const showBackButton = (pathname, user) => {
  return pathname.pathname.split("/")?.filter((i) => i)?.length > 1 ?? false;
  // if (
  //   getUserTier(user) == SubscriptionTier.TIER1 &&
  //   (pathname.pathname.includes("jobs") || !pathname.key)
  // )
  //   return false;
  // else return true;
};

const Subheader = () => {
  const authContext = useContext(AuthContext)
  const user = authContext.currentUser
  const location = useLocation();
  const history = useHistory();

  return (
  <div className="subheader py-2 py-lg-6 subheader-solid" id="kt_subheader">
    <div className="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
      <div className="d-flex align-items-center flex-wrap mr-1">
        <div className="d-flex align-items-baseline flex-wrap mr-5">
          <h4 onClick={(event)=>history.goBack()}>
            {backLink(location, user)}
          </h4>
        </div>
      </div>
    </div>
  </div>
)}

export default Subheader;