import React, { Component } from 'react';

class InfoModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
      }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if(!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
            <div className="modal fade" id={"info" + "_" + this.props.id} 
                data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{this.props.title}</h5>
                    {/* <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close" />
                    </button> */}
                    </div>
                    <div className="modal-body">
                        {this.props.body}
                        {this.props.videoUrl &&
                            <div className="mt-8">
                                <video id="modal-video" width="100%" height="240" controls>
                                    <source src={this.props.videoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        }
                    </div>
                    <div className="modal-footer">
                    <button onClick={this.props.yesButton.onClick} data-dismiss="modal" type="button" className="btn btn-primary font-weight-bold">{this.props.yesButton.title}</button>
                    </div>
                </div>
                </div>
            </div>
            <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target={"#info" + "_" + this.props.id} >
            </button>
            </React.Fragment>
        );
    }
}

export default InfoModal;