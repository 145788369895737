import React, { Component } from 'react';
import RichDataTable from '../../components/Tables/RichDataTable';
import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import { showToast } from '../../shared/Util';
import { addClient, getClients } from '../../shared/Api';
import { normalizeInput } from '../../shared/FormHelper';
import { Company, ImportIcon, PlusIcon } from '../../components/Icons/Svg';
import { CSVLink } from "react-csv";
import CSVReader from 'react-csv-reader';
import { getPlanTier, getUserTier } from '../../shared/data/context/UserEntity';
import SubscriptionRestrictionComponent from '../Profile/subscription/SubscriptionRestrictionComponent';
import { SubscriptionTierUI } from '../../shared/data/subscription/SubscriptionUI';

class Client extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;

  state = {
    fields: [
      {
        display: 'Company Name',
        name: 'companyName'
      },
      {
        display: 'Phone Number',
        name: 'phoneNumber'
      }
    ],
    data: null,
    importing: false
  };

  componentDidMount() {    
    this.loadClients();
  }

  importClients = async (data, fileInfo) => {
    this.setState({ importing: true });
    const clients = [];

    for (let index = 0; index < data.length; index++) {
      let element = data[index];

      const phone = element.phone_number ?? element.phone
      const address = [
        element?.address ?? "",
        element?.street_address ?? "",
        element?.city ?? "",
        element?.state ?? "",
        element?.zip?.toString() ?? ""
      ]
        .filter(part => {
          return part?.trim() !== ""

        }) 
        .join(", ");

      const client = {
        companyName: element.company_name,
        email: element.email,
        phoneNumber: phone,
        address: address?.replace("\n",  ", ")?.replace(/\s+/g, ' ') || "",
      }
      
      const clientAlreadyAdded = this.state.data?.some((clientState)=>{return clientState.companyName==client.companyName}) ?? false
      console.log(clientAlreadyAdded)

      if (client.companyName && !clientAlreadyAdded) {
        clients.push(client);
      }
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    await Promise.all(clients.map(async (client) => {
      const result = await addClient(inventoryId, client)
    }));

    await this.loadClients();
    this.setState({ importing: false });


    if (clients.length === 1) {
      showToast("success", "Client imported");
    } else if (clients.length > 1) {
      showToast("success", "Clients imported");
    } else {
      return
    }
  }

  loadClients = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const clients = await getClients(inventoryId);
    clients.data.forEach(element => {
      element.phoneNumber = normalizeInput(element.phoneNumber);
    });

    const data = clients.data?.sort((a, b) => {
      return a.companyName > b.companyName ? 1 : b.companyName > a.companyName ? -1 : 0;
    })

    this.setState({ data: clients.data });
  }

  
  handleImportClick = () => {
    document.getElementById("import-button").click();
  }

  render() {
    if (getPlanTier(this.context.currentUser.subscription.plan.plan_code) == SubscriptionTierUI.TIER1) {
      return (
        <SubscriptionRestrictionComponent permission={this.user.permissions} />
      );
    } else {
    if (!this.state.data) {
      return <Loader />
    }
    if(this.state.data.length === 0) {
      return (
        <div className="d-flex align-items-center mb-10">
          <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
          <div className='mainpage-graphic'>
              <Company width={'200px'} height={'200px'} opacity={'0.3'} />
            </div>
              <span className="font-italic">You don't have any clients at the moment. You can start adding by clicking on one of the buttons below.</span>&nbsp;
              {this.user.permissions === 'crewmember' ? null 
              : 
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline mr-2">
                <button type="button" className={this.state.importing ? "btn btn-light-primary font-weight-bolder dropdown-toggle spinner spinner-white spinner-right"
                : "btn btn-light-primary font-weight-bolder dropdown-toggle"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="svg-icon svg-icon-md">
                    <ImportIcon />
                  </span>Import</button>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <ul className="navi flex-column navi-hover py-2">
                    <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                      <li className="navi-item">
                        <a role="button" onClick={this.handleImportClick} className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-import" />
                          </span>
                          <span className="navi-text">Import</span>
                        </a>
                      </li>
                      <li className="navi-item">
                        <CSVLink
                          filename="import-template-csv.csv"
                          data={[]}
                          headers={[
                            { label: "Company Name", key: "companyName" },
                            { label: "Phone Number", key: "phoneNumber" },
                            { label: "Address", key: "address" },
                            { label: "Email", key: "email" }
                          ]}
                          className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-excel-o" />
                          </span>
                          <span className="navi-text">Template</span>
                        </CSVLink>
                      </li>
                  </ul>
                </div>
                </div>
                &nbsp;
                <a onClick={() => { this.props.history.push('/clients/new')}} className="btn btn-primary font-weight-bolder">
                  <span className="svg-icon svg-icon-md">
                    <PlusIcon />
                  </span>Add client
                </a>
              </div>}
              <CSVReader
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={(data, fileInfo) => { this.importClients(data, fileInfo); }}
                  onError={(err) => { console.log(err); }}
                  inputId="import-button"
                  /* ref={importButtonRef} */
                  parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header =>
                      header
                        .toLowerCase()
                        .replace(/\W/g, '_')
                  }}
                />
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <RichDataTable
              title="Clients"
              hideImageColumn={true}
              newRecord={this.user.permissions === 'crewmember' ? null : {
                title: 'Add client',
                onClick: () => { this.props.history.push('/clients/new') }
              }}
              export={{
                csv: {
                  filename: 'client-list.csv',
                  headers: [
                    { label: "Company Name", key: "companyName" },
                    { label: "Phone Number", key: "phoneNumber" },
                    { label: "Address", key: "address" },
                    { label: "Email", key: "email" },
                  ],
                  data: this.state.data
                },
                pdf: {
                  filename: 'client-list.pdf',
                  title: 'Client list',
                  headers: [
                    { label: "Company Name", key: "companyName" },
                    { label: "Phone Number", key: "phoneNumber" },
                    { label: "Address", key: "address" },
                    { label: "Email", key: "email" },
                  ],
                  data: this.state.data
                },
                print: true
              }}
              import={{
                onImport: this.importClients,
                importing: this.state.importing
              }}
              fields={this.state.fields}
              data={this.state.data}
              actions={{
                delete: {
                  enabled: false,
                  onClick: (id) => {
                    
                  }
                },
                edit: {
                  enabled: this.user.permissions !== 'crewmember',
                  onClick: (id) => {
                    this.props.history.push('/clients/' + id + '/edit');
                  }
                },
                detail: {
                  enabled: true,
                  onClick: (id) => {
                    this.props.history.push('/clients/' + id);
                  }
                }
              }}
              showExportButton={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
}

export default Client;