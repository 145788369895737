import React from "react";

const TextInputWithButton = (props) => {
  return (
    <div>
      <input
        type="text"
        className="form-control"
        // autoFocus={props.index === 0}
        value={props.field.value}
        placeholder={props.field.placeholder}
        name={props.field.name}
        onChange={props.handleChange}
        onBlur={(event) => {
          if (props.touchField) props.touchField(props.field);
          if (props.onBlur) props.onBlur(event);
        }}
        id={props.id}
        data-toggle={props.field.dataToggle}
        title={props.field.title}
        data-placement={props.field.dataPlacement}
        disabled = {(props.field.disabled)? "disabled" : ""}
      />
      <button
        id={props.id + "-button"}
        onClick={(event) => {
          event.preventDefault();
          props.field.buttonClick();
        }}
      >
        {props.field.buttonText}
      </button>
    </div>
  );
};

export default TextInputWithButton;