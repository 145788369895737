import React from 'react';

const CheckboxInput = (props) => {
    return (
        <div className="d-flex align-items-left">
        <label className={props.containerClassName ? props.containerClassName + " checkbox" : "checkbox"}>
            <input
                type="checkbox"
                className={props.className}
                autoFocus={props.index === 0}
                checked={props.field.value}
                placeholder={props.field.placeholder}
                name={props.field.name}
                onChange={props.handleChange}
                onBlur={() => { if(props.touchField) props.touchField(props.field) }}
                id={props.id}
            />
            <span></span>
        </label>
        {props.field.checkboxText && 
        <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
        <div className="mb-1">
          {props.field.checkboxText}
        </div>
      </div>
}
      </div>
    );
}

export default CheckboxInput;