import React from 'react';
import { Person } from '../../Icons/Svg';

const headerMobile = () => (
    <div id="kt_header_mobile" className="header-mobile align-items-center header-mobile-fixed">
        {/*begin::Logo*/}
        <a href="index.html">
          {/* <img alt="Logo" src="assets/media/logos/logo-light.png" /> */}
          EQUAPPMENT
        </a>
        {/*end::Logo*/}
        {/*begin::Toolbar*/}
        <div className="d-flex align-items-center">
          {/*begin::Aside Mobile Toggle*/}
          <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
            <span />
          </button>
          {/*end::Aside Mobile Toggle*/}
          {/*begin::Topbar Mobile Toggle*/}
          <button className="btn btn-hover-text-primary p-0 ml-2" id="kt_header_mobile_topbar_toggle">
            <span className="svg-icon svg-icon-xl">
              {/*begin::Svg Icon | path:assets/media/svg/icons/General/User.svg*/}
              <Person/>
              {/*end::Svg Icon*/}
            </span>
          </button>
          {/*end::Topbar Mobile Toggle*/}
        </div>
        {/*end::Toolbar*/}
      </div>
);

export default headerMobile;