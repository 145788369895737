import data from "@iconify/icons-mdi/map-marker";
import React, { useState } from "react";

export class TabData {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export interface TabComponentProps {
  tabs: TabData[];
  onTabSelected: (data: TabData) => void;
}

const TabsComponent = ({
  children,
  tabs,
  onTabSelected = () => {},
}: React.PropsWithChildren<TabComponentProps>) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0].id);

  return (
    <React.Fragment>
      <div
        className="wizard wizard-4"
        id="kt_wizard"
        // data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <div className="wizard-nav" key={"tabs-component-root"}>
          <div
            className="wizard-steps"
            key={"tabs-component-wizard-steps"}
            data-total-steps={6}
          >
            {tabs?.map((tab) => {
              return (
                <div
                  key={"tab-component" + tab.id}
                  className="wizard-step"
                  onClick={() => {
                    onTabSelected(tab);
                    setSelectedTab(() => tab.id);
                  }}
                  data-wizard-clickable="true"
                  data-wizard-type="step"
                  data-wizard-state={tab.id === selectedTab ? "current" : ""}
                >
                  <div className="wizard-wrapper">
                    <div className="wizard-label">
                      <div className="wizard-title">{tab.name}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {children}
      </div>
    </React.Fragment>
  );
};

export default TabsComponent;
