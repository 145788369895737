import React, { Component } from "react";
import { Route, Link, Switch, BrowserRouter } from "react-router-dom";

import { AuthContext } from "../../shared/Auth";

import Profile from "./Profile";
import ProfileEdit from "./ProfileEdit";
import ChangePassword from "./ChangePassword";
import { CreditCard, Lock, Person, Company } from "../../components/Icons/Svg";
import GettingStarted from "./GettingStarted";
import SubscriptionComponent from "./subscription/SubscriptionComponent";

class Me extends Component {
  static contextType = AuthContext;

  currentUser = this.context.currentUser;

  state = {
    user: {
      email: this.currentUser.email,
      firstName: this.currentUser.userProfile.firstName,
      lastName: this.currentUser.userProfile.lastName,
      phoneNumber: this.currentUser.userProfile.phoneNumber,
      permissions: this.currentUser.userProfile.permissions,
      avatar: this.currentUser.userProfile.avatar,
      imageTimeStamp: this.currentUser.userProfile.imageTimeStamp,
      company: this.currentUser.company.name,
    },
    hostedLoginToken: this.currentUser.hostedLoginToken,
    editMode: false,
  };

  toggleEditMode = () => {
    this.setState({ editMode: !this.state.editMode });
  };

  getInvoiceLink = () => {
    return (
      process.env.REACT_APP_RECURLY_ACCOUNT_HOST +
      "/" +
      this.state.hostedLoginToken
    );
  };

  render() {
    return (
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="card card-custom">
            <div className="card-header card-header-tabs-line nav-tabs-line-3x">
              <div className="card-toolbar">
                <ul className="nav nav-tabs nav-bold nav-tabs-line nav-tabs-line-3x">
                  <li className="nav-item mr-3">
                    <Link
                      to={`${this.props.match.url}`}
                      className={
                        this.props.location.pathname === "/me" ||
                        this.props.location.pathname === "/me/edit"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          <Person />
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">Profile</span>
                    </Link>
                  </li>
                  <li className="nav-item mr-3">
                    <Link
                      to={`${this.props.match.url}/changepassword`}
                      className={
                        this.props.location.pathname === "/me/changepassword"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          <Lock />
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">
                        Change Password
                      </span>
                    </Link>
                  </li>
                  <li className="nav-item mr-3">
                    <Link
                      to={`${this.props.match.url}/gettingstarted`}
                      className={
                        this.props.location.pathname === "/me/gettingstarted"
                          ? "nav-link active"
                          : "nav-link"
                      }
                      data-toggle="tab"
                    >
                      <span className="nav-icon">
                        <span className="svg-icon">
                          <Company />
                        </span>
                      </span>
                      <span className="nav-text font-size-lg">
                        Getting Started
                      </span>
                    </Link>
                  </li>
                  {this.state.user.permissions == 'owner' && 
                    <li className="nav-item mr-3">
                      <Link
                        to={`${this.props.match.url}/subscription`}
                        className={
                          this.props.location.pathname === "/me/subscription"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        data-toggle="tab"
                      >
                        <span className="nav-icon">
                          <span className="svg-icon">
                            <Company />
                          </span>
                        </span>
                        <span className="nav-text font-size-lg">
                          Subscription and Payment Info
                        </span>
                      </Link>
                    </li>
                  }
                  {/* <li className="nav-item mr-3" style={{ cursor: 'pointer' }}>
                      <a href={this.getInvoiceLink()} target="_blank" className="nav-link">
                        <span className="nav-icon">
                          <span className="svg-icon">
                            <CreditCard/>
                          </span>
                        </span>
                        <span className="nav-text font-size-lg">Subscription and Payment Info</span>
                      </a>
                    </li> */}
                </ul>
              </div>
            </div>
            <div className="card-body">
              <form className="form" id="kt_form">
                <div className="tab-content">
                  <div
                    className="tab-pane show active px-7"
                    id="kt_user_edit_tab_1"
                    role="tabpanel"
                  >
                    <Route
                      path={`${this.props.match.path}`}
                      exact={true}
                      render={(props) => (
                        <Profile
                          onEditClick={this.toggleEditMode}
                          user={this.state.user}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/edit`}
                      render={(props) => (
                        <ProfileEdit
                          onCancelClick={this.toggleEditMode}
                          user={this.state.user}
                          {...props}
                        />
                      )}
                    />
                    <Route
                      path={`${this.props.match.path}/changepassword`}
                      exact={true}
                      render={(props) => <ChangePassword {...props} />}
                    />
                    <Route
                      path={`${this.props.match.path}/gettingstarted`}
                      exact={true}
                      render={(props) => <GettingStarted {...props} />}
                    />
                    <Route
                      path={`${this.props.match.path}/subscription`}
                      exact={true}
                      render={(props) => <SubscriptionComponent />}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Me;
