import React from "react";
import { ExportIcon } from "../Icons/Svg";
import { CSVLink } from "react-csv";
import { LabelKeyObject } from "react-csv/lib/core";

export interface ExportReportProps {
  title?: string | null;
  header?: LabelKeyObject[];
  data?: any[];
  reportTypes?: ReportType[];
}

type ReportType = "Print" | "PDF" | "Excel";

const EQExportReportComponent = ({
  title = "",
  header,
  data = [],
  reportTypes = ["Print", "Excel", "PDF"],
}: ExportReportProps) => {
  return (
    <React.Fragment>
      <div className="dropdown dropdown-inline">
        <button
          type="button"
          className="btn btn-light-primary font-weight-bolder dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="svg-icon svg-icon-md">
            <ExportIcon />
          </span>
          Export
        </button>
        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
          <ul className="navi flex-column navi-hover py-2">
            <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">
              Choose an option:
            </li>
            {reportTypes.some((type) => type == "Print") && (
              <li className="navi-item">
                <a
                  role="button"
                  onClick={() => {
                    //TODO implement creating PDF and opening print view
                    // printPDFTable(props.export.pdf.title, props.export.pdf.headers, props.export.pdf.data)
                  }}
                  className="navi-link"
                >
                  <span className="navi-icon">
                    <i className="la la-print" />
                  </span>
                  <span className="navi-text">Print</span>
                </a>
              </li>
            )}
            {reportTypes.some((type) => type == "Excel") && (
              <li className="navi-item">
                <CSVLink
                  filename={title ?? "file-name"}
                  data={data}
                  headers={header}
                  className="navi-link"
                >
                  <span className="navi-icon">
                    <i className="la la-file-excel-o" />
                  </span>
                  <span className="navi-text">Excel</span>
                </CSVLink>
              </li>
            )}
            {reportTypes.some((type) => type == "PDF") && (
              <li className="navi-item">
                <a
                  onClick={() => {
                    //TODO implement creating PDF and download it
                    //     downloadPDFTable(props.export.pdf.title, props.export.pdf.filename,
                    //   props.export.pdf.headers, props.export.pdf.data)
                  }}
                  role="button"
                  className="navi-link"
                >
                  <span className="navi-icon">
                    <i className="la la-file-pdf-o" />
                  </span>
                  <span className="navi-text">PDF</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EQExportReportComponent;
