import moment from 'moment';
import React, { Component } from 'react';
import BasicForm from '../../../../components/Form/BasicForm';
import { updateFormOnChange, touchField, fieldsToObject, objectToFields } from '../../../../shared/FormHelper';
import { getMessages } from '../../../../components/Messages/GetMessages';

class JobDetailsStep extends Component {
    state = {
        userLocation:{
            lat:0.0,
            lng:0.0
        },
        form: {
            name: 'add-job-equipment',
            isValid: false,
            submitErrorMessage: null,
            loading: false,
            fields: [
                {
                    name: 'title',
                    value: '',
                    defaultValue: '',
                    type: 'text',
                    label: 'Job Name',
                    placeholder: 'Job Name',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('job_name'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('job_name_required')
                        }
                    ]
                },
                {
                    name: 'jobID',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Job ID',
                    type: 'text',
                    label: 'Job ID',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('job_id'),
                    required: true,
                    validators: [
                        {
                            type: 'required',
                            message: getMessages('job_id_required')
                        }
                    ]
                },
                {
                    name: 'dates',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Dates',
                    type: 'daterangepicker',
                    label: 'Dates',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('job_startend'),
                    required: false,
                    startDate: {
                        name: 'startDate',
                        value: moment()
                    },
                    endDate: {
                        name: 'endDate',
                        value: moment().add('days', 7)
                    }
                },
                {
                    name: 'status',
                    value: 'published',
                    defaultValue: 'job',
                    placeholder: 'Job Type',
                    type: 'radiobuttons',
                    label: 'Job Type',
                    isValid: true,
                    validationMessage: '',
                    options: [
                        {
                            value: 'published',
                            label: 'Job'
                        },
                        {
                            value: 'draft',
                            label: 'Bid'
                        }
                    ],
                    isTouched: false,
                    additionalMessage: getMessages('job_question'),
                    required: false
                },
                {
                    name: 'description',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Description',
                    rows: 6,
                    type: 'textarea',
                    label: 'Description (optional)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('job_description'),
                    required: false
                },
                {
                    name: 'totalFootage',
                    value: '',
                    defaultValue: '',
                    placeholder: 'Total Footage',
                    type: 'text',
                    label: 'Total Footage (optional)',
                    isValid: true,
                    validationMessage: '',
                    isTouched: false,
                    additionalMessage: getMessages('job_footage'),
                    required: false
                }
            ],
            buttons: [
                {
                    title: 'Next',
                    className: 'btn btn-primary font-weight-bolder text-uppercase px-9 py-4 float-right ml-2',
                    onClick: () => this.addJob(),
                    disabled: () => !this.isFormValid()
                },
                {
                    title: 'Cancel',
                    className: 'btn btn-light-secondary font-weight-bolder text-uppercase px-9 py-4 float-right',
                    link: '/jobs',
                    disabled: () => false
                }
            ]
        }
    };

    componentDidMount() {
        this.getUserLocation()
        window.scrollTo(0, 0);
        
        this.props.setStep('job');
        this.loadObjectData();

        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-details-title"]').tooltip({ trigger: 'manual' });
            window.$('[data-toggle="tooltip"]').tooltip('hide');
            window.$('[id="job-details-title"]').tooltip('show');
            document.getElementById("skip-tutorial-button").style.display = "block";
        }
    }

    componentWillUnmount() {
        if(this.props.isTutorial && this.props.tutorialStep === 'addingJob') {
            window.$('[id="job-details-title"]').tooltip('hide');
            document.getElementById("skip-tutorial-button").style.display = "none";
        }
    }

    loadObjectData = () => {
        if(!this.props.obj || !this.props.obj.job)
            return;

        let form = {...this.state.form};
        const jobDetails = {...this.props.obj.job};
        const fields = objectToFields(form.fields, jobDetails);

        form.fields = fields;
        form.isValid = true;
        this.setState({ form: form });
    }

    isFormValid = () => {
        return this.state.form.isValid;
    }

    addJob = async () => {
        const obj = fieldsToObject(this.state.form.fields);

        this.props.setJobDetails({...obj, locationLat:this.state.userLocation.lat, locationLong:this.state.userLocation.lng});
        this.props.history.push('/jobs/new/location');
    }

    prepareForm = () => {
        let form = {...this.state.form};

        form.handleInputChange = (event, obj) => {
            const updatedForm = updateFormOnChange(event, obj, this.state.form);

            if(event.target.name === "dates") {
                const startDate = obj.startDate;
                const endDate = obj.endDate;

                this.props.onDateChange(startDate, endDate);
            }

            if(!updatedForm)
                return;

            this.setState({ form: updatedForm });
        }

        form.touchField = (event) => {
            const updatedForm = touchField(event, this.state.form);

            if(!updatedForm)
                return;
                
            this.setState({ form: updatedForm });
        }

        return form;
    }

    getUserLocation = async () =>{
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition((success)=>{
                const location = {lat:success.coords.latitude, lng:success.coords.longitude}
                this.setState({userLocation:location})
            })
        }
    }

    render() {
        const form = this.prepareForm();

        return (
            <>
                <a id="job-details-title" data-toggle="tooltip" title="There are 8 steps to creating a job, but only the Job Name, ID and Schedule Dates are required. The more info you include, the more efficient your job will run."
                data-placement="left">
                    <h3 className="mb-10 font-weight-bold text-dark">Job Details</h3>
                </a>
                <BasicForm {...form} />
            </>
        );
    }
}

export default JobDetailsStep;