import React from "react";

interface EQCheckBoxProps {
  title: string;
  hint?: string;
  required?: boolean;
  onChecked?: (checked: boolean) => void;
  checked: boolean;
}

const EQCheckBox = (props: EQCheckBoxProps) => {
  return (
    <React.Fragment>
      <div className="mb-5">
        <div>
          {props.title}{" "}
          <span className="text-danger">{props.required ? "*" : null}</span>
        </div>
        <input
          id={`eq-checkbox-id-${props.title}`}
          type="checkbox"
          checked={props.checked}
          placeholder={props.title}
          onChange={(event) => {
            if (props.onChecked) props.onChecked(event.target.checked);
          }}
        />
        {props.hint && (
          <span className="form-text text-muted mt-2">{props.hint}</span>
        )}
      </div>
    </React.Fragment>
  );
};

export default EQCheckBox;
