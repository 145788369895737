import React, { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import ReauthenticateModal from '../../components/Modals/ReauthenticateModal';
import app, { db, storage } from '../../firebase';
import { validateField } from '../../shared/Validation';
import { getMessages } from '../../components/Messages/GetMessages';

class ChangePassword extends Component {
    static contextType = AuthContext;

    state = {
        user: null,
        reauthenticateData: {
            email: '',
            password: '',
            error: null,
            loading: false
        },
        reauthenticateModalShow: false,
        fields: [
            {
                name: 'password',
                value: '',
                defaultValue: '',
                type: 'password',
                label: 'Password',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: null,
                required: true,
                userProfile: true,
                originalUser: false,
                validators: [
                    {
                        type: 'required',
                        message: getMessages('password_required')
                    },
                    {
                        type: 'lowercaseIncluded',
                        message: getMessages('pass_lowercase')
                    },
                    {
                        type: 'uppercaseIncluded',
                        message: getMessages('pass_uppercase')
                    },
                    {
                        type: 'digitIncluded',
                        message: getMessages('pass_digit')
                    },
                    {
                        type: 'minCharacters',
                        value: 8,
                        message: getMessages('pass_characters')
                    },
                ]
            },
            {
                name: 'passwordConfirm',
                value: '',
                defaultValue: '',
                type: 'password',
                label: 'Password Confirm',
                isValid: true,
                validationMessage: '',
                isTouched: false,
                additionalMessage: null,
                required: true,
                userProfile: true,
                originalUser: false,
                validators: [
                    {
                        type: 'required',
                        message: getMessages('confirm_pass_required')
                    },
                    {
                        type: 'lowercaseIncluded',
                        message: getMessages('pass_lowercase')
                    },
                    {
                        type: 'uppercaseIncluded',
                        message: getMessages('pass_uppercase')
                    },
                    {
                        type: 'digitIncluded',
                        message: getMessages('pass_digit')
                    },
                    {
                        type: 'minCharacters',
                        value: 8,
                        message: getMessages('pass_characters')
                    },
                    {
                        type: 'equal',
                        message: getMessages('pass_nomatch')
                    }
                ]
            }
        ],
        isValid: false,
        submitError: false,
        submitErrorMessage: '',
        successMessage: '',
        loading: false,
        accountCreated: false
    };

    updatePassword = (credential) => {
        this.setState({ loading: true });

        const currentUser = credential ? credential.user : this.context.currentUser;
        currentUser.updatePassword(this.state.fields.find(x => x.name === 'password').value)
            .then((res) => {
                this.setState({ loading: false });
                this.clearFields();
            })
            .catch(err => {
                if (err.code === 'auth/requires-recent-login') {
                    this.setState({ loading: false, reauthenticateModalShow: true });
                }

                this.setState({ loading: false });
            });
    }

    clearFields = () => {
        let updatedFields = [...this.state.fields];
        updatedFields.forEach(x => {
            x.value = x.defaultValue;
        });

        this.setState({ fields: updatedFields });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.updatePassword();
    }

    handleChange = (event) => {        
        let fields = [...this.state.fields];
        let field = fields.find(x => x.name === event.target.name);

        if (!field)
            return;

        field.value = event.target.value;
        field.isTouched = true;
        this.validate();

        this.setState({ fields: fields, isValid: !(fields.some(x => !x.isValid)) });
    }

    getObjectFromFields = () => {
        let obj = {};
        this.state.fields.forEach(item => {
            if (item.name !== 'image')
                obj[item.name] = item.value;
        });

        return obj;
    }

    touchField = (field) => {
        if(field.isTouched)
            return;

        let fields = [...this.state.fields];
        let updatedField = fields.find(x => x.name === field.name);
        if (!updatedField)
            return;

        updatedField.isTouched = true;
        this.setState({ fields: fields });
    }

    reauthenticate = () => {
        const currentUser = this.context.currentUser;
        const self = this;

        app.auth()
            .signInWithEmailAndPassword(currentUser.email, this.state.reauthenticateData.password)
            .then(function (userCredential) {
                self.setState({ reauthenticateModalShow: false });
                self.updatePassword(userCredential);
            })
            .catch(err => {
                console.log(err);
                self.setState({ reauthenticateData: { ...this.state.reauthenticateData, error: 'Password confirmation failed.' } })
            });
    }

    addValidationClass = (field, cl) => {
        if (field.isValid && field.value && field.isTouched) return cl + ' is-valid';
        if (!field.isValid && field.isTouched) return cl + ' is-invalid';

        return cl;
    }

    handleChangeReauthenticate = (event) => {
        let reauth = { ...this.state.reauthenticateData };
        reauth[event.target.name] = event.target.value;

        this.setState({ reauthenticateData: reauth });
    }

    isChanged() {
        return this.state.fields.some(x => x.value !== x.defaultValue);
    }

    validate = () => {
        this.state.fields.forEach(field => {
            if(field.name === 'passwordConfirm') {
                const passwordConfirmField = this.state.fields.find(x => x.name === 'password');
                validateField(field, passwordConfirmField);
            } else {
                validateField(field);
            }            
        });
    }

    render() {
        const submitBtnClasses = 'btn btn-light-primary font-weight-bold';
        const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
        const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-xl-9">
                        <div className="my-5 step" data-wizard-type="step-content" data-wizard-state="current">
                            <h5 className="text-dark font-weight-bold mb-10">Change Your Password:</h5>
                            {this.state.fields.map((field,index) => (
                                <div key={"chng-psw-flds"+index} className="form-group row">
                                    <label className="col-xl-3 col-lg-3 col-form-label">{field.label} {field.required ? <span className="text-danger">*</span> : null}</label>                                    
                                    <div className="col-lg-9 col-xl-9">
                                        <input
                                            name={field.name}
                                            type={field.type}
                                            value={field.value}
                                            onChange={this.handleChange}
                                            className={this.addValidationClass(field, "form-control form-control-solid form-control-lg")}
                                            disabled={field.disabled}
                                        />
                                        <div className="invalid-feedback">{field.validationMessage}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="card-footer pb-0">
                    <div className="row">
                        <div className="col-xl-2" />
                        <div className="col-xl-7">
                            <div className="row">
                                <div className="col-3" />
                                <div className="col-9">
                                    <button disabled={!this.state.isValid} 
                                        onClick={this.handleSubmit} className={submitBtnClassesFinal}>Change password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReauthenticateModal
                    show={this.state.reauthenticateModalShow}
                    title="Confirm your identity"
                    yesButton={{
                        title: "Confirm",
                        onClick: () => { this.reauthenticate(); }
                    }}
                    noButton={{
                        title: "Cancel",
                        onClick: () => { this.setState({ reauthenticateModalShow: false }) }
                    }}
                    handleChange={this.handleChangeReauthenticate}
                    data={this.state.reauthenticateData}
                />
            </React.Fragment>
        );
    }
}

export default ChangePassword;