import React, { Component } from 'react';

import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';

import { getClient, deleteClient, getContacts, removeContact, updateClient } from '../../shared/Api';
import { Edit, Trash } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';
import { normalizeInput } from '../../shared/FormHelper';
import RichDataTable from '../../components/Tables/RichDataTable';

class ClientDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;

  state = {
    client: null,
    deleteClientModalActive: false,
    deleteContactModalActive: false,
    selectedContactId: null,
    contacts: [],
    contactDeleting: false,
    contactFields: [
      {
        display: 'First Name',
        name: 'firstName'
      },
      {
        display: 'Last Name',
        name: 'lastName'
      },
      {
        display: 'Position',
        name: 'position'
      },
      {
        display: 'Email',
        name: 'email'
      },
      {
        display: 'Phone Number',
        name: 'phoneNumber'
      },
      {
        display: 'Other Phone Number',
        name: 'phoneNumber2'
      },
      {
        display: 'Notes',
        name: 'notes'
      }        
    ]
  }

  componentDidMount = async () => {
    this.loadData();
  }

  loadData = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const getClientResult = await getClient(inventoryId, id);

    this.setState({ client: getClientResult.data });
    this.loadContacts();
  }

  loadContacts = async () => {
    const id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const getContactsResult = await getContacts(inventoryId, id);
    const contactList = getContactsResult.data;

    if(this.state.client && (this.state.client.firstName || this.state.client.lastName)) {
      contactList.unshift({ id: id, firstName: this.state.client.firstName, lastName: this.state.client.lastName });
    }

    this.setState({ contacts: contactList });
  }

  deleteClient = async () => {
    const id = this.state.client.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await deleteClient(inventoryId, id);

    this.setState({ deleteClientModalActive: false });
    this.props.history.push("/clients");
  }

  deleteContact = async () => {
    this.setState({contactDeleting: true});
    const id = this.state.client.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    if(id === this.state.selectedContactId) {
      await updateClient(inventoryId, id, { firstName: '', lastName: '' });
    } else {
      await removeContact(inventoryId, id, this.state.selectedContactId);
    }

    this.setState({ deleteContactModalActive: false, contactDeleting: false });
    this.loadData();
  }

  getClass = (type) => {
    return "primary";
  }

  render() {
    if (this.state.client) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">Client details
                  <span className="d-block text-muted pt-2 font-size-sm">{this.state.client.companyName}</span></h3>
              </div>
              <div className="card-toolbar">
                {this.user.permissions !== 'crewmember' &&
                  <>
                    <a onClick={() => { this.props.history.push('/clients/' + this.state.client.id + '/edit') }} className="btn btn-primary btn-sm font-weight-bold mr-4">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Edit />
                      </span>Edit</a>
                    <a onClick={() => { this.setState({ deleteClientModalActive: true }) }} className="btn btn-danger btn-sm font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Trash />
                      </span>Delete</a>
                  </>}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-4">
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Company name:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">{this.state.client.companyName}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Phone number:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">{normalizeInput(this.state.client.phoneNumber, '')}</span>
                </div>
              </div>

              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Address:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">{this.state.client.address}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Email:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">{this.state.client.email}</span>
                </div>
              </div>
              <div className="form-group row my-2">
                <label className="col-4 col-form-label">Notes:</label>
                <div className="col-8">
                  <span className="form-control-plaintext font-weight-bolder">{this.state.client.notes}</span>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div>
                <RichDataTable
                  title="Contact list"
                  hideImageColumn={true}
                  newRecord={this.user.permissions === 'crewmember' ? null : {
                    title: 'Add contact',
                    onClick: () => { this.props.history.push('/clients/' + this.state.client.id + '/contacts/new') }
                  }}
                  fields={this.state.contactFields}
                  data={this.state.contacts}
                  actions={{
                    delete: {
                      enabled: true,
                      onClick: (id) => {
                        this.setState({ selectedContactId: id, deleteContactModalActive: true });
                      }
                    },
                    edit: {
                      enabled: this.user.permissions !== 'crewmember',
                      onClick: (id) => {
                        this.props.history.push('/clients/' + this.props.match.params.id + '/contacts/' + id + '/edit');
                      }
                    },
                    detail: {
                      enabled: false,
                      onClick: (id) => {
                      }
                    }
                  }}
                  removeShadow={true}
                  showExportButton={false}
                />
              </div>
            </div>
          </div>
          <ConfirmModal
            id={"delete-client"}
            show={this.state.deleteClientModalActive}
            title="Delete client"
            body={getMessages('delete_client')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteClient(); }
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteClientModalActive: false }) }
            }}
          />
          <ConfirmModal
            id={"delete-contact"}
            show={this.state.deleteContactModalActive}
            title="Delete contact"
            body={getMessages('delete_contact')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteContact(); },
              spinner: this.state.contactDeleting
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteContactModalActive: false, selectedContactId: null }) }
            }}
          />
        </React.Fragment>
      );
    } else {
      return <Loader />
    }
  }
}

export default ClientDetails;