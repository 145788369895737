import React from 'react';

const Select = (props) => {
    function isSelected(value) {
        const arr = props.field.value.split(',');
        return arr.includes(value);
    }

    return (
        <>
            <select
                className={props.className}
                autoFocus={props.index === 0}
                value={props.field.value}
                placeholder={props.field.placeholder}
                name={props.field.name}
                onChange={props.handleChange}
                onBlur={() => {if(props.touchField) props.touchField(props.field) }}
                id={props.id}
                data-toggle={props.field.dataToggle}
                title={props.field.title}
                data-placement={props.field.dataPlacement}
            >
                {props.field.options.map(x => {
                    return (
                        <option value={x.value}>{x.display}</option>
                    );
                })}
            </select>           
        </>
    );
}

export default Select;