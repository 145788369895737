import React, { useEffect, useRef, useState } from "react";
import DefaultListItem from "./DefaultListItem";
import DefaultListContactItem from "./DefaultListContactItem";
import Pagination from "../../components/Pagination/Pagination";
import DefaultListNotificationItem from "./DefaultListNotificationItem";

const DefaultList = (props) => {
  const indexOfLastPost = props.currentPage * props.postsPerPage;
  const indexOfFirstPost = indexOfLastPost - props.postsPerPage;
  const currentPosts = props.data.slice(indexOfFirstPost, indexOfLastPost);
  return (
    
    <React.Fragment>
      <div>
        {currentPosts.map((item, index) => (
          
          <ul className="ul-override" key={"dflt-lst-itms" + index}>
            {props.mode === "client" && (
              <DefaultListContactItem
                item={item}
                currentUser={props.currentUser}
                switchToEditForm={props.switchToEditForm}
                showEditButton={props.showEditButton}
                canSelect={props.canSelect}
                onSelected={()=>{
                  props.onSelected(item);
                }}
                selectedItem={props.selectedItems?.some((selectedItem)=>selectedItem==item.id)??false}
              />
            )}
            {props.mode === "notification" && (
              <DefaultListNotificationItem
                item={item}
                currentUser={props.currentUser}
                onClick={props.onClick}
                canEdit={props.canEdit}
                match={props.match}
              />
            )}
            {props.mode === "default" && (
              <DefaultListItem
                item={item}
                currentUser={props.currentUser}
                match={props.match}
              />
            )}
          </ul>
        ))}
        {currentPosts.length !== 0 ? (
          <Pagination
            postsPerPage={props.postsPerPage}
            totalPosts={props.data.length}
            paginate={props.paginate}
            currentPage={props.currentPage}
          />
        ) : (
          <span className="d-block align-items-center text-muted pt-2 font-size-sm">
            No items
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default DefaultList;
