import React, { useContext, useState } from "react";
import { functions } from "../../firebase";
import { showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";
import { CrewMemberEntity } from "../../shared/data/Crew";
import ReportComponent, { ReportRecipient } from "./ReportComponent";

interface BoreLogProfileReportProps {
  jobId: string;
  crewMembers: CrewMemberEntity[];
  boreId: string
}

const BoreLogProfileReport = ({ jobId, crewMembers, boreId }: BoreLogProfileReportProps) => {
  const authContext = useContext(AuthContext);

  const [sending, setSending] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const sendBoreLogProfileReport = async (emailsToSend: string[]) => {
    if (!sending) {
      setSending(true);
      let sendBoreLogProfileReportFunc = functions.httpsCallable("sendBoreLogProfileReport");

      await sendBoreLogProfileReportFunc({
        jobID: jobId,
        userID: authContext.currentUser.uid,
        recipients: emailsToSend.join(","),
        boreID: boreId
      })
        .then((result) => {
          showToast("success", "Report has been sent successfully.");
        })
        .catch((exception) => {
          showToast("danger", "Error sending report.");
        });
      setSending(false);
    }
  };

  const getDialogItems = () => {
    const user = authContext.currentUser;

    const crewMemberItems: ReportRecipient[] =
      crewMembers
        ?.filter((crewMember) => crewMember.id != null)
        .map((crewMember) => {
          return new ReportRecipient(
            crewMember.id ?? "",
            `${crewMember.firstName} ${crewMember.lastName}`,
            crewMember.email ?? "Unknown email"
          );
        }) ?? [];

    const items: ReportRecipient[] = [
      new ReportRecipient(
        user.uid,
        user.displayName ?? "Unknown",
        user.email,
        true,
        true
      ),
    ].concat(crewMemberItems);

    return items;
  };

  return (
    <React.Fragment>
      <div className="form-group float-right">
        <button
          id="borelog-profile-report-btn"
          data-toggle="tooltip"
          onClick={() => {
            if (!sending) setShowModal(true);
          }}
          className={
            sending
              ? "btn btn-primary font-weight-bold float-right mr-4 w-100 spinner spinner-white spinner-right"
              : "btn btn-primary font-weight-bold float-right mr-4 w-100"
          }
        >
          BoreLog Profile Report
        </button>
      </div>
      <ReportComponent
        id="borelog-profile-report"
        title="BoreLog Profile report"
        showDialog={showModal}
        reportRecipients={getDialogItems()}
        onDismiss={() => {
          setShowModal(false);
        }}
        sendReport={(recipients) => {
          setShowModal(false);
          setSending(true);
          sendBoreLogProfileReport(
            recipients.map((recipients) => recipients.email)
          );
        }}
      />
    </React.Fragment>
  );
};

export default BoreLogProfileReport;
