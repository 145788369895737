import React, { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import { getCrews } from '../../shared/Api';
import Loader from '../../components/Layout/Loader/Loader';
import { CrewsIos, PlusIcon } from '../../components/Icons/Svg';
import { Link } from 'react-router-dom';
import { fromRGBValue } from '../../shared/Util';
import RichDataTable from '../../components/Tables/RichDataTable';

class Crews extends Component {
    static contextType = AuthContext;
    user = this.context.currentUser.userProfile;

    state = {
        crews: null,
        crewFields: [
            {
                display: 'Crew ID',
                name: 'crewID'
              },
              {
                display: 'Crew Name',
                name: 'crewName',
                customField: true,
                template: (crew) => {
                    return '<span role="button" data-id="' + crew.id + '" id="btn-goToCrew">' + crew.crewName + '</span>';
                }
              },
              {
                display: 'Crew Color',
                name: 'crews',
                customField: true,
                template: (crew) => {
                    return this.getCrewSymbol(crew);
                }
              }
        ],
        sortBy: 'name',
        keyword: '',
    }

    getCrewSymbol = (crew) => {
        
            return '<div class="symbol symbol-30 flex-shrink-0 mr-4">\
            <div class="symbol-label" style= " background-color:rgba(' + fromRGBValue(crew.red) + ', ' + fromRGBValue(crew.green) + ', ' + fromRGBValue(crew.blue) + ',' + crew.alpha + ')" ></div>\
            </div>';
    }

    goToCrew = (event, crewId) => {
        if(event)
            event.preventDefault();
            this.props.history.push("/crews/" + crewId);
    }

    getCrewsForTable = () => {
        let crews = this.state.crews;
        let sortBy = this.state.sortBy;
        let keyword = this.state.keyword;

        crews = keyword === '' ? crews 
                              : crews.filter(x => x.crewName.toLowerCase().includes(keyword.toLowerCase()) 
                                || x.crewID.toLowerCase().includes(keyword.toLowerCase()));

        switch(sortBy) {
            case "name": 
                crews.sort((a, b) => {
                    return (a.crewName > b.crewName) ? 1 : ((b.crewName > a.crewName) ? -1 : 0);
                });
                break;
            case "id": 
                crews.sort((a, b) => {
                    return (a.crewID > b.crewID) ? 1 : ((b.crewID > a.crewID) ? -1 : 0);
                });
                break;
        }

        return crews;
    }

    componentDidMount() {
        this.loadCrews();
    }

    loadCrews = async () => {
        const currentUser = this.context.currentUser;
        const inventoryId = currentUser.company.inventoryID;

        const getCrewsResult = await getCrews(inventoryId);
        this.setState({ crews: getCrewsResult.data });
    }

    render() {
        if (!this.state.crews) {
            return <Loader />
        }
        if (this.state.crews.length===0) {
            return (
                <div className="d-flex align-items-center mb-10">
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
                        <div className='mainpage-graphic'>
                            <CrewsIos width={'200px'} height={'200px'} opacity={'0.3'} />
                        </div>
                        <span className="font-italic">You don't have any crews at the moment. You can start adding by clicking the button below.</span>&nbsp;
                        {this.user.permissions === 'crewmember' ? null 
                        : 
                        <div className="card-toolbar">
                            <Link to="/crews/new" className="btn btn-primary font-weight-bolder">
                                <span className="svg-icon svg-icon-md">
                                    <PlusIcon />
                                </span>Add crew
                            </Link>&nbsp;
                            </div>}
                    </div>
                </div>
            );
        }
        const crewsList = this.getCrewsForTable();
        return (
            <React.Fragment>
                <div className="d-flex flex-column-fluid">
                    <div className="container">
                            <div className="card card-custom">
                            <div className="card-header">
                                <div className="card-title">
                                    <h3 className="card-label">Crews</h3>
                                </div>
                                <div className="card-toolbar">
                                    {this.user.permissions !== 'crewmember' &&
                                    <Link to="/crews/new" className="btn btn-light-primary font-weight-bold">
                                        <i className="ki ki-plus icon-md mr-2" />Add Crew</Link>}
                                </div>
                            </div>
                                <div className='card-body'>
                                <div className="row align-items-center mb-12">
                                        <div className="col-lg-4 my-2 my-md-0">
                                            <div className="input-icon">
                                            <input type="text" className="form-control" 
                                                value={this.state.keyword}
                                                onChange={(e) => this.setState({ keyword: e.target.value })}
                                                placeholder="Search..." id="kt_jobs_list_search_query" />
                                            <span>
                                                <i className="flaticon2-search-1 text-muted" />
                                            </span>
                                            </div>
                                        </div>
                                        <select value={this.state.sortBy} 
                                            onChange={(e) => this.setState({ sortBy: e.target.value })}
                                            className="custom-select col-lg-4 my-2 my-md-0">
                                            <option value="name">Sort by name</option>
                                            <option value="id">Sort by Crew ID</option>
                                        </select>
                                        </div>
                                        <div className="timeline timeline-5">
                                            <>
                                                <RichDataTable
                                                        fields={this.state.crewFields}
                                                        data={crewsList}
                                                        hideImageColumn={true}
                                                        hideHeader={true}
                                                        goToCrew={this.goToCrew}
                                                        actions={{
                                                            delete: {
                                                              enabled: false,
                                                              onClick: (id) => {
                                                                console.log(id);
                                                              }
                                                            },
                                                            edit: {
                                                              enabled: this.user.permissions !== 'crewmember',
                                                              onClick: (id) => {
                                                                this.props.history.push('/crews/' + id + '/edit');
                                                              }
                                                            },
                                                            detail: {
                                                              enabled: true,
                                                              onClick: (id) => {
                                                                this.props.history.push('/crews/' + id);
                                                              }
                                                            },
                                                            duplicate: {
                                                              enabled: false,
                                                              onClick: (id) => {
                                                                console.log(id);
                                                              }
                                                            }
                                                          }}
                                                />
                                            </>
                                        </div>
                                    </div>
                                <div>
                                </div>
                            </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Crews;