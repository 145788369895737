import React from 'react';

const TextAreaInput = (props) => {
    return (
        <textarea
            type="textarea"
            rows={props.field.rows}
            className={props.className}
            autoFocus={props.index === 0}
            placeholder={props.field.placeholder}
            value={props.field.value}
            name={props.field.name}
            onChange={props.handleChange}
            onBlur={props.touchField}
            id={props.id}
        />
    );
}

export default TextAreaInput;