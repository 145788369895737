import React, { createRef, useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { ExportIcon, PlusIcon, ImportIcon } from '../Icons/Svg';
import { CSVLink } from "react-csv";
import CSVReader from 'react-csv-reader';
import { downloadPDFTable, fromRGBValue, printPDFTable, toRGBValue } from '../../shared/Util';
import usePrevious from '../../hooks/usePrevious';

let gDatatable = null;

const RichDataTable = (props) => {
  const tableRef = useRef(null);
  /* const importButtonRef = useRef(); */
  const [loaded, setLoaded] = useState(false);
  const [length, setLength] = useState(0);
  const [showCustomFilters, setShowCustomFilters] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const data  = [...props.data];
  const previousData = usePrevious(data);

  
  const havePropsChanged = () => {
    
    if (data.length !== length)
      return true;
    
    let haveChanged = false;
      
    for (let i = 0; i < data.length; i++) {
      if(data[i].id !== previousData[i].id ){
        haveChanged = true;
      }
      }
      
    return haveChanged;
  }

  useEffect(() => {
    if (!loaded) {
      setLength(props.data.length);

      let fields = props.fields.map(x => {
        if (x.name === 'equipmentID') {
          return {
            field: 'equipmentID',
            title: 'Equipment ID',
            sortable: false,
            template: function (data) {
              return '<span role="button" data-id="' + data.id + '" id="btn-detail">' + data.equipmentID + '</span>';
            }
          }
        }

        if(x.name === 'title') {
          return {
            field: 'title',
            title: 'Job name',
            template: function (data) {
              return '<span role="button" data-id="' + data.id + '" id="btn-goToJob">' + data.title + '</span>';
          }
        }
      }

      if(x.customField) {
        return {
          field: x.name,
          title: x.display,
          template: (data) => x.template(data)
        }
      }

        if (x.name === 'firstName' || x.name === 'lastName') {
          return {
            field: x.name,
            title: x.display,
            width: 128
          }
        }

        if (x.name === 'email') {
          return {
            field: x.name,
            title: x.display,
            width: 250
          }
        }

        return {
          field: x.name,
          title: x.display
        }
      });

      if(!props.hideImageColumn) {
        fields.unshift({
          field: 'Image',
          title: '',
          width: 80,
          sortable: false,
          template: function (data) {
            const image = data.image ? data.image : "assets/media/equappment/img_placeholder.jpg";
            if (data.crew) {
              if(data.authorizationStatus === 'authorized') {
                return '<div role="button" data-id="' + data.id + '" id="btn-detail" class="bgi-no-repeat bgi-size-cover rounded border min-h-80px image-input-wrapper"\
                        style="background-image: url(\'' + image + '\'); background-size: \'contain, cover\'; background-position: \'center\'">\
                        <span style="margin-top: 62px; background-color:rgba(' + fromRGBValue(data.crew.red) + ', ' + fromRGBValue(data.crew.green) + ', ' + fromRGBValue(data.crew.blue) + ' , ' + data.crew.alpha + ')" class="dot crew-dot"></span>\
                        <i style="margin-top: 60px; left: 62px; position: absolute; color: darkslategrey" class="fas fa-user-shield icon-xl" />\
                      </div>';
              } else {
                return '<div role="button" data-id="' + data.id + '" id="btn-detail" class="bgi-no-repeat bgi-size-cover rounded border min-h-80px image-input-wrapper"\
                        style="background-image: url(\'' + image + '\'); background-size: \'contain, cover\'; background-position: \'center\'">\
                        <span style="margin-top: 62px; background-color:rgba(' + fromRGBValue(data.crew.red) + ', ' + fromRGBValue(data.crew.green) + ', ' + fromRGBValue(data.crew.blue) + ' , ' + data.crew.alpha + ')" class="dot crew-dot"></span>\
                      </div>';
              }
            } else if (!data.crew && data.authorizationStatus === 'authorized') {
              return '<div role="button" data-id="' + data.id + '" id="btn-detail" class="bgi-no-repeat bgi-size-cover rounded border min-h-80px image-input-wrapper"\
                      style="background-image: url(\'' + image + '\'); background-size: \'contain, cover\'; background-position: \'center\'">\
                      <i style="margin-top: 60px; left: 62px; position: absolute; color: darkslategrey" class="fas fa-user-shield icon-xl" />\
                      </div>'
            }
            else  {
              return '<div role="button" data-id="' + data.id + '" id="btn-detail" class="bgi-no-repeat bgi-size-cover rounded border min-h-80px image-input-wrapper"\
                        style="background-image: url(\'' + image + '\'); background-position: center center">\
                      </div>';
            }
          }
        });
      }

      if (props.actions) {
        fields.push({
          field: 'Actions',
          title: 'Actions',
          sortable: false,
          width: 240,
          autoHide: false,
          overflow: 'visible',
          template: function (data) {
            let actionsTemplate = '';

            if (props.actions.detail.enabled) {
              actionsTemplate +=
                '<a data-id="' + data.id + '" id="btn-detail" class="btn btn-sm btn-clean btn-icon mr-2" title="Details">\
                                <span class="svg-icon svg-icon-md">\
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">\
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                                            <polygon points="0 0 24 0 24 24 0 24"/>\
                                            <rect fill="#000000" opacity="0.3" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000) " x="7.5" y="7.5" width="2" height="9" rx="1"/>\
                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997) "/>\
                                        </g>\
                                    </svg>\
                                </span>\
                            </a>\
                            ';
            }

            if (props.actions.edit.enabled) {
              actionsTemplate +=
                '<a data-id="' + data.id + '" id="btn-edit" class="btn btn-sm btn-clean btn-icon mr-2" title="Edit">\
                                <span class="svg-icon svg-icon-md">\
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">\
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                                            <rect x="0" y="0" width="24" height="24"/>\
                                            <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero"\ transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>\
                                            <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>\
                                        </g>\
                                    </svg>\
                                </span>\
                            </a>\
                            ';
            }

            if (props.actions.duplicate && props.actions.duplicate.enabled) {
              actionsTemplate +=
                '<a data-id="' + data.id + '" id="btn-duplicate" class="btn btn-sm btn-clean btn-icon mr-2" title="Duplicate">\
                                  <span class="svg-icon svg-icon-md"><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo2/dist/../src/media/svg/icons/General/Duplicate.svg--><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">\
                                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                                      <rect x="0" y="0" width="24" height="24"/>\
                                      <path d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>\
                                      <path d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z" fill="#000000"/>\
                                  </g>\
                              </svg>\
                            </a>\
                            ';
            }

            if (props.actions.delete.enabled) {
              actionsTemplate +=
                '<a data-id="' + data.id + '" id="btn-delete" class="btn btn-sm btn-clean btn-icon mr-2" title="Delete">\
                                <span class="svg-icon svg-icon-md">\
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">\
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">\
                                            <rect x="0" y="0" width="24" height="24"/>\
                                            <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>\
                                            <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>\
                                        </g>\
                                    </svg>\
                                </span>\
                            </a>\
                            ';
            }

            return "<div>" + actionsTemplate + "</div>";
          }
        });
      }

      var datatable = window.$('#kt_datatable').KTDatatable({
        columns: fields,
        data: {
          type: 'local',
          source: props.data,
          pageSize: 10,
        },
        layout: {
          scroll: false,
          footer: false
        },
        sortable: false,
        pagination: true,
        search: {
          input: $('#kt_datatable_search_query'),
          key: 'generalSearch'
        },
      });

      $('#kt_datatable').on('click', '#btn-detail', function () {
        var id = $(this).data("id");
        props.actions.detail.onClick(id);
      });

      $('#kt_datatable').on('click', '#btn-edit', function () {
        var id = $(this).data("id");
        props.actions.edit.onClick(id);
      });

      $('#kt_datatable').on('click', '#btn-delete', function () {
        var id = $(this).data("id");
        //datatable.api().row($(this).parents('tr')).remove().draw();    
        props.actions.delete.onClick(id);
      });

      $('#kt_datatable').on('click', '#btn-duplicate', function () {
        var id = $(this).data("id");
        props.actions.duplicate.onClick(id);
      });

      $('#kt_datatable').on('click', '#btn-goToJob', function (e) {
        var id = $(this).data("id");
        props.goToJob(e,id);
      });

      $('#kt_datatable').on('click', '#btn-goToCrew', function (e) {
        var id = $(this).data("id");
        props.goToCrew(e,id);
      });

      if (props.filters) {
        props.filters.forEach(filter => {
          window.$('#kt_datatable_search_' + filter.name).on('change', function () {
            datatable.search($(this).val().toLowerCase(), filter.name);
          });

          window.$('#kt_datatable_search_' + filter.name).selectpicker();
        });
      }

      setLoaded(true);
      gDatatable = datatable;
    } else {
      if (gDatatable && havePropsChanged()) {
        reloadEmpty(() => {
          setTimeout(() => {
            gDatatable.originalDataSet = props.data;
            gDatatable.reload();
            setLength(props.data.length);
          }, 500)
        });
      }

      if(gDatatable && props.waitForImages && !imagesLoaded) {
        gDatatable.originalDataSet = props.data;
        gDatatable.reload();
        setImagesLoaded(true);
      }
    }
  });



  const reloadEmpty = (callback) => {
    gDatatable.originalDataSet = [];
    gDatatable.reload();
    callback();
  }

  const handleImportClick = () => {
    document.getElementById("import-button").click();
  }

  const getFilterOptions = (filter) => {
    if (!props.data)
      return [];

    let options = props.data.map(x => x[filter.name]);
    options = [...new Set(options)];
    options.unshift('All');

    return options.map(x => {
      if (x === 'All')
        return (<option value="">All</option>);

      return (
        <option value={x}>{x}</option>
      );
    });
  }

  return (
    <React.Fragment>
      {/*begin::Card*/}
      <div className="card card-custom" style={props.removeShadow ? {boxShadow: 'none'} : {}}>
        {!props.hideHeader && 
        <div className="card-header flex-wrap border-0 pt-6 pb-0">
          <div className="card-title">
            <h3 className="card-label">{props.title}
              <span className="d-block text-muted pt-2 font-size-sm">{props.description}</span></h3>
          </div>
          <div className="card-toolbar">
            {props.import ?
              <>
              <div className="dropdown dropdown-inline mr-2">
                <button type="button" className={props.import.importing ? "btn btn-light-primary font-weight-bolder dropdown-toggle spinner spinner-white spinner-right"
                : "btn btn-light-primary font-weight-bolder dropdown-toggle"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="svg-icon svg-icon-md">
                    <ImportIcon />
                  </span>Import</button>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <ul className="navi flex-column navi-hover py-2">
                    <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                      <li className="navi-item">
                        <a role="button" onClick={handleImportClick} className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-import" />
                          </span>
                          <span className="navi-text">Import</span>
                        </a>
                      </li>
                      <li className="navi-item">
                        <CSVLink
                          filename="import-template-csv.csv"
                          data={[]}
                          headers={props.export.csv.headers}
                          className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-excel-o" />
                          </span>
                          <span className="navi-text">Template</span>
                        </CSVLink>
                      </li>
                  </ul>
                </div>
              </div>
              <CSVReader
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={(data, fileInfo) => { props.import.onImport(data, fileInfo); }}
                  onError={(err) => { console.log(err); }}
                  inputId="import-button"
                  /* ref={importButtonRef} */
                  parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header =>
                      header
                        .toLowerCase()
                        .replace(/\W/g, '_')
                  }}
                />
              </>
              : null}
            {props.export ?
              <div className="dropdown dropdown-inline mr-2">
                <button type="button" className="btn btn-light-primary font-weight-bolder dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <span className="svg-icon svg-icon-md">
                    <ExportIcon />
                  </span>Export</button>
                <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <ul className="navi flex-column navi-hover py-2">
                    <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                    {props.export.pdf && props.export.print &&
                      <li className="navi-item">
                        <a role="button" onClick={() => printPDFTable(props.export.pdf.title, props.export.pdf.headers,
                          props.export.pdf.data)} className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-print" />
                          </span>
                          <span className="navi-text">Print</span>
                        </a>
                      </li>}
                    {props.export.csv &&
                      <li className="navi-item">
                        <CSVLink
                          filename={props.export.csv.filename}
                          data={props.export.csv.data}
                          headers={props.export.csv.headers}
                          className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-excel-o" />
                          </span>
                          <span className="navi-text">Excel</span>
                        </CSVLink>
                      </li>
                    }
                    {props.export.pdf &&
                      <li className="navi-item">
                        <a onClick={() => downloadPDFTable(props.export.pdf.title, props.export.pdf.filename,
                          props.export.pdf.headers, props.export.pdf.data)} role="button" className="navi-link">
                          <span className="navi-icon">
                            <i className="la la-file-pdf-o" />
                          </span>
                          <span className="navi-text">PDF</span>
                        </a>
                      </li>}
                  </ul>
                </div>
              </div>
              : null}
            {props.newRecord ?
              <a id={props.newRecord.id} onClick={props.newRecord.onClick} 
              data-toggle={props.newRecord.dataToggle} title={props.newRecord.toggleTitle}
              className="btn btn-primary font-weight-bolder">
                <span className="svg-icon svg-icon-md">
                  <PlusIcon />
                </span>{props.newRecord.title}</a>
              : null}
          </div>
        </div>}
        <div className="card-body">
          <div className="mb-7">
            {!props.hideHeader && 
              <div className="row align-items-center">
              <div className="col-lg-12 col-xl-12">
                <div className="row align-items-center">
                  <div className="col-lg-4 my-2 my-md-0">
                    <div className="input-icon">
                      <input type="text" className="form-control" placeholder="Search..." id="kt_datatable_search_query" />
                      <span>
                        <i className="flaticon2-search-1 text-muted" />
                      </span>
                    </div>
                  </div>
                  {props.customFilter &&
                    <div>
                      {!showCustomFilters ? <button className="btn btn-link" onClick={() => setShowCustomFilters(true)}>Show Filters</button> : <button className="btn btn-link" onClick={() => { if (props.onHideFilters) props.onHideFilters(); setShowCustomFilters(false) }}>Hide Filters</button>}
                    </div>}
                </div>
                {props.customFiltered && showCustomFilters &&
                  <div className="col-lg-12 col-xl-12 mt-6">
                    {props.customFilter}
                    <div className="separator separator-solid my-7"></div>
                  </div>}
              </div>
            </div>
            }
          </div>
          <table ref={tableRef} className="datatable datatable-bordered datatable-head-custom" id="kt_datatable">
          </table>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RichDataTable;