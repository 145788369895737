import { validateField } from './Validation';
import { toRGBValue, fromRGBValue } from './Util';
import moment from 'moment';

export const fieldsToObject = (fields) => {
    let obj = {};
    fields.forEach(field => {
        if (field.type === 'dropzone') {

        }
        else if (field.type === 'daterangepicker') {
            obj[field.startDate.name] = field.startDate.value.toDate();
            obj[field.endDate.name] = field.endDate.value.toDate();
        }
        else if (field.type === 'radiobuttons' && field.allowCustom && field.value === field.custom) {
            obj[field.name] = field.customValue;
        }
        else if (field.type === 'datepicker') {
            obj[field.name] = moment(field.value).toDate();
        }
        else if (field.type === 'number' && field.dataType === 'float') {
            obj[field.name] = parseFloat(field.value);
        }
        else if (field.type === 'colorpicker') {
            obj.red = toRGBValue(field.value.red);
            obj.green = toRGBValue(field.value.green);
            obj.blue = toRGBValue(field.value.blue);
            obj.alpha = field.value.alpha;
        }
        else if(field.type==='textWithButton'){
            obj[field.name] = field.value
        }
        else {
            obj[field.name] = field.value;
        }
    });

    return obj;
};

export const objectToFields = (fields, obj) => {
    fields.forEach(field => {
        if (field.type === 'daterangepicker') {
            field.startDate.value = moment(obj[field.startDate.name]);
            field.endDate.value = moment(obj[field.endDate.name]);
        } else if (field.type === 'dropzone') {
            field.value = obj[field.name];
            field.defaultValue = obj[field.name];
            field.localSrc = obj[field.name];
        } else if (field.type === 'colorpicker') {
            field.value = {
                red: fromRGBValue(obj.red),
                green: fromRGBValue(obj.green),
                blue: fromRGBValue(obj.blue),
                alpha: obj.alpha
            }
        }
        else if (field.type === 'location') {
            field.value = {
                lat: obj.lat,
                long: obj.long
            }
        }
        else {
            if(field.subtype === 'phone') {
                field.value = normalizeInput(obj[field.name], '');
            } else {
                field.value = obj[field.name] ?? null;
            }
        }
    });

    return fields;
};

export const mergeObjects = (target, source) => {
    return Object.assign(target, source);
};

export const mergeFieldsToObject = (target, fields) => {
    return mergeObjects(target, fieldsToObject(fields));
};

export const isValid = (fields) => {
    return !fields.some(x => !x.isValid);
};

export const hasValues = (fields) => {
    return fields.some(x => x.value)
}

export const validate = (fields) => {
    fields.forEach(field => {
        validateField(field);
    });
}

  export const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    let currentValue = value.replace(/[^\d]/g, '');    

    if (!previousValue || value.length > previousValue.length) {       
        let returnValue = '';
        const startWith1 = currentValue.charAt(0) === '1';
        if(startWith1)
            currentValue = currentValue.substring(1);

        const cvLength = currentValue.length;
        if (cvLength < 4) {
            returnValue = currentValue;
        } else if (cvLength < 7) {
            returnValue = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
        } else {
            returnValue = `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, currentValue.length)}`;
        }            

        if(startWith1) 
            returnValue = "+1 " + returnValue;

        return returnValue;
    }

    return value;
  };

export const updateFormOnChange = (event, obj, form) => {
    if (!event || !event.target)
        return;

    let fields = [...form.fields];
    let field = fields.find(x => x.name === event.target.name);

    if(!field && form.otherFields){
        let otherFields = [...form.otherFields]
        field = otherFields.find(x => x.name === event.target.name);
    }

    if (!field)
        return form;

    if (field.type === 'daterangepicker') {
        field.startDate.value = obj.startDate;
        field.endDate.value = obj.endDate;
    }
    else if (field.type === 'radiobuttons' && field.allowCustom && event.target.type === 'text') {
        field.customValue = event.target.value;
    }
    else if (field.type === 'checkbox') {
        field.value = event.target.checked;
    }
    else if (field.type === 'multiselect') {
        let values = field.value.split(",");
        var index = values.indexOf(event.target.value);
        if (index !== -1) {
            values.splice(index, 1);
        } else {
            values.push(event.target.value);
        }

        field.value = values.join(',');
    }
    else if (field.type === 'colorpicker') {
        field.value = event.target.value;
    }
    else {
        if(field.subtype === 'phone') {
            field.value = normalizeInput(event.target.value, field.value);
        } else {
            field.value = event.target.value;
        }
    }

    field.isTouched = true;
    validate(fields);
    
    if(form.otherFields){
        
        let primaryFields = [...form.fields];
        let otherFields = [...form.otherFields];
        validate(otherFields);
        if(isValid(primaryFields) && !hasValues(otherFields)) {
            form.isValid = true;
        }else{
         let allFields = [...primaryFields, ...otherFields]
         form.isValid = isValid(allFields);
        }
    } else {
        form.isValid = isValid(fields);
    }
    return form;
};

export const resetFormValues = (form) => {
    form.fields.forEach(x => {
        x.value = x.defaultValue
        x.isTouched= false
    });
    if(form.otherFields){
        form.otherFields.forEach(x => {
            x.value = x.defaultValue
            x.isTouched= false
        });  
    }
}

export const touchField = (event, form) => {
    if (!event || !event.target)
        return;

    let fields = [...form.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
        return form;

    field.isTouched = true;
    return form;
};

export const getSubmitClass = (baseClass, form) => {
    if (form.loading)
        baseClass += " spinner spinner-white spinner-right";

    return baseClass;
}