import React, { useEffect, useReducer, useState } from "react";
import GoogleMapReact from "google-map-react";
import EQMarker from "./EQMarker";
import Geosuggest from "react-geosuggest";

const EQMap = (props) => {
  const [mapState, setMapState] = useState({
    zoom: 10,
    center: {
      lat: 37.42216,
      lng: -122.08427,
    },
    draggable: true,
    editable: props.editable,
    mapType: props.mapType,
    enableSearch: props.enableSearch,
  });

  const initLocations = (locations, mapType) => {
    if (locations == null) {
      return [];
    }
    if (locations.length > 1) {
      switch (mapType) {
        case "MULTIPOINT":
          return locations;
        case "LINE":
          return [locations[0], locations[locations.length - 1]];
        default:
          return locations;
      }
    } else {
      return locations;
    }
  };

  const [locations, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case "SET_LOCATION":
          action.payload.id = state.locations.length;
          switch (mapState.mapType) {
            case "MULTIPOINT":
              const multipointResult = {
                ...state,
                locations: [...state.locations, action.payload],
              };
              return multipointResult;
            case "LINE":
              let currentLocations = state.locations;
              if (state.locations.length > 0) {
                currentLocations = [state.locations[0], action.payload];
              } else {
                currentLocations = [...state.locations, action.payload];
              }
              const lineResult = {
                ...state,
                locations: currentLocations,
              };
              return lineResult;
            default:
              const result = { ...state, locations: [action.payload] };
              return result;
          }
        case "CHANGE_LOCATION": {
          const result = {
            ...state,
            locations: state.locations.map((element, index) => {
              if (index == action.payload.id) {
                return {
                  ...element,
                  lat: action.payload.lat,
                  lng: action.payload.lng,
                };
              }
              return element;
            }),
          };
          return result;
        }
      }
    },
    {
      locations: initLocations(props.locations, props.mapType),
    }
  );

  useEffect(() => {
    if (props.onLocationsLoaded != null)
      props.onLocationsLoaded(locations.locations);
  }, [locations]);

  const changePinLocation = (childKey, childProps, mouse) => {
    if (mapState.editable) {
      setMapState((previousState) => {
        return { ...previousState, draggable: false };
      });
      const newLocation = {
        id: childKey,
        lat: mouse.lat,
        lng: mouse.lng,
      };
      dispatch({
        type: "CHANGE_LOCATION",
        payload: newLocation,
      });
    }
  };

  const getPinIcon = (index) => {
    if (mapState.mapType == "LINE" && index == 1) return "flaticon2-pin-1";
  };

  const fitBounds = (map, maps) => {
    const bounds = new maps.LatLngBounds();
    locations.locations.forEach((location) => {
      bounds.extend(new maps.LatLng(location.lat, location.lng));
    });
    map.fitBounds(bounds);
    maps.event.addDomListenerOnce =
      (map,
      "idle",
      () => {
        maps.addDomListener =
          (map,
          maps,
          "resize",
          () => {
            map.fitBounds(bounds);
          });
      });
  };

  return (
    <React.Fragment>
      <div className="form-group">
       {mapState.enableSearch && (
          <>
            <Geosuggest
              autoActivateFirstSuggest={true}
              inputClassName="form-control"
              suggestsClassName="sug-container"
              suggestItemClassName="dropdown-item"
              suggestItemActiveClassName="sug-item-active"
              onSuggestSelect={(result) => {
                dispatch({
                  type: "SET_LOCATION",
                  payload: result.location,
                });
                setMapState((previousState) => {
                  return { ...previousState, center: result.location };
                });
              }}
              autoComplete="off"
            />
          </>
        )}
        </div>
      <div style={{ height: "40vh", width: "100%" }}>
        <GoogleMapReact
          draggable={mapState.draggable}
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
          defaultZoom={mapState.zoom}
          center={mapState.center}
         
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={({ map, maps }) => {
            if (locations.locations.length > 0) fitBounds(map, maps);
            map.addListener("click", e => {
              if (mapState.editable) {
                const newLocation = {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                };
                dispatch({
                  type: "SET_LOCATION",
                  payload: newLocation,
                });
              }
            })
          }}
          onChildMouseDown={(index, props, mouse) => {
            console.log(index);
          }}
          onChildMouseMove={changePinLocation}
          onChildMouseUp={(index, props, mouse) => {
            setMapState((previousState) => {
              return { ...previousState, draggable: true };
            });
          }}
          onChildClick={() => {
            console.log("on child click");
          }}
        >
          {locations.locations.map((location, index) => {
            return (
              <EQMarker
                key={index}
                lat={location.lat}
                lng={location.lng}
                icon={getPinIcon(index)}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    </React.Fragment>
  );
};

export default EQMap;
