import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
  
const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
    const hex = x.toString(16)
    return hex.length === 1 ? '0' + hex : hex
}).join('')

function initializeColor(r, g, b, a) {
    const color = {
        rgb: {
            r: r,
            g: g,
            b: b,
            a: a
        },
        hex: rgbToHex(r, g, b)
    }

    return color;
}

const ColorPicker = (props) => {
    const [color, setColor] = useState();
    const handleChange = color => { 
        const event = { target: { name: props.field.name, value: 
            { red: color.rgb.r, green: color.rgb.g, blue: color.rgb.b, alpha: color.rgb.a } } };
        props.handleChange(event);
    };

    return <SketchPicker
        color={initializeColor(props.field.value.red, props.field.value.green, props.field.value.blue, props.field.value.alpha)}
        onChangeComplete={handleChange} />
}

export default ColorPicker;