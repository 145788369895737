import React from 'react';

import TextInput from './TextInput';
import TextAreaInput from './TextAreaInput';
import NumberInput from './NumberInput';
import DropzoneInput from './DropzoneInput';
import DateRangePicker from './DateRangePicker';
import RadioButtons from './RadioButtons';
import DatePicker from './DatePicker';
import Static from './Static';
import MultiSelect from './MultiSelect';
import CheckboxInput from './CheckboxInput';
import MultiImageInput from './MultiImageInput';
import ColorPicker from './ColorPicker';
import Select from './Select';
import TextInputWithButton from './TextInputWithButton';

const EQInput = (props) => {
    if(props.field.type === "text") {
        return <TextInput {...props} />
    }

    if(props.field.type === "textarea") {
        return <TextAreaInput {...props} />
    }

    if(props.field.type === "number") {
        return <NumberInput {...props} />
    }

    if(props.field.type === "dropzone") {
        return <DropzoneInput {...props} />
    }

    if(props.field.type === "daterangepicker") {
        return <DateRangePicker {...props} />
    }

    if(props.field.type === "radiobuttons") {
        return <RadioButtons {...props} />
    }

    if(props.field.type === "datepicker") {
        return <DatePicker {...props} />
    }

    if(props.field.type === "static") {
        return <Static {...props} />
    }

    if(props.field.type === "multiselect") {
        return <MultiSelect {...props} />
    }

    if(props.field.type === "checkbox") {
        return <CheckboxInput {...props} />
    }

    if(props.field.type === "multiimage") {
        return <MultiImageInput {...props} />
    }

    if(props.field.type === "colorpicker") {
        return <ColorPicker {...props} />
    }

    if(props.field.type === "select") {
        return <Select {...props} />
    }
    if(props.field.type === "textWithButton"){
        return <TextInputWithButton {...props}/>
    }
    
    return null;
}

export default EQInput;