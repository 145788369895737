import React, { useEffect, useState } from "react";

interface ReportOptionsProps {
  onOptionSelected: (option: number) => void;
}

const ReportOptions = ({ onOptionSelected = () => {} }: ReportOptionsProps) => {
  const [formState, setFormState] = useState({
    selectedReportOptions: 0,
  });

  useEffect(() => {
    onOptionSelected(formState.selectedReportOptions);
  }, [formState.selectedReportOptions]);

  return (
    <React.Fragment>
      <div>
        <label className="mt-5">Report options</label>
        <div className="radio-inline">
          <label className="radio radio-success">
            <input
              type="radio"
              checked={formState.selectedReportOptions == 0}
              onChange={(event) => {
                setFormState((previousState) => ({ selectedReportOptions: 0 }));
              }}
            />
            <span></span>
            PDF
          </label>
          <label className="radio radio-success">
            <input
              type="radio"
              checked={formState.selectedReportOptions == 1}
              onChange={(event) => {
                setFormState((previousState) => ({ selectedReportOptions: 1 }));
              }}
            />
            <span></span>
            Excel
          </label>
          <label className="radio radio-success">
            <input
              type="radio"
              checked={formState.selectedReportOptions == 2}
              onChange={(event) => {
                setFormState((previousState) => ({ selectedReportOptions: 2 }));
              }}
            />
            <span></span>
            PDF & Excel
          </label>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReportOptions;
