import React, { useContext, useEffect, useState } from "react";
import { getLocateTickets } from "../../shared/api/LocateTicketsApi";
import LocateTicketListComponent from "./list/LocateTicketListComponent";
import { LocateTicketEntity } from "../../shared/data/LocateTickets";
import { Link } from "react-router-dom";
import { ContextEntity } from "../../shared/data/context/ContextEntity";
import { AuthContext } from "../../shared/Auth";
import { JobEntity } from "../../shared/data/job/Job";

interface LocateTicketComponentProps {
  job: JobEntity;
  setTabActive: (tab: string) => void;
}

const LocateTicketComponent = ({
  job,
  setTabActive = () => {},
}: LocateTicketComponentProps) => {
  const context: ContextEntity = useContext(AuthContext);
  const [locateTickets, setLocateTickets] = useState<LocateTicketEntity[]>([]);

  useEffect(() => {
    loadData();
    setTabActive("locatetickets");
  }, []);

  const loadData = async () => {
    const response = await getLocateTickets(context.currentUser?.company?.inventoryID??"", job.id??"");
    const responseData = (response.data ?? []).sort((a, b) => (a.ticketID ?? "") > (b.ticketID ?? "") ? 1 : -1);
    setLocateTickets(responseData);
  };

  return (
    <React.Fragment>
      <div className="d-flex flex-column flex-grow-1">
        <div className="d-flex flex-row form-group row-col-2 mx-0 float-right">
          <Link
            to={{ 
              pathname: `/jobs/${job.jobID}/locatetickets/new`,
              state: {
                locateTicket: new LocateTicketEntity(job.id, context.currentUser?.userProfile?.companyID, context.currentUser?.company?.inventoryID, job.locationLat, job.locationLong),
              },
            }}
            className="btn btn-primary font-weight-bold mr-4"
          >
            New locate ticket
          </Link>
        </div>
      </div>
      <LocateTicketListComponent
        locateTickets={locateTickets}
        onRefreshList={() => {
          loadData();
        }}
        hideOptions={false}
      />
    </React.Fragment>
  );
};

export default LocateTicketComponent;
