import { Timestamp } from "firebase/firestore"
import { TaskEntity } from "./Task"
import { parseFromFirebaseDate } from "../../Util"
import { FirebaseInventoriesCollections, FirebaseRootCollections, FirebaseTimeLogCollections } from "../../firebase/FirebaseSchema"

export class TimeLogEntity {
    id?: string | null = null
    taskID: string
    duration?: number | null = null
    footage?: number | null = null
    quantity?: number | null = null
    yards?: number | null = null
    jobID: string
    notes?: string | null = null
    startDate?: Timestamp | null = null
    imageTimeStamp?: Timestamp | null = null
    photos?: Timestamp[] | null = null
    address?: string | null = null
    crewmembers?: string[] | null = null
    crews?: string[] | null = null
    equipments?: string[] | null = null
    lat?: number | null = null
    lng?: number | null = null
    userID: string

    constructor(jobId: string, userId: string, taskId: string) {
        this.taskID = taskId
        this.jobID = jobId
        this.userID = userId
    }

    static imageUrl(timeLog?: TimeLogEntity): ImageEntity {
        const url: ImageEntity = {
            value: timeLog?.imageTimeStamp ?? null, src: "/timeLog/" +
                timeLog?.id +
                "/" +
                timeLog?.imageTimeStamp?.seconds +
                "/"
        }
        return url
    }

    static getAllImagesUrls(timeLog: TimeLogEntity): ImageEntity[] {
        const urls = [this.imageUrl(timeLog)]
        const photos: ImageEntity[] = timeLog.photos?.map((photo) => {
            return {
                value: photo, src: "/timeLog/" +
                    timeLog?.id +
                    "/" +
                    photo?.seconds +
                    "/"
            }
        }) ?? []

        return [...urls, ...photos]
    }

    static buildTimeLog(timeLog: TimeLogEntity, task: TaskEntity | null = null, userId: string | null = null): TimeLogEntity {
        return {
            ...timeLog,
            crewmembers: task?.crewmembers, crews: task?.crews,
            lat: task?.lat, lng: task?.lng, address: task?.address,
            equipments: task?.equipments, userID: timeLog?.userID ?? userId
        }
    }
}

export const buildTimeLogFileEntityPath = (inventoryId: string, timeLogId:string) => {
    return `/${FirebaseRootCollections.INVENTORIES}/${inventoryId}/${FirebaseInventoriesCollections.TIMELOG}/${timeLogId}/${FirebaseTimeLogCollections.FILES}`
}

export const buildTimeLogFileUploadPath = (companyId: string, timeLogId: string, folderName: string) => {
    return `${companyId}/timeLog/${timeLogId}/${folderName}`
}

export const buildTImeLogFilesPath = (companyId:string, timeLogId: string) => {
    return `${companyId}/timeLog/${timeLogId}`
}

export interface ImageEntity {
    file?: any | null
    value?: Timestamp | null | undefined;
    src: string;
}
