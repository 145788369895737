import React, { useEffect } from 'react';

const CheckOutInItem = (props) => {
  useEffect(() => {
    var element = document.getElementById("check_in_out_item_" + props.id);
    //var height = parseInt(KTUtil.css(element, 'height'));
    var height = 190;

    if (!element) {
        return;
    }

    var options = {
        series: [props.percentage],
        chart: {
            height: height,
            type: 'radialBar',
            width: '160px',
            parentHeightOffset: 0,
        },
        grid: {
            show: true,
            padding: { left: 0, right: 0, top: -10, bottom: 0 },
          },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "65%"
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        show: false,
                        fontWeight: '700'
                    },
                    value: {
                        color: window.KTApp.getSettings()['colors']['gray']['gray-700'],
                        fontSize: "14px",
                        fontWeight: '700',
                        offsetY: -8,
                        show: true,
                        formatter: function (val) {
                            return [(val + '%'), 'Complete'];
                        }
                    }
                },
                track: {
                    background: window.KTApp.getSettings()['colors']['theme']['light']['primary'],
                    strokeWidth: '100%'
                }
            }
        },
        colors: [window.KTApp.getSettings()['colors']['theme']['base']['primary']],
        stroke: {
            lineCap: "round",
        },
        labels: ["Progress"]
    };

    var chart = new window.ApexCharts(element, options);
    chart.render();
  }, []);

  return (
    <div id={"check_in_out_item_" + props.id}></div>
  );
}

export default CheckOutInItem;