import React, { Component, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import loader from '../../components/Layout/Loader/Loader';
import DefaultList from '../../components/Lists/DefaultList';
import InfoModal from '../../components/Modals/InfoModal';
import { getNotifications, updateNotification } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';

const Notifications = () => {

    const {currentUser} = useContext(AuthContext);
    const {loadNotifications} = useContext(AuthContext);
    const {notifications} = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(5);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [notificationModalActive, setNotificationModalActive] = useState(false);
    
    const updateSingleNotification = async (item) => {
        
        setSelectedNotification(item);
        setNotificationModalActive(true);
        let seenField = item.seenByUser;

        if(!seenField.includes(currentUser.uid)) {
            seenField.push(currentUser.uid);
            let obj = {
                seenByUser: seenField
            };
            const result = await updateNotification(item.id, obj)
        }
    }

    const paginate=(pageNumber) => {
        setCurrentPage(pageNumber);
      }
    
    const clear = () =>{
        setSelectedNotification(null);
        setNotificationModalActive(false);
        loadNotifications(currentUser.uid);
    }

        
        if(!notifications)
            return loader
        const canEdit = currentUser.uid === "zfnO1CRDeWetLPsqUB8a6lR8Wu32" || currentUser.uid === "CfaHqGxDiqhn0u0iI8HZM2q8cXC3";
        return (
            <div className="card card-custom">
                <div className="card-header">
                    <div className="card-title">
                        <h3>Notifications</h3>
                    </div>
                    <div className="card-toolbar">
                        {(currentUser.uid === "zfnO1CRDeWetLPsqUB8a6lR8Wu32" || currentUser.uid === "CfaHqGxDiqhn0u0iI8HZM2q8cXC3") && 
                            <Link to="/notifications/new" className="btn btn-light-primary font-weight-bold">
                            <i className="ki ki-plus icon-md mr-2" />Add Notification</Link>
                        }
                    </div>
                </div>
                <div className='card-body'>
                    <DefaultList 
                        data = {notifications}
                        currentPage={currentPage}
                        postsPerPage={postsPerPage}
                        paginate={paginate}
                        currentUser={currentUser}
                        mode = "notification"
                        onClick={updateSingleNotification}
                        canEdit={canEdit}
                    />
                </div>
                <InfoModal 
                    show={notificationModalActive}
                    title={selectedNotification && selectedNotification.title}
                    id="notify-data-question"
                    body={selectedNotification && selectedNotification.description}
                    yesButton={{
                    title: "Close",
                    onClick: clear
                    }}
                />
            </div>
        )
}

export default Notifications;