import React, { useEffect } from 'react';
import * as moment from 'moment';

const DateRangePicker = (props) => {
    useEffect(() => {
        const id = props.id;
        window.$("#" + id).daterangepicker({
            buttonClasses: ' btn',
            applyClass: 'btn-primary',
            cancelClass: 'btn-secondary',
            startDate: props.field.startDate.value,
            endDate: props.field.endDate.value,
            minDate: props.field.minDate,
            isInvalidDate: (date) => {
                return !isValid(date);
            }
        }, function(start, end, label) {
            let event = {
                target: {
                    name: props.field.name
                }
            };

            props.handleChange(event, { startDate: start, endDate: end });
        });
    }, []);

    function isValid(date) {
        
        if(!props.invalidRanges)
            return true;

        let valid = true;
        props.invalidRanges.forEach(element => {
            const range = moment().range(element.startDate, element.endDate);
            if(range.contains(date)) {
                valid = false;
            }
        });

        return valid;
    }

    return (
        <input type='text' 
               className={props.className} 
               autoFocus={props.index === 0}
               id={props.id}
               readOnly 
               placeholder={props.placeholder} />
    );
}

export default DateRangePicker;