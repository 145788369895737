import React, { useEffect, useRef, useState } from 'react';
import CheckboxInput from '../../../components/Form/Inputs/CheckboxInput';
import DatePicker from '../../../components/Form/Inputs/DatePicker';
import TextInput from '../../../components/Form/Inputs/TextInput';
import { AngleDoubleRight } from '../../../components/Icons/Svg';
import Select from 'react-select';

const CustomFilter = (props) => {
  return (
    <React.Fragment>
      <br />
      <div className="form-group row">
        {props.filters.map((filter,index) => {
          if (filter.name === "availableToday" || filter.name === "onJobToday" || filter.name === "beingServicedToday") {
            return (
              <div key={"cstm-fltr-chkbx"+index} className="col-lg-6 col-xl-6">
                <div className="row">
                  <div className="col-4 col-form-label">
                    <label>{filter.display}</label>
                  </div>
                  <div className="col-8 col-form-label">
                    <CheckboxInput field={filter} className={"form-control"} handleChange={filter.onChange} />
                  </div>
                </div>
              </div>
            );
          } else if (filter.name === "availableOn") {
            return (
              <div key={"cstm-fltr-dtpckr"+index} className="col-lg-6 col-xl-6">
                <div className="row">
                  <div className="col-4 col-form-label">
                    <label>{filter.display}</label>
                  </div>
                  <div className="col-8">
                    <DatePicker field={filter} className={"form-control"} handleChange={filter.onChange} />
                  </div>
                </div>
              </div>
            );
          } else if (filter.name === "byJobID") {
            return (
              <div key={"cstm-fltr-slct"+index} className="col-lg-6 col-xl-6">
                <div className="row">
                  <div className="col-4 col-form-label">
                    <label>{filter.display}</label>
                  </div>
                  <div className="col-8">
                    <Select
                      options={props.ids} isSearchable={true}
                      onChange={(event) => filter.onChange(event)}
                      value={props.selectedJobID}
                      defaultValue={props.selectedJobID}
                      isClearable={true} backspaceRemovesValue={true} />
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>
    </React.Fragment>
  )
}

export default CustomFilter;