import { Timestamp } from "firebase/firestore";
import { getBore, updateBore } from "../Api";
import { BoreEntity } from "../data/bore/BoreEntity";
import { ResponseEntity } from "../data/Response";
import { TimeLogEntity } from "../data/taskmanagement/TimeLog";
import { getTask, saveTask, saveTimeLog } from "./TaskManagerApi";
import { TaskEntity } from "../data/taskmanagement/Task";

/**
 * This method closes bore. Also adds one time log to corresponding task and completes the task if task exists.
 * @param inventoryId inventoryId this bore belongs to
 * @param boreId bore identifier
 * @param totalDuration this information is required for updating bore when closing it and creating timelog
 * @param totalFootage this information is required for updating bore when closing it and creating timelog
 */
export const finishBore = async (inventoryId: string, boreId: string, totalDuration: number, totalFootage: number, userId: string) => {
    try {
        //First we close bore by setting finished value to true
        await updateBore(inventoryId, boreId, { finished: true, totalFootage: totalFootage, totalDuration: totalDuration });

        //Attempt to add timelog and complete corresponding task
        const bore = (await getBore(inventoryId, boreId))?.data as BoreEntity | null

        if (bore && bore.jobID && bore.taskID) {

            const task = (await getTask(inventoryId, bore.taskID))?.data as TaskEntity | null

            const timeLog = new TimeLogEntity(bore.jobID, userId, bore.taskID)
            timeLog.lat = bore.lat ?? null
            timeLog.lng = bore.lng ?? bore?.long ?? null
            timeLog.startDate = Timestamp.now()
            timeLog.notes = bore?.notes ?? ""
            timeLog.footage = totalFootage
            timeLog.imageTimeStamp = null
            timeLog.duration = totalDuration
            timeLog.address = bore.address ?? null
            timeLog.crewmembers = null
            timeLog.crews = null
            timeLog.yards = null
            timeLog.quantity = null
            timeLog.photos = null
            timeLog.equipments = null

            await saveTimeLog(inventoryId, timeLog)
            if (task) {
                task.taskStatus = "closed"
                await saveTask(inventoryId, task)
            }
        }

        return new ResponseEntity<BoreEntity>(null, null)
    } catch (error: any) {
        return new ResponseEntity<BoreEntity>(null, error)
    }
}