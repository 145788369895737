import React from 'react';
import { Link } from 'react-router-dom';
import {  Person,  } from '../Icons/Svg';

const DefaultListNotificationItem=(props)=>{

    const dotStyle = {
        position: 'absolute',
        backgroundColor: "red",
        left: '75%',
        right: 0,
        marginLeft: 'auto', 
        marginRight: 'auto',
        height: "12px",
        width: "12px"
      }
return(
<React.Fragment>
  <div className="d-flex flex-column-fluid">
  <div className="container">
        <div className="d-flex">
          <div className="flex-grow-1">
            <div className="d-flex align-items-center justify-content-between flex-wrap mt-2">
              <div className="mr-3">
                <a role="button" 
                  onClick={() => props.onClick(props.item)}
                  className="d-flex align-items-center text-dark text-hover-primary font-size-h5 font-weight-bold mr-3">{props.item.title}
                  &nbsp;<div className="text-muted font-size-h6 font-weight-bold">
                   {props.item.position}</div>
                </a>
                <div className="d-flex flex-wrap my-2">
                  <div  className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                      <Person />
                    </span> By {props.item.createdBy} {props.item.dateCreated}</div>
                </div>
              </div>
              {
                !props.item.read &&
                <div className="dot crew-dot" style={dotStyle}>
                </div>
              }
              {
                props.canEdit &&
                <Link to={`/notifications/${props.item.id}/edit`} className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2">Edit</Link>
              }
            </div>
          </div>
        </div>
        <div className="separator separator-solid my-7" />
  </div>
</div>
</React.Fragment>
   )
}

export default DefaultListNotificationItem;