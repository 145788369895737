import moment from "moment";
import React from "react";

interface EQDatePickerProps {
  title: string;
  hint?: string;
  date?: Date;
  minDate?: Date,
  maxDate?: Date,
  required?: boolean;
  
  onDateSelected?: (input: string) => void;
}

const EQDatePicker = (props: EQDatePickerProps) => {

    const getDate = (date?:Date) =>{
        return date ? moment(date)?.format("YYYY-MM-DD") : undefined
    }
  return (
    <React.Fragment>
      <div
        key={`eq-input-text-${props.title}`}
        className="form-group"
      >
        <label>
          {props.title}{" "}
          <span className="text-danger">{props.required ? "*" : null}</span>
        </label>{" "}
        <input
          type="date"
          className="form-control"
          value={getDate(props.date)}
          max={getDate(props.maxDate)}
          min={getDate(props.minDate)}
          onChange={(event) => {
            if (props.onDateSelected) props.onDateSelected(event.target.value);
          }}
        ></input>
        {props.hint && <div className="form-text text-muted">{props.hint}</div>}
      </div>
    </React.Fragment>
  );
};

export default EQDatePicker;
