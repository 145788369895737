import React from 'react';
import EQInput from './Inputs/EQInput';

const SimpleForm = (props) => {
    const addValidationClass = (field, cl) => {
        if(field.type === 'select')
            return cl;

        if (field.isValid && field.value && field.isTouched) return cl + ' is-valid';
        if (!field.isValid && field.value !== field.defaultValue) return cl + ' is-invalid';

        return cl;
    }

    const generateInputId = (field) => {
        return props.formUniqueName + "-input-" + field.name;
    }

    return (
        // <form onSubmit={props.onSubmit} id={props.formUniqueName + "-form"} noValidate="novalidate" action="POST">
        <div>
            <div className="card-body">
                {props.fields.map((item, index) => {
                    const field =
                        <div key={"smpl-frm-fld"+index} className="form-group">
                            <label>{item.label} <span className="text-danger">{item.required ? "*" : null}</span></label>
                            <EQInput 
                                field={item} 
                                id={generateInputId(item)}
                                touchField={props.touchField}
                                index={index}
                                className={addValidationClass(item, "form-control")}
                                handleChange={props.handleInputChange}
                                onBlur={props.onBlur}
                            />
                            {item.additionalMessage ?
                                <span className="form-text text-muted">{item.additionalMessage}</span> : null}
                            <div className="invalid-feedback">{item.validationMessage}</div>
                        </div>
                    return field;
                })}
                {props.otherFields && 
                <>
                <h4>Contact</h4>
                {props.otherFields.map((item, index) => {
                    const field =
                        <div key={"smpl-frm-fld"+index} className="form-group">
                            <label>{item.label} <span className="text-danger">{item.required ? "*" : null}</span></label>
                            <EQInput 
                                field={item} 
                                id={generateInputId(item)}
                                touchField={props.touchField}
                                /* index={index} */
                                className={addValidationClass(item, "form-control")}
                                handleChange={props.handleInputChange}
                                onBlur={props.onBlur}
                            />
                            {item.additionalMessage ?
                                <span className="form-text text-muted">{item.additionalMessage}</span> : null}
                            <div className="invalid-feedback">{item.validationMessage}</div>
                        </div>
                    return field;})}
                </>
                }
            </div>
            <div className="card-footer">
                <button
                    disabled={props.submitButton.disabled}
                    className={props.submitButton.className}
                    onClick={props.submitButton.onClick}>{props.submitButton.title}</button>
                {props.cancelButton ?    
                    <button onClick={props.cancelButton.onClick} className={props.cancelButton.className}>{props.cancelButton.title}</button>
                : null }
            </div>
            </div>
        // </form>
    );
}

export default SimpleForm;