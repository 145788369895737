import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'

const Dropzone = (props) => {
  const onDrop = useCallback(acceptedFiles => {
    props.field.onDrop(acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return (
    <div className={isDragActive ? "dropzone dropzone-default dropzone-primary dz-clickable" : "dropzone dropzone-default dz-clickable"} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="dropzone-msg dz-message needsclick">
            <h3 className="dropzone-msg-title">Drop files here or click to upload.</h3>
            {props.supportedTypes ? 
              <span className="dropzone-msg-desc">{props.supportedTypes}</span>
              :
              <span className="dropzone-msg-desc">JPEG, PNG, SVG</span>
            }
        </div>
    </div>
  );
}

export default Dropzone;