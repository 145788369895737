import React, { useContext, useEffect, useState } from "react";
import SubscriptionPlanComponent from "./SubscriptionPlanComponent";
import { AuthContext } from "../../../shared/Auth";
import { UserEntity, getPlanTier } from "../../../shared/data/context/UserEntity";
import {
  SubscriptionTierUI,
  generateMonthlyPlans,
  generateYearlyPlans,
} from "../../../shared/data/subscription/SubscriptionUI";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { changeSubscription } from "../../../shared/api/SubscriptionApi";
import { SubscriptionEntity } from "../../../shared/data/subscription/Subscription";
import { showToast } from "../../../shared/Util";

interface SubscriptionComponentForm{
  selectedPlan?: string | null;
  isLoading:boolean
}

export const SubscriptionComponent = () => {
  const context = useContext(AuthContext);

  const [confirmationText, setConfirmationText] = useState<string>("");

  const [currentUser, setCurrentUser] = useState<UserEntity>(context.currentUser)

  const [formState, setFormState] = useState<SubscriptionComponentForm>({
    selectedPlan: null,
    isLoading: false,
  });

  const [subscriptionPlan, setSubscriptionPlan] =
    useState<SubscriptionEntity | null>(currentUser?.subscription ?? null);

  const [subscriptionPeriod, setSubscriptionPeriod] =
    useState<String>("monthly");

  const [confirmModalActive, setConfirmModalActive] = useState(false);
  const getInvoiceLink = () => {
    return (
      process.env.REACT_APP_RECURLY_ACCOUNT_HOST +
      "/" +
      currentUser.hostedLoginToken
    );
  };

  const changePlan = async (code: string) => {
    setFormState((previousState)=>{ return {...previousState, isLoading:true}})
    changeSubscription(code).then(
      (result) => {
        setFormState((previousState)=>{ return {...previousState, isLoading:false}})
        setSubscriptionPlan(() => result);
      },
      (error) => {
        setFormState((previousState)=>{ return {...previousState, isLoading:false}})
        showToast("danger", error?.message);
      }
    );
  };

  useEffect(() => {
    const isUpgrade = isPlanUpgrade(
      subscriptionPlan?.plan?.plan_code ?? "",
      formState.selectedPlan ?? ""
    );
    if (isUpgrade)
      setConfirmationText(
        () =>
          "You are about to upgrade your company account.\nAll your data will remain intact and you will gain access to features from new Tier\nThis will upgrade all users on the company account.\nAre you sure you want to make this change?\n"
      );
    else
      setConfirmationText(
        () =>
          "You are about to downgrade your company account.\nAll your data will remain intact, but you will no longer have access to any features from previous Tier\nThis will downgrade all users on the company account.\nAre you sure you want to make this change?\n"
      );
  }, [formState]);

  const isPlanUpgrade = (currentPlan: string, selectedPlan: string) => {
    const selectedTier = getPlanTier(selectedPlan);
    const currentTier = getPlanTier(currentPlan);
    if (
      currentTier == SubscriptionTierUI.TIER1 &&
      (selectedTier == SubscriptionTierUI.TIER1 ||
        selectedTier == SubscriptionTierUI.TIER2 ||
        selectedTier == SubscriptionTierUI.TIER3)
    )
      return true;

    if (
      currentTier == SubscriptionTierUI.TIER2 &&
      (selectedTier == SubscriptionTierUI.TIER2 ||
        selectedTier == SubscriptionTierUI.TIER3)
    )
      return true;

    if (
      currentTier == SubscriptionTierUI.TIER3 &&
      selectedTier == SubscriptionTierUI.TIER3
    )
      return true;

    return false;
  };

  return (
    <React.Fragment>
      <div className="card-body p-lg-17">
        <div className="d-flex flex-column">
          <div className="mb-13 text-center">
            <h1 className="fs-2hx fw-bold mb-5">Choose your Equappment Plan</h1>

            <div className="text-gray-600 fw-semibold fs-5">
              If you would like more information about your current subscription
              and payment, please click
              <a
                href={getInvoiceLink()}
                target="_blank"
                className="link-primary fw-bold"
              >
                {` this link.`}
              </a>
            </div>
          </div>

          <div className="nav-group nav-group-outline mx-auto mb-15">
            <div
              className={`btn btn-clean btn-active btn-active-secondary px-6 py-3 me-2 ${
                subscriptionPeriod === "monthly" ? "active" : ""
              }`}
              onClick={() => {
                setSubscriptionPeriod(() => "monthly");
              }}
            >
              Monthly
            </div>

            <div
              className={`btn btn-clean btn-active btn-active-secondary px-6 py-3 me-2 ${
                subscriptionPeriod == "yearly" ? "active" : ""
              }`}
              onClick={() => {
                setSubscriptionPeriod(() => "yearly");
              }}
            >
              Annual
            </div>
          </div>

          <div className="row g-10">
            <SubscriptionPlanComponent
              subscriptions={
                subscriptionPeriod == "monthly"
                  ? generateMonthlyPlans().map((item) => {
                      const endPeriod = new Date(
                        Date.parse(subscriptionPlan?.current_period_ends_at?._)
                      );
                      endPeriod.setHours(0, 0, 0, 0);
                      return {
                        ...item,
                        nextPlanDate: endPeriod.toDateString(),
                      };
                    })
                  : generateYearlyPlans().map((item) => {
                      const endPeriod = new Date(
                        Date.parse(subscriptionPlan?.current_period_ends_at?._)
                      );
                      endPeriod.setHours(0, 0, 0, 0);
                      return {
                        ...item,
                        nextPlanDate: endPeriod.toDateString(),
                      };
                    })
              }
              pendingSubscription={subscriptionPlan?.pending_subscription}
              currentPlan={subscriptionPlan?.plan?.plan_code?.toString() ?? ""}
              isLoading={formState.isLoading}
              onPlanSelected={(planId) => {
                setFormState((previousState)=>{ return {...previousState, selectedPlan:planId}})
                setConfirmModalActive(() => true);
              }}
            />
          </div>
        </div>
      </div>
      <ConfirmModal
        show={confirmModalActive}
        title="Changing subscription"
        body={confirmationText}
        yesButton={{
          title: "Yes",
          onClick: () => {
            if (formState.selectedPlan) changePlan(formState.selectedPlan);
            setConfirmModalActive(() => false);
          },
        }}
        noButton={{
          title: "Cancel",
          onClick: () => {
            setFormState((previousState)=>{ return {...previousState, selectedPlan:null}})
            setConfirmModalActive(() => false);
          },
        }}
      />
    </React.Fragment>
  );
};

export default SubscriptionComponent;
