import React, { useContext, useEffect, useState } from "react";
import { DumpSiteEntity } from "../../shared/data/DumpSite";
import GoogleMapReact from "google-map-react";
import Geosuggest from "react-geosuggest";
import EQMarker from "../../components/GoogleMap/EQMarker";
import { saveDumpSite } from "../../shared/api/SoilConditionsApi";
import { useHistory } from "react-router-dom";
import { showToast } from "../../shared/Util";
import { AuthContext } from "../../shared/Auth";

interface DumpSiteProps {
  dumpSiteProp?: DumpSiteEntity | null;
}

const NewDumpSiteComponent = ({ dumpSiteProp }: DumpSiteProps) => {
  const context = useContext(AuthContext);
  const history = useHistory();
  const [dumpSite, setDumpSite] = useState<DumpSiteEntity | undefined | null>(dumpSiteProp);

  const save = async (dumpSite: DumpSiteEntity) => {
    const response = await saveDumpSite(dumpSite);
    if (!response.message) history.goBack();
    else showToast("error", response.message);
  };

  const isFormValid = () => {
    if (
      dumpSite?.lat &&
      dumpSite?.long &&
      dumpSite?.userId &&
      dumpSite?.companyId
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const currentUser = context.currentUser;
    const companyId = currentUser.userProfile.companyID;
    setDumpSite((previousState) => {
      return {
        ...previousState,
        userId: currentUser.uid,
        companyId: companyId,
      };
    });
  }, []);

  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h3 className="mb-10 font-weight-bold text-dark">
            New Dump Location
          </h3>
          <div className="">
            <div key="dump-site-notes" className="form-group">
              <label>Notes</label>
              <textarea
                rows={3}
                className="form-control"
                value={dumpSite?.notes ?? ""}
                onChange={(input) => {
                  setDumpSite((previousState) => {
                    return { ...previousState!, notes: input.target.value };
                  });
                }}
              />
            </div>
            <span className="form-text text-muted">
              The location for this dump site
            </span>
            <span className="form-text text-muted">
              Address: {dumpSite?.address}
            </span>
            <span className="form-text text-muted">
              Latitude: {dumpSite?.lat}
            </span>
            <span className="form-text text-muted">
              Longitude: {dumpSite?.long}
            </span>

            <div className="form-group mt-6">
              <Geosuggest
                autoActivateFirstSuggest={true}
                inputClassName="form-control"
                suggestsClassName="sug-container"
                suggestItemClassName="dropdown-item"
                initialValue={dumpSite?.address ?? ""}
                suggestItemActiveClassName="sug-item-active"
                onSuggestSelect={(result: any) => {
                  setDumpSite((previousState) => {
                    return {
                      ...previousState,
                      lat: result?.location?.lat ?? 0.0,
                      long: result?.location?.lng ?? 0.0,
                      address: result?.label ?? "",
                    };
                  });
                }}
                autoComplete="off"
              />
            </div>
            <div style={{ height: "40vh", width: "100%" }}>
              <GoogleMapReact
                options={{
                  mapTypeControl: true,
                  mapTypeControlOptions: {
                    style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                  },
                  mapTypeId: "satellite",
                }}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
                }}
                center={{
                  lat: dumpSite?.lat ?? 0,
                  lng: dumpSite?.long ?? 0,
                }}
                zoom={14}
                yesIWantToUseGoogleMapApiInternals={true}
                onGoogleApiLoaded={({ map, maps }) => {
                  map.addListener("click", (e: any) => {
                    setDumpSite((previousState) => {
                      return {
                        ...previousState,
                        lat: e.latLng.lat(),
                        long: e.latLng.lng(),
                      };
                    });
                  });
                }}
              >
                <EQMarker
                  key={103}
                  lat={dumpSite?.lat}
                  lng={dumpSite?.long}
                  pinColor={"darkgreen"}
                />
              </GoogleMapReact>
            </div>
            <div className="mt-8">
              <div>Public entry</div>
              <input
                className="mt-2"
                type="checkbox"
                key="dump-site-public-entry"
                checked={dumpSite?.publicEntry ?? false}
                onChange={(event) => {
                  setDumpSite((previousState) => {
                    return {
                      ...previousState,
                      publicEntry: event.currentTarget.checked,
                    };
                  });
                }}
              />
              <span className="form-text text-muted">
                Make dump site for this location public to Equappment community.
                Your personal data will not be shared.
              </span>
            </div>
            <div className="justify-content-between border-top mt-5 pt-10"></div>
            <button
              key="new-dump-site-btn-save"
              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 mr-2"
              disabled={!isFormValid()}
              onClick={(event) => {
                event.preventDefault();
                if (dumpSite) save(dumpSite);
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NewDumpSiteComponent;
