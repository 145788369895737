import * as moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { storage } from '../firebase';
import { Timestamp } from 'firebase/firestore';


export const getUnixDate = (date, dateFormat = "MM/DD/YY") => {
  try {
    return moment(moment(date).format(dateFormat)).unix();
  } catch {
    return null;
  }
};

export const parseFromFirebaseDate = (date, dateFormat = "MM/DD/YY") => {
  try {
    return moment(new Date(date.seconds * 1000)).format(dateFormat);
  } catch (exception) {
    return null;
  }
};

export const toFirebaseDate = (date) => {
  try {
    return Timestamp.fromDate(moment(date).toDate());
  } catch (error) {
    return null;
  }
};

export const getImageUrlFromFirebase = async (companyId, url) => {
  try {
    const imageUrl = await storage
      .ref(`/${companyId}/${url}`)
      .child("med")
      .getDownloadURL();
    return imageUrl;
  } catch (exception) {
    console.error(exception)
    return null;
  }
};

export const parseDate = (date, dateFormat = "MM/DD/YY") =>{
    try{
        return moment(date.toDate()).format(dateFormat);
    }catch(exception){
        return date
    }
}

export const sortAlphabetically = (data, type) => {
    const arr = data;

    if(type==="id") {
        arr.sort(function(a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });
        return arr;
    } else{
        arr.sort(function(a, b) {
            if (a.display < b.display) {
              return -1;
            }
            if (a.display > b.display) {
              return 1;
            }
            return 0;
          });
          return arr
    }
}

export const showToast = (type, message) => {
    var content = {};
    content.message = message;

    window.$.notify(content, {
        type: type,
        allow_dismiss: false,
        newest_on_top: true,
        mouse_over: false,
        showProgressbar: false,
        spacing: 10,
        timer: 1200,
        placement: {
            from: 'top',
            align: 'right'
        },
        offset: {
            x: 30,
            y: 30
        },
        delay: 1000,
        z_index: 10000,
        animate: {
            enter: 'animate__animated animate__rubberBand',
            exit: 'animate__animated animate__flash'
        }
    });
}

export const printPDFTable = (title, headers, data, image) => {
    const doc = generatePDFTable(title, headers, data, image);

    doc.autoPrint();
    doc.output('dataurlnewwindow');
}

export const downloadPDFTable = (title, filename, headers, data, image) => {
    const doc = generatePDFTable(title, headers, data, image);

    doc.save(filename);
}

export const generatePDFTable = (title, headers, data, image, orientation = "landscape") => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    // const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const rows = headers.map(header => header.label);

    const columns = data.map(row => {
        const arr = [];
        headers.forEach(header => {
            arr.push(row[header.key])
        });

        return arr;
    })

    let content = {
        startY: 50,
        head: [rows],
        body: columns
    };
    
    doc.text(title, 300, 40, {aligni:'center'});

    if(image!=null){
        const imageWidth = (doc.internal.pageSize.getWidth()*0.85);
        const imageHeight = imageWidth*0.65
        const startX = (doc.internal.pageSize.getWidth()-imageWidth)/2
        doc.addImage(image, ".jpeg", startX, 50, (imageWidth), imageHeight, "alias1", "SLOW")
        content.startY = imageHeight + 60
    }

    doc.autoTable(content);

    return doc;
}

export const isNumeric = (str) => {
    if (typeof str != "string") return false;

    return !isNaN(str) &&
        !isNaN(parseFloat(str))
}

export const toRGBValue = (value) => {
    return value / 255;
}

export const fromRGBValue = (value) => {
    return value * 255;
}

export const isOverlap = (firstStartDate, firstEndDate, secondStartDate, secondEndDate) => {
    if(moment(firstStartDate).isBefore(moment(secondStartDate)) && moment(firstEndDate).isAfter(moment(secondStartDate))) {
        return true;
    }
    
    if(moment(secondStartDate).isBefore(moment(firstStartDate)) && moment(secondEndDate).isAfter(moment(firstStartDate))){
        return true;
    }

    if(moment(firstStartDate).isAfter(moment(secondStartDate)) && moment(firstEndDate).isBefore(moment(secondEndDate))){
        return true;
    }

    if(moment(secondStartDate).isAfter(moment(firstStartDate)) && moment(secondEndDate).isBefore(moment(firstEndDate))){
        return true;
    }

    if(moment(firstStartDate).isSame(moment(secondStartDate)) || moment(firstStartDate).isSame(moment(secondEndDate))) {
        return true;
    }

    if(moment(firstEndDate).isSame(moment(secondStartDate)) || moment(firstEndDate).isSame(moment(secondEndDate))) {
        return true;
    }

    return false;
}

export function isWithinRadius(pinLat, pinLng, startLat, startLng, endLat, endLng, radiusKm) {
  const distanceToStart = calculateDistance(pinLat, pinLng, startLat, startLng);
  const distanceToEnd = calculateDistance(pinLat, pinLng, endLat, endLng);

  return distanceToStart <= radiusKm && distanceToEnd <= radiusKm;
}

export function calculateMiddlePoint(startLat, startLng, endLat, endLng) {
    // Convert degrees to radians
    const radLat1 = (Math.PI / 180) * startLat;
    const radLng1 = (Math.PI / 180) * startLng;
    const radLat2 = (Math.PI / 180) * endLat;
    const radLng2 = (Math.PI / 180) * endLng;
  
    // Calculate average latitude and longitude
    const avgLat = ((radLat1 + radLat2) / 2) * (180 / Math.PI);
    const avgLng = ((radLng1 + radLng2) / 2) * (180 / Math.PI);
  
    return { lat: avgLat, lng: avgLng };
  }

 export function calculateDistance(startLat, startLng, endLat, endLng) {
    const R = 6371; // Earth's radius in kilometers
  
    const dLat = toRadians(endLat - startLat);
    const dLng = toRadians(endLng - startLng);
  
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(startLat)) * Math.cos(toRadians(endLat)) *
      Math.sin(dLng / 2) * Math.sin(dLng / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = R * c * 1000; // Distance in meters
    return distance;
  }
  
  // Helper function to convert degrees to radians
  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }