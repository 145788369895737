import React, { Component } from 'react';
import RichDataTable from '../../components/Tables/RichDataTable';
import { db, storage } from '../../firebase';
import { AuthContext } from "../../shared/Auth";
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { getMessages } from '../../components/Messages/GetMessages';
import Loader from '../../components/Layout/Loader/Loader';
import { showToast, sortAlphabetically } from '../../shared/Util';
import { addCrewmember, getCrewmembers, getCrewSchedules, getJobs } from '../../shared/Api';
import CustomFilter from './CrewmemberFilters/CustomFilter';
import moment from 'moment';
import { normalizeInput } from '../../shared/FormHelper';
import { CrewmemberIos, ImportIcon, PlusIcon } from '../../components/Icons/Svg';
import { CSVLink } from 'react-csv';
import CSVReader from 'react-csv-reader';

class Crewmember extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    fields: [
      {
        display: 'First name',
        name: 'firstName'
      },
      {
        display: 'Last name',
        name: 'lastName'
      },
      {
        display: 'Email',
        name: 'email'
      },
      {
        display: 'Phone number',
        name: 'phoneNumber'
      },
      // {
      //   display: 'Skills',
      //   name: 'certifications'
      // },
      {
        display: 'Position',
        name: 'position'
      },

    ],
    data: null,
    deleteCrewModalActive: false,
    selectedCrewmemberId: null,
    importing: false,
    filtered: {
      byJobID: '',
      onJobToday: false,
      availableOn: '',
      availableToday: false
    },
    reservations: [],
    data: null,
    displayData: null,
    jobIDs: [],
    selectedJobID: "",
  };

  componentDidMount() {
    const updateJobIdBack = this.context.updateJobIdBack;
    updateJobIdBack(null);
    
    this.loadCrew();
    this.loadFilterData();
  }

  importCrewmembers = async (data, fileInfo) => {
    this.setState({ importing: true });
    const crewmembers = [];

    for (let index = 0; index < data.length; index++) {
      let element = data[index];

      if (!element.first_name) {
        const error = "First name is missing for item at row " + (index + 1) + ".";
        showToast("danger", error);
        this.setState({ importing: false });
        return;
      }

      if (!element.last_name) {
        const error = "Last name is missing for item at row " + (index + 1) + ".";
        showToast("danger", error);
        this.setState({ importing: false });
        return;
      }

      // if (!element.email) {
      //   const error = "Email is missing for item at row " + (index + 1) + ".";
      //   showToast("danger", error);
      //   this.setState({ importing: false });
      //   return;
      // }

      // if (!element.phone_number) {
      //   const error = "Phone number is missing for item at row " + (index + 1) + ".";
      //   showToast("danger", error);
      //   this.setState({ importing: false });
      //   return;
      // }

      const crewmember = {
        firstName: element.first_name,
        lastName: element.last_name,
        email: element.email??"",
        phoneNumber: element.phone_number??"",
        certifications: element.certifications,
        hasProfileImage: false,
        authorizationStatus: "neverInvited",
        position: element.position
      }

      crewmembers.push(crewmember);
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    await Promise.all(crewmembers.map(async (crewmember) => {
      const result = await addCrewmember(inventoryId, companyId, crewmember)
    }));

    await this.loadCrew();
    this.setState({ importing: false });


    if (crewmembers.length >= 1) {
      showToast("success", "Crewmember imported");
    }  else {
      showToast("errror", "Crewmember imported");

    }
  }

  componentWillUnmount = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    document.getElementById("skip-tutorial-button").style.display = "none";
  }

  showTutorialMessage = () => {
    document.getElementById("black-layer-container").style.display = "block";
    document.getElementById("add-crewmember-btn").style.zIndex = "1001";
    
    window.$('[data-toggle="tooltip"]').tooltip({ trigger: 'manual' });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    window.$('[data-toggle="tooltip"]').tooltip('show');

    document.getElementById("skip-tutorial-button").style.display = "block";
  }

  loadCrew = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    const crewmembers = await getCrewmembers(inventoryId, companyId, true, true);
    crewmembers.data.forEach(x => x.phoneNumber = normalizeInput(x.phoneNumber));
    crewmembers.data.sort((a, b) => {
      return (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0);
  });


    this.setState({ data: crewmembers.data, displayData: crewmembers.data });

    if(this.isTutorial) {
      this.showTutorialMessage();
    }    
  }

  resetFilters = () => {
    const filtered = {
      byJobID: '',
      onJobToday: false,
      availableOn: '',
      availableToday: false
    }
    
    this.setState({ filtered: filtered });
    const filteredData = this.filter(filtered);

    this.setState({displayData: filteredData}); 
  }

  deleteCrewmember = () => {
    const id = this.state.selectedCrewmemberId;
    if (!id)
      return;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    db.collection("inventories").doc(inventoryId)
      .collection('crew').doc(id).delete()
      .then(() => {
        const data = [...this.state.data];
        const newData = data.filter(function (obj) {
          return obj.id !== id;
        });

        this.setState({ data: newData });
      }).catch((error) => {

      });

    this.setState({ deleteCrewModalActive: false });
  }

  loadFilterData = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    const schedulesResult = await getCrewSchedules(inventoryId);
    const schedules = schedulesResult.data;

    const jobsResult = await getJobs(inventoryId);
    const jobs = jobsResult.data;
    const jobIds = jobs.map(x => ({ value: x.id, label: x.jobID }));
    
    sortAlphabetically(jobIds, "id");

    this.setState({ reservations: schedules, jobIDs: jobIds });
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const filterName = name;
    const filters = { ...this.state.filtered };

    if (type === "checkbox") {
      filters[filterName] = checked;
    } else {
      filters[filterName] = value;
    };

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  handleSelect = (event) => {
    if (!event) {
      const filters = { ...this.state.filtered };
      filters["byJobID"] = "";
      const filteredData = this.filter(filters);
      this.setState({ selectedJobID: null, filtered: filters, displayData: filteredData })
      return
    }

    const jobValue = event;
    this.setState({ selectedJobID: jobValue })

    const filters = { ...this.state.filtered };
    filters["byJobID"] = event.value;

    this.setState({ filtered: filters });
    const filteredData = this.filter(filters);
    this.setState({ displayData: filteredData });
  }

  filter = (filters) => {
    let tmpData = this.state.data;

    if (filters.availableToday) {
      let today = new Date().toDateString();
      tmpData = this.checkDateAvailability(today, tmpData);
    }

    if (filters.availableOn) {
      tmpData = this.checkDateAvailability(filters.availableOn, tmpData);
    }

    if (filters.onJobToday) {
      let today = new Date().toDateString();
      tmpData = this.checkJobToday(today, tmpData);
    }

    if (filters.byJobID) {
      tmpData = this.checkJobID(filters.byJobID, tmpData);
    }

    return tmpData;
  }

  checkDateAvailability = (date, data) => {
    let reservations = this.state.reservations;
    let reserved = [];

    reservations.forEach(reservation => {
      if (moment(date).isBetween(moment(reservation.startDate.toDate()).format("MM/DD/YY"), moment(reservation.endDate.toDate()).format("MM/DD/YY"), undefined, '[]')) {
        reserved.push(reservation)
      } else {
        return;
      }
    });

    let unavailableIDs = reserved.map(x => x.crewMemberID);
    let available = data.filter(item => !unavailableIDs.includes(item.id));

    return available;
  }

  checkJobToday = (today, data) => {
    let reservations = this.state.reservations;
    let reservedToday = [];

    reservations.forEach(reservation => {
      if ((moment(today).isBetween(moment(reservation.startDate.toDate()).format("MM/DD/YY"), moment(reservation.endDate.toDate()).format("MM/DD/YY"), undefined, '[]')) && reservation.type === 1) {
        reservedToday.push(reservation)
      } else {
        return;
      }
    })

    let reservedTodayIDs = reservedToday.map(x => x.crewMemberID);
    let onJob = data.filter(item => reservedTodayIDs.includes(item.id));

    return onJob;
  }

  checkJobID = (id, data) => {
    let reservations = this.state.reservations;
    let wantedReservations = [];

    reservations.forEach(reservation => {
      if (reservation.jobID === id) {
        wantedReservations.push(reservation)

      } else {
        return;
      }
    });

    let wantedReservationsIDs = wantedReservations.map(x => x.crewMemberID)
    let wanted = data.filter(item => wantedReservationsIDs.includes(item.id))

    return wanted;
  }

  crewmemberFilters = () => {
    return [
      {
        name: 'availableToday',
        type: 'checkbox',
        display: 'Available Today',
        value: this.state.filtered.availableToday,
        onChange: this.handleChange
      },
      {
        name: 'availableOn',
        type: 'datepicker',
        display: 'Available On',
        value: this.state.filtered.availableOn,
        onChange: this.handleChange
      },
      {
        name: 'onJobToday',
        type: 'checkbox',
        display: 'On Job Today',
        value: this.state.filtered.onJobToday,
        onChange: this.handleChange
      },
      {
        name: 'byJobID',
        type: 'text',
        display: 'By Job ID',
        value: this.state.filtered.byJobID,
        onChange: this.handleSelect
      }
    ];
  }

  handleImportClick = () => {
    document.getElementById("import-button").click();
  }

  render() {
    if (!this.state.data) {
      return <Loader />
    }
    if(this.state.data.length === 0) {
      return (
        <div className="d-flex align-items-center mb-10">
          <div className="d-flex flex-column flex-grow-1 font-weight-bold align-items-center">
              <div className="mainpage-graphic">
                <CrewmemberIos width={'200px'} height={'200px'} opacity={'0.3'}/>
                </div>
              <span className="font-italic">You don't have any crewmembers at the moment. You can start adding by clicking on one of the buttons below.</span>&nbsp;
              {this.user.permissions === 'crewmember' ? null 
              : 
              <div className="card-toolbar">
                <div className="dropdown dropdown-inline mr-2">
                  <button type="button" className={this.state.importing ? "btn btn-light-primary font-weight-bolder dropdown-toggle spinner spinner-white spinner-right"
                  : "btn btn-light-primary font-weight-bolder dropdown-toggle"} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span className="svg-icon svg-icon-md">
                      <ImportIcon />
                    </span>Import</button>
                  <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <ul className="navi flex-column navi-hover py-2">
                      <li className="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2">Choose an option:</li>
                        <li className="navi-item">
                          <a role="button" onClick={this.handleImportClick} className="navi-link">
                            <span className="navi-icon">
                              <i className="la la-file-import" />
                            </span>
                            <span className="navi-text">Import</span>
                          </a>
                        </li>
                        <li className="navi-item">
                          <CSVLink
                            filename="import-template-csv.csv"
                            data={[]}
                            headers={[
                              { label: "First Name", key: "firstName" },
                              { label: "Last Name", key: "lastName" },
                              { label: "Email", key: "email" },
                              { label: "Phone Number", key: "phoneNumber" },
                              { label: "Certifications", key: "certifications" },
                              { label: "Position", key: "position" }
                            ]}
                            className="navi-link">
                            <span className="navi-icon">
                              <i className="la la-file-excel-o" />
                            </span>
                            <span className="navi-text">Template</span>
                          </CSVLink>
                        </li>
                    </ul>
                  </div>
                </div>
                &nbsp;
                <a id="add-crewmember-btn" onClick={() => { this.props.history.push('/crewmembers/new')}} className="btn btn-primary font-weight-bolder">
                  <span className="svg-icon svg-icon-md">
                    <PlusIcon />
                  </span>Add crewmember
                </a>
              </div>}
              <CSVReader
                  inputStyle={{ display: 'none' }}
                  onFileLoaded={(data, fileInfo) => { this.importCrewmembers(data, fileInfo); }}
                  onError={(err) => { console.log(err); }}
                  inputId="import-button"
                  /* ref={importButtonRef} */
                  parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                    transformHeader: header =>
                      header
                        .toLowerCase()
                        .replace(/\W/g, '_')
                  }}
                />
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <RichDataTable
              title="Crewmembers"
              newRecord={this.user.permissions === 'crewmember' ? null : {
                title: 'Add crewmember',
                onClick: () => { 
                  if(this.isTutorial) {
                    window.$('[data-toggle="tooltip"]').tooltip('hide');
                    const nextTutorialStep = this.context.nextTutorialStep;
                    nextTutorialStep();
                    document.getElementById("black-layer-container").style.display = "none";
                  }
                  this.props.history.push('/crewmembers/new');
                },
                id: "add-crewmember-btn",
                dataToggle: "tooltip",
                toggleTitle: "Let's Add in the People that Get the Job Done!"
              }}
              export={{
                csv: {
                  filename: 'crewmember-list.csv',
                  headers: [
                    { label: "First Name", key: "firstName" },
                    { label: "Last Name", key: "lastName" },
                    { label: "Email", key: "email" },
                    { label: "Phone Number", key: "phoneNumber" },
                    { label: "Certifications", key: "certifications" },
                    { label: "Position", key: "position" }
                  ],
                  data: this.state.data
                },
                pdf: {
                  filename: 'crewmember-list.pdf',
                  title: 'Crewmemnber list',
                  headers: [
                    { label: "First Name", key: "firstName" },
                    { label: "Last Name", key: "lastName" },
                    { label: "Email", key: "email" },
                    { label: "Phone Number", key: "phoneNumber" },
                    { label: "Certifications", key: "certifications" },
                    { label: "Position", key: "position" }
                  ],
                  data: this.state.data
                },
                print: true
              }}
              import={{
                onImport: this.importCrewmembers,
                importing: this.state.importing
              }}
              fields={this.state.fields}
              data={this.state.displayData}
              actions={{
                delete: {
                  enabled: false,
                  onClick: (id) => {
                    this.setState({ selectedCrewmemberId: id, deleteCrewModalActive: true })
                  }
                },
                edit: {
                  enabled: this.user.permissions !== 'crewmember',
                  onClick: (id) => {
                    this.props.history.push('/crewmembers/' + id + '/edit');
                  }
                },
                detail: {
                  enabled: true,
                  onClick: (id) => {
                    this.props.history.push('/crewmembers/' + id);
                  }
                }
              }}
              customFiltered={true}
              customFilter={<CustomFilter
                filters={this.crewmemberFilters()}
                ids={this.state.jobIDs}
                selectedJobID={this.state.selectedJobID} />}
              onHideFilters={() => this.resetFilters()}
              showExportButton={true}
            />
          </div>
        </div>
        <ConfirmModal
          show={this.state.deleteCrewModalActive}
          title="Delete crewmember"
          body={getMessages('delete_crewmember')}
          yesButton={{
            title: "Yes",
            onClick: () => { this.deleteCrewmember(); }
          }}
          noButton={{
            title: "Cancel",
            onClick: () => { this.setState({ deleteCrewModalActive: false }) }
          }}
        />
      </React.Fragment>
    );
  }
}

export default Crewmember;