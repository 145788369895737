import React, { Component } from 'react';

import app, { db } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';

import { Link } from 'react-router-dom';
import * as moment from 'moment';
import $ from 'jquery';
import { storage, functions } from '../../firebase';
import { showToast } from '../../shared/Util';

import { getCrewSchedulesForCrew, getJobs, getCrewmember, updateCrewmember, inviteExists, addInvite, getUserByCrewmemberId } from '../../shared/Api';
import { Edit, Trash, MailOpened } from '../../components/Icons/Svg';
import { getMessages } from '../../components/Messages/GetMessages';

import { fromRGBValue } from '../../shared/Util';
import { normalizeInput } from '../../shared/FormHelper';

class CrewmemberDetails extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;
  isTutorial = this.context.isTutorial;
  tutorialStep = this.context.tutorialStep;

  state = {
    crewmember: null,
    deleteCrewModalActive: false,
    schedules: [],
    view: 'month',
    calendar: null,
    sendingInvite: false,
    sendInviteError: "",
    id: null,
    isOwner: false
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);
    this.context.updateBackButtonPoint(this.props.location.pathname);

    let id = this.props.match.params.id;
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const companyId = currentUser.userProfile.companyID;

    if(id === 'new') {
      id = localStorage.getItem('tmp-crew-id');
    }

    const getCrewmemberResult = await getCrewmember(inventoryId, id, companyId, true, true);
    const user = await getUserByCrewmemberId(getCrewmemberResult.data.id)

    this.setState({ crewmember: getCrewmemberResult.data, id: id, isOwner: (user?.data?.permissions==='owner'?true:false) });
    this.loadSchedules();

    if(this.isTutorial) {
      document.getElementById("skip-tutorial-button").style.display = "block";
    }
    
    if(this.isTutorial && this.tutorialStep === 'showReport') {
      setTimeout(() => {
        this.showTutorialMessage();
      }, 1000)
    }
  }

  deleteCrewmember = async () => {
    const id = this.state.crewmember.id;

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    if(this.state.crewmember.authorizationStatus === 'authorized') {
      const user = await getUserByCrewmemberId(id);
      
      db.collection("users").doc(user.data.id)
      .delete()
      .then(() => {
        db.collection("inventories").doc(inventoryId)
          .collection('crew').doc(id).delete()
          .then(() => {
            var removeAuthentication = functions.httpsCallable('removeAuthUser');

            removeAuthentication({ userId: user.data.id }).then((result) => {
              var updateQuantityFunction = functions.httpsCallable('api/updateSubscriptionQuantity');
              updateQuantityFunction({ recurlyAccountCode: this.user.recurlyAccountCode, increment: -1 }).then((result) => {
                this.props.history.push('/crewmembers')
              }).catch((error) => {
                console.log(error);
              });
            }).catch((error) => {
                console.log(error);
            });
            
          }).catch((error) => {

          });
      }).catch((error) => {

      });
    } else {
      db.collection("inventories").doc(inventoryId)
      .collection('crew').doc(id).delete()
      .then(() => {
        this.props.history.push('/crewmembers')
      }).catch((error) => {

      });
    }

    this.setState({ deleteCrewModalActive: false });
  }

  getClass = (type) => {
    return "primary";
  }

  loadSchedules = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.state.id;

    const reservations = await getCrewSchedulesForCrew(inventoryId, id);
    const jobs = await getJobs(inventoryId);
    let schedules = reservations.data;

    schedules.forEach(schedule => {
      schedule.job = jobs.data.find(x => x.id === schedule.jobID);
      if(schedule.job) {
        schedule.title = schedule.job.jobID;
        schedule.description = schedule.job.description;
      }
    });

    schedules = schedules.filter(x => x.job);

    this.setState({ schedules: schedules.sort((a, b) => (a.startDate > b.startDate) ? -1 : 1) });
    this.drawCalendar(schedules);
  }

  showTutorialMessage = () => {
    // document.getElementById("black-layer-container").style.display = "block";
    // document.getElementById("add-crewmember-btn").style.zIndex = "1001";
    
    window.$('[data-toggle="tooltip"]').tooltip({ trigger: 'manual' });
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    window.$('[data-toggle="tooltip"]').tooltip('show');
  }

  drawCalendar = (schedules) => {
    if (schedules.length <= 0)
      return;

    const self = this;

    var todayDate = moment().startOf('day');
    var YM = todayDate.format('YYYY-MM');
    var YESTERDAY = todayDate.clone().subtract(1, 'day').format('YYYY-MM-DD');
    var TODAY = todayDate.format('YYYY-MM-DD');
    var TOMORROW = todayDate.clone().add(1, 'day').format('YYYY-MM-DD');

    var calendarEl = document.getElementById('kt_calendar');
    var calendar = new window.FullCalendar.Calendar(calendarEl, {
      plugins: ['bootstrap', 'interaction', 'dayGrid', 'basicWeek', 'list'],
      themeSystem: 'bootstrap',

      //isRTL: KTUtil.isRTL(),

      header: {
        left: 'prev,next today',
        center: 'title'
      },

      height: 800,
      contentHeight: 780,
      aspectRatio: 3,  // see: https://fullcalendar.io/docs/aspectRatio

      nowIndicator: true,
      now: TODAY,

      views: {
        dayGridMonth: { buttonText: 'month' },
        dayGridWeek: { buttonText: 'week' }
      },

      defaultView: 'dayGridMonth',
      defaultDate: TODAY,

      editable: true,
      eventLimit: true, // allow "more" link when too many events
      navLinks: true,
      eventColor: '#378006',
      events: schedules.map(x => {
        return {
          id: x.id,
          title: x.title,
          description: x.description,
          jobId: x.jobID,
          start: x.startDate.toDate(),
          end: x.endDate.toDate(),
          className: "fc-event-" + this.getClass(x.type)
        };
      }),

      eventRender: function (info) {
        var element = $(info.el);

        if (info.event.extendedProps && info.event.extendedProps.description) {
          if (element.hasClass('fc-day-grid-event')) {
            element.data('content', info.event.extendedProps.description);
            element.data('placement', 'top');
            //window.KTApp.initPopover(element);
          } else if (element.hasClass('fc-time-grid-event')) {
            element.find('.fc-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
          } else if (element.find('.fc-list-item-title').length !== 0) {
            element.find('.fc-list-item-title').append('<div class="fc-description">' + info.event.extendedProps.description + '</div>');
          }
        }

        element.css('background-color', '#C9F7F5');
      },
      eventClick: function (info) {
        self.props.history.push("/jobs/" + info.event.extendedProps.jobId + '/details');
      }
    });

    this.setState({ calendar: calendar });
    try {
      calendar.render();
    } catch(err) {}
  }

  goTo = (view) => {
    switch (view) {
      case 'list':
        this.setState({ view: 'list' });
        break;
      case 'month':
        this.setState({ view: 'month' });
        this.state.calendar.changeView("dayGridMonth");
        break;
      case 'week':
        this.setState({ view: 'week' });
        this.state.calendar.changeView("dayGridWeek");
        break;
    }
  }

  getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  getInviteCode = async () => {
    let inviteEx = true;
    let inviteCode = null;

    while(inviteEx) {
      inviteCode = this.getRandomInt(100000000, 999999999);
      inviteEx = await inviteExists(inviteCode);
    }
    
    return inviteCode;
  }

  sendInvite = async () => {
    if(this.isTutorial) {
      window.$('[data-toggle="tooltip"]').tooltip('hide');
    }

    if(!this.state.crewmember.email){
      this.setState({sendInvite:false, sendInviteError:"Crewmember is missing an email. Invite cannot be sent!"})
      return
    }
    
    const currentUser = this.context.currentUser;
    const companyId = currentUser.userProfile.companyID;
    const inventoryId = currentUser.company.inventoryID;
    const id = this.state.id;

    this.setState({ sendingInvite: true });

    const inviteCode = await this.getInviteCode();
    const invite = {
      companyID: companyId,
      invitedUserID: id,
      invitedUserEmail: this.state.crewmember.email,
      inviteCode: inviteCode
    };

    await addInvite(invite);

    const deepLink = process.env.REACT_APP_INVITE_URL +  "?link=" + process.env.REACT_APP_INVITE_RETURN_URL + '?companyID=' + encodeURIComponent(companyId)
      + encodeURIComponent("&") + 'invitedUserID=' + encodeURIComponent(id) + encodeURIComponent("&") + 'invitedUserEmail=' + encodeURIComponent(this.state.crewmember.email)
      + encodeURIComponent("&") + 'inviteCode=' + encodeURIComponent(inviteCode);

    let inviteUserFunction = functions.httpsCallable("inviteCrewmember");
    let result = await inviteUserFunction({ firstName: this.state.crewmember.firstName, 
      email: this.state.crewmember.email, link: deepLink, inviteCode: inviteCode });

    if(result.data.success && this.state.crewmember.authorizationStatus !== 'invited') {
      updateCrewmember(inventoryId, id, { authorizationStatus: 'invited' });
      const crewmember = {...this.state.crewmember};
      crewmember.authorizationStatus = 'invited';
      this.setState({ crewmember: crewmember });
    }

    showToast('success', 'Invite has been sent successfully.');
    this.setState({ sendingInvite: false });

    if(this.isTutorial) {
      const setTutorialStep = this.context.setTutorialStep;
      setTutorialStep("addJob");
      this.props.history.push('/jobs');
    }
  }

  componentWillUnmount = () => {
    window.$('[data-toggle="tooltip"]').tooltip('hide');
    document.getElementById("skip-tutorial-button").style.display = "none";
  }

  render() {
    if (this.state.crewmember) {
      return (
        <React.Fragment>
          <div className="card card-custom">
            {/*begin::Header*/}
            <div className="card-header h-auto py-4">
              <div className="card-title">
                <h3 className="card-label">Crewmember details
                 <span className="d-block text-muted pt-2 font-size-sm">{this.state.crewmember.firstName} {this.state.crewmember.lastName}</span></h3>
              </div>
              <div className="card-toolbar">
                {(this.user.permissions !== 'crewmember' && !this.state.isOwner) &&
                  <>
                    {
                      (this.state.crewmember.authorizationStatus === 'neverInvited' 
                      || this.state.crewmember.authorizationStatus === 'accessRevoked') &&
                      <a onClick={this.sendInvite} className={this.state.sendingInvite ? "btn btn-light-secondary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                      : "btn btn-light-secondary btn-sm font-weight-bold mr-4"} data-toggle="tooltip" title="Give your crewmember access to all the operational info needed by clicking here">
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <MailOpened />
                        </span>Send Invite
                      </a>
                    }
                    {
                      this.state.crewmember.authorizationStatus === 'invited' &&
                      <a onClick={this.sendInvite} className={this.state.sendingInvite ? "btn btn-light-secondary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right"
                      : "btn btn-light-secondary btn-sm font-weight-bold mr-4"}>
                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                          <MailOpened />
                        </span>Resend Invite
                      </a>
                    }
                    <a onClick={() => { this.props.history.push('/crewmembers/' + this.state.crewmember.id + '/edit') }} className="btn btn-success btn-sm font-weight-bold mr-4">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Edit />
                      </span>Edit</a>
                    <a onClick={() => { this.setState({ deleteCrewModalActive: true }) }} className="btn btn-danger btn-sm font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Trash />
                      </span>Delete</a>
                  </>}
              </div>
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-4">
              {this.state.crewmember.image ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label"></label>
                  <div className="col-8">
                    <div className="image-input image-input-outline" id="kt_profile_avatar" style={{ backgroundImage: 'url(assets/media/users/blank.png)' }}>
                      <div className="image-input-wrapper" style={{ backgroundImage: 'url(' + this.state.crewmember.image + ')', backgroundSize: 'contain, cover', backgroundPosition: 'center', width: '160px', height: '160px' }} />
                    </div>
                  </div>
                </div> : null}
                
                {this.state.sendInviteError ? 
                <div className="form-group row my-2">
                  <label className="col-10 col-form-label text-danger">{this.state.sendInviteError}</label>
                </div> : null}

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">First name:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{this.state.crewmember.firstName}</span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Last name:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{this.state.crewmember.lastName}</span>
                  </div>
                </div>

                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Email:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext">
                      <span className="font-weight-bolder">{this.state.crewmember.email}</span>
                    </span>
                  </div>
                </div>
              
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Phone number:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{normalizeInput(this.state.crewmember.phoneNumber, '')}</span>
                  </div>
                </div>
              
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Skills:</label>
                  <div className="col-8">
                    <span style={{ whiteSpace: "pre-line" }} className="form-control-plaintext font-weight-bolder">{this.state.crewmember.certifications}</span>
                  </div>
                </div>
                {this.state.crewmember.position ?
              <div className="form-group row my-2">
              <label className="col-4 col-form-label">Position:</label>
              <div className="col-8">
                <span style={{ whiteSpace: "pre-line" }} className="form-control-plaintext font-weight-bolder">{this.state.crewmember.position}</span>
              </div>
            </div> : null 
              }
               {this.state.crewmember.otherInfo ?
              <div className="form-group row my-2">
              <label className="col-4 col-form-label">Other info:</label>
              <div className="col-8">
                <span style={{ whiteSpace: "pre-line" }} className="form-control-plaintext font-weight-bolder">{this.state.crewmember.otherInfo}</span>
              </div>
            </div> : null 
              }
                {this.state.crewmember.crew ?
                <div className="form-group row my-2">
                  <label className="col-4 col-form-label">Crew:</label>
                  <div className="col-8">
                    <span className="form-control-plaintext font-weight-bolder">{this.state.crewmember.crew.crewName} &nbsp;&nbsp;
                    <span className="dot crew-dot" 
                    style={{ backgroundColor: "rgba(" + fromRGBValue(this.state.crewmember.crew.red) + ", " + fromRGBValue(this.state.crewmember.crew.green) + ", " + fromRGBValue(this.state.crewmember.crew.blue) + ", " + this.state.crewmember.crew.alpha + ")" }}></span>
                    </span>
                  </div>
                </div> : null}
            </div>
            <div className="card-footer">
            </div>
          </div>
          <div className="card card-custom mt-10">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Crewmember schedule</h3>
              </div>
              <div className="card-toolbar">
                {this.state.schedules.length > 0 &&
                  <>
                    <a onClick={() => this.goTo("month")} className="btn btn-icon btn-light-secondary mr-2">
                      <i className="flaticon2-calendar-2"></i>
                    </a>
                    <a onClick={() => this.goTo("week")} className="btn btn-icon btn-light-secondary mr-2">
                      <i className="flaticon2-calendar-1"></i>
                    </a>
                    <a onClick={() => this.goTo("list")} className="btn btn-icon btn-light-secondary mr-2">
                      <i className="flaticon-list-2"></i>
                    </a>
                  </>
                }

              </div>
            </div>
            <div className="card-body">
              {this.state.schedules.length > 0 ?
                <>
                  <div className="card-body">
                    <div style={{ display: this.state.view !== 'list' ? 'none' : 'block' }}>
                      <div className="timeline timeline-5">
                        <div className="timeline-items">
                          {this.state.schedules.map(schedule => (
                            <Link to={"/jobs/" + schedule.jobID + '/details'} className="timeline-item">
                              <div className="timeline-media bg-light-primary">
                                <span className={"svg-icon svg-icon-" + this.getClass(schedule.type) + " svg-icon-md"}>
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                      <rect x="0" y="0" width="24" height="24" />
                                      <path d="M15.9956071,6 L9,6 C7.34314575,6 6,7.34314575 6,9 L6,15.9956071 C4.70185442,15.9316381 4,15.1706419 4,13.8181818 L4,6.18181818 C4,4.76751186 4.76751186,4 6.18181818,4 L13.8181818,4 C15.1706419,4 15.9316381,4.70185442 15.9956071,6 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                      <path d="M10.1818182,8 L17.8181818,8 C19.2324881,8 20,8.76751186 20,10.1818182 L20,17.8181818 C20,19.2324881 19.2324881,20 17.8181818,20 L10.1818182,20 C8.76751186,20 8,19.2324881 8,17.8181818 L8,10.1818182 C8,8.76751186 8.76751186,8 10.1818182,8 Z" fill="#000000" />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                              <div className="timeline-desc timeline-desc-light-primary">
                                <span className="font-weight-bolder text-secondary">{moment(schedule.startDate.toDate()).format("MMM DD, YYYY")}</span><br />
                                <span className="font-weight-bolder text-secondary">{schedule.title} <span className="font-weight-normal text-dark-50 pb-2">
                                  ({moment(schedule.startDate.toDate()).format("MMM DD, YYYY") + " - " + moment(schedule.endDate.toDate()).format("MMM DD, YYYY")})</span></span>
                                <p className="font-weight-normal text-dark-50 pb-2">
                                  {schedule.description}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: this.state.view === 'list' ? 'none' : 'block' }} id="kt_calendar" />
                  </div>
                  <div style={{ display: this.state.view === 'list' ? 'none' : 'block' }} id="kt_calendar" />
                </> :
                <span className="font-italic">(No items)</span>}
            </div>
          </div>
          <ConfirmModal
            show={this.state.deleteCrewModalActive}
            title="Delete crewmember"
            body={getMessages('delete_crewmember')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteCrewmember(); }
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteCrewModalActive: false }) }
            }}
          />
        </React.Fragment>
      );
    } else {
      return <Loader />
    }
  }
}

export default CrewmemberDetails;