import React, { useContext, useEffect, useState } from "react";
import {
  LocateTicketEntity,
  LocateTicketStatusEntity,
  buildLocateTicketFileEntityPath,
  buildLocateTicketFilesPath,
  getLocateTicketCurrentStatus,
} from "../../shared/data/LocateTickets";
import EQMarker from "../../components/GoogleMap/EQMarker";
import GoogleMapReact from "google-map-react";
import { parseFromFirebaseDate, showToast, toFirebaseDate } from "../../shared/Util";
import { Link, useHistory } from "react-router-dom";
import ConfirmModal from "../../components/Modals/ConfirmModal";
import {
  deleteLocateTicket,
  getLocateTicket,
  saveLocateTicket,
} from "../../shared/api/LocateTicketsApi";
import LocateTicketStatusModal from "./LocateTicketStatusModal";
import MultipleItems from "../../components/Carousels/MultipleItems";
import Viewer from "react-viewer";
import { Timestamp } from "firebase/firestore";
import { capitalizeFirstLetter } from "../../shared/Utils";
import { JobEntity } from "../../shared/data/job/Job";
import { getFileData } from "../../shared/api/FilesApi";
import { ContextEntity } from "../../shared/data/context/ContextEntity";
import { AuthContext } from "../../shared/Auth";
import { FileEntity } from "../../shared/data/folder/FolderStructure";
import moment from "moment";

export const LocateTicketDetailsItem = ({
  title = "",
  primaryText = "",
  secondaryText = "",
}) => {
  return (
    <div className="d-flex flex-column flex-sm-wrap col-stretch my-4">
      <div className="font-weight-boldest">{title}</div>
      <div>{primaryText}</div>
      <div>{secondaryText}</div>
    </div>
  );
};

interface LocateTicketDetailsProps {
  locateTicket: LocateTicketEntity;
  job?: JobEntity | null;
  jobs?: JobEntity[] | null;
}

const LocateTicketDetails = ({
  locateTicket,
  job = null,
  jobs = null
}: LocateTicketDetailsProps) => {
  const history = useHistory();
  const context: ContextEntity = useContext(AuthContext);

  const [formState, setFormState] = useState<any>();
  const [locateTicketState, setLocateTicketState] =
    useState<LocateTicketEntity>(locateTicket);

  const [locateTicketFiles, setLocateTicketFiles] = useState<FileEntity[]>([]);

  useEffect(() => {
    fetchData();
  }, [locateTicket, formState?.showChangeStatusModal??false]);

  const fetchData = async () => {
    const result = await getLocateTicket(locateTicket.id ?? "");
    if (result.data) setLocateTicketState(result.data);
    loadImages();
  };


  const getLocateTicketJob = () =>{
    return jobs?.find((job)=> job.id==locateTicketState.jobID) ?? job
  }

  const loadImages = async () => {
    if (locateTicketState != null && locateTicketState.id) {
      setLocateTicketFiles(() => []);
      const locateTicketFiles = await getFileData(
        buildLocateTicketFileEntityPath(locateTicketState.id),
        buildLocateTicketFilesPath(context.currentUser?.userProfile?.companyID??"", locateTicketState.id??"")
      );
      setLocateTicketFiles(() => locateTicketFiles.data ?? []);
    }
  };

  const deleteData = async () => {
    const result = await deleteLocateTicket(locateTicketState.id ?? "");
    if (result.data) history.goBack();
    else showToast("danger", result.message);
  };

  const changeStatus = async (
    status?: LocateTicketStatusEntity | null,
    revisionNumber?: string | null,
    expirationDate?: Timestamp | null
  ) => {
    if (status) {
      const locateTicket = { ...locateTicketState };
      locateTicket.status?.push(status);
      locateTicket.revisionNo = revisionNumber;
      locateTicket.expirationDate = expirationDate;

      const result = await saveLocateTicket(locateTicket);
      if (result.data) setLocateTicketState(result.data);
      else showToast("danger", result.message);
    }
  };

  const getUrlFileName = (url: string) => {
    const regExp = new RegExp(".+(\\/|%2F)(.+)\\?.+");
    const match = regExp.exec(url);
    if (match) {
      return match[2];
    }
    return "Unknown";
  };

  const getImagesForViewer = () => {
    return locateTicketFiles.map((file)=>{
      return {src: file.url??""}
    })
  };

  return (
    <React.Fragment>
      <div className="card card-custom p-6">
        <div className="card-header flex-wrap p-0">
          <div className="card-title">
            <h3 className="card-label">
              Locate Ticket Details
              <span className="d-block text-muted font-size-sm"></span>
            </h3>
          </div>
          <div className="card-toolbar">
            <div>
              <div
                className="btn btn-light-danger font-weight-bolder ml-2 float-right"
                onClick={() => {
                  setFormState((previousState: any) => {
                    return { ...previousState, showDeleteModal: true };
                  });
                }}
              >
                Delete
              </div>
              <Link
                className="btn btn-primary font-weight-bolder ml-2 float-right"
                to={{
                  pathname:
                    jobs != null
                      ? "/locatetickets/new"
                      : `/jobs/${locateTicketState.jobID}/locatetickets/new`,
                  state: {
                    locateTicket: locateTicketState,
                    jobs: jobs,
                  },
                }}
                href="#"
              >
                Edit
              </Link>
              <button
                className="btn btn-primary font-weight-bolder ml-2 float-right"
                disabled={
                  getLocateTicketCurrentStatus(locateTicketState)?.type ==
                  "approved"
                }
                onClick={() => {
                  setFormState((previousState: any) => {
                    return { ...previousState, showChangeStatusModal: true };
                  });
                }}
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
        <div style={{ height: "25vh", width: "100%" }}>
          <GoogleMapReact
            options={{
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
              },
              mapTypeId: "satellite",
            }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? "",
            }}
            defaultZoom={14}
            defaultCenter={{
              lat: locateTicketState?.lat ?? 0.0,
              lng: locateTicketState?.lng ?? 0.0,
            }}
            center={{
              lat: locateTicketState?.lat ?? 0.0,
              lng: locateTicketState?.lng ?? 0.0,
            }}
          >
            <EQMarker
              key={1}
              lat={locateTicketState?.lat ?? 0.0}
              lng={locateTicketState?.lng ?? 0.0}
              pinColor={"darkgreen"}
            />
          </GoogleMapReact>
        </div>
        <div className="card mb-6 mt-6" key="locate-ticket-details-container">
          <div
            className="mt-2 p-5"
            key="locate-ticket-details-container-fields"
          >
            <LocateTicketDetailsItem
              title="Locate Ticket ID"
              primaryText={locateTicketState?.ticketID ?? ""}
            />
            {getLocateTicketJob() && (
              <Link
                className="navi-link"
                to={{
                  pathname: `/jobs/${getLocateTicketJob()?.id}/details`,
                }}
                href="#"
              >
                <LocateTicketDetailsItem
                  title="Job details"
                  primaryText={`${getLocateTicketJob()?.jobID}(${
                    getLocateTicketJob()?.title
                  })`}
                />
              </Link>
            )}
            {locateTicketState?.revisionNo && (
              <LocateTicketDetailsItem
                title="Revision Number"
                primaryText={locateTicketState?.revisionNo ?? ""}
              />
            )}
            <LocateTicketDetailsItem
              title="Work Begin Date"
              primaryText={
                parseFromFirebaseDate(locateTicketState.startDate) ?? ""
              }
            />
            {getLocateTicketCurrentStatus(locateTicketState)?.type ==
              "approved" && (
              <LocateTicketDetailsItem
                title="Expiration date"
                primaryText={
                  parseFromFirebaseDate(locateTicketState.expirationDate) ?? ""
                }
              />
            )}
            {/* <EQEditableField
              title={"Expiration Date"}
              primaryText={
                parseFromFirebaseDate(locateTicketState.expirationDate) ?? ""
              }
              secondaryText={""}
              isEditable={
                getLocateTicketCurrentStatus(locateTicketState)?.type ==
                "approved"
              }
              onValueChanged={(value) => {
                const date = toFirebaseDate(value) ?? locateTicketState.expirationDate
                setLocateTicketState((previousState) => {
                  return {...previousState, expirationDate:date}
                })
              }}
            /> */}
            <LocateTicketDetailsItem
              title="Location"
              primaryText={`${locateTicketState.lat ?? ""}, ${
                locateTicketState.lng ?? ""
              }`}
              secondaryText={locateTicketState?.address ?? ""}
            />
            <LocateTicketDetailsItem
              title="Notes"
              primaryText={locateTicketState?.notes ?? ""}
            />

            <div className="my-4">
              <MultipleItems
                slidesToShow={9}
                laptop={7}
                tablet={5}
                mobile={2}
                removePhoto={false}
                images={locateTicketFiles?.map((locateTicketFile) => {
                  return {
                    image: locateTicketFile.url,
                    title: locateTicketFile.fileName,
                    name: locateTicketFile.name,
                    createdAt: moment(
                      locateTicketFile.createdAt?.toDate()
                    ).format("MM/DD/YY, hh:mma"),
                    uploadedBy: locateTicketFile.createdBy
                  };
                })}
                setSelectedImage={(index: number) => {
                  const selectedFile = locateTicketFiles[index];
                  if (getUrlFileName(selectedFile.url??"").endsWith("med")) {
                    setFormState((previousState: any) => {
                      return { ...previousState, showImageViewer: true };
                    });
                  } else {
                    window.open(
                      selectedFile.url??"",
                      "_blank",
                      "noopener,noreferrer"
                    );
                  }
                }}
              />
            </div>
            <Viewer
              images={getImagesForViewer()}
              visible={formState?.showImageViewer ?? false}
              onClose={() => {
                setFormState((previousState: any) => {
                  return { ...previousState, showImageViewer: false };
                });
              }}
              activeIndex={0}
              onChange={(image, index) => {}}
            />
          </div>
        </div>
        <div className="m-2">
          <div className="d-flex flex-row mb-6 align-items-center">
            <h4 className="font-weight-bold flex-grow-1">Status History</h4>
          </div>
          {locateTicketState?.status
            ?.sort((a, b) =>
              (a.createdAt ?? Timestamp.now()) >
              (b.createdAt ?? Timestamp.now())
                ? -1
                : 1
            )
            ?.map((status) => {
              return (
                <LocateTicketDetailsItem
                  title={`Status: ${capitalizeFirstLetter(status.type ?? "")}`}
                  primaryText={parseFromFirebaseDate(status.date)}
                  secondaryText={`Created at: ${parseFromFirebaseDate(
                    status.createdAt
                  )} by ${status.userName}`}
                />
              );
            })}
        </div>
        <ConfirmModal
          id="locate-ticket-details"
          show={formState?.showDeleteModal ?? false}
          title="Delete Locate Ticket"
          body={`Are you sure you want to delete locate ticket ${locateTicketState.ticketID}?`}
          yesButton={{
            title: "Yes",
            onClick: () => {
              setFormState((previousState: any) => {
                deleteData();
                return { ...previousState, showDeleteModal: false };
              });
            },
          }}
          noButton={{
            title: "Cancel",
            onClick: () => {
              setFormState((previousState: any) => {
                return { ...previousState, showDeleteModal: false };
              });
            },
          }}
        />{" "}
      </div>
      <LocateTicketStatusModal
        formId={`locate-ticket-item-delete-${locateTicketState.id}`}
        show={formState?.showChangeStatusModal ?? false}
        locateTicketStatus={getLocateTicketCurrentStatus(locateTicketState)}
        revisionNumber={locateTicketState.revisionNo}
        onConfirm={(status, revisionNumber, expirationDate) => {
          changeStatus(status, revisionNumber, expirationDate);
          setFormState((previousState: any) => {
            return { ...previousState, showChangeStatusModal: false };
          });
        }}
        onCancel={() => {
          setFormState((previousState: any) => {
            return { ...previousState, showChangeStatusModal: false };
          });
        }}
      />
    </React.Fragment>
  );
};

export default LocateTicketDetails;
