import { Component } from 'react';
import { functions } from '../../../firebase';
import { Link } from 'react-router-dom';
import { AuthContext } from "../../../shared/Auth";
import ConfirmModal from '../../../components/Modals/ConfirmModal';
import InfoModal from '../../../components/Modals/InfoModal';
import $ from 'jquery';
import HelpMeGetStartedModal from '../../../components/Modals/HelpMeGetStartedModal';
import ReactPixel from 'react-facebook-pixel';


class WelcomeScreen extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      showQuestionModal: false,
      showNotifyModal: false,
      nofitying: false,
      showHelpMeModal: false,
    };
  }

  componentDidMount() {
    this.setState({ showQuestionModal: true });
    ReactPixel.pageView();
  }

  enterDataNotify = async () => {
    this.setState({ nofitying: true });
    var nofityForDataEntry = functions.httpsCallable("initialData");
    await nofityForDataEntry({ userID: this.context.currentUser.uid });
    this.setState({
      showNotifyModal: true,
      nofitying: false,
      showQuestionModal: false,
    });
  };

  // workaround
  clearModals = () => {
    $("body").removeClass("modal-open");
    $(".modal-backdrop").remove();
  };

  goToAccount = (event) => {
    event.preventDefault();

    const setShowWelcomeScreen = this.context.setShowWelcomeScreen;
    setShowWelcomeScreen(false);
  };

  render() {
    const currentUser = this.context.currentUser;

    return (
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-content d-flex flex-column pt-md-32 pt-12">
            <div className="pb-5 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                Welcome to Equappment, {currentUser.userProfile.firstName}!
              </h3>
              <div className="text-muted font-weight-bold font-size-h4">
                Your Free Trial Has Begun.
              </div>
            </div>
            <div className="pb-5 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                STEP 1 - Install App
              </h3>
              <div className="font-weight-bold font-size-h6 pl-6 mt-8">
                * Use Equappment from any browser (like Chrome, Edge, Safari) by
                going to app.equappment.com <br />
                <br />
                * If you have an iPhone or iPad, download and install Equappment
                from the Apple App Store <br />
                <Link
                  to={{
                    pathname:
                      "https://apps.apple.com/us/app/equappment/id1454739075?ls=1",
                  }}
                  target="_blank"
                  className="btn btn-outline-dark font-weight-bold px-8 py-4 my-3 rounded-0 mt-4"
                >
                  <i className="socicon-apple"></i> Download on the App Store
                </Link>
              </div>
            </div>

            <div className="pb-5 pb-lg-15">
              <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                STEP 2 - How to use EQ
              </h3>
              <div className="font-weight-bold font-size-h6 pl-6 mt-8">
                <Link
                  onClick={() => {
                    this.setState({ showHelpMeModal: true });
                  }}
                  className="btn btn-outline-dark font-weight-bold px-8 py-4 my-3 rounded-0 mt-4"
                >
                  Help me get started
                </Link>
              </div>
            </div>

            <div className="pb-5 pb-lg-15">
              <div className="text-muted font-weight-bold font-size-h4">
                <Link
                  className="btn btn-primary font-weight-bold mr-4 w-50"
                  onClick={this.goToAccount}
                  to="/"
                >
                  Proceed to your account
                </Link>
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          show={this.state.showQuestionModal}
          title="Enter data"
          id="enter-data-question"
          body="Would you like us to enter your initial data for you?"
          yesButton={{
            title: "Yes",
            onClick: this.enterDataNotify,
            spinner: this.state.nofitying,
          }}
          noButton={{
            title: "No",
            onClick: () => {
              this.setState({ showQuestionModal: false });
              this.clearModals();
            },
          }}
        />
        <InfoModal
          show={this.state.showNotifyModal}
          title="Enter data"
          id="notify-data-question"
          body="We have been notified and will contact you for data!"
          yesButton={{
            title: "OK",
            onClick: this.clearModals,
          }}
        />
        <HelpMeGetStartedModal
          show={this.state.showHelpMeModal}
          title="Help me get started"
          id="help-me-get-started"
          yesButton={{
            title: "OK",
            onClick: () => {
              this.setState({ showHelpMeModal: false });
            },
          }}
        />
      </div>
    );
  }
}

export default WelcomeScreen;