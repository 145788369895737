import React, { Component } from 'react';
import Dropzone from '../Dropzone/Dropzone';
import { File, Close } from '../Icons/Svg';

class UploadFileModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if (!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="addFileModal" 
                    data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Upload File</h5>
                                <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" className="ki ki-close" />
                                </button>
                            </div>
                            <div className="modal-body text-center">
                                <Dropzone field={{ onDrop: this.props.onImageDrop }}
                                    supportedTypes="JPEG, PNG, SVG, DOC, PDF, XLS, CSV" />

                                {this.props.files.map((item, index) => (
                                    <>
                                        <p className='mt-20 text-left'>{item.name} <span role="button" onClick={() => this.props.onRemove(index)} 
                                            className="svg-icon svg-icon-3x"><Close /></span></p>
                                        <textarea
                                            type="textarea"
                                            rows="3"
                                            className="form-control mb-4"
                                            value={item.description}
                                            placeholder="Caption"
                                            name="Caption"
                                            onChange={(e) => this.props.handleDescriptionChange(e, index)}
                                        />
                                    </>
                                ))}
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.props.save} type="button" disabled={!this.props.files || this.props.files.length === 0}
                                    className={this.props.saving ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right" : "btn btn-primary font-weight-bold"}>Save</button>
                                <button onClick={this.props.toggle} type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFileModal">
                </button>
            </React.Fragment>
        );
    }
}

export default UploadFileModal;