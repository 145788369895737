import React, { Component } from 'react';
import SimpleForm from '../../components/Form/SimpleForm';
import { validateField, validateEmail } from '../../shared/Validation';


import app, { db, storage } from '../../firebase';

import { AuthContext } from "../../shared/Auth";
import { getMessages } from '../../components/Messages/GetMessages';
import { normalizeInput } from '../../shared/FormHelper';
import { CrewMemberPosition } from '../../shared/data/Crew';

class CrewmemberNew extends Component{
   static contextType = AuthContext;
   isTutorial = this.context.isTutorial;
   tutorialStep = this.context.tutorialStep;

   loadPositions = () => {
    const crewMemberPositions = Object.values(CrewMemberPosition)
      .map((crewMemberPosition) => {
        return { value: crewMemberPosition, display: crewMemberPosition };
      })
      .sort((a, b) => {
        return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
      });

    crewMemberPositions.unshift({ value: "None", display: "None" });
    crewMemberPositions.push({ value: "Other", display: "Other" })
    return crewMemberPositions;
  };

   state={
   fields: [
       {
       name: 'firstName',
       value: '',
       defaultValue: '',
       type: 'text',
       label: 'First name',
       isValid: true,
       validationMessage: '',
       isTouched: false,
       additionalMessage: null,
       required: true,
       dataToggle: "tooltip", 
       title: "This is pretty straightforward. Don't forget to include extra info like their skills, licenses, and certifications. Heck, throw in their birthday too.",
       dataPlacement: "left",
       validators: [
         {
           type: 'required',
           message: getMessages('firstname_required')
         }
       ]
       },
       {
           name: 'lastName',
           value: '',
           defaultValue: '',
           type: 'text',
           label: 'Last name',
           isValid: true,
           validationMessage: '',
           isTouched: false,
           additionalMessage: null,
           required: true,
           validators: [
             {
               type: 'required',
               message: getMessages('lastname_required')
             }
           ]
       },
       {
           name: 'email',
           value: '',
           defaultValue: '',
           type: 'text',
           label: 'Email',
           isValid: true,
           validationMessage: '',
           isTouched: false,
           additionalMessage: null,
           required: false
       },
       {
           name: 'phoneNumber',
           value: '',
           defaultValue: '',
           type: 'text',
           subtype: 'phone',
           label: 'Phone number',
           isValid: true,
           validationMessage: '',
           isTouched: false,
           additionalMessage: null,
           required: false,
          //  validators: [
          //    {
          //      type: 'required',
          //      message: getMessages('phone_required')
          //    }
          //  ]
       },
       {
         name: "positionSelection",
         value: "",
         defaultValue: "",
         placeholder: "Position",
         type: "select",
         label: "Position",
         isValid: true,
         validationMessage: "",
         isTouched: false,
         required: false,
         options: this.loadPositions(),
       },
       {
           name: 'certifications',
           value: '',
           defaultValue: '',
           type: 'textarea',
           label: 'Skills',
           isValid: true,
           validationMessage: '',
           isTouched: false,
           additionalMessage: null,
           required: false,
           rows: 4
         },
         {
          name: "otherInfo",
          value: "",
          defaultValue: "",
          type: "text",
          label: "Other Info",
          isValid: true,
          validationMessage: "",
          isTouched: false,
          additionalMessage: null,
          required: false,
        },
         {
           name: 'image',
           value: '',
           defaultValue: '',
           type: 'dropzone',
           label: 'Crewmember Photo',
           isValid: true,
           validationMessage: '',
           isTouched: false,
           additionalMessage: null,
           required: false,
           localSrc: '',
           onDrop: (acceptedFiles) => { this.onImageDrop(acceptedFiles); },
           onRemove: () => { this.onImageRemove(); }
         }
       ],
       isValid: false,
       submitError: false,
       submitErrorMessage: '',
       loading: false,
       accountCreated: false
   };

   componentDidMount = () => {
    if(this.isTutorial && this.tutorialStep === 'addingCrewmember') {
      this.setSection1Active(true);
      document.getElementById("skip-tutorial-button").style.display = "block";
    }
   }

   componentWillUnmount = () => {
      window.$('[data-toggle="tooltip"]').tooltip('hide');
      document.getElementById("skip-tutorial-button").style.display = "none";
    }

   setSection1Active = (active) => {
    window.$('[id="add-crewmember-input-firstName"]').tooltip({ trigger: 'manual' });
    window.$('[id="add-crewmember-input-firstName"]').tooltip('hide');

    if(active) window.$('[id="add-crewmember-input-firstName"]').tooltip('show');

    window.$('[id="add-crewmember-input-firstName"]').css("border", active ? "1px solid" : "1px solid #E4E6EF");
  }

   validate = () => {
    this.state.fields.forEach(field => {
      validateField(field);
    });
  }
  
   onImageDrop = (acceptedFiles) => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    const reader = new FileReader();
    const loc = window.URL.createObjectURL(acceptedFiles[0]);

    imageField.value = acceptedFiles[0];
    imageField.localSrc = loc;
    this.setState({ fields: fields });
  }
   
   onImageRemove = () => {
    let fields = [...this.state.fields];
    let imageField = fields.find(x => x.name === 'image');

    imageField.value = '';
    imageField.localSrc = '';
    this.setState({ fields: fields });
  }
   clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach(element => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }
   
   getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach(element => {
      if ((element.required && !element.value) || !element.isValid) isValid = false;
    });

    return isValid;
  }
   
   touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find(x => x.name === field.name);
    if (!updatedField)
      return;

    updatedField.isTouched = true;
    this.setState({ fields: fields });
  }
   
   addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + ' is-valid';
    if (!field.isValid && field.value) return cl + ' is-invalid';

    return cl;
  }

  handlePositionSelection = (fields, selectedPosition) => {
    if (selectedPosition === "Other") {
      const index = fields.findIndex(
        (field) => field.name === "positionSelection"
      );
      fields.splice(index + 1, 0, {
        name: "position",
        value: "",
        defaultValue: "",
        placeholder: "Other Position",
        type: "text",
        label: "Other Position",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: false,
      });
    } else {
      const index = fields.findIndex((field) => field.name === "position");
      if (index >= 0) fields.splice(index, 1);
    }
  };

   handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find(x => x.name === event.target.name);

    if (!field)
      return;

    if(field.subtype === 'phone') {
      field.value = normalizeInput(event.target.value, field.value);
    } else if (field.name === "positionSelection") {
      if (event.target.value === "None") field.value = null;
      else field.value = event.target.value;
      this.handlePositionSelection(fields, event.target.value);
    }else {
      field.value = event.target.value;
    }

    this.validate();

    if (!field.value)
      field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });
  }
   
   
   getObjectFromFields = () => {
    let obj = {};
    this.state.fields.forEach(item => {
      if(item.name !== 'image')
        obj[item.name] = item.value;
    });

    return obj;
  }
   
   handelSubmit = (event) => {
    this.setState({ loading: true });
    event.preventDefault();
    // const obj = this.getObjectFromFields();

    const crewMemberObject = this.getObjectFromFields();
    const { positionSelection, ...obj } = {
      ...crewMemberObject,
      position: crewMemberObject.position ?? crewMemberObject.positionSelection,
    };

    obj.hasProfileImage = false;

    const imageField = this.state.fields.find(x => x.name === 'image');
    if(imageField.value) {
      obj.imageTimeStamp = new Date();
    }

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    obj.authorizationStatus='neverInvited';

    db.collection('inventories')
      .doc(inventoryId)
      .collection('crew')
      .add(obj)
      .then(doc => {
        if(imageField.value) {
          db.collection('inventories')
            .doc(inventoryId)
            .collection('crew')
            .doc(doc.id)
            .get()
            .then(document => {
              const storageRef = storage.ref(currentUser.userProfile.companyID + '/crewMember/' + doc.id + '/' + document.data().imageTimeStamp.seconds + '/med'); 
              storageRef.put(imageField.value).then((snapshot) => {
                db.collection("inventories").doc(inventoryId)
                  .collection("crew").doc(doc.id).update({
                    hasProfileImage: true
                  }).then(document => {
                    if(this.isTutorial) {
                      this.setSection1Active(false);
                      const nextTutorialStep = this.context.nextTutorialStep;
                      localStorage.setItem("tmp-crew-id", doc.id);
                      nextTutorialStep();
                    }
                    this.props.history.goBack()
                    //this.props.history.push("/crewmembers/" + doc.id);
                  });
              });
            });
        } else {
          if(this.isTutorial) {
            this.setSection1Active(false);
            const nextTutorialStep = this.context.nextTutorialStep;
            localStorage.setItem("tmp-crew-id", doc.id);
            nextTutorialStep();
          }
          this.props.history.goBack()
          //this.props.history.push("/crewmembers/" + doc.id);
        }
      })
      .catch(error => {
        console.log(error);
      }); 
  }
   
   submitButton = () => {
    const submitBtnClasses = 'btn btn-primary mr-2';
    const submitBtnClassesWithError = this.state.submitError ? submitBtnClasses + ' is-invalid' : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading ? submitBtnClassesWithError + ' spinner spinner-white spinner-right' : submitBtnClassesWithError;

    return {
      title: "Save",
      className: submitBtnClassesFinal,
      onClick: this.handelSubmit,
      disabled: !this.state.isValid || this.state.loading
    };
  }
   
   cancelButton = () => {
      return {
        title: "Cancel",
        className: "btn btn-secondary mr-2",
        onClick: () => { 
          this.props.history.goBack()
          // this.props.history.push('/crewmembers');
         }
      };
    }
   
   
   render(){
      return(
      <React.Fragment>
        <div className="card card-custom">
          <div className="card-header">
            <h3 className="card-title">
              Add new crewmember
                        </h3>
          </div>
          <SimpleForm
            formUniqueName="add-crewmember"
            fields={this.state.fields}
            touchField={this.touchField}
            submitButton={this.submitButton()}
            cancelButton={this.cancelButton()}
            handleInputChange={this.handleChange}
            onSubmit={this.handelSubmit}
          />
        </div>
      </React.Fragment>
         

      );
   }

}

export default CrewmemberNew;