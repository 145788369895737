import { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

class Landing extends Component {
  componentDidMount = () => {
    ReactPixel.pageView();
  };

  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-content d-flex flex-column pt-md-32 pt-12">
            <div className="font-weight-bolder btn-lg">
              <h4 className="text-center">
                <div>Start your 14-day free trial of Equappment.</div>
                <div className="pt-4">
                  No-risk, free trial is fully functional with unlimited
                  project, crewmember, and equipment management. Invite your
                  entire team to try Equappment.
                </div>
                <div className="pt-4">
                  It's all included with the free trial!
                </div>
              </h4>
            </div>
            <div className="row mb-8">
              <img
                src="assets/media/equappment/landing.gif"
                className="w-100"
              />
            </div>
            <div className="row mb-4">
              <Link
                to="/signup"
                className="btn btn-block eq-button font-weight-bold px-24 py-4 my-3 rounded-0 btn-lg"
              >
                Start Free Trial
              </Link>
            </div>
            <div className="row mb-10">
              <Link
                to="/join"
                className="btn btn-block btn-light-primary font-weight-bold px-24 py-4 my-3 rounded-0 btn-lg"
              >
                Join your team on EQ
              </Link>
            </div>
            <div className="login-container text-center pb-10">
            <div className="text-center pt-4 pb-4">
                {"(Already have an account? "}
                <Link to="/signin" className=" mb-10">
                  Log In
                </Link>
                {")"}
              </div>
              <h5>Looking to join an existing account?</h5>
              <div>
                If you need to join another Equappment account, please have
                someone log into Equappment and invite you from their account
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Landing);
