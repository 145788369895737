import GoogleMapReact from 'google-map-react';


const CustomGoogleMap = ({ children, ...props }) => {

  return (
    <div style={{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyCTL64lm5MvytuyaZtz5fGEoFK36v95_Yg' }}
            {...props}
          >
            {children}
        </GoogleMapReact>
      </div>
    )
}

export default CustomGoogleMap;