export const groundConditionOptions = [
    {
      id: 1,
      name: "None",
    },
    {
      id: 2,
      name: "Caliche",
    },
    {
      id: 3,
      name: "Clay",
    },
    {
      id: 4,
      name: "Cobbles",
    },
    {
      id: 5,
      name: "Dirt",
    },
    {
      id: 6,
      name: "Gravel",
    },
    {
      id: 7,
      name: "Hardpan",
    },
    {
      id: 8,
      name: "Sand",
    },
    {
      id: 9,
      name: "Sandstone",
    },
    {
      id: 10,
      name: "Shale",
    },
    {
      id: 11,
      name: "Other",
    },
  ];