import React, { Component } from "react";
import app from "../../../firebase";
import { withRouter, Redirect } from "react-router-dom";
import firebase from "firebase/compat/app";
import { Link } from "react-router-dom";

import { AuthContext } from "../../../shared/Auth";
import { getMessages } from "../../../components/Messages/GetMessages";

class Signin extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitError: false,
      submitErrorMessage: "",
      loading: false,
      messageState: null,
    };
  }

  componentDidMount() {
    const messageState = this.context.messageState;
    this.setState({ messageState: null });
  }

  componentDidUpdate(prevProps, prevState) {
    const messageState = this.context.messageState;
    if (messageState === "subscription-error" && !this.state.messageState) {
      this.setState({
        messageState: messageState,
        submitError: true,
        submitErrorMessage: getMessages("invalid_subscription"),
      });
    }

    if (!prevState.submitError && this.state.submitError) {
      this.setState({ loading: false });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    this.resetState();

    try {
      await app
        .auth()
        .signInWithEmailAndPassword(this.state.email, this.state.password);
    } catch (error) {
      if (
        error.code === "auth/user-not-found" ||
        error.code === "auth/wrong-password"
      ) {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("incorrect_user_pass"),
        });
      } else if (error.code === "auth/invalid-email") {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("invalid_email"),
        });
      } else {
        this.setState({
          submitError: true,
          submitErrorMessage: getMessages("something_wrong"),
        });
      }

      this.setState({ loading: false });
    }
  };

  signInWithGoogle = async (event) => {
    const googleProvider = new firebase.auth.GoogleAuthProvider();
    try {
      var userCred = await app
        .auth()
        .signInWithPopup(googleProvider)
        return userCred;
    } catch (error) {
      console.log(error);
    }
  };

  signInWithApple = async (event) => {
    const appleProvider = new firebase.auth.OAuthProvider("apple.com");
    try {
      var userCred = await app
        .auth()
        .signInWithPopup(appleProvider)
        return userCred;
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (event) => {
    let updatedState = { ...this.state };
    updatedState[event.target.name] = event.target.value;

    this.setState(updatedState);
  };

  resetState = () => {
    this.setState({
      submitError: false,
      submitErrorMessage: "",
      messageState: null,
    });
  };

  render() {
    const submitBtnClasses =
      "btn btn-block btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 rounded-0";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return (
      <div
        className="d-flex flex-column flex-root"
      >
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          
            <div className="login-content d-flex flex-column pt-md-32 pt-12">
              <a className="login-logo pb-xl-20 pb-15">
                <img
                  src="assets/media/logos/logo-eq.png"
                  className="max-h-100px"
                  alt=""
                />
              </a>
              <div className="row mb-10">
                <div className="col-lg-6">
                  <button
                    onClick={this.signInWithGoogle}
                    className="btn btn-block btn-light-dark font-weight-bold px-8 py-4 my-3 rounded-0"
                  >
                    <i className="socicon-google"></i> Sign in with Google
                  </button>
                </div>
                <div className="col-lg-6">
                  <button
                    onClick={this.signInWithApple}
                    className="btn btn-block btn-outline-dark font-weight-bold px-8 py-4 my-3  rounded-0"
                  >
                    <i className="socicon-apple"></i> Sign in with Apple
                  </button>
                </div>
              </div>
              <div className="pb-5 pb-lg-15 mb-md-2 mb-6">
                <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                  or Sign in with email and password
                </h3>
                <div className="text-muted font-weight-bold font-size-h4">
                  New Here?
                  <Link
                    to="/signup"
                    className="text-primary font-weight-bolder"
                  >
                    {" "}
                    Create Account
                  </Link>
                </div>
              </div>
              <div className="login-form">
                <form
                  onSubmit={this.handleSubmit}
                  className="form"
                  id="kt_login_singin_form"
                  noValidate="novalidate"
                >
                  <div className="form-group">
                    <label className="font-size-h6 font-weight-bolder text-dark">
                      Email
                    </label>
                    <input
                      value={this.state.email}
                      className="form-control form-control-solid py-7 px-6 rounded-0 border-0 input-h-over"
                      type="email"
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <div className="d-flex justify-content-between mt-n5">
                      <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                        Password
                      </label>
                      <Link
                        tabIndex="-1"
                        to="/resetpassword"
                        className="text-primary font-size-h6 font-weight-bolder pt-5"
                      >
                        Forgot Password
                      </Link>
                    </div>
                    <input
                      value={this.state.password}
                      className="form-control form-control-solid py-7 px-6 rounded-0 border-0 input-h-over"
                      type="password"
                      name="password"
                      autoComplete="off"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <button
                        type="submit"
                        id="kt_login_singin_form_submit_button"
                        className={submitBtnClassesFinal}
                        disabled={!this.state.email || !this.state.password}
                      >
                        Sign in
                      </button>
                      <div className="invalid-feedback justify-content-center">
                        {this.state.submitErrorMessage}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Signin);
