import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const greatPlaceStyle = {
  position: "absolute",
  transform: "translate(-58%, -98%)",
  color: "darkred",
  fontSize: "26px",
};
const AnyReactComponent = ({ text }) => (
  <i style={greatPlaceStyle} className="flaticon2-pin"></i>
);

const LocationPin = ({ text }) => (
  <div
    className="pin"
    style={{
      width: "40px",
      height: "40px",
      transform: "translate(-20px , -20px)",
    }}
  >
    <Icon icon={locationIcon} className="pin-icon" />
  </div>
);

class GoogleMap extends Component {
  static defaultProps = {
    zoom: 11,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "100%", width: "100%" }}>
        <GoogleMapReact
          resetBoundsOnResize={true}
          bootstrapURLKeys={{ key: "AIzaSyCTL64lm5MvytuyaZtz5fGEoFK36v95_Yg" }}
          center={{
            lat: this.props.lat,
            lng: this.props.lng,
          }}
          onGoogleApiLoaded={({ map, maps }) => {
            map.addListener('click', e => {
              const lat = e.latLng.lat()
              const lng = e.latLng.lng()
              this.props.onClick({lat:lat, lng:lng});
            })
          }}
          defaultZoom={this.props.zoom}
          onClick={(coords) => {
          }}
          onChildClick={(coords) => {
            //this.props.onClick(coords);
          }}
        >
          <AnyReactComponent
            lat={this.props.lat}
            lng={this.props.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;
