import { db } from "../../firebase"
import { isBetweenDates, isNullOrEmpty } from "../Utils"
import { CrewMemberEntity } from "../data/Crew"
import { ResponseEntity } from "../data/Response"
import { JobEntity } from "../data/job/Job"
import { FirebaseInventoriesCollections, FirebaseRootCollections } from "../firebase/FirebaseSchema"
import { getCrewMembersSchedules, getCrewsSchedules, saveCrewMembersSchedules, saveCrewSchedules } from "./CrewApi"
import { getEquipmentSchedules, saveEquipmentSchedules } from "./EquipmentApi"

export const getJobReportCrewMembers = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseInventoriesCollections.CREW_MEMBERS)
            .get()

        const crewMembers = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<CrewMemberEntity[]>(crewMembers, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity[]>(null, error)
    }
}

export const saveJobReportCrewMembers = async (inventoryId: string, jobId: string, crewMember: CrewMemberEntity) => {
    try {
        await db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.JOBS)
            .doc(jobId)
            .collection(FirebaseInventoriesCollections.CREW_MEMBERS)
            .add({...crewMember})

        return new ResponseEntity<CrewMemberEntity>(crewMember, "")
    } catch (error: any) {
        return new ResponseEntity<CrewMemberEntity>(crewMember, error)
    }
}

export const updateJobReservations = async (inventoryId: string, job: JobEntity) => {
    if (!isNullOrEmpty(job.id)) {
        const crews = (await getCrewsSchedules(inventoryId, job.id ?? "")).data?.filter((crew) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crew.endDate?.toDate())
        })
            ?.map((crew) => { return { ...crew, startDate: job.startDate, endDate: job.endDate } }) ?? []

        const crewMembers = (await getCrewMembersSchedules(inventoryId, job.id ?? "")).data?.filter((crewMember) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), crewMember.endDate?.toDate())
        })
            ?.map((crewMember) => { return { ...crewMember, startDate: job.startDate, endDate: job.endDate } }) ?? []


        const equipment = (await getEquipmentSchedules(inventoryId, job.id ?? "")).data?.filter((equipment) => {
            return !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.startDate?.toDate()) ||
                !isBetweenDates(job.startDate?.toDate(), job.endDate?.toDate(), equipment.endDate?.toDate())
        })
            ?.map((equipment) => { return { ...equipment, startDate: job.startDate, endDate: job.endDate } }) ?? []


        const allRequests = [
            ...[crews?.map((crew) => { return saveCrewSchedules(inventoryId, crew) }) ?? []],
            ...[crewMembers?.map((crewMember) => { return saveCrewMembersSchedules(inventoryId, crewMember) }) ?? []],
            ...[equipment?.map((equipment) => { return saveEquipmentSchedules(inventoryId, equipment) }) ?? []]
        ]

        Promise.all(allRequests)
    }
}