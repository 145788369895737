import React from 'react'
import { AngleDoubleRight } from '../../components/Icons/Svg'


const RoleDefinitions = () => {
   
   return (
      <div className="accordion accordion-light accordion-light-borderless accordion-svg-toggle" id="accordionExample7">
      {/*begin::Item*/}
      <div className="card">
        {/*begin::Header*/}
        <div className="card-header" id="headingOne7">
          <div className="card-title" data-toggle="collapse" data-target="#collapseOne7" aria-expanded="true" role="button">
            <span className="svg-icon svg-icon-primary">
              {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
              <AngleDoubleRight/>
              {/*end::Svg Icon*/}
            </span>
            <div className="card-label text-dark pl-4">Role Owner</div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div id="collapseOne7" className="collapse show" aria-labelledby="headingOne7" data-parent="#accordionExample7">
          <div className="card-body text-dark-50 font-size-lg pl-12">
            <h6>Permissions</h6>
            <ul>
              <li>Owner of company account and subscription with Equappment</li>
              <li>Receives invoices and makes payments</li>
              <li>Receives all emails associated to the account and subscription</li>
              <li>View and edit all payment information</li>
              <li>Adds (invites) users to app</li>
              <li>Removes users from app</li>
              <li>Assigns and changes roles for users</li>
              <li>Add, view and edits all information</li>
              <li>All Administrator permissions</li>
            </ul>
          </div>
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Item*/}
      {/*begin::Item*/}
      <div className="card">
        {/*begin::Header*/}
        <div className="card-header" id="headingTwo7">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseTwo7" aria-expanded="true" role="button">
            <span className="svg-icon svg-icon-primary">
              {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
              <AngleDoubleRight/>
              {/*end::Svg Icon*/}
            </span>
            <div className="card-label text-dark pl-4">Role Administrator</div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div id="collapseTwo7" className="collapse" aria-labelledby="headingTwo7" data-parent="#accordionExample7">
          <div className="card-body text-dark-50 font-size-lg pl-12">
            <h6>Permissions</h6>
            <ul>
              <li>View all information</li>
              <li>Create and edit jobs</li>
              <li>Create bores on jobs</li>
              <li>Create bore logs for any job</li>
              <li>Add photos for any bore</li>
              <li>Check equipment/tools out</li>
              <li>Check equipment/tools in</li>
              <li>Edit job location and get directions</li>
              <li>Add photos for any job</li>
              <li>Add and edit equipment</li>
              <li>Add and edit crewmembers</li>
              <li>Schedule equipment for service</li>
              <li>Export and share Job Kickoff Report & Checklist</li>
              <li>All Crewmember permissions</li>
            </ul>
          </div>
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Item*/}
      {/*begin::Item*/}
      <div className="card">
        {/*begin::Header*/}
        <div className="card-header" id="headingThree7">
          <div className="card-title collapsed" data-toggle="collapse" data-target="#collapseThree7" aria-expanded="true" role="button">
            <span className="svg-icon svg-icon-primary">
              {/*begin::Svg Icon | path:assets/media/svg/icons/Navigation/Angle-double-right.svg*/}
              <AngleDoubleRight/>
              {/*end::Svg Icon*/}
            </span>
            <div className="card-label text-dark pl-4">Role Crewmember</div>
          </div>
        </div>
        {/*end::Header*/}
        {/*begin::Body*/}
        <div id="collapseThree7" className="collapse" aria-labelledby="headingThree7" data-parent="#accordionExample7">
          <div className="card-body text-dark-50 font-size-lg pl-12">
            <h6>Permissions</h6>
            <ul>
              <li>View all information</li>
              <li>Check equipment out for jobs they are assigned to</li>
              <li>Check equipment in for jobs they are assigned to</li>
              <li>Create bore logs for jobs they are assigned to</li>
              <li>Add photos to bore log entries they are assigned to</li>
              <li>Add photos to jobs they are assigned to</li>
              <li>Export and share bore logs</li>
              <li>View job location and get directions</li>
              <li>Export and share Job Completion Report</li>
            </ul>
          </div>
        </div>
        {/*end::Body*/}
      </div>
      {/*end::Item*/}
    </div>
               
      
   )
}

export default RoleDefinitions;
