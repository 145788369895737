import { Timestamp } from "firebase/firestore";
import { TaskEntity } from "./Task";

export class SegmentEntity {
  id?: string | null = null;
  jobID: string;
  segmentID?: string | null = "";
  title?: string | null = "";
  description?: string | null = "";
  summary?: string | null = "";
  endLat?: number;
  endLng?: number;
  startLat?: number;
  startLng?: number;
  soilConditions?: string | null = "";
  startDate?: Timestamp | null = null;

  constructor(jobID: string) {
    this.jobID = jobID
  }
}

export class SegmentAndTaskEntity {
  segment?: SegmentEntity | null = null
  task: TaskEntity

  constructor(task: TaskEntity, segment: SegmentEntity | null = null) {
    this.task = task
    this.segment = segment
  }
}