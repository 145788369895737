import React from 'react';
import { NavigationUp } from '../../Icons/Svg';

const scrollTop = () => (
    <div id="kt_scrolltop" className="scrolltop">
        <span className="svg-icon">
          <NavigationUp/>
        </span>
      </div>
);

export default scrollTop;