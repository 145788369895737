import React, { Component } from "react";
import app, { db, functions } from "../../../firebase";
import { Redirect, withRouter } from "react-router-dom";
import { getMessages } from "../../../components/Messages/GetMessages";
import {
  addCrewmember,
  updateCrewmember,
  getCompanyOwner,
  getExampleEquipment,
  getExampleCrew,
  getExampleCrewmember,
  getExampleClient,
  getExampleContact,
  getExampleJob,
  getExampleJobFolder,
  addEquipment,
  addClient,
  addContact,
  addJob,
  addJobFolder,
  addCrew,
  addExampleEquipment,
  addExampleClient,
  addExampleContact,
  addExampleCrewmember,
  addExampleJob,
  addExampleCrew,
  addExampleJobFolder,
} from "../../../shared/Api";
import { normalizeInput } from "../../../shared/FormHelper";
import LandingScreenInfo from "../Landing/LandingInfo";

class AccountDetails extends Component {
  constructor(props, context) {
    super(props);

    const fields = [
      {
        name: "firstName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "First Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: true,
      },
      {
        name: "lastName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "Last Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: true,
      },
      {
        name: "phoneNumber",
        value: "",
        defaultValue: "",
        type: "phone",
        subtype: "phone",
        label: "Phone Number",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: false,
      },
    ];

    let crewmemberInvite = localStorage.getItem("crewmemberInviteState");
    if (crewmemberInvite) {
      try {
        crewmemberInvite = JSON.parse(crewmemberInvite);
      } catch (err) {
        console.log(err);
        crewmemberInvite = null;
      }
    }

    if (!crewmemberInvite) {
      fields.push({
        name: "companyName",
        value: "",
        defaultValue: "",
        type: "text",
        label: "Company Name",
        isValid: true,
        validationMessage: "",
        isTouched: false,
        required: true,
      });
    }

    this.state = {
      fields: fields,
      isValid: false,
      submitError: false,
      submitErrorMessage: "",
      loading: false,
      crewmemberInvite: crewmemberInvite,
    };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    if (!this.state.isValid) return;

    this.setState({ loading: true });
    this.createUser();
  };

  createUser = async () => {
    const currentUser = this.props.currentUser;

    const fields = this.state.fields;
    const firstName = fields.find((x) => x.name === "firstName");
    const lastName = fields.find((x) => x.name === "lastName");
    const phoneNumber = fields.find((x) => x.name === "phoneNumber");
    const companyName = fields.find((x) => x.name === "companyName");
    const password = fields.find((x) => x.name === "password");

    const self = this;

    const exampleEquipmentResult = await getExampleEquipment();
    const exampleCrewResult = await getExampleCrew();
    const exampleCrewmemberResult = await getExampleCrewmember();
    const exampleClientResult = await getExampleClient();
    const exampleContactResult = await getExampleContact();
    const exampleJobResult = await getExampleJob();
    const exampleFolderResult = await getExampleJobFolder();

    if (this.state.crewmemberInvite) {
      const companyOwner = await getCompanyOwner(
        this.state.crewmemberInvite.companyId
      );

      const userProfile = {
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
        email: currentUser.email,
        permissions: "crewmember",
        recurlyAccountCode: null,
        companyID: this.state.crewmemberInvite.companyId,
        company: null,
        crewmemberID: this.state.crewmemberInvite.crewmemberId,
        imageTimeStamp: null,
        recurlyAccountCode: companyOwner.recurlyAccountCode,
      };

      db.collection("users")
        .doc(currentUser.uid)
        .set(userProfile)
        .then(async () => {
          currentUser.userProfile = userProfile;

          db.collection("companies")
            .doc(this.state.crewmemberInvite.companyId)
            .get()
            .then(async (company) => {
              currentUser.company = company.data();

              const invId = company.data().inventoryID;
              updateCrewmember(
                invId,
                this.state.crewmemberInvite.crewmemberId,
                { authorizationStatus: "authorized" }
              );

              let invitation = await db
                .collection("invitations")
                .where(
                  "inviteCode",
                  "==",
                  parseInt(this.state.crewmemberInvite.inviteCode)
                )
                .get();

              const data = invitation.docs.map((x) => {
                return { ...x.data(), id: x.id };
              });
              if (data.length > 0) {
                await db.collection("invitations").doc(data[0].id).delete();
              }

              var updateQuantityFunction = functions.httpsCallable(
                "api/updateSubscriptionQuantity"
              );
              updateQuantityFunction({
                recurlyAccountCode: companyOwner.recurlyAccountCode,
              })
                .then((result) => {
                  self.props.updateUser(currentUser);
                })
                .catch((error) => {
                  console.log(error);
                });
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      db.collection("inventories")
        .add({})
        .then((inventory) => {
          db.collection("companies")
            .add({
              name: companyName.value,
              inventoryID: inventory.id,
              owner: currentUser.uid,
              newAccount: true,
            })
            .then((company) => {
              currentUser.company = {
                name: companyName.value,
                inventoryID: inventory.id,
                owner: currentUser.uid,
                newAccount: true,
              };

              const userProfile = {
                firstName: firstName.value,
                lastName: lastName.value,
                phoneNumber: phoneNumber.value,
                email: currentUser.email,
                permissions: "owner",
                recurlyAccountCode: null,
                companyID: company.id,
                company: null,
                imageTimeStamp: null,
                recurlyAccountCode: null,
                webTutorialPending: true,
              };

              db.collection("users")
                .doc(currentUser.uid)
                .set(userProfile)
                .then(() => {
                  currentUser.userProfile = userProfile;

                  var createRecurlyAccount = functions.httpsCallable(
                    "api/createRecurlyAccount"
                  );
                  createRecurlyAccount()
                    .then((result) => {
                      currentUser.subscriptionActive = true;

                      const crewMemb = {
                        firstName: currentUser.userProfile.firstName,
                        lastName: currentUser.userProfile.lastName,
                        phoneNumber: currentUser.userProfile.phoneNumber,
                        email: currentUser.userProfile.email,
                        authorizationStatus: "authorized",
                        userID: currentUser.uid,
                        certifications: null,
                        imageTimeStamp: null,
                        hasProfileImage: false,
                      };

                      addCrewmember(inventory.id, company.id, crewMemb);
                      addExampleEquipment(
                        inventory.id,
                        company.id,
                        exampleEquipmentResult.data
                      );
                      addExampleClient(inventory.id, exampleClientResult.data);
                      addExampleContact(
                        inventory.id,
                        "4Cs05TP6vZlDKPzFnZTa",
                        exampleContactResult.data
                      );
                      addExampleCrewmember(
                        inventory.id,
                        company.id,
                        exampleCrewmemberResult.data
                      );
                      addExampleJob(inventory.id, exampleJobResult.data);
                      addExampleCrew(inventory.id, exampleCrewResult.data);
                      exampleFolderResult.data.forEach((folder) => {
                        addExampleJobFolder(inventory.id, folder);
                      });

                      self.props.setShowWelcomeScreen(true);
                      self.props.updateUser(currentUser);
                    })
                    .catch((error) => {
                      app.auth().signOut();
                      this.props.history.push("/signin");
                    });
                })
                .catch(function (error) {
                  app.auth().signOut();
                  this.props.history.push("/signin");
                });
            });
        });
    }
  };

  clearValues() {
    let updatedFields = [...this.state.fields];
    updatedFields.forEach((element) => {
      element.value = element.defaultValue;
    });

    this.setState({ fields: updatedFields });
  }

  handleChange = (event) => {
    let fields = [...this.state.fields];
    let field = fields.find((x) => x.name === event.target.name);

    if (!field) return;

    if (field.subtype === "phone") {
      field.value = normalizeInput(event.target.value, field.value);
    } else {
      field.value = event.target.value;
    }
    this.validate(field);

    if (!field.value) field.isTouched = false;

    const globalValid = this.getGlobalValid(fields);
    this.setState({ fields: fields, isValid: globalValid });
  };

  validate = (field) => {
    if (field.name === "firstName") {
      if (field.value.length > 0) {
        field.isValid = true;
        field.validationMessage = "";
      } else {
        field.isValid = false;
        field.validationMessage = getMessages("firstname_required");
      }
    }

    if (field.name === "lastName") {
      if (field.value.length > 0) {
        field.isValid = true;
        field.validationMessage = "";
      } else {
        field.isValid = false;
        field.validationMessage = getMessages("lastname_required");
      }
    }

    if (field.name === "phoneNumber") {

      let currentValue = field.value.replace(/[^\d]/g, "");
      if (currentValue.length > 9) {
        field.isValid = true;
        field.validationMessage = "";
      } else {
        field.isValid = false;
        field.validationMessage = getMessages("invalid_phone");
      }
    }

    if (field.name === "companyName") {
      if (field.value.length > 0) {
        field.isValid = true;
        field.validationMessage = "";
      } else {
        field.isValid = false;
        field.validationMessage = getMessages("company_required");
      }
    }

    if (field.name === "password") {
      let isValid = true;
      if (field.value.search(/[a-z]/) < 0) {
        isValid = false;
        field.validationMessage = getMessages("pass_lowercase");
      } else if (field.value.search(/[A-Z]/) < 0) {
        isValid = false;
        field.validationMessage = getMessages("pass_uppercase");
      } else if (field.value.search(/[0-9]/) < 0) {
        isValid = false;
        field.validationMessage = getMessages("pass_digit");
      } else if (field.value.length < 8) {
        isValid = false;
        field.validationMessage = getMessages("pass_characters");
      }

      field.isValid = isValid;
    }

    if (field.name === "passwordConfirm") {
      const password = this.state.fields.find((x) => x.name === "password");
      if (field.value === password.value) {
        field.isValid = true;
        field.validationMessage = "";
      } else {
        field.isValid = false;
        field.validationMessage = getMessages("pass_nomatch");
      }
    }
  };

  getGlobalValid = (fields) => {
    let isValid = true;
    fields.forEach((element) => {
      if (element.required && (!element.value || !element.isValid))
        isValid = false;
    });

    return isValid;
  };

  touchField = (field) => {
    let fields = this.state.fields;
    let updatedField = fields.find((x) => x.name === field.name);
    if (!updatedField) return;

    updatedField.isTouched = true;
    this.setState({ fields: fields });
  };

  addValidationClass(field, cl) {
    if (field.isValid && field.value) return cl + " is-valid";
    if (!field.isValid && field.value) return cl + " is-invalid";

    return cl;
  }

  gnerateInputId(field) {
    return "ca-input-" + field.name;
  }

  render() {
    const submitBtnClasses =
      "btn btn-block btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3 rounded-0";
    const submitBtnClassesWithError = this.state.submitError
      ? submitBtnClasses + " is-invalid"
      : submitBtnClasses;
    const submitBtnClassesFinal = this.state.loading
      ? submitBtnClassesWithError + " spinner spinner-white spinner-right"
      : submitBtnClassesWithError;

    return (
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column flex-root"
      >
        <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="login-aside order-1 order-lg-1 bgi-no-repeat bgi-position-x-right">
            <div className="login-conteiner bgi-position-x-right bgi-position-y-bottom">
              <LandingScreenInfo />
            </div>
          </div>
          <div className="login-container order-2 order-lg-1 d-flex flex-center-h flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
            <div className="login-content d-flex flex-column pt-md-32 pt-12">
              <div className="pb-5 pb-lg-15">
                <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h2-lg">
                  {this.state.crewmemberInvite
                    ? "Enter your contact information. Once submitted, you will be joined to your company’s account."
                    : "You're almost there. Just fill in the info below and your free trial of Equappment will begin."}
                </h3>
              </div>
              <div className="login-form">
                <form
                  onSubmit={this.handleSubmit}
                  className="form"
                  id="kt_login_singin_form"
                  action="POST"
                >
                  {this.state.fields.map((item, index) => {
                    const field = (
                      <div key={index} className="form-group">
                        <label className="font-size-h6 font-weight-bolder text-dark">
                          {item.label}
                          {item.required && (
                            <span className="text-danger"> *</span>
                          )}
                        </label>
                        <input
                          autoFocus={index === 0}
                          id={this.gnerateInputId(item)}
                          key={index}
                          value={item.value}
                          className={this.addValidationClass(
                            item,
                            "form-control form-control-solid input-h-over py-7 px-6 rounded-0 border-0"
                          )}
                          type={item.type}
                          name={item.name}
                          onChange={this.handleChange}
                          onBlur={() => {
                            this.touchField(item);
                          }}
                        />
                        <div className="invalid-feedback">
                          {item.validationMessage}
                        </div>
                      </div>
                    );

                    return field;
                  })}
                  <div className="row">
                    <div className="col-lg-12">
                      <button
                        disabled={!this.state.isValid || this.state.loading}
                        type="submit"
                        id="kt_login_singin_form_submit_button"
                        className={submitBtnClassesFinal}
                      >
                        Create account
                      </button>
                      <div className="invalid-feedback justify-content-center">
                        {this.state.submitErrorMessage}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AccountDetails);
