import React, { Component } from 'react';
import { AuthContext } from "../../shared/Auth";
import Loader from '../../components/Layout/Loader/Loader';
import { touchField, updateFormOnChange, objectToFields, fieldsToObject } from '../../shared/FormHelper';
import { getMessages } from '../../components/Messages/GetMessages';
import BasicForm from '../../components/Form/BasicForm';
import { getCrew, updateCrew, deleteCrew } from '../../shared/Api';
import { Trash } from '../../components/Icons/Svg';
import ConfirmModal from '../../components/Modals/ConfirmModal';

class CrewsEdit extends Component {
  static contextType = AuthContext;
  user = this.context.currentUser.userProfile;

  state = {
    crew: null,
    deleting: false,
    deleteCrewModalActive: false,
    jobId: null,
    addCrewForm: {
      name: 'add-new-crew',
      isValid: true,
      submitErrorMessage: null,
      saving: false,
      fields: [
        {
          name: 'crewID',
          value: '',
          defaultValue: '',
          type: 'text',
          label: 'Crew ID #',
          placeholder: 'Crew ID',
          isValid: true,
          validationMessage: '',
          isTouched: false,
          additionalMessage: getMessages('form_field_description_message_crew_id'),
          required: true,
          validators: [
            {
              type: 'required',
              message: 'Crew ID is required.'
            }
          ]
        },
        {
          name: 'crewName',
          value: '',
          defaultValue: '',
          type: 'text',
          label: 'Crew Name',
          placeholder: 'Crew Name',
          isValid: true,
          validationMessage: '',
          isTouched: false,
          additionalMessage: getMessages('form_field_description_message_crew_name'),
          required: true,
          validators: [
            {
              type: 'required',
              message: 'Crew name is required.'
            }
          ]
        },
        {
          name: 'crewColor',
          value: {
            red: 255,
            green: 0,
            blue: 0,
            alpha: 0.3
          },
          defaultValue: '',
          type: 'colorpicker',
          label: 'Crew Color',
          placeholder: 'Crew Color',
          isValid: true,
          validationMessage: '',
          isTouched: false,
          additionalMessage: getMessages('form_field_description_message_crew_color'),
          required: true,
          validators: [
            {
              type: 'required',
              message: 'Crew color is required.'
            }
          ]
        }
      ],
      buttons: [
        {
          title: 'Save',
          className: 'btn btn-primary font-weight-bolder text-uppercase py-4 mr-2 ',
          onClick: () => this.submit(),
          disabled: () => false,
          submit: true
        },
        {
          title: 'Cancel',
          className: 'btn btn-light-secondary font-weight-bolder text-uppercase py-4',
          onClick: () => this.props.history.goBack(),
          disabled: () => false
        }
      ]
    }
  };

  componentDidMount() {
    this.loadCrew();
  }

  loadCrew = async () => {
    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;
    const jobId = this.props.match.params.jobId;

    const getCrewResult = await getCrew(inventoryId, crewId);
    const form = { ...this.state.addCrewForm };
    const fields = objectToFields(form.fields, getCrewResult.data);

    form.fields = fields;
    this.setState({ addCrewForm: form, crew: getCrewResult.data, jobId: jobId });
  }

  deleteCrew = async () => {
    const id = this.state.crew.id;
    this.setState({ deleting: true });

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;

    await deleteCrew(inventoryId, id);

    this.setState({ deleteCrewModalActive: false, deleting: false });

    // if(this.state.jobId) {
    //   this.props.history.push("/jobs/" + this.state.jobId + '/details/crews');
    // } else {
      this.props.history.goBack()//push("/crews");
    // }
  }

  prepareAddCrewForm = () => {
    let form = this.state.addCrewForm;

    form.handleInputChange = (event, obj) => {
      const updatedForm = updateFormOnChange(event, obj, this.state.addCrewForm);

      if (!updatedForm)
        return;

      this.setState({ form: updatedForm });
    }

    form.touchField = (event) => {
      const updatedForm = touchField(event, this.state.addCrewForm);

      if (!updatedForm)
        return;

      this.setState({ form: updatedForm });
    }

    return form;
  }

  submit = async () => {
    const form = { ...this.state.addCrewForm };
    form.saving = true;
    this.setState({ addCrewForm: form });

    const currentUser = this.context.currentUser;
    const inventoryId = currentUser.company.inventoryID;
    const crewId = this.props.match.params.id;

    const crew = fieldsToObject(this.state.addCrewForm.fields);

    const addCrewResult = await updateCrew(inventoryId, crewId, crew);

    form.saving = false;
    this.setState({ addCrewForm: form });
    this.props.history.goBack()
    // this.props.history.push('/crews');
  }

  render() {
    if (!this.state.crew) {
      return <Loader />
    }

    const addCrewForm = this.prepareAddCrewForm();

    return (
      <div className="card card-custom">
        <div className="card-header h-auto py-4">
          <div className="card-title">
            <h3 className="card-label">Edit crew
                 <span className="d-block text-muted pt-2 font-size-sm">{this.state.crew.crewName}</span></h3>
          </div>
          <div className="card-toolbar">
            <a onClick={() => { this.setState({ deleteCrewModalActive: true }) }} className="btn btn-danger btn-sm font-weight-bold">
                      <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                        <Trash />
                      </span>Delete</a>
          </div>
        </div>
        <div className="card-body py-4"><BasicForm {...addCrewForm} /></div>
          <ConfirmModal
            show={this.state.deleteCrewModalActive}
            title="Delete crew"
            id="delete-crew-n-modal"
            body={getMessages('delete_crew')}
            yesButton={{
              title: "Yes",
              onClick: () => { this.deleteCrew(); },
              spinner: this.state.deleting
            }}
            noButton={{
              title: "Cancel",
              onClick: () => { this.setState({ deleteCrewModalActive: false }) }
            }}
          />
      </div>
    );
  }
}

export default CrewsEdit;