import React, { Component } from 'react';

class AddFolderModal extends Component {
    constructor(props) {
        super(props);

        this.hiddenBtnRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.show && !prevProps.show) {
            this.hiddenBtnRef.current.click();
        }

        if (!this.props.show && prevProps.show) {
            this.hiddenBtnRef.current.click();
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal fade" id="addFolderModal" 
                    data-backdrop="static" tabIndex={-1} role="dialog" aria-labelledby="staticBackdrop" aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Folder</h5>
                                <button onClick={this.props.toggle} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <i aria-hidden="true" className="ki ki-close" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.props.folderName}
                                    placeholder="Folder Name"
                                    name="folder-name"
                                    onChange={this.props.handleChange}
                                />
                            </div>
                            <div className="modal-footer">
                                <button onClick={this.props.save} type="button" disabled={!this.props.folderName}
                                    className={this.props.saving ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right" : "btn btn-primary font-weight-bold"}>Save</button>
                                <button onClick={this.props.toggle} type="button" className="btn btn-light-primary font-weight-bold" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <button style={{ display: 'none' }} ref={this.hiddenBtnRef} type="button" className="btn btn-primary" data-toggle="modal" data-target="#addFolderModal">
                </button>
            </React.Fragment>
        );
    }
}

export default AddFolderModal;