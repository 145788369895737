import React from "react";
import DefaultSvg from "./DefaultSvg";

export const Person = (props) => {
  return <DefaultSvg color={props.color} type={"person"} />;
};

export const Email = (props) => {
  return <DefaultSvg color={props.color} type={"email"} />;
};

export const Phone = (props) => {
  return <DefaultSvg color={props.color} type={"phone"} />;
};

export const Shield = (props) => {
  return <DefaultSvg color={props.color} type={"shield"} />;
};

export const Company = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"company"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};

export const Lock = (props) => {
  return <DefaultSvg color={props.color} type={"lock"} />;
};

export const CreditCard = (props) => {
  return <DefaultSvg color={props.color} type={"creditcard"} />;
};

export const AngleDoubleRight = (props) => {
  return <DefaultSvg color={props.color} type={"angledoubleright"} />;
};

export const ShieldUser = (props) => {
  return <DefaultSvg color={props.color} type={"shielduser"} />;
};

export const ShieldLock = (props) => {
  return <DefaultSvg color={props.color} type={"shieldlock"} />;
};

export const GroupChat = (props) => {
  return <DefaultSvg color={props.color} type={"groupchat"} />;
};

export const SendingMail = (props) => {
  return <DefaultSvg color={props.color} type={"sendingmail"} />;
};

export const Terms = (props) => {
  return <DefaultSvg color={props.color} type={"terms"} />;
};

export const Privacy = (props) => {
  return <DefaultSvg color={props.color} type={"privacy"} />;
};

export const NavigationUp = (props) => {
  return <DefaultSvg color={props.color} type={"navigationup"} />;
};

export const Dashboard = (props) => {
  return <DefaultSvg color={props.color} type={"dashboard"} />;
};

export const Tools = (props) => {
  return <DefaultSvg color={props.color} type={"tools"} />;
};

export const Hummer = (props) => {
  return <DefaultSvg color={props.color} type={"hummer"} />;
};

export const People = (props) => {
  return <DefaultSvg color={props.color} type={"people"} />;
};

export const ExportIcon = (props) => {
  return <DefaultSvg color={props.color} type={"exporticon"} />;
};

export const ImportIcon = (props) => {
  return <DefaultSvg color={props.color} type={"importicon"} />;
};

export const PlusIcon = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"plusicon"}
      width={props.width}
      height={props.height}
    />
  );
};

export const SearchIcon = (props) => {
  return <DefaultSvg color={props.color} type={"searchicon"} />;
};

export const PenRuler = (props) => {
  return <DefaultSvg color={props.color} type={"penruler"} />;
};

export const Flatten = (props) => {
  return <DefaultSvg color={props.color} type={"flatten"} />;
};

export const Trash = (props) => {
  return <DefaultSvg color={props.color} type={"trash"} />;
};

export const Edit = (props) => {
  return <DefaultSvg color={props.color} type={"edit"} />;
};

export const ArrowRight = (props) => {
  return <DefaultSvg color={props.color} type={"arrowright"} />;
};

export const ArrowLeft = (props) => {
  return <DefaultSvg color={props.color} type={"arrowleft"} />;
};

export const UpdateIcon = (props) => {
  return <DefaultSvg color={props.color} type={"updateicon"} />;
};

export const CheckIcon = (props) => {
  return <DefaultSvg color={props.color} type={"checkicon"} />;
};

export const CheckList = (props) => {
  return <DefaultSvg color={props.color} type={"checklist"} />;
};

export const CheckCircle = (props) =>{
  return <DefaultSvg color={props.color} type={"checkCircle"}/>
}

export const JobBores = (props) => {
  return <DefaultSvg color={props.color} type={"jobbores"} />;
};

export const SelectClient = (props) => {
  return <DefaultSvg color={props.color} type={"selectClient"} />;
};
export const Folder = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"folder"}
      width={props.width}
      height={props.height}
    />
  );
};

export const File = (props) => {
  return <DefaultSvg color={props.color} type={"file"} />;
};

export const Close = (props) => {
  return <DefaultSvg color={props.color} type={"close"} />;
};

export const MailOpened = (props) => {
  return <DefaultSvg color={props.color} type={"mail-opened"} />;
};

export const EquipmentIos = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"equipmentiOS"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};

export const CrewsIos = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"crewsiOS"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};

export const CrewmemberIos = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"crewmemberiOS"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};
export const JobsIos = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"jobsiOS"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};
export const Materials = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"materials"}
      width={props.width}
      height={props.height}
    />
  );
};
export const Marker = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"marker"}
      width={props.width}
      height={props.height}
    />
  );
};
export const ThickPlus = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"thickplus"}
      width={props.width}
      height={props.height}
    />
  );
};
export const Camera = (props) => {
  return (
    <DefaultSvg
      color={props.color}
      type={"camera"}
      width={props.width}
      height={props.height}
      opacity={props.opacity}
    />
  );
};
export const Calculator = (props) => {
  return <DefaultSvg color={props.color} type={"calculator"} />;
};
export const SoilCondition = (props) => {
  return <DefaultSvg color={props.color} type={"soilConditions"} />;
};
export const LocateTicket = (props) => {
  return <DefaultSvg color={props.color} type={"locateTicket"} />;
};
export const Circle = (props) => {
  return <DefaultSvg color={props.color} type={"circle"} />;
};
export const Check = (props) => {
  return <DefaultSvg color={props.color} type={"check"} />;
};
export const Save = (props) => {
  return <DefaultSvg color={props.color} type={"save"} />;
}
