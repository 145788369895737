import React, { useContext, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AuthContext } from "../../../shared/Auth";

const Header = () => {
  const context = useContext(AuthContext)
  const {currentUser} = useContext(AuthContext);
  const {newNotifications} = useContext(AuthContext);

  const location = useLocation()

  useEffect(() => {
    if (!location.pathname.includes("soilconditions") && !location.pathname.includes("dumpsite"))
      context.setSelectedPlace(null);
  }, [location]);

  return (
    <div id="kt_header" className="header header-fixed">
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          <div className="breadcrumb mt-4" style={{ backgroundColor: "inherit" }}>
            <h6 class="font-weight-bolder mb-0">{currentUser.company.name}</h6>
          </div>        
          <div />
          <div className="topbar">
              <Link to="/notifications" className="topbar-item">
               <div className='notification'>
                  <i className='flaticon2-bell-1 icon-lg text-dark'></i>
                  <span className='badge'>{newNotifications !== 0 && newNotifications}</span>
               </div>
              </Link>
              &nbsp;
            <div className="topbar-item">
              <div className="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{currentUser.userProfile.firstName}</span>
                <span className="symbol symbol-lg-35 symbol-25 symbol-light-success">
                  <span className="symbol-label font-size-h5 font-weight-bold">{currentUser.userProfile.firstName.charAt(0)}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Header;