export enum SubscriptionPlanCode {
    T1_MONTH = "t1-steel-month-14day",
    T1_YEAR = "t1-steel-annual-14day",
    T2_MONTH = "t2-carbide-month-14day",
    T2_YEAR = "t2-carbide-annual-14day",
    T3_MONTH = "t3-diamond-month-14day",
    T3_YEAR = "t3-diamond-annual-14day",
    OLD_PLAN_1 = "15um",
    OLD_PLAN_2 = "15umwt",
    OLD_PLAN_3 = "15um-3days",
    OLD_PLAN_4 = "15um-14day",
}

export function isOldPlan(planCode:string): boolean {
    return planCode == SubscriptionPlanCode.OLD_PLAN_1 ||
        planCode == SubscriptionPlanCode.OLD_PLAN_2 ||
        planCode == SubscriptionPlanCode.OLD_PLAN_3 ||
        planCode == SubscriptionPlanCode.OLD_PLAN_4
}

export class SubscriptionEntity {
    current_period_ends_at?: any | null = null
    current_period_started_at?: any | null = null
    plan?: SubscriptionPlanEntity | null = null
    pending_subscription?: PendingSubscriptionEntity | null = null

}

export class SubscriptionPlanEntity {
    name?: string | null = null
    plan_code?: SubscriptionPlanCode | null = null
}

export class PendingSubscriptionEntity {
    plan?: SubscriptionPlanEntity | null = null
    quantity: number | null = null
    unit_amount_in_cents: number | null = null
}