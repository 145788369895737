import React, { Component } from 'react';
import { Check, Edit, People, ShieldLock, Trash } from '../../components/Icons/Svg';
import loader from '../../components/Layout/Loader/Loader';
import ConfirmModal from '../../components/Modals/ConfirmModal';
import { deleteSoilCondition, getSoilCondition, getSoilConditionsForBore, updateSoilCondition } from '../../shared/Api';
import { AuthContext } from '../../shared/Auth';

class SoilConditionsDetails extends Component {

    static contextType = AuthContext
    state = {
        soilCondition: null,
        loading: false,
        canEdit: false,
        validated: false,
        canValidate: false,
        canTogglePrivate: false,
        updatingSoilConditions: false,
        deleteConditionSelected: false,
        otherSoilConditions: null,
        deletingSoilConditions: false,
    }

    componentDidMount = async () => {
        await this.loadSoilCondition();
    }

    loadSoilCondition = async (externalId) => {

        this.setState({loading: true});
        const id = externalId ? externalId : this.props.match.params.id;
        const currentUser = this.context.currentUser;

        let soilConditionResult = await getSoilCondition(id);
        let soilCondition = soilConditionResult.data;
        if(soilCondition.boreId) {
            let soilConditionsForBore = await getSoilConditionsForBore(soilCondition.boreId);
            let filtered = soilConditionsForBore.data.filter(x=>x.id !== soilCondition.id);
            this.setState({otherSoilConditions: filtered});
        }
        const canTogglePrivate = currentUser.userProfile.companyID === soilCondition.companyId;
        const canEdit = canTogglePrivate && !soilCondition.boreId;
        const validated = soilCondition.validatedBy.some(x => x === currentUser.uid);
        
        const canValidate =  soilCondition.publicEntry &&  soilCondition.companyId !== currentUser.userProfile.companyID;

        let dateCreated = soilCondition.createdAt.toDate().toLocaleString();
        soilCondition.dateCreated = dateCreated;

        this.setState({soilCondition: soilCondition, canEdit: canEdit, loading: false, validated: validated, canValidate: canValidate, canTogglePrivate: canTogglePrivate});
    }

    updateSoilCondition = async () => {
        this.setState({updatingSoilConditions: true})
        const id = this.state.soilCondition.id;

        if(this.state.soilCondition.publicEntry) {
            await updateSoilCondition(id, { publicEntry: false});
        } else {
            await updateSoilCondition(id, { publicEntry: true});
        }
        
        await this.loadSoilCondition();
        this.setState({updatingSoilConditions: false});
    }

    deleteCondit = async () => {
        this.setState({deletingSoilConditions: true})
        const id = this.state.soilCondition.id;
        await deleteSoilCondition(id);
        this.setState({deleteConditionSelected: false});
        this.props.history.push('/soilconditions/');
        
    }

    validate = async () => {
        this.setState({updatingSoilConditions: true})
        const id = this.state.soilCondition.id;
        const currentUser = this.context.currentUser;
        let validations = [...this.state.soilCondition.validatedBy];
        validations.push(currentUser.uid);
        const obj = {
            validatedBy: validations
        }
        const updateResult = await updateSoilCondition (id, obj);
        
        await this.loadSoilCondition();
        this.setState({updatingSoilConditions: false});
    }

    getValidatedString = () => {
         if (this.state.soilCondition.validatedBy.length === 1) {
            return "1 user"
        } else {
            return  this.state.soilCondition.validatedBy.length + " users"
        }
    }

    reloadComponent = async (id) => {
        this.props.history.push('/soilconditions/' + id);
        await this.loadSoilCondition(id);
    }
    
    render(){
        if (this.state.soilCondition) {
            return (
              <>
                <div className="card card-custom">
                    <div className="card-header h-auto py-4">
                        <div className="card-title">
                            <h3 className="card-label">Soil Condition Details</h3>
                        </div>
                        <div className="card-toolbar">
                            {this.state.canValidate && this.state.validated &&
                                <button disabled className={this.state.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <Check />
                                    </span>Validated
                                </button>
                            }
                            {this.state.canValidate && !this.state.validated &&
                                <button onClick={this.validate} className={this.state.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <Check />
                                    </span>Validate
                                </button>
                            }
                            {this.state.canEdit &&
                                <>
                                    <a onClick={() => { this.props.history.push('/soilconditions/' + this.state.soilCondition.id + '/edit') }} className="btn btn-success btn-sm font-weight-bold mr-4">
                                        <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <Edit />
                                        </span>Edit
                                    </a>
                                    <button onClick={() => {this.setState({deleteConditionSelected: true})}} className={this.state.deletingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <Trash />
                                    </span>Delete
                                    </button>
                                </>
                            }
                            {this.state.soilCondition.publicEntry && this.state.canTogglePrivate && 
                                <button onClick={this.updateSoilCondition} className={this.state.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <ShieldLock />
                                    </span>Make Private
                                </button>}
                            {!this.state.soilCondition.publicEntry && this.state.canTogglePrivate &&
                                <button onClick={this.updateSoilCondition} className={this.state.updatingSoilConditions ? "btn btn-primary btn-sm font-weight-bold mr-4 spinner spinner-white spinner-right" : "btn btn-primary btn-sm font-weight-bold mr-4"}>
                                    <span className="svg-icon svg-icon-md svg-icon-gray-500 mr-1">
                                        <People />
                                    </span>Make Public
                                </button>
                            }

                        </div>
                    </div>
                    <div className="card-body py-4">
                        <div className="form-group row my-2">
                            <label className="col-4 col-form-label">Soil condtition:</label>
                            <div className="col-8">
                                <span className="form-control-plaintext font-weight-bolder">{this.state.soilCondition.soilConditions}</span>
                            </div>
                        </div>
                        <div className="form-group row my-2">
                            <label className="col-4 col-form-label">Depth:</label>
                            <div className="col-8">
                                <span className="form-control-plaintext font-weight-bolder">{this.state.soilCondition.depth}</span>
                            </div>
                        </div>
                        <div className="form-group row my-2">
                            <label className="col-4 col-form-label">Location:</label>
                            <div className="col-8">
                                <span className="form-control-plaintext">
                                <span className="font-weight-bolder">{this.state.soilCondition.lat} , {this.state.soilCondition.long} </span>
                                </span>
                            </div>
                        </div>
                        <div className="form-group row my-2">
                            <label className="col-4 col-form-label">Created at:</label>
                            <div className="col-8">
                                <span className="form-control-plaintext font-weight-bolder">{this.state.soilCondition.dateCreated}</span>
                            </div>
                        </div>
                        <div className="form-group row my-2">
                            <label className="col-4 col-form-label">Validated by:</label>
                            <div className="col-8">
                                <span style={{ whiteSpace: "pre-line" }} className="form-control-plaintext font-weight-bolder">{this.getValidatedString()}</span>
                            </div>
                        </div>
                        {this.state.otherSoilConditions && 
                            <div className="card-spacer rounded card-rounded flex-grow-1">
                                <h5>Other soil conditions for this bore</h5>
                                {this.state.otherSoilConditions.map((item, index) => (
                                    <div key = {"brs-lgs" + index}className="row m-0 border rounded mt-10">
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 mb-4">{item.soilConditions}</div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Soil Conditions</div>
                                    </div>
                                    <div className="col px-8 py-6">
                                        <div className="font-size-h6 font-weight-bold mb-4">{item.depth} </div>
                                        <div className="font-size-h6 text-muted font-weight-bolder">Depth</div>
                                    </div>
                                    <div className="col-1 px-8 py-6">
                                        <div className="dropdown dropdown-inline ml-2" data-toggle="tooltip" title="Quick actions" data-placement="left">
                                            <a className="btn btn-hover-light-primary btn-sm btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="ki ki-bold-more-hor" />
                                            </a>
                                            <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
                                                <ul className="navi navi-hover">
                                                    <div className="navi-item">
                                                        <a type='button' className="navi-link" onClick={()=>{this.reloadComponent(item.id)}}>
                                                            <span className="navi-text">
                                                                View Soil Condition Details
                                                            </span>
                                                        </a>
                                                    </div>
                                                </ul>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <ConfirmModal 
                    show={this.state.deleteConditionSelected}
                    title="Delete Soil Condition"
                    body="Are you sure you want to delete this soil condition?"
                    yesButton={{
                        title: "Yes",
                        onClick: () => { this.deleteCondit(); }
                    }}
                    noButton={{
                        title: "Cancel",
                        onClick: () => { this.setState({ deleteConditionSelected: false })}
                    }}
                />
              </>
            )
        } else {
            return loader
        }
    }
}

export default SoilConditionsDetails;