import React, { useContext, useEffect, useState } from "react";
import {
  LocateTicketEntity,
  LocateTicketStatusEntity,
  getLocateTicketCurrentStatus,
} from "../../../shared/data/LocateTickets";
import { Link } from "react-router-dom";
import { parseFromFirebaseDate, showToast, toFirebaseDate } from "../../../shared/Util";
import LocateTicketStatusModal from "../LocateTicketStatusModal";
import {
  deleteLocateTicket,
  saveLocateTicket,
} from "../../../shared/api/LocateTicketsApi";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { Timestamp } from "firebase/firestore";
import { capitalizeFirstLetter } from "../../../shared/Utils";
import { JobEntity } from "../../../shared/data/job/Job";
import { ContextEntity } from "../../../shared/data/context/ContextEntity";
import { AuthContext } from "../../../shared/Auth";

interface LocateTicketItemProps {
  locateTicket: LocateTicketEntity;
  job?: JobEntity | null;
  jobs?: JobEntity[] | null;
  hideOptions: boolean,
  onDelete?: () => void;
}

const LocateTicketItemComponent = ({
  locateTicket,
  job = null,
  jobs = null,
  hideOptions = false,
  onDelete = () => {},
}: LocateTicketItemProps) => {
  const context: ContextEntity = useContext(AuthContext);
  const [formState, setFormState] = useState<any>();

  const [locateTicketState, setLocateTicketState] = useState(locateTicket);

  useEffect(() => {
    setLocateTicketState(locateTicket);
  }, [locateTicket]);

  const submittedDate = locateTicketState.status
    ?.filter((item: LocateTicketStatusEntity) => item.type == "submitted")
    ?.pop()?.date;

  const changeStatus = async (
    status?: LocateTicketStatusEntity | null,
    revisionNumber?: string | null,
    expirationDate?: Timestamp | null
  ) => {
    if (status) {
      locateTicket.status?.push(status);
      locateTicket.revisionNo = revisionNumber;
      locateTicket.expirationDate = expirationDate;
      const result = await saveLocateTicket(locateTicket);
      if (result.data) setLocateTicketState(result.data);
      else showToast("danger", result.message);
    }
  };

  const deleteData = async () => {
    const result = await deleteLocateTicket(locateTicketState.id ?? "");
    if (result.data) onDelete();
    else showToast("danger", result.message);
  };

  const getCurrentStatus = () => {
    const status = getLocateTicketCurrentStatus(locateTicketState)?.type;
    if (status == "submitted" && locateTicketState.revisionNo) {
      return "Re-Submitted";
    } else if (status == "approved") {
      const daysDifference = getDaysUntilExpiration();
      if (daysDifference <= 0) {
        changeStatus(
          new LocateTicketStatusEntity(
            Timestamp.now(),
            locateTicket.expirationDate,
            "expired",
            context.currentUser?.uid,
            context.currentUser?.displayName
          ),
          locateTicket.revisionNo,
          locateTicket.expirationDate
        );
        return "Expired"
      }
      else if (daysDifference == 1) {
        return "1 Day Until Expired";
      } else if (daysDifference <= 3) {
        return `3 Days or Less Until Expired`;
      } else {
        return "Approved";
      }
    } else {
      return capitalizeFirstLetter(status ?? "");
    }
  };

  const getStatusColor = () => {

    const status = getLocateTicketCurrentStatus(locateTicketState)?.type;

    const daysDifference = getDaysUntilExpiration();

    switch (status) {
      case "Ready to submit":
        return "locate-ticket-ready-to-submit";
      case "submitted":
        return "locate-ticket-submitted";
      case "approved":
        if (daysDifference <= 0) return "locate-ticket-expired";
        else if (daysDifference == 1) return "locate-ticket-expiring-in-1-day";
        else if (daysDifference <= 3) return "locate-ticket-expiring-in-3-days";
        else return "locate-ticket-approved";
      case "expired":
        return "locate-ticket-expired";
    }
  };

  const getDaysUntilExpiration = () => {
    var daysDifference = 0;

    if (locateTicketState.expirationDate) {
      const differenceInMilliseconds =
        (locateTicketState.expirationDate?.toDate().getTime() ?? 0) -
        Timestamp.now().toDate().getTime();
      daysDifference = differenceInMilliseconds / (24 * 60 * 60 * 1000);
    }
    return Math.ceil(daysDifference);
  };

  const getLocation = () =>{
    if(locateTicket.address)
      return locateTicket.address
    else
    return `Lat: ${locateTicketState.lat} Lng: ${locateTicketState.lng}`
  }

  return (
    <React.Fragment key={`locate-ticket-itm-${locateTicketState?.id}`}>
      <div className="card card-custom d-flex p-4">
      <div className="d-flex d-flex-row align-items-center mb-1">
        <Link
          to={{
            pathname: jobs!=null ? "/locatetickets/details" : `/jobs/${locateTicketState.jobID}/locatetickets/details`,// "/locatetickets/details",
            state: {
              locateTicket: locateTicketState,
              job: job,
              jobs: jobs
            },
          }}
          href="#"
          className="flex-grow-1"
        >
          <div className="flex-grow-1">
            <h6 className="font-weight-bold text-dark">
              Ticket ID: {locateTicketState?.ticketID}
            </h6>
            {locateTicketState.revisionNo && (
              <h6 className="font-weight-bold text-dark">
                Revision #: {locateTicketState?.revisionNo}
              </h6>
            )}
            {job && (
              <div>
                <h6 className="text-muted font-weight-bold">
                  Job ID: {job.jobID}
                </h6>
                <h6 className="text-muted font-weight-bold">
                  Job Name: {job.title}
                </h6>
              </div>
            )}
            {(locateTicketState.address ||
              (locateTicketState.lat && locateTicketState.lng)) && (
              <h6 className="text-muted font-weight-bold">
                {`Location: ${getLocation()}`}
              </h6>
            )}
            <h6 className="text-muted font-weight-bold">
              Submit Date: {parseFromFirebaseDate(submittedDate)}
            </h6>
            <h6 className="text-muted font-weight-bold">
              Work Begin Date:{" "}
              {parseFromFirebaseDate(locateTicketState.startDate)}
            </h6>
            {locateTicket?.expirationDate && (
              <h6 className="text-muted font-weight-bold">
                Expiration Date:{" "}
                {parseFromFirebaseDate(locateTicketState.expirationDate)}
              </h6>
            )}
            <h6 className={getStatusColor()}>{getCurrentStatus()}</h6>
          </div>
          {/* <div className="separator separator-solid separator-dark my-2"></div> */}
          </Link>
        <div
          className="dropdown dropdown-inline ml-2"
          data-toggle="tooltip"
          title="Quick actions"
          data-placement="left"
        >
          <a
            className="btn btn-hover-light-primary btn-sm btn-icon"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="ki ki-bold-more-hor" />
          </a>
          <div className="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">
            <ul className="navi navi-hover">
              {getLocateTicketCurrentStatus(locateTicketState)?.type !=
                "approved" &&
                jobs == null && (
                  <li className="navi-item">
                    <a
                      href="#"
                      onClick={() => {
                        setFormState((previousState: any) => {
                          return {
                            ...previousState,
                            showChangeStatusModal: true,
                          };
                        });
                      }}
                      className="navi-link"
                    >
                      <span className="navi-text">Change Status</span>
                    </a>
                  </li>
                )}
              <li className="navi-item">
                <Link
                  className="navi-link"
                  to={{
                    pathname: jobs!=null ? "/locatetickets/new" : `/jobs/${locateTicketState.jobID}/locatetickets/new`,
                    state: {
                      locateTicket: locateTicketState,
                      jobs: jobs,
                    },
                  }}
                  href="#"
                >
                  Edit
                </Link>
              </li>
              {(job == null && !hideOptions) && (
                <li className="navi-item">
                  <a
                    href="#"
                    onClick={() => {
                      setFormState((previousState: any) => {
                        return {
                          ...previousState,
                          showConfirmationDialog: true,
                        };
                      });
                    }}
                    className="navi-link"
                  >
                    <span className="navi-text">Delete</span>
                  </a>
                </li>
              )}
              {job != null && (
                <li className="navi-item">
                  <Link
                    className="navi-link"
                    to={{
                      pathname: `jobs/${job.id}/details`,
                    }}
                    href="#"
                  >
                    Job details
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        </div>
      </div>
      <ConfirmModal
        show={formState?.showConfirmationDialog ?? false}
        id={`locate-ticket-item-delete-${locateTicketState.id}`}
        title={"Delete Locate Ticket"}
        body={`Are you sure you want to delete Locate Ticket ${locateTicketState.ticketID}?`}
        yesButton={{
          title: "Yes",
          onClick: () => {
            deleteData();
            setFormState((previousState: any) => {
              return { ...previousState, showConfirmationDialog: null };
            });
          },
          spinner: false,
        }}
        noButton={{
          title: "No",
          onClick: () => {
            setFormState((previousState: any) => {
              return { ...previousState, showConfirmationDialog: null };
            });
          },
        }}
      />
      <LocateTicketStatusModal
        formId={`locate-ticket-change-status-modal-${locateTicketState.id}`}
        show={formState?.showChangeStatusModal ?? false}
        locateTicketStatus={getLocateTicketCurrentStatus(locateTicketState)}
        revisionNumber={locateTicket.revisionNo}
        onConfirm={(status, revisionNumber, expirationDate) => {
          changeStatus(status, revisionNumber, expirationDate);
          setFormState((previousState: any) => {
            return { ...previousState, showChangeStatusModal: false };
          });
        }}
        onCancel={() => {
          setFormState((previousState: any) => {
            return { ...previousState, showChangeStatusModal: false };
          });
        }}
      />
    </React.Fragment>
  );
};

export default LocateTicketItemComponent;
