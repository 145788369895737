import React from "react";
import MultipleItems from "../../Carousels/MultipleItems";
import Dropzone from "../../Dropzone/Dropzone";

const MultiImageInput = (props) => {
  const images = props.field.value; //.map(x=>x.localSrc)

  return (
    <React.Fragment>
      {props.field.dataToggle && (
        <a
          id={props.id}
          data-toggle={props.field.dataToggle}
          title={props.field.title}
          data-placement={props.field.dataPlacement}
        />
      )}
      <Dropzone {...props} />
      {props?.field?.uploadingImage && (
        <div className="my-2">Uploading image</div>
      )}
      <div className="mt-5">
        <MultipleItems
          slidesToShow={9}
          laptop={7}
          tablet={5}
          mobile={2}
          images={images}
          editFile={props.field.editFile}
          removePhoto={props.field.onRemove}
          onNameChanged={props.field.onNameChanged}
          setSelectedImage={props.field.setSelectedImage}
        />
      </div>
    </React.Fragment>
  );
};

export default MultiImageInput;
