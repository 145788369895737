import { Timestamp } from "firebase/firestore";
import React from "react";
import { parseFromFirebaseDate } from "../../../shared/Util";
import moment from "moment";
import WorkTimeEntity, {
  DailyProductionCheckInEntity,
} from "../../../shared/data/job/Job";
import ReportOptions from "./ReportOptions";

interface DailyCheckInModalProps {
  dailyReport: DailyProductionCheckInEntity;
  sending:boolean
  onReportChanged?: (report: DailyProductionCheckInEntity) => void;
  onConfirm?: () => void;
  onWorkTimeSelected?: (
    workTime: WorkTimeEntity
  ) => void;
  onAddExternalWorkTime?:()=>void;
}

const DailyCheckInComponent = ({
  dailyReport,
  sending,
  onReportChanged = () => {},
  onConfirm = () => {},
  onWorkTimeSelected = () => {},
  onAddExternalWorkTime: onAddExternal = () => {}
}: DailyCheckInModalProps) => {
  const isCheckInTimeSet = () => {
    return dailyReport.day && dailyReport.startTime && dailyReport.endTime;
  };
  const isReportValid = () => {
    return dailyReport?.day && dailyReport?.startTime && dailyReport?.endTime;
  };
  return (
    <React.Fragment>
      <div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Daily Production Check-in
              </h5>
            </div>
            <div className="modal-body">
              <div>
                <label className="mr-10">What day is this check-in for?</label>
                <input
                  className=" mr-10 form-control"
                  type="date"
                  value={
                    moment(dailyReport.day, "MM/DD/YY").format("YYYY-MM-DD") ??
                    parseFromFirebaseDate(Timestamp.now(), "YYYY-MM-DD")
                  }
                  onChange={(input) => {
                    const data: DailyProductionCheckInEntity = {
                      ...dailyReport,
                      day: moment(input.target.value).format("MM/DD/YY"),
                    };
                    onReportChanged(data);
                  }}
                ></input>
              </div>
              <div className="mt-5">
                <label className="mr-10">
                  What time did the crews arrive at the job?
                </label>
                <input
                  className=" mr-10 form-control"
                  type="time"
                  value={dailyReport?.startTime ?? undefined}
                  onChange={(input) => {
                    const data: DailyProductionCheckInEntity = {
                      ...dailyReport,
                      startTime: input.target.value,
                    };
                    onReportChanged(data);
                  }}
                ></input>
              </div>
              <div className="mt-5">
                <label className="mr-10">
                  What time did the crews leave the job?
                </label>
                <input
                  className=" mr-10 form-control"
                  type="time"
                  step="300"
                  value={dailyReport?.endTime ?? undefined}
                  onChange={(input) => {
                    const data: DailyProductionCheckInEntity = {
                      ...dailyReport,
                      endTime: input.target.value,
                    };
                    onReportChanged(data);
                  }}
                ></input>
              </div>
              {isCheckInTimeSet() && (
                <div>
                  <div className="mt-5">
                    <label className="mr-10">
                      What accomplishments did your crew encounter on the job
                      today in addition to the production listed
                      below?(optional)
                    </label>
                    <input
                      className="mr-10 form-control"
                      type="text"
                      value={dailyReport?.accomplishments ?? undefined}
                      onChange={(input) => {
                        const data: DailyProductionCheckInEntity = {
                          ...dailyReport,
                          accomplishments: input.target.value,
                        };
                        onReportChanged(data);
                      }}
                    ></input>
                  </div>
                  <h5 className="mt-5 mr-10">Adjust work times</h5>
                  <label className="font-italic">
                    Click on crewmember to update worktimes
                  </label>
                  <div className="mt-2 card card-stretch p-2">
                    {dailyReport.workTimes.map((workTime) => {
                      return (
                        <div
                          className="card card-custom d-flex flex-column pb-1"
                          onClick={() => {
                            onWorkTimeSelected(workTime);
                          }}
                        >
                          <div>{` ${workTime.isExternal ? "*" : ""} ${
                            workTime.firstName
                          } ${workTime.lastName}`}</div>
                          {workTime.notOnJob ?<div>
                            <div className="font-weight-bolder font-italic">Not On Job</div>
                          </div> :
                            <div>
                              <div>{`Work time: ${workTime.startTime} - ${workTime.endTime}`}</div>
                              <div>{`Travel time: ${workTime.travelTime}`}</div>
                            </div>
                          }
                        </div>
                      );
                    })}

                    <button
                      onClick={() => {
                        onAddExternal();
                      }}
                      type="button"
                      className={"btn btn-primary font-weight-bold"}
                      data-dismiss="modal"
                    >
                      Add external
                    </button>
                  </div>
                  <div className="mt-5">
                    <label className="mr-10">
                      What hurdles did your crew encounter on the job
                      today?(optional)
                    </label>
                    <input
                      className=" mr-10 form-control"
                      type="text"
                      value={dailyReport?.hurdles ?? undefined}
                      onChange={(input) => {
                        const data: DailyProductionCheckInEntity = {
                          ...dailyReport,
                          hurdles: input.target.value,
                        };
                        onReportChanged(data);
                      }}
                    ></input>
                  </div>
                  <div className="mt-5">
                    <label className="mr-10">
                      Any other notes about tomorrow?(optional)
                    </label>
                    <input
                      className=" mr-10 form-control"
                      type="text"
                      value={dailyReport?.notes ?? undefined}
                      onChange={(input) => {
                        const data: DailyProductionCheckInEntity = {
                          ...dailyReport,
                          notes: input.target.value,
                        };
                        onReportChanged(data);
                      }}
                    ></input>
                  </div>
                  <ReportOptions onOptionSelected={(value)=>{
                    const data: DailyProductionCheckInEntity = {
                      ...dailyReport,
                      pdfExcel: value,
                    };
                    onReportChanged(data);
                  }}/>

                  <div className="d-flex align-items-left mt-5">
                    <label className="checkbox checkbox-lg checkbox-light-primary checkbox-inline flex-shrink-0 m-0">
                      <input
                        onChange={(value) => {
                          const data: DailyProductionCheckInEntity = {
                            ...dailyReport,
                            saveFile: value.target.checked,
                          };
                          onReportChanged(data);
                        }}
                        type="checkbox"
                        defaultValue={1}
                        checked={dailyReport.saveFile}
                      />
                      <span />
                    </label>
                    <div className="d-flex flex-column flex-grow-1 font-weight-bold ml-5">
                      <a className="text-dark text-hover-primary mb-1 font-size-lg">
                        Save this report to Job Documents
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                disabled={!isReportValid()}
                onClick={() => {
                  onConfirm();
                }}
                type="button"
                className={
                  sending
                    ? "btn btn-primary font-weight-bold spinner spinner-white spinner-right"
                    : "btn btn-primary font-weight-bold"
                }
                data-dismiss="modal"
              >
                Create report
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DailyCheckInComponent;
