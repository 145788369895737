import { db } from "../../firebase"
import { ResponseEntity } from "../data/Response"
import { TaskTypeEntity } from "../data/taskmanagement/Task"
import { FirebaseInventoriesCollections, FirebaseRootCollections, FirebaseSettingsDocuments, FirebaseSettingsTaskCollections } from "../firebase/FirebaseSchema"

export const getTaskTypes = async () => {
    try {
        const result = await db.collection(FirebaseRootCollections.SETTINGS)
            .doc(FirebaseSettingsDocuments.TASK)
            .collection(FirebaseSettingsTaskCollections.TASK_TYPE)
            .get()

        const taskTypes = result.docs.map((document) => {
            return new TaskTypeEntity(document.id, document.data().name)
        }).filter((taskType) => taskType.name)

        return new ResponseEntity<TaskTypeEntity[]>(taskTypes, "")
    } catch (error: any) {
        return new ResponseEntity<TaskTypeEntity[]>([], error)
    }
}

export const getCompanyTaskTypes = async (inventoryId: string) => {
    try {
        const result = await db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.CUSTOM_TASK_TYPES)
            .get()


        const taskTypes = result.docs.map((document) => {
            return new TaskTypeEntity(document.id, document.data().name, true)
        }).filter((taskType) => taskType.name)

        return new ResponseEntity<TaskTypeEntity[]>(taskTypes, "")
    } catch (error: any) {
        return new ResponseEntity<TaskTypeEntity[]>([], error)
    }
}

export const saveCompanyTaskType = async (inventoryId: string, taskName: string) => {
    try {
        const taskTypeAlreadyExists = (await getJobTaskTypes(inventoryId)).data?.some((taskType) => { return taskType.name.toLowerCase() === taskName.toLowerCase() })
        if (!taskTypeAlreadyExists) {
            await db.collection(FirebaseRootCollections.INVENTORIES)
                .doc(inventoryId)
                .collection(FirebaseInventoriesCollections.CUSTOM_TASK_TYPES)
                .add({ name: taskName })
        }
        return new ResponseEntity<TaskTypeEntity>(null, "")

    } catch (error: any) {
        return new ResponseEntity<TaskTypeEntity>(null, error)
    }
}

export const getJobTaskTypes = async (inventoryId: string) => {
    try {
        const taskTypes = ((await getTaskTypes()).data ?? []).sort((a, b)=>a.name.localeCompare(b.name))
        const customTaskTypes = ((await getCompanyTaskTypes(inventoryId)).data ?? []).sort((a, b)=>a.name.localeCompare(b.name))

        return new ResponseEntity<TaskTypeEntity[]>([...customTaskTypes, ...taskTypes], "")
    } catch (error: any) {
        return new ResponseEntity<TaskTypeEntity[]>([], error)
    }
}