import { db } from "../../firebase"
import { ResponseEntity } from "../data/Response"
import { EquipmentEntity, EquipmentReservationEntity } from "../data/equipment/Equipment"
import { FirebaseInventoriesCollections, FirebaseRootCollections } from "../firebase/FirebaseSchema"

export const getAllEquipment = async (inventoryId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.EQUIPMENT)
            .get()

        const equipment: EquipmentEntity[] = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<EquipmentEntity[]>(equipment, "")
    } catch (error: any) {
        return new ResponseEntity<EquipmentEntity[]>(null, error)
    }
}

export const getJobAssignedEquipment = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.RESERVATIONS)
            .where("jobID", "==", jobId)
            .get()

        const equipment = await getAllEquipment(inventoryId)
        const equipmentReservations = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        const jobAssignedEquipment = equipment?.data?.filter((equipment) => 
        equipmentReservations.some((result: EquipmentReservationEntity) => result.equipmentID == equipment.id))

        return new ResponseEntity<EquipmentEntity[]>(jobAssignedEquipment, "")
    } catch (error: any) {
        return new ResponseEntity<EquipmentEntity[]>(null, error)
    }
}

export const getEquipmentSchedules = async (inventoryId: string, jobId: string) => {
    try {
        const result = await db
            .collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.RESERVATIONS)
            .where("jobID", "==", jobId)
            .get()

        const equipmentReservations = result.docs.map((doc: any) => {
            return { ...doc.data(), id: doc.id }
        })

        return new ResponseEntity<EquipmentReservationEntity[]>(equipmentReservations, "")
    } catch (error: any) {
        return new ResponseEntity<EquipmentReservationEntity[]>(null, error)
    }
}

export const saveEquipmentSchedules = async (inventoryId: string, equipmentReservation: EquipmentReservationEntity) => {
    try {
        const reservationsCollection = db.collection(FirebaseRootCollections.INVENTORIES)
            .doc(inventoryId)
            .collection(FirebaseInventoriesCollections.RESERVATIONS)

        const { id, ...equipmentReservationWithoutId } = equipmentReservation
        if (!equipmentReservation.id) {
            const response = await reservationsCollection.add(equipmentReservation)
            equipmentReservation.id = response.id
        } else {
            const response = await reservationsCollection.doc(equipmentReservation.id).update(equipmentReservationWithoutId)
        }
        return new ResponseEntity<EquipmentReservationEntity>(equipmentReservation, "")
    } catch (error: any) {
        return new ResponseEntity<EquipmentReservationEntity>(equipmentReservation, error)
    }
}
